import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useIntl } from "react-intl";
import ModalData from "./modal-data/ModalData";
import { generateData } from "./modal-data/modalDataGenerator";

const TenderInfoModalDetail = (props) => {
  const { tenderInfoDetailModal, hideTenderInfoDetailModal } = useGlobalStore(
    (state) => ({
      tenderInfoDetailModal: state.tenderInfoDetailModal,
      hideTenderInfoDetailModal: state.hideTenderInfoDetailModal,
    }),
  );
  const intl = useIntl();

  const handleClose = () => {
    hideTenderInfoDetailModal();
  };

  if (!tenderInfoDetailModal.open) return null;

  return (
    <GeneralPurposeModal
      open={tenderInfoDetailModal.open}
      onClose={handleClose}
      title={tenderInfoDetailModal.title}>
      <ModalData
        data={generateData(
          tenderInfoDetailModal.data.detail,
          tenderInfoDetailModal.data,
          intl,
        )}
        modalName={tenderInfoDetailModal.data.detail}
      />
    </GeneralPurposeModal>
  );
};

export default TenderInfoModalDetail;
