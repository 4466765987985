import { CallSplit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { getProducts } from "pages/TenderInformationContent/tenderInformationContentService";
import {
  formatCurrencyLocale,
  formatValueLocale,
  isAwardedContract,
  isLangCL,
  isLangCO,
  isLangCR,
  isLangMX,
  isLangPE,
  isLangEC,
} from "utils/common";

const managedItemsListHeader = (intl, modalityType, tags) => [
  {
    accessorKey: "itemNumber",
    header: intl.formatMessage({
      id: "tabL.Nro",
      defaultMessage: "#",
    }),
    size: "90",
    enableSorting: false,
    enableEditing: false,
  },
  {
    accessorKey: "description",
    header: intl.formatMessage({
      id: "tabL.Descripcion",
      defaultMessage: "Descripción",
    }),
    size: "150",
    enableEditing: false,
    enableSorting: false,
  },
  {
    accessorKey: "code",
    header: intl.formatMessage({
      id: "tabL.Codigo_Cubso",
      defaultMessage: "Código CUBSO",
    }),
    enableEditing: false,
    size: "170",
    enableSorting: false,
  },
  {
    accessorKey: "qty",
    header: intl.formatMessage({
      id: "tabL.Cantidad",
      defaultMessage: "Cantidad",
    }),
    enableEditing: false,
    size: "100",
    align: "center",
    enableSorting: false,
    Cell: ({ cell, column }) => {
      return formatValueLocale(cell.getValue()) ?? "";
    },
  },
  {
    accessorKey: "uom",
    header: intl.formatMessage({
      id: "tabL.Unidad",
      defaultMessage: "Unidad de Medida",
    }),
    enableEditing: false,
    size: "140",
    align: "center",
    enableSorting: false,
  },
  {
    accessorKey: "isPrimary",
    header: "Alternativo",
    enableEditing: false,
    size: "130",
    align: "center",
    enableSorting: false,
    disableTooltip: true,
    Cell: ({ cell, column }) =>
      cell.getValue() ? null : (
        <Tooltip
          title={"Ítem alternativo"}
          placement="top"
          disableInteractive
          arrow
          PopperProps={{ disablePortal: true }}>
          <CallSplit
            sx={{
              color: "green",
              fontSize: "1.5rem",
              verticalAlign: "middle",
            }}
          />
        </Tooltip>
      ),
  },
  {
    accessorKey: "estimatedTotal",
    header: intl.formatMessage({
      id: "tabL.Estimado",
      defaultMessage: "Precio Unitario",
    }),
    size: "130",
    align: "right",
    enableSorting: false,
    Cell: ({ cell, column }) =>
      formatCurrencyLocale(
        cell.getValue(),
        null,
        cell.row.original.estimatedValueCurrency,
      ),
  },
  ...(isLangPE()
    ? [
        {
          accessorKey: "estimatedValueTotal",
          header: "Precio Total",
          size: "130",
          align: "right",
          enableSorting: false,
          Cell: ({ cell, column }) =>
            formatCurrencyLocale(
              cell.row.original.estimatedTotal
                ? cell.row.original.estimatedTotal * cell.row.original.qty
                : null,
              null,
              cell.row.original.estimatedValueCurrency,
            ),
        },
      ]
    : []),
  {
    accessorKey: "offered",
    header: "Cotizado",
    align: "center",
    cellType: "checkbox",
    editType: "checkbox",

    size: "100",
    enableSorting: false,
  },
  {
    accessorKey: "product", //FullDescription
    header: "Producto",
    size: "250",
    align: "center",
    enableSorting: false,
    Cell: ({ cell, column }) => {
      const row = cell.getValue();
      if (!row || (!row.productCode && !row.productDesc)) return "";
      let renderString = `(${row.productCode}) - ${row.productDesc}${
        row.data ? ` - ${Object.values(JSON.parse(row.data)).join(" - ")}` : ""
      }`;
      return renderString;
    },
    editType: "autocomplete-paging",
    // editOptions: products,
    editOptionsLabel: (option) => {
      if (!option || (!option.productCode && !option.productDesc)) return "";
      return `(${option.productCode}) - ${option.productDesc}${
        option.data
          ? ` - ${Object.values(JSON.parse(option.data)).join(" - ")}`
          : ""
      }`;
    },
    editOptionsLoadCallback: (request) => {
      return getProducts(request);
    },
  },

  {
    accessorKey: "tagIds",
    header: "Etiquetas",
    size: "200",
    Cell: ({ cell, column }) =>
      cell
        .getValue()
        .map((ti) => tags.find((t) => t.id === ti)?.name)
        .join(", "),
    editType: "multiselect",
    editOptions: tags,
  },
  {
    accessorKey: "comments",
    header: "Comentario",
    size: "200",

    enableSorting: false,
  },
  ...(isLangCR() ||
  isLangPE() ||
  isLangCL() ||
  isLangCO() ||
  isLangMX() ||
  isLangEC() // TODO: make intlHeaderIds for table
    ? [
        {
          accessorKey: "deliveryDate",
          header: intl.formatMessage({
            id: "tabL.FechaEntrega",
            defaultMessage: "Fecha de entrega",
          }),
          align: "center",
          cellType: "date",
          enableEditing:
            isLangPE() ||
            isLangCL() ||
            isLangCO() ||
            isLangMX() ||
            isLangEC() ||
            (isLangCR() && isAwardedContract(modalityType)),
          editType: "date",
        },
      ]
    : []),
];

export default managedItemsListHeader;
