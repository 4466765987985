import { Fragment, useState } from "react";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { TextField } from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";

const Comments = ({
  managingTenderData,
  handleManagingTenderData,
  tenderManageData,
}) => {
  const [addCommentModalOpen, setAddCommentModalOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const { height, userInfo, setShouldRefetch } = useGlobalStore((state) => ({
    height: state.height,
    userInfo: state.userInfo,
    setShouldRefetch: state.setShouldRefetch,
  }));

  const handleCloseCommentModal = () => {
    setNewComment("");
    setAddCommentModalOpen(false);
  };

  const handleSave = () => {
    const comment = {
      comment: newComment,
      updatedByUserName: userInfo.fullName,
      manageId: tenderManageData.manageId,
    };
    let newManageComments = [...managingTenderData.manageComments, comment];
    handleManagingTenderData({
      ...managingTenderData,
      manageComments: newManageComments,
    });
    handleCloseCommentModal();
    setShouldRefetch();
  };

  const fetchData = async (pageNumber, pageSize, filter, sorting) => {
    return {
      result: managingTenderData.manageComments,
      total: managingTenderData.manageComments.length,
    };
  };

  const columns = [
    {
      accessorKey: "comment",
      header: "Comentario",
      size: "300",
    },
    {
      accessorKey: "updatedByUserName",
      header: "Usuario",
      size: "100",
    },
    {
      accessorKey: "lastUpdate",
      header: "Fecha",
      size: "105",
      align: "center",
      cellType: "datetime",
    },
  ];

  const options = {
    tableId: "gridManagementComments",
    header: columns,
    height: height - 217, // Aribitrary height to fit exactly the content
    tableHeaderTitle: "Comentarios",
    toolbarButtons: {
      Add: true,
      AddFunc: () => setAddCommentModalOpen(true),
    },
  };

  return (
    <Fragment>
      <GeneralPurposeModal
        open={addCommentModalOpen}
        onClose={handleCloseCommentModal}
        closeText="Cancelar"
        title="Agregar Comentario"
        maxWidth="xs"
        onSave={handleSave}
        onSaveDisabled={!newComment}>
        <TextField
          variant="outlined"
          id="comments"
          label="Comentario"
          value={newComment}
          autoComplete="off"
          fullWidth
          multiline
          rows={6}
          onChange={(event) => {
            const { value } = event.target;
            setNewComment(value);
          }}
        />
      </GeneralPurposeModal>
      <FSTMaterialReactTable options={options} getData={fetchData} />
    </Fragment>
  );
};

export default Comments;
