import { Logger, LogLevel, UserAgentApplication } from "msal";
import { IS_DEVELOPMENT } from "../utils/common";

const debugSystemConfig = {
  logger: new Logger((logLevel, message) => {}, {
    level: LogLevel.Verbose,
    piiLoggingEnabled: false,
    correlationId: "1234",
  }),
};
const systemConfig = IS_DEVELOPMENT ? debugSystemConfig : {};
const msalInstance = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    redirectUri: `${window.location.origin}/`,
    postLogoutRedirectUri: `${window.location.origin}/`,
  },
  cache: { cacheLocation: "localStorage", storeAuthStateInCookie: false },
  system: systemConfig,
  navigateToLoginRequestUrl: false,
});

msalInstance.handleRedirectCallback((error, response) => {});

const loginRequest = { scopes: ["user.read"], prompt: "select_account" };

export const login = async (callback) => {
  try {
    await msalInstance.loginRedirect(loginRequest);
    !!callback && callback(null, true);
  } catch (error) {
    !!callback && callback(error, false);
  }
};

export const selectAccount = async () => {
  await msalInstance.loginRedirect({
    scopes: ["user.read"],
    prompt: "select_account",
  });
};

export const isMsalUserLoggedIn = () => {
  const account = msalInstance.getAccount();
  return !!account;
};

export const acquireToken = async () => {
  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_MSAL_CLIENT_ID],
    });
    if (!!response) {
      return { token: response, error: null };
    }
  } catch (error) {
    return { token: null, error };
  }
};

export const logout = (userInfo, appInsights, callback) => {
  if (userInfo && appInsights) {
    appInsights.trackEvent({
      name: "User Logout",
      properties: {
        userId: userInfo.userId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        fullName: userInfo.fullName,
        companyName: userInfo.companyName,
        roleId: userInfo.roleId,
        roleName: userInfo.roleName,
        language: userInfo.language,
        url: window.location.href,
      },
    });
  }
  sessionStorage.clear();
  localStorage.clear();
  msalInstance.logout();
  // if callback is a function, then call it
  if (!!callback && typeof callback === "function") callback();
};
