import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@mui/material";
import svgImage8 from "../../assets/images/illustrations/505.svg";

export default function ErrorComponent() {
  const handleReload = () => {
    // Reload and go to "/""
    window.location.href = "/";
  };

  return (
    <Fragment>
      <div className="app-wrapper bg-secondary">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 mx-auto text-center text-black">
                        <img
                          src={svgImage8}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h1 className="display-1 mb-3 px-4 font-weight-bold">
                          Ha ocurrido un error inesperado
                        </h1>
                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          Por favor refresque el sitio.
                        </h3>
                        <p>Si el problema persiste, contacte a soporte.</p>
                        <Button
                          onClick={handleReload}
                          size="large"
                          color="primary"
                          variant="contained"
                          className="text-white mt-4"
                          sx={{ borderRadius: 2 }}>
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                          </span>
                          <span className="btn-wrapper--label">
                            Volver al Inicio
                          </span>
                        </Button>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
