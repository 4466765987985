import React from "react";
import { connect } from "react-redux";
import { Switch } from "@mui/material";

import clsx from "clsx";

import { Tooltip, Box, Button } from "@mui/material";
import Fab from "@mui/material/Fab";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Drawer from "@mui/material/Drawer";

import {
  setSidebarFixed,
  setSidebarShadow,
  setSidebarUserbox,
  setSidebarFooter,
  setContentBackground,
  setFooterFixed,
  setFooterShadow,
} from "../../reducers/ThemeOptions";

import PerfectScrollbar from "react-perfect-scrollbar";

const ThemeConfigurator = (props) => {
  const [state, setState] = React.useState({
    right: false,
  });

  const {
    sidebarFixed,
    setSidebarFixed,
    sidebarUserbox,
    setSidebarUserbox,
    sidebarFooter,
    setSidebarFooter,
    sidebarShadow,
    setSidebarShadow,
    contentBackground,
    setContentBackground,

    footerFixed,
    setFooterFixed,
    footerShadow,
    setFooterShadow,
  } = props;

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  // Sidebar

  const toggleSidebarFixed = () => {
    setSidebarFixed(!sidebarFixed);
  };
  const toggleSidebarShadow = () => {
    setSidebarShadow(!sidebarShadow);
  };

  const toggleSidebarUserbox = () => {
    setSidebarUserbox(!sidebarUserbox);
  };
  const toggleSidebarFooter = () => {
    setSidebarFooter(!sidebarFooter);
  };

  // Footer

  const toggleFooterFixed = () => {
    setFooterFixed(!footerFixed);
  };
  const toggleFooterShadow = () => {
    setFooterShadow(!footerShadow);
  };

  return (
    <Box sx={{ display: { xs: "none", lg: "block" } }}>
      <Box className="theme-config-wrapper" component="div">
        <Fab
          size="large"
          color="primary"
          onClick={toggleDrawer("right", true)}
          className="configurator-btn">
          <SettingsTwoToneIcon />
        </Fab>
        <Drawer
          ModalProps={{
            BackdropProps: {
              classes: {
                root: "drawer-backdrop",
              },
            },
          }}
          variant="temporary"
          anchor="right"
          open={state.right}
          className="drawer-wrapper-right"
          onClose={toggleDrawer("right", false)}>
          <Box p={0} className="theme-config-content">
            <PerfectScrollbar>
              <Tooltip title="Close theme configurator" placement="left">
                <Fab
                  color="secondary"
                  className="drawer-close-btn"
                  onClick={toggleDrawer("right", false)}>
                  <CloseTwoToneIcon />
                </Fab>
              </Tooltip>

              <h4 className="theme-configurator--heading">Sidebar</h4>
              <ul className="theme-configurator--list">
                <li>
                  <Switch
                    className="switch-small toggle-switch-line toggle-switch-success"
                    onChange={toggleSidebarFixed}
                    checked={props.sidebarFixed}
                  />
                  <div className="theme-configurator--list__heading">
                    Fixed sidebar
                  </div>
                </li>
                <li>
                  <Switch
                    className="switch-small toggle-switch-line toggle-switch-success"
                    onChange={toggleSidebarShadow}
                    checked={props.sidebarShadow}
                  />
                  <div className="theme-configurator--list__heading">
                    Sidebar shadow
                  </div>
                </li>
                <li>
                  <Switch
                    className="switch-small toggle-switch-line toggle-switch-success"
                    onChange={toggleSidebarFooter}
                    checked={props.sidebarFooter}
                  />
                  <div className="theme-configurator--list__heading">
                    Sidebar footer
                  </div>
                </li>
                <li>
                  <Switch
                    className="switch-small toggle-switch-line toggle-switch-success"
                    onChange={toggleSidebarUserbox}
                    checked={props.sidebarUserbox}
                  />
                  <div className="theme-configurator--list__heading">
                    Sidebar userbox
                  </div>
                </li>
              </ul>
              <h4 className="theme-configurator--heading">Main content</h4>

              <ul className="theme-configurator--list">
                <li className="d-block py-3 px-2">
                  <div>
                    <div className="rounded-sm pt-2 text-center">
                      <h5 className="font-size-lg font-weight-bold mb-2">
                        Color schemes
                      </h5>
                      <div className="theme-configurator--swatches">
                        <div
                          onClick={() => {
                            setContentBackground("");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-primary",
                            {
                              active:
                                contentBackground === "bg-neutral-primary",
                            },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-gray-100");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-gray-100",
                            { active: contentBackground === "bg-gray-100" },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-gray-200");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-gray-200",
                            { active: contentBackground === "bg-gray-200" },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-gray-300");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-gray-300",
                            { active: contentBackground === "bg-gray-300" },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-gray-400");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-gray-400",
                            { active: contentBackground === "bg-gray-400" },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-gray-500");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-gray-500",
                            { active: contentBackground === "bg-gray-500" },
                          )}
                        />
                        <div className="divider my-2" />
                        <div
                          onClick={() => {
                            setContentBackground("bg-neutral-primary");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-primary",
                            {
                              active:
                                contentBackground === "bg-neutral-primary",
                            },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-neutral-secondary");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-secondary",
                            {
                              active:
                                contentBackground === "bg-neutral-secondary",
                            },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-neutral-success");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-success",
                            {
                              active:
                                contentBackground === "bg-neutral-success",
                            },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-neutral-info");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-info",
                            { active: contentBackground === "bg-neutral-info" },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-neutral-warning");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-warning",
                            {
                              active:
                                contentBackground === "bg-neutral-warning",
                            },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-neutral-danger");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-danger",
                            {
                              active: contentBackground === "bg-neutral-danger",
                            },
                          )}
                        />
                        <div
                          onClick={() => {
                            setContentBackground("bg-neutral-dark");
                          }}
                          className={clsx(
                            "theme-config-swatch theme-config-swatch--lg bg-neutral-dark",
                            { active: contentBackground === "bg-neutral-dark" },
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <h4 className="theme-configurator--heading">Footer</h4>
              <ul className="theme-configurator--list">
                <li>
                  <Switch
                    className="switch-small toggle-switch-line toggle-switch-success"
                    onChange={toggleFooterFixed}
                    checked={props.footerFixed}
                  />
                  <div className="theme-configurator--list__heading">
                    Fixed footer
                  </div>
                </li>
                <li>
                  <Switch
                    className="switch-small toggle-switch-line toggle-switch-success"
                    onChange={toggleFooterShadow}
                    checked={props.footerShadow}
                  />
                  <div className="theme-configurator--list__heading">
                    Footer shadow
                  </div>
                </li>
              </ul>
            </PerfectScrollbar>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarShadow: state.ThemeOptions.sidebarShadow,

  contentBackground: state.ThemeOptions.contentBackground,

  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarFixed: (enable) => dispatch(setSidebarFixed(enable)),
  setSidebarUserbox: (enable) => dispatch(setSidebarUserbox(enable)),
  setSidebarFooter: (enable) => dispatch(setSidebarFooter(enable)),
  setSidebarShadow: (enable) => dispatch(setSidebarShadow(enable)),

  setContentBackground: (color) => dispatch(setContentBackground(color)),

  setFooterFixed: (enable) => dispatch(setFooterFixed(enable)),
  setFooterShadow: (enable) => dispatch(setFooterShadow(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator);
