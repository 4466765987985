import { FilterAlt } from "@mui/icons-material";
import { PageTitle } from "layout-components/index";
import { Fragment } from "react";
import AnalysisFiltersAdmin from "./AnalysisFiltersAdmin";
import {
  PURCHASE_ORDER_ANALYSIS_MODULE_ID,
  TENDER_ANALYSIS_MODULE_ID,
} from "utils/constants";

const AnalysisFilters = ({ analysisModuleId }) => {
  const getTitleDescription = () => {
    switch (analysisModuleId) {
      case TENDER_ANALYSIS_MODULE_ID:
        return "Licitaciones";
      case PURCHASE_ORDER_ANALYSIS_MODULE_ID:
        return "Órdenes de Compra";
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Filtros de Visualización de Reportes"
        titleDescription={`Administración de filtros de ${getTitleDescription()}.`}
        icon={<FilterAlt />}
      />
      <AnalysisFiltersAdmin analysisModuleId={analysisModuleId} />
    </Fragment>
  );
};

export default AnalysisFilters;
