import clsx from "clsx";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";

import { connect } from "react-redux";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import CustomSnackbar from "components/Snackbars/CustomSnackbar";
import { useGlobalStore } from "global-store/useGlobalStore";
import { LangContext } from "intl/context/langContext";
import TenderInfoModalDetail from "pages/TenderInformationContent/InfoContent/utils-info/TenderInfoModalDetail";
import { ADMIN_GENERAL_ROLE_ID, ADMIN_ROLE_ID } from "utils/constants";
import useDynamicHeight from "utils/hooks/useDynamicHeight";
import { Header, Sidebar } from "../../layout-components";

//TODO: when sidebar is opened in small window and then user clicks a page, it should close the sidebar
const LeftSidebar = (props) => {
  const { children, sidebarToggle, contentBackground, userInfo } = props;

  const langChange = useContext(LangContext);
  const height = useDynamicHeight(); // Just used to update the height of the page

  const { setUserInfo } = useGlobalStore((state) => ({
    setUserInfo: state.setUserInfo,
  }));

  useEffect(() => {
    langChange.setLanguage(userInfo.language);
    setUserInfo(userInfo);
  }, []);

  return (
    <>
      <LoadingSpinner>
        <main className={clsx("app-wrapper", contentBackground)}>
          <Header userInfo={userInfo} />
          <div className="app-main">
            <Sidebar userInfo={userInfo} />
            <div
              className={clsx("app-content", "app-content-sidebar-fixed", {
                "app-content-sidebar-collapsed": sidebarToggle,
              })}>
              <div
                className="app-content--inner bg-gray-200"
                //style={{ backgroundColor: '#fcfcfc' }}
              >
                <div className="app-content--inner__wrapper">{children}</div>
              </div>
            </div>
          </div>
          <CustomSnackbar />
          <TenderInfoModalDetail />
        </main>
      </LoadingSpinner>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node,
  sidebarToggle: PropTypes.bool,
  contentBackground: PropTypes.string,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  const { user } = state;
  const {
    companyName,
    fullName,
    loginPending,
    roleId,
    language,
    manageDefaultTab,
  } = user;
  const userModules = user.applicationModules;
  const {
    appVersion,
    applicationModules,
    currencyList,
    legalDocumentWTStatus,
    tendersStatus,
  } = app;
  let currencyDefault = currencyList?.filter((item) => item.default);
  currencyDefault = currencyDefault?.length > 0 ? currencyDefault[0] : null;

  const isAdmin = roleId === ADMIN_ROLE_ID || roleId === ADMIN_GENERAL_ROLE_ID;
  const isAdminGeneral = roleId === ADMIN_GENERAL_ROLE_ID;
  return {
    ...ownProps,
    userInfo: {
      companyName,
      fullName,
      loginPending,
      roleId,
      isAdmin,
      isAdminGeneral,
      userModules,
      applicationModules,
      language,
      appVersion,
      manageDefaultTab,
      currencyList,
      currencyDefault,
      legalDocumentWTStatus,
      tendersStatus,
    },

    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    sidebarFixed: state.ThemeOptions.sidebarFixed,

    contentBackground: state.ThemeOptions.contentBackground,
  };
};

export default connect(mapStateToProps)(LeftSidebar);
