import * as awardedParser from "intl/tenderInformation/js/data-parsers/awardedDataParser";
import * as itemDetailsParser from "intl/tenderInformation/js/data-parsers/itemDetailsParser";

export const CR_LINE_MODAL = "Línea";
export const CR_BATCH_MODAL = "Partida";
export const CR_ENGAGEMENT_MODAL = "Contratación";
export const CR_CONTRACT_MODAL = "Contrato";
export const CR_PURCHASE_ORDER_MODAL = "Ordenes de Pedido";
export const AR_DELIVERY_DETAIL_MODAL = "Detalle de Entrega";
export const DETAIL_AWARDEDS_MODAL = "Adjudicaciones / Ofertas";

export const generateData = (detail, allData, intl) => {
  let data = allData.data[0];
  switch (detail) {
    case CR_BATCH_MODAL: {
      return setBatchData(data, intl);
    }
    case CR_LINE_MODAL: {
      return setLineData(data, intl);
    }
    case CR_ENGAGEMENT_MODAL: {
      let requestNumber = allData.RequestNumber;
      return setEngagementData(data, requestNumber, intl);
    }
    case CR_CONTRACT_MODAL: {
      let contractNumber = allData.contractNumber;
      return setContractData([data], contractNumber);
    }
    case CR_PURCHASE_ORDER_MODAL: {
      return setPurchaseOrderData(data);
    }
    case AR_DELIVERY_DETAIL_MODAL: {
      return data;
    }
    case DETAIL_AWARDEDS_MODAL: {
      return data;
    }
    default:
      return null;
  }
};

const setBatchData = (data, intl) => {
  const { SicopBatch } = data;
  let batchDetails = itemDetailsParser.getBatchDetails(SicopBatch, intl);
  let paymentMethod = itemDetailsParser.getPaymentMethod(SicopBatch, intl);
  let incoterms = itemDetailsParser.getIncoterms(SicopBatch, intl);
  let policy = itemDetailsParser.getPolicy(SicopBatch, intl);
  let college = itemDetailsParser.getCollege(SicopBatch, intl);

  return {
    otherConditions: {
      all: batchDetails,
      paymentMethod: paymentMethod,
      incoterm: incoterms,
    },
    policy: policy,
    college: college,
  };
};

const setLineData = (data, intl) => {
  let requestNumber = itemDetailsParser.getRequestNumber(data, intl);
  let delivery = itemDetailsParser.getDelivery(data, intl);
  let deliveryItems = itemDetailsParser.getDeliveryItems(data, intl);
  let deliveryItemsList = itemDetailsParser.getDeliveryItemsList(data, intl);
  let guarrantee = itemDetailsParser.getGuarrantee(data, intl);
  let budgetFirstLine = itemDetailsParser.getBudgetFirstLine(data, intl);
  let budgetSecondLine = itemDetailsParser.getBudgetSecondLine(data, intl);
  let sampleFirstLine = itemDetailsParser.getSampleFirstLine(data, intl);
  let sampleSecondLine = itemDetailsParser.getSampleSecondLine(data, intl);

  return {
    requestNumber: requestNumber,
    delivery: delivery,
    deliveryItems: deliveryItems,
    deliveryItemsList: deliveryItemsList,
    guarrantee: guarrantee,
    budgetFirstLine: budgetFirstLine,
    budgetSecondLine: budgetSecondLine,
    sampleFirstLine: sampleFirstLine,
    sampleSecondLine: sampleSecondLine,
  };
};

const setEngagementData = (data, requestNumber, intl) => {
  let dataFilteredByRequestNumber = (data.length !== 0 ? data : []).filter(
    (item) => item.RequestNumber === requestNumber,
  );

  // This allows rendering data titles when the requestNumber is not in the data or
  // when the data is empty (not scrapped yet)
  if (dataFilteredByRequestNumber.length === 0) {
    dataFilteredByRequestNumber = [{}];
  } else {
    dataFilteredByRequestNumber = dataFilteredByRequestNumber[0];
  }

  let engagementInfo = itemDetailsParser.getEngagement(
    dataFilteredByRequestNumber,
    intl,
  );
  let usersInfo = itemDetailsParser.getUsersInfo(
    dataFilteredByRequestNumber,
    intl,
  );
  let usersData = itemDetailsParser.getUsersData(
    dataFilteredByRequestNumber,
    intl,
  );
  let attachments = itemDetailsParser.getAttachments(
    dataFilteredByRequestNumber,
    intl,
  );
  let attachmentsData = itemDetailsParser.getAttachmentsData(
    dataFilteredByRequestNumber,
    intl,
  );

  return {
    engagement: engagementInfo,
    users: usersInfo,
    usersData: usersData,
    attachments: attachments,
    attachmentsData: attachmentsData,
  };
};

const setContractData = (data, contractNumber) => {
  let dataByContractNumber = (data.length !== 0 ? data : []).filter(
    (item) => item.contractNumber === contractNumber,
  );

  // This allows rendering data titles when the contractNumber is not in the data or
  // when the data is empty (not scrapped yet)
  if (dataByContractNumber.length === 0) {
    dataByContractNumber = {};
  } else {
    dataByContractNumber = dataByContractNumber[0];
  }

  let contractorInfo = awardedParser.getContractorInfo(dataByContractNumber);
  let contractInfo = awardedParser.getContractInfo(dataByContractNumber);
  let otherConditions = awardedParser.getOtherConditions(dataByContractNumber);
  let warrantyReception =
    awardedParser.getWarrantyReception(dataByContractNumber);
  let warrantyInfoHeader = awardedParser.getWarrantyInfoHeader();
  let warrantyInfoData =
    awardedParser.getWarrantyInfoData(dataByContractNumber);
  let goodsHeader = awardedParser.getGoodsHeader();
  let goodsInfo = awardedParser.getGoodsInfo(dataByContractNumber);
  let usersHeader = awardedParser.getUsersHeader();
  let usersInfo = awardedParser.getUsersInfo(dataByContractNumber);
  let contractorAnswer =
    awardedParser.getContractorAnswer(dataByContractNumber);

  return {
    contractorInfo: contractorInfo,
    contractInfo: contractInfo,
    otherConditions: otherConditions,
    warrantyReception: warrantyReception,
    warrantyInfoHeader: warrantyInfoHeader,
    warrantyInfoData: warrantyInfoData,
    goodsHeader: goodsHeader,
    goodsInfo: goodsInfo,
    usersHeader: usersHeader,
    usersInfo: usersInfo,
    contractorAnswer: contractorAnswer,
  };
};

const setPurchaseOrderData = (data) => {
  let generalProcedureInfo = awardedParser.getGeneralProcedureInfo(data);
  let purchaseOrderInfo = awardedParser.getPurchaseOrderInfo(data);
  let fiscalSpeciesInfo = awardedParser.getFiscalSpeciesInfo(data);
  let purchaseOrderGoodsHeader = awardedParser.getPurchaseOrderGoodsHeader();
  let purchaseOrderGoodsInfo = awardedParser.getPurchaseOrderGoodsInfo(data);
  let approvalHistory = awardedParser.getApprovalHistory(data);
  let approvalApplicationsHistory =
    awardedParser.getApprovalApplicationsHistory(data);
  let amountAwarded = awardedParser.getAmountAwarded(data);
  let awardedOrdersHeaders = awardedParser.getAwardedOrdersHeader();
  let awardedOrdersInfo = awardedParser.getAwardedOrdersInfo(data);
  let purchaseOrderHistory = awardedParser.getPurchaseOrderHistory(data);
  let purchaseOrderHistoryItemsHeader =
    awardedParser.getPurchaseOrderHistoryItemsHeader();
  let purchaseOrderHistoryItemsInfo =
    awardedParser.getPurchaseOrderHistoryItemsInfo(data);

  return {
    generalProcedureInfo: generalProcedureInfo,
    purchaseOrderInfo: purchaseOrderInfo,
    fiscalSpeciesInfo: fiscalSpeciesInfo,
    purchaseOrderGoodsHeader: purchaseOrderGoodsHeader,
    purchaseOrderGoodsInfo: purchaseOrderGoodsInfo,
    approvalHistory: approvalHistory,
    approvalApplicationsHistory: approvalApplicationsHistory,
    amountAwarded: amountAwarded,
    awardedOrdersHeaders: awardedOrdersHeaders,
    awardedOrdersInfo: awardedOrdersInfo,
    purchaseOrderHistory: purchaseOrderHistory,
    purchaseOrderHistoryItemsHeader: purchaseOrderHistoryItemsHeader,
    purchaseOrderHistoryItemsInfo: purchaseOrderHistoryItemsInfo,
    CheckBalance: data.CheckBalance,
  };
};
