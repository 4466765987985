import {
  Delete,
  InsertDriveFile,
  Replay,
  SaveSharp,
  Shop,
} from "@mui/icons-material";
import { Box, Button, Card, IconButton, Tooltip } from "@mui/material";
import AutocompletePaging from "components/CustomFields/AutocompletePaging";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import InfoDialog from "components/ModalDialogs/InfoDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import { TenderFilesParameters } from "pages/TenderInformationContent/tenderFilesParameters";
import { Fragment, useEffect, useState } from "react";
import ImportFileIcon from "utils/custom-icons/ImportFile";
import { saveNewExcel } from "utils/excelExportService";
import useDataFile from "utils/hooks/useDataFile";
import {
  getOrganisms,
  getOrganismsByUniqueId,
  putOrganisms,
} from "./settingsService";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";

const OrganismsPAC = () => {
  const [modalDiscardConfirm, setModalDiscardConfirm] = useState(false);
  const {
    setSnackbarSaveChanges,
    setShouldRefetch,
    hideLoadingSpinner,
    showSavingStateSpinnerNoTimeout,
    showLoadingStateSpinnerNoTimeout,
    setSnackbar,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    setShouldRefetch: state.setShouldRefetch,
    hideLoadingSpinner: state.hideLoadingSpinner,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    setSnackbar: state.setSnackbar,
  }));

  const [initialData, setInitialData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [isDataModified, setIsDataModified] = useState(false);

  const [entity, setEntity] = useState(null);

  const columns = [
    {
      accessorKey: "uniqueId",
      header: "RUC",
      size: 135,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: "Nombre",
      size: 270,
      enableSorting: true,
    },
    {
      accessorKey: "department",
      header: "Departamento",
      size: 135,
      enableSorting: false,
    },
    {
      accessorKey: "state",
      header: "Provincia",
      size: 135,
      enableSorting: false,
    },
    {
      accessorKey: "district",
      header: "Distrito",
      size: 135,
      enableSorting: false,
    },
  ];

  const fetchTableData = async (pageNumber, pageSize, filter, sorting) => {
    if (firstRender) {
      let orderBy = sorting.length > 0 ? sorting[0].id : "";
      let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;
      const request = {
        isSelected: true,
        OrderAsc: orderAsc,
        PageNumber: pageNumber,
        filter: filter,
        OrderBy: orderBy,
        pageSize: 999999,
        search: filter,
      };
      const organisms = await getOrganisms(request);
      setInitialData(organisms.data.currentPageItems);
      setCurrentData(organisms.data.currentPageItems);
      setFirstRender(false);

      return {
        result: organisms.data.currentPageItems.slice(
          (pageNumber - 1) * pageSize,
          pageNumber * pageSize,
        ),
        total: organisms.data.totalItems,
      };
    }

    return {
      result: currentData
        .filter(
          (item) =>
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            item.uniqueId.toLowerCase().includes(filter.toLowerCase()),
        )
        .slice((pageNumber - 1) * pageSize, pageNumber * pageSize),
      total: currentData.length,
    };
  };

  // -- Discard -- //
  const handleCloseModalDiscardConfirm = () => {
    setModalDiscardConfirm(false);
  };

  const handleDiscard = async () => {
    // showLoadingStateSpinner(); // TBD
    setCurrentData(initialData);
    setModalDiscardConfirm(false);
    setIsDataModified(false);
    setShouldRefetch();
  };
  // --  -- //

  // -- Save -- //
  const handleSave = async () => {
    showSavingStateSpinnerNoTimeout(); // TBD
    const organismsIds = currentData.map((item) => +item.clientUnitId);
    setFirstRender(true);
    await putOrganisms(organismsIds);
    hideLoadingSpinner();
    setSnackbarSaveChanges(true);
    setIsDataModified(false);
    setShouldRefetch();
  };
  // --  -- //

  // -- Delete -- //
  const handleDeleteItem = (item) => {
    let newData = currentData.filter(
      (i) => i.clientUnitId !== item.clientUnitId,
    ); //"ENTITY"
    setCurrentData(newData);
    setIsDataModified(true);
    setShouldRefetch();
  };

  const handleDeleteAll = () => {
    setCurrentData([]);
    setIsDataModified(true);
    setShouldRefetch();
  };
  // --  -- //

  // -- Autocomplete -- //
  const loadOptionsCallback = async (request) => {
    return getOrganisms(request);
  };

  const disabledOption = (option) => {
    return currentData.some(
      (item) => item.clientUnitId === option.clientUnitId,
    );
    // filterToManage[entityKey].some(
    //   (item) =>
    //     item.entity.uniqueId === option.uniqueId ||
    //     +item.entity[entityIdKey] === +option[entityIdKey],
    // )
  };

  const handleChangeAutocomplete = (value) => {
    if (value) {
      let newData = [...currentData, value];
      setCurrentData(newData);
      setIsDataModified(true);
      setEntity(value);
      setShouldRefetch();
    }
  };

  useEffect(() => {
    setEntity(null);
  }, [entity]);
  // --  -- //

  // -- Import -- //
  const [modalNotFoundOpen, setModalNotFoundOpen] = useState(false);
  const [notFoundOrganisms, setNotFoundOrganisms] = useState([]);
  const dataFile = useDataFile();

  const addFiles = () => {
    document.getElementById("import-file-xlsx-organisms").click();
  };

  const validateFile = (file, data) => {
    if (file.size > TenderFilesParameters.MaxFileSize10MB) {
      const message = `El archivo ${
        file ? file.name : ""
      } no puede superar el tamaño maximo permitido de 10 MB!`;
      setSnackbar({
        open: true,
        title: "Error",
        subTitle: message,
        severity: "error",
      });
      return false;
    }
    if (!data) {
      const message = `El archivo ${
        file ? file.name : ""
      } es de tipo inválido o no tiene datos!`;
      setSnackbar({
        open: true,
        title: "Error",
        subTitle: message,
        severity: "error",
      });
      return false;
    }
    return true;
  };

  const importFile = async (event) => {
    showLoadingStateSpinnerNoTimeout();
    const data = await dataFile.getDataFile(event);
    if (!validateFile(event.target?.files[0], data)) {
      hideLoadingSpinner();
      return;
    }

    let organismsToAdd = await getOrganismsByUniqueId(data);
    if (organismsToAdd.data.notFound.length > 0) {
      setModalNotFoundOpen(true);
      setNotFoundOrganisms(organismsToAdd.data.notFound);
    }
    let newClienUnits = organismsToAdd.data.clientUnits.filter(
      (item) =>
        !currentData.some(
          (currentItem) => currentItem.clientUnitId === item.clientUnitId,
        ),
    );
    let newData = [...currentData, ...newClienUnits];
    setCurrentData(newData);
    setIsDataModified(true);
    setSnackbar({
      open: true,
      title: "Resultado de la importación",
      subTitle:
        organismsToAdd.data.notFound.length > 0
          ? "Algunas entidades no pudieron ser encontradas con los datos ingresados"
          : "Entidades importadas correctamente.",
      severity: "info",
    });
    setTimeout(() => {
      hideLoadingSpinner();
      setShouldRefetch(); // with timeout to wait for the new data to be applied
    }, 750);
  };

  const handleCloseModalNotFound = () => {
    setModalNotFoundOpen(false);
    setNotFoundOrganisms([]);
  };
  // --  -- //

  // -- Export -- //
  const handleDownloadExcel = async () => {
    await saveNewExcel(
      currentData.slice(),
      columns,
      "Entidades Seleccionadas PAC",
      "Entidades",
    );
  };
  // --  -- //

  const options = {
    tableId: "gridOrganismsPAC",
    header: columns,
    tableHeaderTitle: "Entidades seleccionadas",

    enableDeleting: true,
    enableSorting: false,
    deleteFunc: (row) => handleDeleteItem(row),
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Administrar Entidades"
        icon={<Shop />}
        endComponent={
          <Fragment>
            <Box sx={{ display: "flex", gap: "1.2rem" }}>
              <Tooltip id="download-excel-button" title="Descargar Excel">
                <span>
                  <IconButton
                    sx={{
                      color: "green",
                      "&:disabled": { color: "light-gray" },
                    }}
                    onClick={handleDownloadExcel}
                    disabled={currentData.length === 0}>
                    <BsFillFileEarmarkExcelFill style={{ fontSize: 22 }} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip id="import-button" title="Importar desde archivo">
                <IconButton color="primary">
                  <ImportFileIcon
                    handleIconClick={() => {
                      addFiles();
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                id="remove-button"
                title="Eliminar todos los seleccionados">
                <span>
                  <IconButton
                    color="error"
                    sx={{
                      "&:hover": { backgroundColor: "error" },
                      opacity: 0.8,
                    }}
                    onClick={handleDeleteAll}
                    disabled={currentData.length === 0}>
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip id="save-button" title="Guardar cambios">
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSave}
                    disabled={!isDataModified}
                    endIcon={<SaveSharp />}>
                    Guardar
                  </Button>
                </span>
              </Tooltip>
              <Tooltip id="close-button" title="Descartar cambios">
                <span>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => setModalDiscardConfirm(true)}
                    disabled={!isDataModified}
                    endIcon={<Replay />}>
                    Descartar
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Fragment>
        }
      />
      <Box
        sx={{
          height: `calc(100% - ${
            document.getElementById("autocompleteOrganismsPACCard")
              ?.clientHeight
          }px - 1.5rem)`,
        }}>
        <Card
          id="autocompleteOrganismsPACCard"
          style={{
            marginBottom: "1rem",
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}>
          <AutocompletePaging
            value={null}
            onChange={handleChangeAutocomplete}
            loadOptionsCallback={loadOptionsCallback}
            getOptionLabel={(option) =>
              option ? `${option.name}  |  ${option.uniqueId}` : ""
            }
            textFieldPlaceholder="Seleccione..."
            fullWidth
            textFieldVariant="outlined"
            getOptionDisabled={disabledOption}
          />
        </Card>
        <FSTMaterialReactTable options={options} getData={fetchTableData} />
      </Box>

      <DeleteDialog
        open={modalDiscardConfirm}
        onClose={handleCloseModalDiscardConfirm}
        onDelete={handleDiscard}
        title={`¿Está seguro que desea descartar los cambios?`}
        subTitle={`Los cambios realizados se perderán.`}
        deleteText="Descartar"
      />

      <InfoDialog
        open={modalNotFoundOpen}
        onClose={handleCloseModalNotFound}
        title={`RUCs no encontrados`}
        subTitle={`Algunos RUCs no pudieron ser encontrados con los datos ingresados: ${notFoundOrganisms.join(
          ", ",
        )}`}
      />

      <input
        accept=".xlsx,.xls,.txt"
        style={{ display: "none" }}
        id="import-file-xlsx-organisms"
        type="file"
        hidden
        onInput={(e) => importFile(e)}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </Fragment>
  );
};

export default OrganismsPAC;
