import { post, get, put, Delete } from "config/axios";

// -- Sellers -- //
export const listSellers = async () => {
  const request = {
    Filter: "",
    OrderBy: "email",
    OrderAsc: false,
  };
  return await post("/api/Seller/ListSellers", request);
};

export const getSellers = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.filter,
    PageNumber: pagination.pageNumber,
    OrderAsc: pagination.orderAsc,
  };
  return await post("/api/Seller/ListSellers", request);
};

export const addOrUpdateSeller = async (request) => {
  return await post("/api/Seller/AddOrUpdateSeller", request);
};

export const deleteSeller = async (request) => {
  return await post("/api/Seller/DeleteSellers", request);
};

export const getSellersReduced = async () => {
  return await get("/api/seller/reduced");
};

// -- Banks -- //
export const getBanks = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.filter,
    PageNumber: pagination.pageNumber,
    OrderAsc: pagination.orderAsc,
  };
  return await post("/api/Bank/ListBanks", request);
};

export const addOrUpdateBank = async (request) => {
  return await post("/api/Bank/AddOrUpdateBank", request);
};

export const deleteBank = async (request) => {
  return await post("/api/Bank/DeleteBanks", request);
};

// -- Internal Status -- //
export const getManageInternalStatus = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.filter,
    PageNumber: pagination.pageNumber,
    OrderAsc: pagination.orderAsc,
  };
  return await post(
    "/api/ManageInternalStatus/ListManageInternalStatuses",
    request,
  );
};

export const addOrUpdateManageInternalStatus = async (request) => {
  return await post(
    "/api/ManageInternalStatus/AddOrUpdateManageInternalStatus",
    request,
  );
};

export const deleteManageInternalStatus = async (request) => {
  return await post(
    "/api/ManageInternalStatus/DeleteManageInternalStatuses",
    request,
  );
};

// -- Tags -- //
export const getTags = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.filter,
    PageNumber: pagination.pageNumber,
    OrderAsc: pagination.orderAsc,
  };
  return await post("/api/Tag/ListTags", request);
};

export const addOrUpdateTag = async (request) => {
  return await post("/api/Tag/AddOrUpdateTag", request);
};

export const deleteTag = async (request) => {
  return await post("/api/Tag/DeleteTags", request);
};

export const listTags = async () => {
  const request = {
    Filter: "",
    OrderBy: "tagId",
    OrderAsc: true,
  };
  return await post("/api/Tag/ListTags", request);
};

export const getTagsNames = async () => {
  return await get("/api/tag/names");
};

// -- Categories -- //
export const getCategories = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.filter,
    PageNumber: pagination.pageNumber,
    OrderAsc: pagination.orderAsc,
  };
  return await post("/api/category/listcategories", request);
};

export const addOrUpdateCategory = async (request) => {
  return await post("/api/category/addorupdatecategory", request);
};

export const deleteCategory = async (request) => {
  return await post("/api/category/deletecategories", request);
};

export const listCategories = async () => {
  return await post("/api/Category/ListCategories", {});
};

// -- Delete Reasons -- //
export const getDeleteReasons = async (request) => {
  return await post("/api/deletereason/ListDeleteReasons", {});
};

export const addOrUpdateDeleteReason = async (request) => {
  return await post("/api/deletereason/addorupdatedeletereason", request);
};

export const deleteDeleteReason = async (request) => {
  return await post("/api/deletereason/deletedeletereason", request);
};

// -- Products -- //
export const massivePostProduct = async (request) => {
  return await post("/api/product/massive", request);
};

export const postProduct = async (request) => {
  return await post("/api/product", request);
};

export const deleteProduct = async (id) => {
  return await Delete(`/api/product/${id}`);
};

export const deleteAllProducts = async () => {
  return await Delete("/api/product");
};

export const putProduct = async (request) => {
  return await put("/api/product", request);
};

export const getProducts = async (request) => {
  /* given request is an object with the following structure:
    request = {
      OrderBy: pagination.orderBy,
      PageSize: pagination.pageSize,
      Filter: pagination.search,
      PageNumber: pagination.PageNumber,
      OrderAsc: pagination.OrderAsc,
    };
    add these as query params to the url if they are not null (using the same name as the object key)
    */
  let url = "/api/product?";
  for (const key in request) {
    if (request[key] != null && request[key] !== "") {
      url += `${key}=${request[key]}&`;
    }
  }

  // if last char is & or ? remove it
  if (url.endsWith("&") || url.endsWith("?")) {
    url = url.slice(0, -1);
  }
  return await get(url);
};
