import { put } from "config/axios";

const putUserClientSettings = async (request) => {
  return await put("/api/user/updateUserClientSettings", request);
};

export const updateUserClientSettings = async () => {
  let request = {};
  Object.keys(localStorage).filter((key) => {
    if (key.indexOf("grid") === 0) {
      request[key] = [JSON.parse(localStorage.getItem(key))];
    }
    return key;
  });
  let manageDefaultTab = localStorage.getItem("manageDefaultTab");
  if (manageDefaultTab) {
    request["manageDefaultTab"] = [JSON.parse(manageDefaultTab)];
  }
  let selectedFilters = localStorage.getItem("selectedFilters");
  if (selectedFilters) {
    request["selectedFilters"] = [JSON.parse(selectedFilters)];
  }

  let gridSettings = localStorage.getItem("gridSettings");
  if (gridSettings) {
    request["gridSettings"] = [JSON.parse(gridSettings)];
  } else {
    request["gridSettings"] = [{}];
  }

  const saveUserSetting = await putUserClientSettings(JSON.stringify(request));
  return saveUserSetting;
};

export const getGridSettings = (gridName) => {
  const gridStorage = JSON.parse(localStorage.getItem("gridSettings"));
  if (!gridStorage) {
    return null;
  }
  const gridSettings = gridStorage[gridName];

  return gridSettings || null;
};

export const setGridSettings = (gridName, gridSettings) => {
  let gridStorage = JSON.parse(localStorage.getItem("gridSettings"));
  if (!gridStorage) {
    localStorage.setItem("gridSettings", JSON.stringify({}));
    gridStorage = JSON.parse(localStorage.getItem("gridSettings"));
  }
  gridStorage[gridName] = gridSettings;

  localStorage.setItem("gridSettings", JSON.stringify(gridStorage));
};
