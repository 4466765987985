import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Button } from "@mui/material";

const InfoDialog = ({
  open,
  onClose,
  title = null,
  subTitle = null,
  acceptText = "Aceptar",
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
            <FontAwesomeIcon
              icon={["fas", "circle-info"]}
              className="d-flex align-self-center display-3"
            />
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">{title || "Información"}</h4>
        <p className="mb-0 text-black-50">{subTitle || "Información."}</p>
        <div className="pt-4">
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            className="mx-1">
            <span className="btn-wrapper--label">{acceptText}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default InfoDialog;
