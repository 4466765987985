import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

export const flattenSheetDataFromJson = (data, header) => {
  if (!data?.length) return [header];
  // Flattens the data from JSON to a 2D array with header based order
  let fields = Object.keys(data[0]);
  let sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
};

export const getNewWorkbook = () => {
  // Creates a new workbook with a single sheet
  const workbook = new ExcelJS.Workbook();
  workbook.addWorksheet("Hoja1");
  return workbook;
};

export const saveNewWorkbook = async (workbook, fileName) => {
  // Saves the workbook as a new file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, fileName);
};

/* header: [ { header: "Header 1", accessorKey: "field1" }, ...] */
export const flattenSheetDataFromJsonFieldBased = (data, header) => {
  // flatten the data from JSON to a 2D array with header based order and based on the header fields
  if (!data?.length) return [header.map((item) => item.header)];
  let fields = header.map((item) => item.accessorKey);
  let sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header.map((item) => item.header));
  return sheetData;
};

const columnToNumber = (column) => {
  let result = 0;
  for (let i = 0; i < column.length; i++) {
    result *= 26;
    result += column.charCodeAt(i) - "A".charCodeAt(0) + 1;
  }
  return result;
};

export const insertData = (worksheet, data, startCell) => {
  const [startColumn, startRow] = startCell.split(/(\d+)/).filter(Boolean);
  let currentRow = parseInt(startRow, 10);

  for (const rowData of data) {
    for (let col = 0; col < rowData.length; col++) {
      const currentColumn = columnToNumber(startColumn) + col;
      worksheet.getCell(currentRow, currentColumn).value = rowData[col];
    }
    currentRow++;
  }
};

const MAX_LENGTH = 50;
const getMaxLengthValues = (data) => {
  const maxValues = [];
  for (let col = 0; col < data[0].length; col++) {
    let max = 0;
    for (const element of data) {
      if (element[col].toString().length > max) {
        max = element[col].toString().length;
      }
    }
    maxValues.push(+max > MAX_LENGTH ? MAX_LENGTH : max);
  }
  return maxValues;
};

export const fillSheet = (
  workbook,
  sheetNumber,
  data,
  sheetName = `Hoja${sheetNumber + 1}`,
) => {
  const worksheet =
    workbook.getWorksheet(sheetNumber) || workbook.addWorksheet(sheetName);
  worksheet.name = sheetName;

  // Fills a sheet with data
  insertData(worksheet, data, "A1");

  // Sets the width of the columns based on the longest length of both header and data
  const maxValues = getMaxLengthValues(data);
  for (let i = 0; i < data[0].length; i++) {
    worksheet.getColumn(i + 1).width = Math.max(20, maxValues[i] + 5);
  }

  return worksheet;
};

export const fillSheetWithHeaderBold = (
  workbook,
  sheetNumber,
  data,
  sheetName = `Hoja${sheetNumber + 1}`,
) => {
  const sheet = fillSheet(workbook, sheetNumber, data, sheetName);
  sheet.getRow(1).font = { bold: true };
};

export const saveNewExcel = async (
  data,
  header,
  fileName,
  sheetName = "Hoja1",
) => {
  if (!sheetName) sheetName = "Hoja1";
  const workbook = getNewWorkbook();
  const sheetData = flattenSheetDataFromJsonFieldBased(data, header);
  fillSheetWithHeaderBold(workbook, 1, sheetData, sheetName);
  return await saveNewWorkbook(workbook, fileName);
};

export const sheetDataToJSON = (header, data) => {
  // Converts the sheet data to JSON
  return data.map(function (row) {
    return row.reduce(function (result, field, index) {
      if (field === undefined) field = null;
      result[header[index]] = field;
      return result;
    }, {});
  });
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const saveExcel = (data, fileName) => {
  const blob = b64toBlob(
    data,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );
  saveAs(blob, fileName);
};

export const savePdf = (data, fileName) => {
  const blob = b64toBlob(data, "application/pdf");
  saveAs(blob, fileName);
};
