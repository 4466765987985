import {
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";

export default function BalanceHistoryModalData(props) {
  return (
    <GeneralPurposeModal
      open={props.open}
      title={"Consulta Historial de órdenes de pedido"}
      onClose={props.handleClose}>
      <CardContent>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {props.data.purchaseOrderHistory?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}>
                    {row.name}
                  </TableCell>
                  <TableCell>{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <CardContent>
          <TableContainer
            component={Paper}
            className="card-box text-black-50 bg-secondary">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  {props.data.purchaseOrderHistoryItemsHeader?.map(
                    (row, idx) => (
                      <TableCell key={idx}>{row}</TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.purchaseOrderHistoryItemsInfo?.map((row, idx) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={idx}>
                    {row.map((row2, idx2) => (
                      <TableCell key={`${row2}${idx2}`}>{row2}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            style={{ marginTop: "2%" }}
            className="card-box text-black-50 bg-secondary">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}>
                  Total
                </TableCell>
                <TableCell>{props.data.CheckBalance?.HistoryTotal}</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </CardContent>
      </CardContent>
    </GeneralPurposeModal>
  );
}
