import { useContext } from "react";
import { LangContext } from "../../context/langContext";

const useHandleDataByLang = (id, type, data) => {
  const langChange = useContext(LangContext);

  const getDataByCountry = (etq) => {
    const filter = langChange.configForm[id].Filter[type];
    const tab = filter.find((item) => item.id === etq);
    let auxArray = [];
    if (tab) {
      const ArrayKeys = tab.keys.split(".");
      const ArrayTypes = tab.types.split(".");
      auxArray = data[ArrayKeys[0]] ? data[ArrayKeys[0]] : data;
      ArrayTypes.map((type, index) => {
        if (index >= 1) {
          auxArray = auxArray[ArrayKeys[index]]
            ? auxArray[ArrayKeys[index]]
            : [];
        }
        if (type === "J") {
          try {
            auxArray = JSON.parse(auxArray);
          } catch (e) {
            console.warn("No data for selected tab");
          }
        }
      });
    }

    return auxArray;
  };

  return {
    getDataByCountry,
  };
};

export default useHandleDataByLang;
