import ButtonModalDetail from "pages/TenderInformationContent/InfoContent/utils-info/ButtonModalDetail";
import { capitalize, formatValueLocale } from "utils/common";
import { ConvertToDateLong } from "utils/DateHelper";

export default {
  TenderContainer: {
    Filter: {
      Tab: [
        { id: "tab.General", keys: "", types: "O" },
        { id: "tab.Cronograma", keys: "tenderStages", types: "O" },
        { id: "tab.ListaItems", keys: "tenderDetails", types: "O" },
        { id: "tab.Documentos", keys: "tenderDocuments", types: "O" },
        { id: "tab.Acciones", keys: "tenderActivities", types: "O" },
        { id: "tab.Oficios", keys: "tenderSupervisions", types: "O" },
      ],
    },
  },
  TenderGestion: {
    Filter: {
      menuInfo: [
        "tab.General",
        "tab.Cronograma",
        "tab.ListaItems",
        "tab.Documentos",
        "tab.Acciones",
        "tab.Oficios",
      ],
      menuGestion: [
        "tab.Asignaciones",
        "tab.ListaItems",
        "tab.CartasFianza",
        "tab.Comentarios",
      ],
    },
  },

  ManagementCalendarExportValues: {
    elements: [
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => item.toString(),
      },
      {
        id: "cols.Publicacion",
        value: "publishedDate",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Nomenclatura", value: "fileNumber" },
      { id: "cols.Convocatoria", value: "tenderNumber" },
      { id: "cols.Entidad", value: "clientUnitName" },
      { id: "cols.Nombre", value: "tenderName" },
      { id: "cols.Situacion", value: "manageInternalStatusDesc" },
      { id: "cols.Referencial", value: "estimatedValue" },
      { id: "cols.OContratacion", value: "tenderDescription" },
      { id: "cols.Moneda", value: "currency" },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      { id: "cols.Items", value: "detailsQty" },
      {
        id: "cols.Finconsultas",
        value: "questionsReceptionEnd",
        format: (item) => ConvertToDateLong(item),
      },
      {
        id: "cols.BasesInt",
        value: "basesIntegratedEnd",
        format: (item) => ConvertToDateLong(item),
      },
      {
        id: "cols.FinRegistro",
        value: "registryEnd",
        format: (item) => ConvertToDateLong(item),
      },
      {
        id: "cols.BuenaPro",
        value: "awardEnd",
        format: (item) => ConvertToDateLong(item),
      },
      {
        id: "cols.FinPresProp",
        value: "offersReceptionEnd",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Usuario", value: "userManage" },
      {
        id: "cols.Segmento",
        value: "categories",
        format: (item) => (item.length > 0 ? item[0] : ""),
      },
      {
        id: "cols.Gestionada",
        value: "dateCreationManage",
        format: (item) => ConvertToDateLong(item),
      },
    ],
  },
  ManagementCalendarValues: {
    elements: [
      { id: "cols.Convocatoria", value: "tenderNumber" },
      {
        id: "cols.Nombre",
        value: "tenderName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.Entidad",
        value: "clientUnitName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.FinPresProp",
        value: "offersReceptionEnd",
        format: (item) => (item ? ConvertToDateLong(item) : "-"),
      },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      {
        id: "cols.Situacion",
        value: "manageInternalStatusDesc",
        format: (item) => (item ? item : "Sin Asignar"),
      },
      { id: "cols.Portal", value: "portal" },
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => (item.length > 0 ? item.toString() : "Sin Asignar"),
      },
    ],
  },
  EmailSubjectConfig: {
    elements: [
      { id: "cols.Nombre", name: "tenderName" },
      { id: "cols.Id", name: "tenderNumber" },
      { id: "cols.Cierre", name: "endDate" },
      { id: "text.Tipo_Compra", name: "tenderTypeDesc" },
      { id: "cols.Portal", name: "portal" },
      { id: "cols.Entidad", name: "clientUnitName" },
      { id: "cols.Usuario", name: "userManage" },
      { id: "cols.Region", name: "regionName" },
    ],
  },
  TenderGeneralInfo: {
    elements: [
      {
        id: "text.Nomenclatura",
        value: "fileNumber",
        xs: 12,
        sm: 6,
        md: 4,
      },
      {
        id: "text.Fecha_Publicacion",
        value: "publishedDate",
        type: "date",
        xs: 12,
        sm: 6,
        md: 2,
      },
      {
        id: "text.Convocatoria",
        value: "tenderNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Tipo_Compra",
        value: "tenderTypeDesc",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Entidad",
        value: "clientUnitName",
        xs: 12,
        sm: 12,
        md: 4,
      },
      {
        id: "text.Descripcion",
        value: "tenderName",
        xs: 12,
        sm: 12,
        md: 8,
      },
    ],
  },
  ListCompGeneralInfo: {
    component: [
      { type: "Grid", id_ff: "TabGeneralInfoFirst" },
      {
        type: "EtqH5",
        id_fm: "tabG.Entidad_Contratante",
        intl_efault: "Entidad Contratante",
        styleParent: { marginLeft: "0.5em", marginTop: "2em" },
      },
      { type: "Grid", id_ff: "TabGeneralInfoLast" },
    ],
  },
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            {
              key: "tenderDescription",
              intlLabelKey: "tabG.Objeto_Contratacion",
            },
            { key: "callNumber", intlLabelKey: "tabG.Convocatoria" },
            { key: "estimatedValue", intlLabelKey: "tabG.Valor_Referencial" },
            {
              key: "publishedDate",
              intlLabelKey: "tabG.Fecha_Publicacion",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabGeneralInfoLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "clientUnitName", intlLabelKey: "tabG.Entidad_Contratante" },
            { key: "clientUnitUniqueId", intlLabelKey: "tabG.nro_ruc" },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "tenderStageType", intlLabelKey: "tabC.Etapa" },
            {
              key: "startDate",
              intlLabelKey: "tabC.Fecha_Inicio",
              cellType: "datetime",
            },
            {
              key: "endDate",
              intlLabelKey: "tabC.Fecha_Fin",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabItemListFirst: {
    element: {
      type: "FSTMRT",
      columns: [
        { accessorKey: "itemNumber", header: "#", size: "90" },
        { accessorKey: "description", header: "Descripción", size: "220" },
        { accessorKey: "code", header: "Código CUBSO", size: "110" },
        {
          accessorKey: "qty",
          header: "Cantidad",
          size: "60",
          textAlign: "center",
        },
        { accessorKey: "reserve", header: "Reserva Para MYPE", size: "80" },
        { accessorKey: "package", header: "Paquete", size: "80" },
        {
          accessorKey: "estimatedValue",
          header: "Valor Referencia/Estimado Total",
          size: "100",
          textAlign: "center",
          Cell: ({ cell }) => formatValueLocale(cell.getValue()),
        },
        { accessorKey: "status", header: "Estado", size: "100" },
        {
          id: "tabL.Adjudicaciones",
          accessorKey: "tenderDetailAwardeds",
          header: "Adjudicaciones/Ofertas",
          size: "100",
          hideToExport: true,
          disableTooltip: true,
          Cell: ({ cell, column }) => {
            if (!cell.getValue() || cell.getValue().length === 0) return null;
            return (
              <ButtonModalDetail
                queryClient={"TabItemListLast"}
                detail={"Adjudicaciones / Ofertas"}
                data={cell.getValue()}
                result={{}}
                titleModal={"Adjudicaciones / Ofertas"}
                modalName={"TabItemListLast"}
              />
            );
          },
        },
      ],
    },
  },
  TabItemListLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "competitorName", intlLabelKey: "tabL.Adj.Postor" },
            { key: "pomotionalLaw", intlLabelKey: "tabL.Adj.Ley" },
            { key: "bonification", intlLabelKey: "tabL.Adj.Bonificacion" },
            { key: "qty", intlLabelKey: "tabL.Adj.Cantidad" },
            {
              key: "ammount",
              intlLabelKey: "tabL.Adj.Monto",
              render: (value) => formatValueLocale(value),
            },
          ],
        },
      },
    ],
  },
  ListCompDocuments: {
    component: [{ type: "Grid", id_ff: "TabProcedureDocument" }],
  },
  TabProcedureDocument: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", intlLabelKey: "tabD.Nro" },
            { key: "stageName", intlLabelKey: "tabD.Etapa" },
            { key: "name", intlLabelKey: "tabD.Documento" },
            {
              key: "link",
              intlLabelKey: "tabD.Archivo",
              render: (value, row) => (
                <a href={value} target="_blank">
                  {row.name}
                </a>
              ),
            },
            {
              key: "publishedDate",
              intlLabelKey: "tabD.Fecha_Publicacion",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabGeneralProcedureActions: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "number", intlLabelKey: "tabA.Nro", primary: true },
            { key: "name", intlLabelKey: "tabA.Accion", primary: true },
            {
              key: "publishedDate",
              intlLabelKey: "tabA.FechaPublicacion",
              cellType: "datetime",
            },
            { key: "description", intlLabelKey: "tabA.Motivo" },
          ],
        },
      },
    ],
  },
  TabSupervisingJob: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "Sin oficios",
          columns: [
            { key: "number", intlLabelKey: "tabO.Nro", primary: true },
            {
              key: "publishDate",
              intlLabelKey: "tabO.FechaPublicacion",
              cellType: "datetime",
            },
            { key: "data", intlLabelKey: "tabO.Datos", primary: true },
            {
              key: "releaseDate",
              intlLabelKey: "tabO.Emision",
              cellType: "datetime",
            },
            {
              key: "link",
              intlLabelKey: "tabO.Link",
              render: (value, row) => <a href={value}>{row.data}</a>,
            },
          ],
        },
      },
    ],
  },

  ValueCmb_Carta: {
    objectName: "subjectsPE",
  },

  // From now on, not used.
  TabDocumentsFolds: { elements: [] },
  TabDocumentsParticularClauses: { elements: [] },
  TabDocumentsAnnexes: { elements: [] },
  TabDocumentsTechnicalFolds: { elements: [] },
  TabRequirement: { elements: [] },
  ListCompWarraty: {
    listData: "StructureDataWarrantyXX",
    component: [],
  },
  TabWarranty: { elements: [] },
  TabOffersInfo: { elements: [] },
  TabContractConditions: { elements: [] },
  TabDelivery: { elements: [] },
  TabRelatedOfficials: { elements: [] },
  TabAwardedContracts: { elements: [] },
  TabAwardedPurchaseOrders: { elements: [] },
  TabItemModalDeliveryDetail: { elements: [] },
};
