import { createTheme } from "@mui/material";
import typography from "./typography";

const muiVars = {
  primary: "#5383ff",
  primaryLight: "#495368",
  primaryDark: "#09142a",
  secondary: "#3d4977",
  inheritDefault1: "#fefefe",
  inheritDefault2: "#f8f9ff",
  second: "#070919",
  indigo: "#7420ff",
  purple: "#793de6",
  pink: "#fc26a4",
  red: "#f83245",
  orange: "#f4772e",
  yellow: "#ffc926",
  green: "#1bc943",
  teal: "#18e1a5",
  cyan: "#27dcf3",
};

const MuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: muiVars.primary,
    },
    grey: {
      300: muiVars.inheritDefault1,
      A100: muiVars.inheritDefault2,
    },
    secondary: {
      main: muiVars.secondary,
    },
    error: {
      main: muiVars.red,
    },
    success: {
      main: muiVars.green,
    },
    warning: {
      main: muiVars.orange,
    },
    helpers: {
      primary: muiVars.blue,
      main: "rgba(25, 46, 91, .035)",
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 8, // using the original "0.5rem" creates error with Material React Table (0.5rem == 8px)
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          paddingLeft: "14px",
          paddingRight: "14px",
        },
        containedSizeSmall: {
          paddingLeft: "14px",
          paddingRight: "14px",
        },
        root: {
          textTransform: "none",
          fontWeight: "normal",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: muiVars.second,
          padding: "8px 16px",
          fontSize: "13px",
        },
        arrow: {
          color: muiVars.second,
        },
      },
    },
  },
  typography,
});

export default MuiTheme;
