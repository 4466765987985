import { get, post } from "config/axios";

export const manageTenderData = async (request) => {
  return await post("/api/Manage/ManageTenderData", request);
};

export const getManageData = async (request) => {
  return await post("/api/Manage/GetManageData", request);
};

export const listInternalStatus = async () => {
  const request = {
    Filter: "",
    OrderBy: "description",
    OrderAsc: false,
  };
  return await post(
    "/api/ManageInternalStatus/ListManageInternalStatuses",
    request,
  );
};

export const listCategories = async () => {
  return await post("/api/Category/ListCategories", {});
};

export const listAllBanks = async () => {
  return await post("/api/Bank/ListBanks", {});
};

export const listTags = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  return await post("/api/Tag/ListTags", request);
};

export const getProducts = async (request) => {
  /* given request is an object with the following structure:
    request = {
      OrderBy: pagination.orderBy,
      PageSize: pagination.pageSize,
      Filter: pagination.search,
      PageNumber: pagination.PageNumber,
      OrderAsc: pagination.OrderAsc,
    };
    add these as query params to the url if they are not null (using the same name as the object key)
    */
  let url = "/api/product?";
  for (const key in request) {
    if (request[key] != null && request[key] !== "") {
      url += `${key}=${request[key]}&`;
    }
  }

  // if last char is & or ? remove it
  if (url[url.length - 1] === "&" || url[url.length - 1] === "?") {
    url = url.slice(0, -1);
  }
  return await get(url);
};

// -- Penalties -- //
export const addOrUpdatePenalty = async (request) => {
  return await post("/api/Penalty/AddOrUpdatePenalty", request);
};

export const listPenalties = async () => {
  return await post("/api/Penalty/ListPenalties");
};

export const listPenaltyReasons = async () => {
  return await post("/api/PenaltyReason/ListPenaltyReasons");
};

export const getPenalties = async (request) => {
  return await post("/api/Penalty/GetPenalties", request);
};

export const deletePenalties = async (request) => {
  return await post("/api/Penalty/DeletePenalties", request);
};

// -- Legal Documents -- //
export const getLegalDocuments = async (request) => {
  return await post("/api/LegalDocument/ListLegalDocuments", request);
};

export const addOrUpdateLegalDocument = async (request) => {
  return await post("/api/LegalDocument/AddOrUpdateLegalDocument", request);
};

export const getCurrency = (request) => {
  return [
    { id: 1, Description: "Dolar", Symbol: "u$s" },
    { id: 2, Description: "Soles", Symbol: "$" },
  ];
};

export const deleteLegalDocuments = async (request) => {
  return await post("/api/LegalDocument/DeleteLegalDocuments", request);
};
