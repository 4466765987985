import { AddCircle, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { TenderFilesParameters } from "pages/TenderInformationContent/tenderFilesParameters";
import ExcelJS from "exceljs";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { isLangCL, nullifyUndefinedValues } from "utils/common";
import { sheetDataToJSON } from "utils/excelExportService";
import { manageOutsiderTenders } from "./outsiderTendersService";
import useOutsiderTenderColumns from "./useOutsiderTenderColumns";

const OutsiderTendersExcelInsert = ({
  sources,
  tenderTypes,
  tendersStatus,
  sellers,
  categories,
  tags,
  getSourceId,
}) => {
  const intl = useIntl();
  const {
    setSnackbarErrorCustom,
    setSnackbarSaveCustom,
    hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout,
  } = useGlobalStore((state) => ({
    setSnackbarErrorCustom: state.setSnackbarErrorCustom,
    setSnackbarSaveCustom: state.setSnackbarSaveCustom,
    hideLoadingSpinner: state.hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
  }));

  //#region Excel data utils
  // -- Excel data utils -- //
  const excelDataAdd = [
    {
      columnValue: "addButtonColumnExcel",
      columnName: "Agregar columna",
      modifiable: false,
    },
  ];

  const columns = useOutsiderTenderColumns();
  const [excelDataOrganization, setExcelDataOrganization] = useState([
    ...columns.getMinimumColumns(),
    ...excelDataAdd,
  ]);
  const [duplicateExcelPositions, setDuplicateExcelPositions] = useState({
    value: false,
    message: "",
  });
  const [excelDataOrganizationHeader, setExcelDataOrganizationHeader] =
    useState(false);

  //function that returns excel columns available from a to bz with format {id: 0, name: A}
  const getExcelColumns = () => {
    let columns = [];
    for (let i = 0; i < 26; i++)
      columns.push({ id: i, name: String.fromCharCode(65 + i) });
    for (let i = 0; i < 26; i++)
      columns.push({ id: i + 26, name: `A${String.fromCharCode(65 + i)}` });
    for (let i = 0; i < 26; i++)
      columns.push({ id: i + 52, name: `B${String.fromCharCode(65 + i)}` });
    return columns;
  };

  const excelDateToJSDate = (date) => {
    // https://learn.microsoft.com/en-us/office/dev/scripts/resources/samples/excel-samples#dates
    return new Date(Math.round((date - 25569) * 86400 * 1000));
  };

  const verifyDuplicatePositions = (data) => {
    let seen = new Set();
    let hasDuplicates = data.some(
      (currentObject) =>
        seen.size === seen.add(currentObject.columnPosition).size,
    );
    hasDuplicates |= data.some(
      (currentObject) => seen.size === seen.add(currentObject.columnValue).size,
    );
    if (hasDuplicates) {
      setDuplicateExcelPositions({
        value: true,
        message: "No debe haber columnas repetidas",
      });
    } else {
      setDuplicateExcelPositions({ value: false, message: "" });
    }
    return hasDuplicates;
  };

  const handleChange = (event, item, mustModifyPosition = false) => {
    //find and modify the item in the excelDataOrganization array
    let excelDataOrganizationCopy = [...excelDataOrganization];
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );

    if (mustModifyPosition) {
      // modify the position of the item
      excelDataOrganizationCopy[itemIndex].columnPosition = event.target.value;
    } else {
      let itemToApply = columns
        .getExtraColumns()
        .find((x) => x.columnValue === event.target.value);
      if (!itemToApply) return; // shouldn't happen
      // modify the name of the item
      // remove dots from the string
      excelDataOrganizationCopy[itemIndex].columnValue =
        itemToApply.columnValue;
      excelDataOrganizationCopy[itemIndex].columnName = itemToApply.columnName;
    }
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleAddColumn = () => {
    // max columnPosition + 1 (so if the user adds a columns and sets the position to 5, then the next column will be 6, even if there is no position 4)
    let maxColumnPosition = Math.max.apply(
      Math,
      excelDataOrganization.slice(0, -1).map((o) => o.columnPosition),
    );
    // this shouldn't happen, but just in case
    if (maxColumnPosition === -Infinity || maxColumnPosition === "NaN")
      maxColumnPosition = excelDataOrganization.length - 1;

    setExcelDataOrganization([
      ...excelDataOrganization.slice(0, -1),
      {
        columnName: "",
        columnValue: "",
        columnPosition: maxColumnPosition + 1,
        modifiable: true,
      },
      ...excelDataAdd,
    ]);
  };

  const handleRemoveColumn = (event, item) => {
    let excelDataOrganizationCopy = [...excelDataOrganization];
    // to find the item all three properties must match
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );
    excelDataOrganizationCopy.splice(itemIndex, 1);
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleChangeHeaderSwitch = (event) => {
    setExcelDataOrganizationHeader(event.target.checked);
  };
  //#endregion Excel data utils

  //#region File utils
  // -- File utils -- //
  const [file, setFile] = useState(null);
  const [messageErrorFile, setMessageErrorFile] = useState(null);

  const addFiles = () => {
    document.getElementById("upload-button-file").click();
  };

  const validateFileSize = (file) => {
    if (file.size > TenderFilesParameters.MaxFileSize10MB) {
      const message = `El archivo ${file.name} supera el tamaño maximo permitido de 10 MB!`;
      setMessageErrorFile(message);
      setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    }
    setMessageErrorFile(null);

    return true;
  };

  const [excelWorkbook, setExcelWorkbook] = useState(null);

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;

    let _file = {
      FileName: event.target.files[0].name,
    };
    const newFiles = event.target.files;
    const f = newFiles[0];
    const excelReader = new FileReader();
    excelReader.onload = async function (e) {
      _file.Content = e.target.result;
      setFile(_file);

      // Parse data
      const bstr = e.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(bstr);
      setExcelWorkbook(workbook);
    };
    if (f instanceof Blob) {
      excelReader.readAsArrayBuffer(f);
    }
  };

  const deleteFile = () => {
    setFile(null);
    setExcelWorkbook({});
  };
  //#endregion File utils

  const handleUpload = async () => {
    if (file.FileName.indexOf("xls") === -1) {
      setSnackbarErrorCustom("El archivo no es válido");
      return;
    }
    showLoadingStateSpinnerNoTimeout("Cargando archivo...");

    const sheet = excelWorkbook.worksheets[0];
    const data = [];
    sheet.eachRow((row, rowNumber) => {
      // Because row.values starts from index 1, remove the empty value in index 0
      let rowValues = row.values;
      rowValues.shift();
      data.push(rowValues);
    });

    // Headers will be ignored
    const headers = data[0];
    const headersWithContent = headers.filter((header) => header != null); // headers with content == headers withtout undefined values
    //

    // array of empty strings of the same length as the excel columns
    // this way we can use the excel specified by the user, with the columns in any order
    let header = Array.from(
      { length: excelDataOrganization.length - 1 },
      () => null,
    );
    excelDataOrganization.forEach((item) => {
      if (item.columnValue !== "addButtonColumnExcel")
        header[item.columnPosition] = item.columnValue;
    });

    const rows = excelDataOrganizationHeader ? data.slice(1) : data; // remove header if present
    const rowsWithContent = rows.filter((row) => row.join("") !== "");

    let parsedData = sheetDataToJSON(header, rowsWithContent);

    // check if any mandatory value is null
    const mandatoryColumns = excelDataOrganization.filter(
      (item) => item.columnValue !== "addButtonColumnExcel" && !item.modifiable,
    );
    let hasNullColumns = false;
    let nullColumn = "";
    mandatoryColumns.forEach((column) => {
      if (
        parsedData.some(
          (item) =>
            item[column.columnValue] === null ||
            item[column.columnValue] === undefined ||
            item[column.columnValue] === "",
        )
      ) {
        hasNullColumns = true;
        nullColumn = column.columnName;
      }
    });
    if (hasNullColumns) {
      setSnackbarErrorCustom(
        `El archivo no se pudo cargar. Verifique que la columna ${nullColumn} no tenga valores vacíos.`,
      );
      hideLoadingSpinner();
      return;
    }
    //

    // -- Parse yes/no values to boolean -- //
    const yesNoKeys = excelDataOrganization
      .filter(
        //TODO: (item) => item.columnType === "yesNo",
        (item) => item.columnName.toLowerCase().includes("si/no"),
      )
      .map((item) => item.columnValue);

    parsedData = parsedData.map((item) => {
      yesNoKeys.forEach((key) => {
        if (item[key].toLowerCase() === "si") item[key] = true;
        else if (item[key].toLowerCase() === "no") item[key] = false;
      });
      return item;
    });
    // --  -- //

    // -- Parse date values to date -- //
    const dateKeys = excelDataOrganization
      .filter(
        //TODO: (item) => item.columnType === "date")
        (item) => item.columnName.toLowerCase().includes("fecha"),
      )
      .map((item) => item.columnValue);

    try {
      parsedData = parsedData.map((item) => {
        dateKeys.forEach((key) => {
          if (item[key]) {
            // Only accepting dates with excel format
            if (item[key] instanceof Date) {
              // ExcelJS already parses dates
              item[key] = item[key].toISOString();
            } else {
              item[key] = excelDateToJSDate(item[key]).toISOString();
            }
          }
        });
        return item;
      });
    } catch (error) {
      setSnackbarErrorCustom(
        "Ocurrió un error al cargar una columna de tipo Fecha. Verifique que los valores de la columna tengan formato de fecha.",
      );
      hideLoadingSpinner();
      return;
    }
    // --  -- //
    // Check if any mandatory value is null
    const mandatoryItems = excelDataOrganization.filter((item) =>
      columns
        .getMinimumColumns()
        .find((column) => column.columnName === item.columnName),
    );
    let hasNullValue = false;
    parsedData.forEach((item, idx) => {
      if (hasNullValue) return;
      mandatoryItems.forEach((mandatory) => {
        if (
          item[mandatory.columnValue] === null ||
          item[mandatory.columnValue] === undefined ||
          item[mandatory.columnValue] === ""
        ) {
          setSnackbarErrorCustom(
            `El archivo no se pudo cargar. Verifique que el campo ${
              mandatory.columnName
            } en la fila ${
              excelDataOrganizationHeader ? idx + 2 : idx + 1
            } no esté vacío.`,
          );
          hideLoadingSpinner();
          hasNullValue = true;
          return;
        }
      });
    });
    if (hasNullValue) {
      hideLoadingSpinner();
      return;
    }

    const request = buildDTOToSave(parsedData);
    if (!request) {
      hideLoadingSpinner();
      return;
    }
    // return;

    manageOutsiderTenders(request)
      .then((response) => {
        setSnackbarSaveCustom("Licitaciones cargadas correctamente.");
        deleteFile();
      })
      .catch((error) => {
        setSnackbarErrorCustom(
          "Ocurrió un error al cargar el archivo. Por favor intente nuevamente.",
        );
      })
      .finally(() => {
        hideLoadingSpinner();
      });
  };

  const fillTenderDetail = (item, index, detailsLength) => {
    // Mandatory: tenderDetailItemNumber, tenderDetailQty, tenderDetailUom, tenderDetailDescription
    // If there are no values in these fields, then the tenderDetail is not created.
    // However, Uom is not mandatory in CL and is filled with "Unidad" by default.
    // Also, if there is no item number, then the tenderDetail will use the detailsLength as item number
    if (item.tenderDetailQty == null || item.tenderDetailDescription == null) {
      // setSnackbarErrorCustom(
      //   `Para Detalle de licitación se deben completar Cantidad y Descripción en la fila ${
      //     excelDataOrganizationHeader ? index + 2 : index + 1
      //   }.`,
      // );
      // TBD si se avisa con warning al menos
      return {};
    }
    if (item.tenderDetailItemNumber == null) {
      item.tenderDetailItemNumber = detailsLength + 1;
    }

    let td = {
      ItemNumber: item.tenderDetailItemNumber,
      Code: item.tenderDetailCode,
      Description: item.tenderDetailDescription,
      Qty: item.tenderDetailQty,
      UOM: item.tenderDetailUom ? item.tenderDetailUom : "Unidad",
      EstimatedValue: isLangCL() // CL does not provide this field. Uses the one from the tender
        ? item.estimatedValue ?? 0
        : item.tenderDetailEstimatedValue ?? 0,
      EstimatedValueCurrency: isLangCL() // CL does not provide this field. Uses the one from the tender
        ? item.currency
        : item.tenderDetailEstimatedValueCurrency,
      Observations: item.tenderDetailObservation,
      Specifications: item.tenderDetailSpecification,
      Conditioning: item.tenderDetailConditioning,
      DetailExtension: {
        Info: JSON.stringify({
          OfferedProduct: item.tenderDetailOfferedProduct, // Used in CL
        }),
      },
    };

    let awarded = fillTenderDetailAwarded(item);
    if (!Object.values(awarded).every((value) => value == null)) {
      td.Awardeds = [awarded];
    }
    return td;
  };

  const fillManageDetail = (item) => {
    // Given lang CL uses specific fields and is already offered ('cotizado'), we set it to true
    if (isLangCL()) {
      item.tenderManageDetailOffered = true;
    }
    // Mandatory: tenderManageDetailOffered
    // If there are no values in these fields, then the manageDetail is not created.
    if (item.tenderManageDetailOffered == null) {
      return {};
    }

    return {
      Offered: item.tenderManageDetailOffered,
      Comments: item.tenderManageDetailComments,
      EstimatedTotal: item.tenderManageDetailEstimatedTotal,
      DeliveryDate: item.tenderManageDetailDeliveryDate,
      ProductCode: item.tenderManageDetailProductCode,
      OfferedDate: item.tenderManageDetailOfferedDate,
    };
  };

  const fillTenderDetailAwarded = (item) => {
    // Mandatory: tenderDetailAwardedAmmount, tenderDetailAwardedQty
    // If there are no values in these fields, then the tenderDetailAwarded is not created.
    if (
      item.tenderDetailAwardedAmmount == null ||
      item.tenderDetailAwardedQty == null
    ) {
      return {};
    }

    return {
      Ammount: item.tenderDetailAwardedAmmount,
      Qty: item.tenderDetailAwardedQty,
      Description: item.tenderDetailAwardedCompetitorDescription,
      Currrency: item.tenderDetailAwardedCurrency,
      Competitor: {
        // BE looks if the competitor exists, if not, creates it
        UniqueId: item.tenderDetailAwardedCompetitorUniqueId,
        Name: item.tenderDetailAwardedCompetitorName,
      },
    };
  };

  //#region Info getters
  // -- Info getters -- //
  const getTenderType = (item, index) => {
    const type = tenderTypes.find((type) => type.name === item.tenderType);
    if (!type) {
      setSnackbarErrorCustom(
        `El ${intl.formatMessage({
          id: "cols.Tipo",
          defaultMessage: "Tipo",
        })} de licitación '${item.tenderType}' no existe en la fila ${
          excelDataOrganizationHeader ? index + 2 : index + 1
        }`,
      );
      // + 2 because the first row is the header and the index starts in 0
      return null;
    }
    return type.name;
  };

  const getTenderStatus = (item, index) => {
    const status = tendersStatus.find(
      (status) => status.name === item.tenderStatus,
    );
    if (!status) {
      setSnackbarErrorCustom(
        `La ${intl.formatMessage({
          id: "cols.Situacion",
          defaultMessage: "Situación",
        })} de licitación '${item.tenderStatus}' no existe en la fila ${
          excelDataOrganizationHeader ? index + 2 : index + 1
        }`,
      );
      // + 2 because the first row is the header and the index starts in 0
      return null;
    }
    return status.name;
  };

  const getTenderManageSeller = (item) => {
    const seller = sellers.find(
      (seller) =>
        (seller.firstName === item.tenderManageSeller.split(" ")[0].trim() &&
          seller.lastName === item.tenderManageSeller.split(" ")[1].trim()) ||
        (seller.firstName === item.tenderManageSeller.split(" ")[1].trim() &&
          seller.lastName === item.tenderManageSeller.split(" ")[0].trim()),
    );
    if (!seller) {
      const newSeller = {
        firstName: item.tenderManageSeller.split(" ")[0].trim(),
        lastName: item.tenderManageSeller.split(" ")[1].trim(),
        email: null,
      };
      return newSeller;
    }
    return seller;
  };

  const getTenderManageCategory = (item) => {
    const category = categories.find(
      (category) => category.name === item.tenderManageCategory,
    );
    if (!category) {
      const newCategory = {
        name: item.tenderManageCategory,
      };
      return newCategory;
    }
    return category;
  };

  const getTenderManageTag = (item) => {
    const tag = tags.find((tag) => tag.name === item.tenderManageTag);
    if (!tag) {
      const newTag = {
        name: item.tenderManageTag,
      };
      return newTag;
    }
    return tag;
  };

  //#endregion Info getters

  const verifyAwardedFields = (item, index) => {
    if (
      item.tenderDetailAwardedCompetitorUniqueId ||
      item.tenderDetailAwardedCompetitorName ||
      item.tenderDetailAwardedAmmount ||
      item.tenderDetailAwardedQty ||
      item.tenderDetailAwardedCurrency
    ) {
      if (
        !item.tenderDetailAwardedCompetitorUniqueId ||
        !item.tenderDetailAwardedCompetitorName ||
        item.tenderDetailAwardedAmmount === "" ||
        item.tenderDetailAwardedAmmount === undefined ||
        item.tenderDetailAwardedAmmount === null ||
        item.tenderDetailAwardedQty === "" ||
        item.tenderDetailAwardedQty === undefined ||
        item.tenderDetailAwardedQty === null ||
        !item.tenderDetailAwardedCurrency
      ) {
        setSnackbarErrorCustom(
          `Para Adjudicación/Oferta se deben completar ID único Competidor (CUIT), Competidor, Monto, Cantidad y Moneda
           en la fila ${excelDataOrganizationHeader ? index + 2 : index + 1}.`,
        );
        return false;
      }
    }
    return true;
  };

  const buildDTOToSave = (data) => {
    let result = [];
    let error = false;
    data.forEach((item, index) => {
      const tender = result.find(
        (resultItem) =>
          resultItem.TenderNumber === item.tenderNumber &&
          resultItem.ClientUnit?.UniqueId === item.clientUnitUniqueId,
      );
      // If the tender number is in results, means that this is another item(tenderDetail) of the same tender
      if (tender) {
        let tenderDetail = tender.Details.find(
          (tenderDetailItem) =>
            tenderDetailItem.ItemNumber === item.tenderDetailItemNumber,
        );
        // If the tenderDetail is in the tender, means that this is another awarded of the same tenderDetail
        if (tenderDetail) {
          if (!verifyAwardedFields(item, index)) {
            error = true;
            return;
          }
          let awarded = fillTenderDetailAwarded(item);
          // if all value are null, means that there is no awarded
          if (!Object.values(awarded).every((value) => value == null)) {
            tenderDetail.Awardeds.push(awarded);
          }
        } else {
          // If the tenderDetail is not in the tender, means that this is a new tenderDetail
          if (!verifyAwardedFields(item, index)) {
            error = true;
            return;
          }
          let detailsLength = tender.Details.length;
          let detail = fillTenderDetail(item, index, detailsLength);
          let manageDetail = fillManageDetail(item);
          let DetailToAdd = null;
          // Checks if the tenderDetail has the mandatory fields
          if (
            detail.ItemNumber != null &&
            detail.Qty != null &&
            detail.Description != null &&
            detail.UOM != null
          ) {
            DetailToAdd = { ...detail, ...manageDetail };
            tender.Details.push(DetailToAdd);
          }
        }
      } else {
        // If the tender number is not in results, means that this is a new tender
        if (!verifyAwardedFields(item, index)) {
          error = true;
          return;
        }
        let detailsLength = 0;
        let detail = fillTenderDetail(item, index, detailsLength);
        let manageDetail = fillManageDetail(item);

        let DetailToAdd = null;
        // Checks if the tenderDetail has the mandatory fields
        if (
          detail.ItemNumber != null &&
          detail.Qty != null &&
          detail.Description != null
        ) {
          DetailToAdd = { ...detail, ...manageDetail };
        }

        let request = {
          // Extension solo va el source name, osea: (nuevo campo, que completen ellos)
          Extension: {
            Info: JSON.stringify({
              SourceName: item.sourceName,
              Mail: item.mail,
            }),
          },
          // Infos: [], // no se sube
          Details: DetailToAdd ? [DetailToAdd] : [],
          // Stages: [], //se crea en el BE
          // Questions: [], // no se sube
          Sellers: item.tenderManageSeller ? [getTenderManageSeller(item)] : [], // buscar por name, y sino ponerlo como nuevo
          Categories: item.tenderManageCategory
            ? [getTenderManageCategory(item)]
            : [], // buscar por name, y sino ponerlo como nuevo
          Tags: item.tenderManageTag ? [getTenderManageTag(item)] : [], // buscar por name, y sino ponerlo como nuevo
          TenderNumber: item.tenderNumber.trim(), // si hay mismo tender number, se agregan los details nomas
          FileNumber: item.fileNumber
            ? item.fileNumber.trim()
            : item.tenderNumber.trim(),
          TenderType: isLangCL()
            ? "Convenio Marco"
            : getTenderType(item, index), // mandan la string (se da en excel opciones) --> despues se mapea (BE)
          Status: getTenderStatus(item, index), // mandan la string (se da en excel opciones) --> despues se mapea (BE)
          PublishedDate: item.publishedDate,
          EndDate: item.endDate,
          AwardDate: item.awardDate,
          TenderName: item.tenderName,
          Link: item.link?.hyperlink ? item.link.hyperlink : item.link,
          TenderDescription: item.tenderDescription,
          Currency: item.currency,
          EstimatedValue: item.estimatedValue ? item.estimatedValue : 0,
          BidTypeLine: null, // probablemente no se use
          ScrapedDate: new Date().toISOString(), //automatic -> fecha de hoy (poner aca)
          SourceId: getSourceId(), // externo, completado x nosotros (extAR, etc)
          ClientUnit: {
            // BE looks if the clientUnit exists, if not, creates it
            UniqueId: item.clientUnitUniqueId.trim(),
            Name: item.clientUnitName.trim(),
            ...(item.clientUniqueId != null || item.clientName != null
              ? {
                  Client: {
                    UniqueId: item.clientUniqueId.trim(),
                    Name: item.clientName.trim(),
                  },
                }
              : {}),
          },
          BidSecurity: item.bidSecurity,
          FaithfulObservance: item.faithfulObservance,
        };

        if (!request.TenderType) {
          error = true;
          return;
        }
        if (!request.Status) {
          error = true;
          return;
        }
        result.push(request);
      }
    });
    if (error) {
      return null;
    }
    result = nullifyUndefinedValues(result);

    return result;
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "1.5rem",
          gap: "1rem",
        }}>
        <Typography sx={{ fontSize: 14 }}>
          Seleccione las columnas del archivo Excel y sus respectivas
          posiciones.
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          width: 900,
          justifyContent: "space-between",
        }}>
        <Card className="card-box bg-secondary p-2" sx={{ my: 4, width: 400 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs sx={{ alignSelf: "center" }}>
              <Typography
                sx={{ fontSize: "medium", marginLeft: "4%" }}
                variant="h6">
                Contiene encabezado
              </Typography>
            </Grid>
            <Grid item xs sx={{ textAlign: "center" }}>
              <Switch
                sx={{ whiteSpace: "nowrap" }}
                color="primary"
                checked={excelDataOrganizationHeader}
                name={"hasHeader"}
                onChange={handleChangeHeaderSwitch}
                size="medium"
              />
            </Grid>
          </Grid>
        </Card>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setExcelDataOrganization([
              ...columns.getAllColumns(),
              ...excelDataAdd,
            ]);
          }}>
          Agregar todas las columnas disponibles
        </Button> */}
      </div>

      <Grid container spacing={2}>
        {excelDataOrganization.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              className="card-box bg-secondary p-3"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                display: "flex",
              }}>
              {item.modifiable ? (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "70%",
                    maxWidth: "70%",
                  }}>
                  <Tooltip title={item.columnName}>
                    <TextField
                      id="outlined-basic"
                      label="Nombre"
                      variant="outlined"
                      name="columnValue"
                      value={item.columnValue}
                      onChange={(event) => handleChange(event, item)}
                      size="small"
                      fullWidth
                      select>
                      {columns.getExtraColumns().map((option) => (
                        <MenuItem
                          key={option.columnValue}
                          value={option.columnValue}>
                          {option.columnName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </Box>
              ) : (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "80%",
                    flexWrap: "wrap",
                  }}>
                  <Typography
                    sx={{ fontSize: "medium", textAlign: "start" }}
                    variant="h6">
                    {item.columnName}
                    {item.columnValue !== "addButtonColumnExcel" && (
                      <span style={{ color: "red" }}> *</span>
                    )}
                  </Typography>
                </Box>
              )}

              {item.columnValue === "addButtonColumnExcel" ? (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <IconButton onClick={handleAddColumn}>
                    <AddCircle />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                    ml: 1,
                  }}>
                  {item.modifiable && (
                    <IconButton
                      onClick={(event) => handleRemoveColumn(event, item)}>
                      <Delete color="error" />
                    </IconButton>
                  )}
                  <TextField
                    fullWidth
                    value={item.columnPosition}
                    variant="outlined"
                    size="small"
                    name={item.columnValue}
                    onChange={(event) => handleChange(event, item, true)}
                    sx={{ width: 70 }}
                    select>
                    {getExcelColumns().map((excelColumns) => (
                      <MenuItem key={excelColumns.id} value={excelColumns.id}>
                        {excelColumns.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      {duplicateExcelPositions.value && (
        <Typography
          sx={{ textAlign: "center", color: "red", pt: 4 }}
          variant="h5">
          {duplicateExcelPositions.message}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          gap: "1rem",
        }}>
        <input
          accept=".xls,.xlsx"
          style={{ display: "none" }}
          id="upload-button-file"
          type="file"
          hidden
          onInput={(e) => {
            handleFileUpload(e);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={addFiles}
          disabled={file !== null}>
          Adjuntar Archivo
        </Button>
        {messageErrorFile && <Alert severity="error">{messageErrorFile}</Alert>}
        <List dense={false} sx={{ mt: "-0.35rem" }}>
          {!file ? (
            <ListItem>
              <Typography>Ningún archivo seleccionado</Typography>
            </ListItem>
          ) : (
            <>
              <ListItem>
                <ListItemText primary={file.FileName} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    size="small"
                    aria-label="delete"
                    onClick={deleteFile}
                    sx={{ ml: 1 }}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>
          )}
        </List>
      </Box>
      <Button
        color="primary"
        variant="contained"
        onClick={handleUpload}
        disabled={file === null}
        sx={{ mt: 2 }}>
        Enviar archivo
      </Button>
    </Fragment>
  );
};

export default OutsiderTendersExcelInsert;
