import { Flag } from "@mui/icons-material";
import ModalCRUD from "./ModalCRUD";
import {
  addOrUpdateManageInternalStatus,
  deleteManageInternalStatus,
  getManageInternalStatus,
} from "./administrationServices";

const columns = [
  { accessorKey: "description", header: "Descripción" },
  {
    accessorKey: "colorCode",
    header: "Imagen",
    disableTooltip: true,
    Cell: ({ cell, column }) => {
      return <Flag style={{ color: cell.getValue() }} sx={{ fontSize: 28 }} />;
    },
    editType: "colorpicker",
    hideToExport: true,
  },
];

const messages = {
  saveSuccess: "La situación se almacenó correctamente.",
  deleteSuccess: "La situación se eliminó correctamente.",
  missingData: "Por favor ingrese nombre y color de la situación.",
  cannotDelete:
    "La situación no puede ser eliminada porque está siendo utilizada.",
  deleteConfirmation: (item) =>
    `¿Está seguro que desea eliminar la situación ${item?.description}?`,
};

const InternalStatusModal = ({ open, onClose }) => {
  const saveVerification = (data) => {
    return data.description && data.colorCode;
  };

  const handleDelete = async (item) => {
    const request = [{ manageInternalStatusId: item.manageInternalStatusId }];
    await deleteManageInternalStatus(request);
  };

  const handleSave = async (data) => {
    const request = [data];
    await addOrUpdateManageInternalStatus(request);
  };

  return (
    <ModalCRUD
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      columns={columns}
      tableInfo={{ id: "gridInternalStatusModal", header: "Situaciones" }}
      modalTitle={"Situaciones"}
      fetchFunction={getManageInternalStatus}
      saveVerification={saveVerification}
      messages={messages}
    />
  );
};

export default InternalStatusModal;
