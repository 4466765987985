import { post, get, put, Delete } from "config/axios";

export const getWithParams = async (request, baseUrl) => {
  /* given request is an object with the following structure:
    request = {
      OrderBy: pagination.orderBy,
      PageSize: pagination.pageSize,
      Filter: pagination.filter,
      PageNumber: pagination.pageNumber,
      OrderAsc: pagination.orderAsc,
    };
    add these as query params to the url if they are not null (using the same name as the object key)
    */
  let url = `/api/${baseUrl}?`;
  for (const key in request) {
    if (request[key] != null && request[key] !== "") {
      url += `${key}=${request[key]}&`;
    }
  }

  // if last char is & or ? remove it
  if (url.endsWith("&") || url.endsWith("?")) {
    url = url.slice(0, -1);
  }
  return await get(url);
};

const common = {
  getSource: async () => {
    return await get("/api/source");
  },
};

export default common;
