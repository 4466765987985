import { Fragment, useCallback, useEffect, useState } from "react";

import { Box, Card, Grid } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import {
  getFiltersCount,
  listAllSearchFilters,
} from "pages/SmartSearch/smartSearchService";
import Chart from "react-apexcharts";
import { formatDate } from "utils/common";
import HomeLoadingState from "../HomeLoadingState";
import HomeTitle from "../HomeTitle";
import { FindInPage } from "@mui/icons-material";

const LoadingState = () => {
  return (
    <Fragment>
      <HomeTitle title="Smart Search" TitleIcon={FindInPage} />
      <HomeLoadingState />
    </Fragment>
  );
};

const OpenTendersChart = ({ openTenders }) => {
  // -- Chart width logic -- //
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const handleResize = useCallback(() => setWidth(window.innerWidth), []);
  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  const handleChartWidth = () => {
    const openTendersByFilterChart = document.getElementById(
      "openTendersByFilterChart",
    );
    if (openTendersByFilterChart) {
      setChartHeight(openTendersByFilterChart.clientHeight - 48);
      if (openTendersByFilterChart.clientWidth - 32 > 1230) {
        setChartWidth(1230);
        return;
      }
      setChartWidth(openTendersByFilterChart.clientWidth - 32);
    }
  };
  useEffect(() => {
    handleChartWidth();
  }, [width]);
  // --  -- //

  const longestString = (arr) => {
    let max = 0;
    arr.forEach((str) => {
      if (str.length > max) max = str.length;
    });
    return max;
  };
  const longest = longestString(openTenders.labels);
  const getWidthByLongestString = (longest) => {
    const x = 450;
    if (longest <= 20) {
      return chartWidth > x ? chartWidth : x;
    } else if (longest <= 50) {
      return chartWidth > x + longest * 5 ? chartWidth : x + longest * 5;
    } else if (longest <= 100) {
      return chartWidth > x + longest * 6 ? chartWidth : x + longest * 6;
    } else if (longest <= 121) {
      return chartWidth > x + longest * 6 ? chartWidth : x + longest * 6;
    } else {
      return chartWidth > x + longest * 6.5 ? chartWidth : x + longest * 6.5;
    }
  };

  return (
    <Card className="card-box mb-4" sx={{ height: "calc(100% - 1rem)" }}>
      <div className="card-header">
        <div className="card-header--title font-weight-bold">
          Licitaciones abiertas por filtro
        </div>
        <div className="card-header--actions"></div>
      </div>
      <Box
        className="d-flex"
        sx={{
          py: 2,
          width: "100%",
          overflowX: "auto",
          height: "calc(100% - 3rem)",
          justifyContent: "flex-start",
          maxHeight: 545,
        }}
        id="openTendersByFilterChart">
        <Chart
          options={{
            labels: openTenders.labels,
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      showAlways: true,
                      label: openTenders.series.reduce((a, b) => a + b, 0),
                      fontSize: "24px",
                      fontWeight: 600,
                      color: "#888",
                      formatter(w) {
                        return "Total";
                      },
                    },
                  },
                },
              },
            },
            tooltip: {
              y: {
                formatter(value) {
                  return `${value} licitaciones`;
                },
              },
            },
          }}
          series={openTenders.series}
          type="donut"
          width={getWidthByLongestString(longest)}
          height={chartHeight < 545 ? 545 - 48 : chartHeight}
        />
      </Box>
    </Card>
  );
};

export default function HomeSmartSearchStats({ homeStats, sources, loading }) {
  const [isLoading, setIsLoading] = useState(true);

  // Cant de licitaciones abiertas por filtro
  const [openTenders, setOpenTenders] = useState({
    result: [],
    labels: [],
    series: [],
  });
  // Cant de licitaciones scrapeadas del dia anterior
  const scrapedTenders = {
    ammount: homeStats?.newOpenTendersToday,
    time: `${formatDate(sources[0]?.lastScrapedDate)}hs`,
  };
  // Cant de licitaciones abiertas por portal
  const openTendersByPortal = homeStats?.openTendersBySource || [];

  const getSSFiltersCount = async () => {
    const response = await listAllSearchFilters();
    const ids = response.data.currentPageItems.map(
      (item) => item.searchFilterId,
    );
    const counts = await getFiltersCount(ids);

    const result = response.data.currentPageItems
      .map((item) => {
        return {
          ...item,
          count: counts.data.find((obj) => obj.filterId === item.searchFilterId)
            .count,
        };
      })
      .sort((a, b) => b.count - a.count);

    setOpenTenders({
      result,
      labels: result.map((item) => item.name),
      series: result.map((item) => item.count),
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getSSFiltersCount();
  }, []);

  if (loading || isLoading) {
    return <LoadingState />;
  }

  return (
    <Fragment>
      <HomeTitle title="Smart Search" TitleIcon={FindInPage} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} flexDirection={"column"}>
          <Grid item xs={12}>
            <Card className="card-box border-0 card-shadow-first p-4 mb-4">
              <div className="d-flex align-items-center">
                <div className="d-40 rounded-circle bg-first text-white text-center font-size-lg mr-3">
                  <LanguageIcon />
                </div>
                <div className="text-black-50">Nuevos resultados hoy</div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                <div>{scrapedTenders.ammount}</div>
              </div>
              <div className="text-black-50 text-center opacity-6 pt-3">
                Última actualización <b>{scrapedTenders.time}</b>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="card-box mb-4">
              <div className="card-header">
                <div className="card-header--title font-weight-bold">
                  Licitaciones abiertas por portal
                </div>
                <div className="card-header--actions"></div>
              </div>
              <Box
                className="table-responsive"
                sx={{
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    WebkitAppearance: "none",
                    width: "10px",
                    height: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    backgroundColor: "#a9a9cfc2",
                    WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f5f5f5",
                  },
                  height: 336,
                }}>
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: "70%" }}>Portal</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openTendersByPortal.map((item) => (
                      <tr key={item.sourceId}>
                        <td>
                          <div className="align-box-row">
                            <span>{item.sourceName}</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center font-size-lg">
                            <small className="text-black-50 d-block">
                              {item.ammount}
                            </small>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <OpenTendersChart openTenders={openTenders} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
