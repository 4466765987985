import {
  Box,
  Grid,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useGlobalStore } from "global-store/useGlobalStore";
import { LangContext } from "intl/context/langContext";
import { Fragment, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  addOrUpdateCompanySetting,
  listCompanySettings,
} from "./settingsService";

const EmailSubjectConfigModal = ({ open, onClose }) => {
  const langChange = useContext(LangContext);
  const intl = useIntl();
  const initialAvailableValues = langChange.configForm[
    "EmailSubjectConfig"
  ].elements.map((item) => ({
    ...item,
    subjectName: intl.formatMessage({ id: item.id, defaultMessage: "N/A" }),
    translation: intl.formatMessage({ id: item.id, defaultMessage: "N/A" }),
    active: false,
  }));
  const { showSavingStateSpinnerNoTimeout, hideLoadingSpinner, setSnackbar } =
    useGlobalStore((state) => ({
      showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
      hideLoadingSpinner: state.hideLoadingSpinner,
      setSnackbar: state.setSnackbar,
    }));
  const [companySettings, setCompanySettings] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectItems, setSubjectItems] = useState([]);
  const [availableValues, setAvailableValues] = useState(
    initialAvailableValues,
  );

  const handleSave = async () => {
    showSavingStateSpinnerNoTimeout();
    let subjectItemsToSave = subjectItems.join(",");
    await addOrUpdateCompanySetting({
      ...companySettings,
      subject: subjectItemsToSave,
    });
    hideLoadingSpinner();

    setSnackbar({
      open: true,
      severity: "success",
      title: "Éxito",
      subTitle: "La configuración ha sido almacenada.",
    });
    onClose();
  };

  const initData = async () => {
    const result = await listCompanySettings();
    setCompanySettings(result.data);
    let availableValuesCopy = [...initialAvailableValues];
    availableValuesCopy.forEach((item) => {
      item.active = result.data.subject.includes(item.name);
    });
    setAvailableValues(availableValuesCopy); //TODO: use structuredClone ?

    let subjectCopy = "";
    // sort the array by the order of the subject without modifying the original array
    let availableValuesCopyToSort = [...availableValuesCopy];
    availableValuesCopyToSort
      .sort((a, b) => {
        return (
          result.data.subject.indexOf(a.name) -
          result.data.subject.indexOf(b.name)
        );
      })
      .filter((item) => item.active)
      .forEach((item, idx) => {
        if (result.data.subject.includes(item.name)) {
          subjectCopy += `${item.subjectName} | `;
          if (
            idx ===
            availableValuesCopy.filter((item) => item.active).length - 1
          ) {
            subjectCopy = subjectCopy.slice(0, -3);
          }
        }
      });

    setSubject(subjectCopy);
    setSubjectItems(
      availableValuesCopy
        .filter((item) => item.active)
        .map((item) => item.name),
    );
  };

  const handleChange = (event) => {
    let availableValuesCopy = [...availableValues];
    availableValuesCopy.forEach((item) => {
      if (item.name === event.target.name) {
        item.active = event.target.checked;
      }
    });
    setAvailableValues(availableValuesCopy);
    let subjectCopy = "";
    let subjectItemsCopy = [];
    // when the user checks or unchecks a checkbox, it needs to mantain the order of the subject
    if (event.target.checked) {
      subjectItemsCopy = [...subjectItems, event.target.name];
    } else {
      subjectItemsCopy = subjectItems.filter(
        (item) => item !== event.target.name,
      );
    }
    subjectItemsCopy.forEach((item, idx) => {
      let itemToPush = availableValuesCopy.find((i) => i.name === item);
      subjectCopy += `${itemToPush.subjectName} | `;
      if (idx === subjectItemsCopy.length - 1) {
        subjectCopy = subjectCopy.slice(0, -3);
      }
    });
    setSubject(subjectCopy);
    setSubjectItems(subjectItemsCopy);
  };

  useEffect(() => {
    if (open) initData();
  }, [open]);

  const maxTextsWidth =
    availableValues.reduce(
      (max, p) => (p.translation.length > max ? p.translation.length : max),
      0,
    ) *
      2 +
    180;

  return (
    <Fragment>
      <GeneralPurposeModal
        title="Configuración Correo GL"
        open={open}
        onClose={onClose}
        onSave={handleSave}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}>
            <Typography sx={{ fontSize: "medium", width: "40%" }} variant="h6">
              Plantilla asunto
            </Typography>
            <Tooltip
              title={`${subject ? "[" : ""}${subject}${
                subject ? "]" : ""
              } Asunto del email`}
              placement="top">
              <TextField
                sx={{ mt: "20px" }}
                fullWidth
                value={`${subject ? "[" : ""}${subject}${
                  subject ? "]" : ""
                } Asunto del email`}
                disabled
                variant="outlined"
                size="small"
                helperText="Los valores entre llaves se reemplazarán por los valores correspondientes."
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Valores Disponibles</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 2,
                alignItems: "center",
              }}>
              {availableValues.map((item, idx) => (
                <Stack
                  key={idx}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center">
                  <Typography
                    sx={{ fontSize: 14, width: maxTextsWidth }}
                    variant="h6">
                    {item.translation}
                  </Typography>
                  <Switch
                    color="primary"
                    checked={item.active}
                    name={item.name}
                    onChange={handleChange}
                    size="small"
                  />
                </Stack>
              ))}
            </Box>
          </Grid>
        </Grid>
      </GeneralPurposeModal>
    </Fragment>
  );
};

export default EmailSubjectConfigModal;
