import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShopIcon from "@mui/icons-material/Shop";
import HomeIcon from "@mui/icons-material/Home";
import { isLangCL, isLangPE } from "utils/common";

const navItems = (intl) => [
  {
    label: "Menú de navegación",
    content: [
      {
        name: "HOME",
        label: "Inicio",
        icon: HomeIcon,
        to: "/",
      },
      {
        name: "SS",
        label: "SmartSearch",
        icon: FindInPageIcon,
        content: [
          {
            label: "Resultados",
            icon: FindInPageIcon,
            to: "/smart-search/resultados",
          },
          {
            label: "Filtros",
            icon: FilterAltIcon,
            to: "/smart-search/filtros",
          },
        ],
      },
      {
        name: "GL",
        label: "Gestión",
        icon: AppRegistrationIcon,
        content: [
          {
            label: "Gestión",
            icon: AppRegistrationIcon,
            to: "/gestion",
          },
          {
            label: "Calendario",
            icon: CalendarTodayIcon,
            to: "/gestion/calendario",
          },
          ...(!isLangCL()
            ? [
                {
                  label: intl.formatMessage({
                    id: "tab.CartasFianza",
                    defaultMessage: "Cartas Fianza",
                  }),
                  icon: AccountBalanceIcon,
                  to: "/gestion/garantias",
                },
              ]
            : []),
          ...(isLangPE()
            ? [
                {
                  label: "Penalidades",
                  icon: MonetizationOnIcon,
                  to: "/gestion/penalidades",
                },
              ]
            : []),
        ],
      },
      {
        name: "AL",
        label: "Análisis Licitaciones",
        icon: BarChartIcon,
        content: [
          {
            label: "Reportes",
            icon: BarChartIcon,
            to: "/analisis",
          },
          {
            label: "Filtros",
            icon: FilterAltIcon,
            to: "/analisis/filtros",
          },
        ],
      },
      {
        name: "OC",
        label: "Órden de Compra",
        icon: ShopIcon,
        content: [
          {
            label: "Reportes",
            icon: ShopIcon,
            to: "/orden-compra",
          },
          {
            label: "Filtros",
            icon: FilterAltIcon,
            to: "/orden-compra/filtros",
          },
        ],
      },
      {
        name: "PAC",
        label: "PAC",
        icon: CalendarMonthIcon,
        to: "/pac",
      },
    ],
  },
];

export default navItems;
