import { BrowserRouter } from "react-router-dom";
import { store } from "config/reduxConfig";
import { Provider } from "react-redux";
import AppRoutes from "./Routes";
import ScrollToTop from "./utils/ScrollToTop";
import "./assets/base.scss";
import CssBaseline from "@mui/material/CssBaseline";

import { persistState } from "utils/statePersistence";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  QueryClient as TSQueryClient,
  QueryClientProvider as TSQueryClientProvider,
} from "@tanstack/react-query";
import { LangProvider } from "intl/context/langContext";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { throttle } from "throttle-debounce";
import { acquireToken, login } from "config/msalConfig";
import { isCurrentUrlProtocolValid } from "utils/common";
import { L10n, registerLicense } from "@syncfusion/ej2-base";
import { useState, useEffect, useMemo } from "react";
import { ErrorComponent } from "components";
import { addIconsToLibrary } from "utils/addIconsToLibrary";

addIconsToLibrary();

store.subscribe(
  throttle(500, () => {
    persistState(store.getState());
  }),
);

// L10n.load({ "es-ES": es });

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

const getValidUrlProtocol = () => {
  if (!isCurrentUrlProtocolValid()) {
    window.location.href = window.location.href.replace("http://", "https://");
  }
  return true;
};

function App() {
  const [validUrlProtocol, setValidUrlProtocol] = useState(
    getValidUrlProtocol(),
  );
  const [acquireTokenError, setAcquireTokenError] = useState(null);

  const init = async () => {
    if (validUrlProtocol) {
      const { error, token } = await acquireToken();
      if (!!error || !token) {
        await login();
      } else if (!error) {
        setAcquireTokenError(error);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
    },
  });
  const tsQueryClient = new TSQueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        // refetchOnWindowFocus: false,
      },
    },
  });

  const browserHistory = createBrowserHistory({ basename: "" });

  const reactPlugin = useMemo(() => new ReactPlugin(), []);

  let appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INST_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={ErrorComponent}
        appInsights={reactPlugin}>
        <LangProvider>
          <QueryClientProvider client={queryClient}>
            <TSQueryClientProvider client={tsQueryClient}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Provider store={store}>
                  <BrowserRouter basename="/">
                    <CssBaseline />
                    <ScrollToTop>
                      <AppRoutes
                        validUrlProtocol={validUrlProtocol}
                        acquireTokenError={acquireTokenError}
                      />
                    </ScrollToTop>
                  </BrowserRouter>
                </Provider>
              </LocalizationProvider>
            </TSQueryClientProvider>
          </QueryClientProvider>
        </LangProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export default App;
