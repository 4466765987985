import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useState } from "react";
import { formatValueLocale } from "utils/common";
import {
  deleteAnalysisFilter,
  getAnalysisFiltersList,
} from "../analysisService";
import ManageAnalysisFiltersModal from "./ManageAnalysisFiltersModal";
import { useIntl } from "react-intl";

export const HeaderTable = (intl) => [
  {
    accessorKey: "operationCount",
    header: "Op.",
    size: 100,
    align: "center",
  },
  {
    accessorKey: "lastOperationDate",
    header: "Ultima Op.",
    size: 180,
    align: "center",
    cellType: "datetime",
  },
  {
    accessorKey: "analysisFilterName",
    header: intl.formatMessage({
      id: "filtros.Nombre",
      defaultMessage: "Nombre",
    }),
    size: 180,
  },
  {
    accessorKey: "types",
    header: "Tipos",
    size: 120,
    align: "center",
    enableSorting: false,
    Cell: ({ cell }) => {
      if (!cell.getValue().length) return "Todos";
      return cell
        .getValue()
        ?.map((item) => item.code)
        .join(", ");
    },
  },
  {
    accessorKey: "dateRangeName",
    header: intl.formatMessage({
      id: "filtros.Periodo",
      defaultMessage: "Período",
    }),
    size: 160,
    align: "center",
    enableSorting: false,
  },
  {
    accessorKey: "startDate",
    header: intl.formatMessage({
      id: "filtros.Desde",
      defaultMessage: "Desde",
    }),
    size: 150,
    align: "center",
    cellType: "date",
  },
  {
    accessorKey: "endDate",
    header: intl.formatMessage({
      id: "filtros.Hasta",
      defaultMessage: "Hasta",
    }),
    size: 150,
    align: "center",
    cellType: "date",
  },
  {
    accessorKey: "priceRangeFrom",
    header: "Desde $",
    size: 120,
    align: "center",
    Cell: ({ cell }) => formatValueLocale(cell.getValue()),
  },
  {
    accessorKey: "priceRangeTo",
    header: "Hasta $",
    size: 120,
    align: "center",
    Cell: ({ cell }) => formatValueLocale(cell.getValue()),
  },
  {
    accessorKey: "includeWords",
    header: `Incluir por ${intl.formatMessage({
      id: "filtros.IncluirExcluirItem",
      defaultMessage: "Ítem",
    })}`,
    size: 200,
    align: "center",
  },
  {
    accessorKey: "excludeWords",
    header: `Excluir por ${intl.formatMessage({
      id: "filtros.IncluirExcluirItem",
      defaultMessage: "Ítem",
    })}`,
    size: 200,
    align: "center",
  },
  {
    accessorKey: "organisms",
    header: "Entidades",
    size: 150,
    align: "center",
    allowSorting: false,
    Cell: ({ cell }) => cell.getValue().length || "0",
  },
  {
    accessorKey: "competitors",
    header: "Competidores",
    size: 150,
    align: "center",
    allowSorting: false,
    Cell: ({ cell }) => cell.getValue().length || "0",
  },
  {
    accessorKey: "creationDate",
    header: "Creación",
    size: 150,
    align: "center",
    cellType: "datetime",
  },
  {
    accessorKey: "createdByName",
    header: "Creado por",
    size: 150,
    align: "center",
  },
  {
    accessorKey: "lastUpdateDate",
    header: "Modificación",
    size: 150,
    align: "center",
    cellType: "datetime",
  },
  {
    accessorKey: "updatedByName",
    header: "Modificado por",
    size: 150,
    align: "center",
  },
];

const AnalysisFiltersAdmin = ({ analysisModuleId }) => {
  const {
    setSnackbarSaveChanges,
    showLoadingStateSpinner,
    showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    setShouldRefetch,
    setSnackbarErrorCustom,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    showLoadingStateSpinner: state.showLoadingStateSpinner,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setShouldRefetch: state.setShouldRefetch,
    setSnackbarErrorCustom: state.setSnackbarErrorCustom,
  }));
  const intl = useIntl();

  const fetchTableData = async (page, pageSize, filter, sorting) => {
    let orderBy = sorting.length > 0 ? sorting[0].id : "lastOperationDate";
    let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;
    const response = await getAnalysisFiltersList(
      {
        pageNumber: page,
        pageSize: pageSize,
        orderBy: orderBy,
        orderAsc: orderAsc,
        filter: filter,
      },
      analysisModuleId,
    );

    return {
      result: response.data.currentPageItems,
      total: response.data.totalItems,
    };
  };

  const [modalFilterManageOpen, setModalFilterManageOpen] = useState(false);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToModify, setItemToModify] = useState(null);

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };
  const handleCloseModalFilterManage = () => {
    setModalFilterManageOpen(false);
    setItemToModify(null);
  };

  const handleDelete = async () => {
    showSavingStateSpinnerNoTimeout();
    deleteAnalysisFilter(itemToDelete.analysisFilterId)
      .then(() => {
        hideLoadingSpinner();
        setSnackbarSaveChanges();
        handleCloseModalDeleteConfirm();
        setShouldRefetch();
      })
      .catch(() => {
        hideLoadingSpinner();
        handleCloseModalDeleteConfirm();
        setSnackbarErrorCustom(
          "El filtro no puede ser eliminado porque está siendo utilizado.",
        );
      });
  };

  const handleSave = async (request) => {
    handleCloseModalFilterManage();
    setShouldRefetch();

    return true;
  };

  const options = {
    tableId: `gridAnalysisFiltersAdmin${analysisModuleId}`,
    header: HeaderTable(intl),
    fullHeight: true,
    tableHeaderTitle: "Listado de filtros",
    toolbarButtons: {
      Add: true,
      AddFunc: () => setModalFilterManageOpen(true),
      Export: true,
      ExportFilename: "Filtros de Visualización de Reportes",
    },
    enableEditing: true,
    enableDeleting: true,
    editFunc: (row) => {
      setItemToModify(row);
      setModalFilterManageOpen(true);
    },
    deleteFunc: (row) => {
      setItemToDelete(row);
      setModalDeleteConfirm(true);
    },
  };

  return (
    <Fragment>
      <FSTMaterialReactTable options={options} getData={fetchTableData} />

      <ManageAnalysisFiltersModal
        open={modalFilterManageOpen}
        onClose={handleCloseModalFilterManage}
        onSave={handleSave}
        filter={itemToModify}
        analysisFilterModuleId={analysisModuleId}
      />
      <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={`¿Está seguro que desea eliminar el filtro ${itemToDelete?.analysisFilterName}?`}
      />
    </Fragment>
  );
};

export default AnalysisFiltersAdmin;
