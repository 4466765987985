import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import hero1 from "assets/images/hero-bg/hero-1.jpg";
import ResponsiveTable from "components/ResponsiveTable/ResponsiveTable";
import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { getAnalysisFiltersList } from "../analysisService";

const SearchFiltersModal = ({
  open,
  onClose,
  analysisFilterModuleId,
  setSelectedFilter,
  height = null,
}) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchResults = async (newValue) => {
    const response = await getAnalysisFiltersList(
      {
        pageNumber: 1,
        pageSize: 9999,
        orderBy: "lastOperationDate",
        orderAsc: false,
        filter: newValue,
      },
      analysisFilterModuleId,
    );

    setResults(response.data.currentPageItems);
    setLoading(false);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length <= 1) return;
    setTimeout(() => {
      setLoading(true);
      fetchResults(e.target.value);
    }, 1000);
  };

  useEffect(() => {
    if (!open) {
      setSearch("");
      setResults([]);
      setLoading(false);
    }
  }, [open]);

  const handleRowClick = (row) => {
    setSelectedFilter({
      uniqueId: row.analysisFilterId,
      name: row.analysisFilterName,
    });
    onClose();
  };

  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="md">
      <DialogTitle
        id="form-dialog-title"
        className="bg-composed-wrapper bg-plum-plate h-100"
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}>
        <div
          className="bg-composed-wrapper--image "
          style={{ backgroundImage: "url(" + hero1 + ")", zIndex: "0" }}
        />
        <span
          className="font-weight-bold text-white m-0"
          style={{
            fontSize: "1.1875rem",
            lineHeight: 1.2,
            fontWeight: 700,
          }}>
          Búsqueda de filtros
        </span>
      </DialogTitle>
      <div
        className="divider border-2 border-light rounded"
        style={{
          width: "100%",
          display: "flex",
          alignSelf: "center",
        }}
      />
      <DialogContent sx={{ overflow: "initial" }}>
        <TextField
          id="search"
          label="Buscar..."
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          // error={search.length <= 1}
          helperText={
            search.length <= 1 ? "Debe ingresar al menos 2 caracteres" : ""
          }
          fullWidth
          sx={{ p: 0 }}
        />
        <div
          className="divider border-2 border-light rounded"
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
            marginTop: "1.5rem",
          }}
        />
      </DialogContent>
      <DialogContent
        sx={{
          // Custom scrollbar
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            WebkitAppearance: "none",
            width: "12px",
            height: "12px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(118, 75, 162, 0.4)",
            WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f5f5f5",
          },
          ...(height && { height: height }),
        }}>
        {/* loading */}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "5rem",
              pb: "5rem",
            }}>
            <PulseLoader color={"var(--primary)"} loading={true} />
          </Box>
        )}

        {/* results */}
        {!loading && (
          <ResponsiveTable
            columns={[
              {
                key: "operationCount",
                label: "Op.",
              },
              {
                key: "lastOperationDate",
                label: "Última Op.",
                cellType: "datetime",
              },
              {
                key: "analysisFilterName",
                label: "Nombre",
              },
              {
                key: "dateRangeName",
                label: "Período",
              },
              {
                key: "startDate",
                label: "Desde",
                cellType: "date",
              },
              {
                key: "endDate",
                label: "Hasta",
                cellType: "date",
              },
            ]}
            data={results}
            loading={loading}
            rowOnClick={(row) => {
              handleRowClick(row);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchFiltersModal;
