import langAR from "../lang/format-message/es-AR.json";
import langCR from "../lang/format-message/es-CR.json";
import langPE from "../lang/format-message/es-PE.json";
import langCL from "../lang/format-message/es-CL.json";
import langCO from "../lang/format-message/es-CO.json";
import langBO from "../lang/format-message/es-BO.json";
import langMX from "../lang/format-message/es-MX.json";
import langEC from "../lang/format-message/es-EC.json";

import configAR from "../lang/format-form/ObjectFormAR";
import configCR from "../lang/format-form/ObjectFormCR";
import configPE from "../lang/format-form/ObjectFormPE";
import configCL from "../lang/format-form/ObjectFormCL";
import configCO from "../lang/format-form/ObjectFormCO";
import configBO from "../lang/format-form/ObjectFormBO";
import configMX from "../lang/format-form/ObjectFormMX";
import configEC from "../lang/format-form/ObjectFormEC";

const useDownloadLang = () => {
  const getLangDetail = (language) => {
    let message = langPE;
    let locale = "es-PE";
    let configForm = configPE;

    switch (language) {
      case "es-AR":
        message = langAR;
        locale = "es-AR";
        configForm = configAR;
        break;
      case "es-PE":
        message = langPE;
        locale = "es-PE";
        configForm = configPE;
        break;
      case "es-CR":
        message = langCR;
        locale = "es-CR";
        configForm = configCR;
        break;
      case "es-CL":
        message = langCL;
        locale = "es-CL";
        configForm = configCL;
        break;
      case "es-CO":
        message = langCO;
        locale = "es-CO";
        configForm = configCO;
        break;
      case "es-BO":
        message = langBO;
        locale = "es-BO";
        configForm = configBO;
        break;
      case "es-MX":
        message = langMX;
        locale = "es-MX";
        configForm = configMX;
        break;
      case "es-EC":
        message = langEC;
        locale = "es-EC";
        configForm = configEC;
    }
    return {
      message: message,
      locale: locale,
      configForm: configForm,
    };
  };

  return {
    getLangDetail,
  };
};

export default useDownloadLang;
