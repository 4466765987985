import ButtonModalDetail from "pages/TenderInformationContent/InfoContent/utils-info/ButtonModalDetail";
import {
  capitalize,
  formatCurrencyLocale,
  getDataJsonBucle,
  getDataJsonComplex,
} from "utils/common";
import structureDataJS from "./structure-data/dataStructure-CR.js";
import { ConvertToDateLong } from "utils/DateHelper.js";

export default {
  ...structureDataJS,
  TenderContainer: {
    Filter: {
      Tab: [
        { id: "tab.General", keys: "", types: "O" },
        { id: "tab.Cronograma", keys: "tenderStages", types: "O" },
        { id: "tab.ListaItems", keys: "tenderDetails", types: "O" },
        { id: "tab.Documentos", keys: "tenderDocuments", types: "O" },
        { id: "tab.Garantias", keys: "extension.Info", types: "J.J" },
        { id: "tab.Ofertas", keys: "extension.Info", types: "J.J" },
        { id: "tab.Condiciones", keys: "extension.Info", types: "J.J" },
        { id: "tab.Entrega", keys: "extension.Info", types: "J.J" },
        {
          id: "tab.Funcionarios",
          keys: "extension.Info.Officials",
          types: "J.J.O",
        },
        { id: "tab.Contratos", keys: "tenderContracts", types: "O" },
        {
          id: "tab.OrdenesPedido",
          keys: "extension.Info.Record.PurchaseOrders",
          types: "J.J.O.O",
        },
      ],
    },
  },
  TenderGestion: {
    Filter: {
      menuInfo: [
        "tab.General",
        "tab.Cronograma",
        "tab.ListaItems",
        "tab.Documentos",
        "tab.Garantias",
        "tab.Ofertas",
        "tab.Condiciones",
        "tab.Entrega",
        "tab.Funcionarios",
        "tab.Contratos",
        "tab.OrdenesPedido",
      ],
      menuGestion: [
        "tab.Asignaciones",
        "tab.ListaItems",
        "tab.CartasFianza",
        "tab.Comentarios",
        "tab.Sanciones",
        "tab.Checklist",
      ],
    },
  },
  ManagementCalendarExportValues: {
    elements: [
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => item.toString(),
      },
      {
        id: "cols.Publicacion",
        value: "publishedDate",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Nomenclatura", value: "fileNumber" },
      { id: "cols.Convocatoria", value: "tenderNumber" },
      { id: "cols.Entidad", value: "clientUnitName" },
      { id: "cols.Nombre", value: "tenderName" },
      { id: "cols.Situacion", value: "manageInternalStatusDesc" },
      { id: "cols.Referencial", value: "estimatedValue" },
      { id: "cols.OContratacion", value: "tenderDescription" },
      { id: "cols.Moneda", value: "currency" },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      { id: "cols.Items", value: "detailsQty" },
      {
        id: "cols.Finconsultas",
        value: "questionsReceptionEnd",
        format: (item) => ConvertToDateLong(item),
      },
      {
        id: "cols.FinPresProp",
        value: "offersReceptionEnd",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Usuario", value: "userManage" },
      {
        id: "cols.Segmento",
        value: "categories",
        format: (item) => (item.length > 0 ? item[0] : ""),
      },
      {
        id: "cols.Gestionada",
        value: "dateCreationManage",
        format: (item) => ConvertToDateLong(item),
      },
    ],
  },
  ManagementCalendarValues: {
    elements: [
      { id: "cols.Convocatoria", value: "tenderNumber" },
      {
        id: "cols.Nombre",
        value: "tenderName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.Entidad",
        value: "clientUnitName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.FinPresProp",
        value: "offersReceptionEnd",
        format: (item) => (item ? ConvertToDateLong(item) : "-"),
      },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      {
        id: "cols.Situacion",
        value: "manageInternalStatusDesc",
        format: (item) => (item ? item : "Sin Asignar"),
      },
      { id: "cols.Portal", value: "portal" },
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => (item.length > 0 ? item.toString() : "Sin Asignar"),
      },
      {
        id: "cols.Finconsultas",
        value: "questionsReceptionEnd",
        format: (item) => (item ? ConvertToDateLong(item) : "-"),
      },
    ],
  },
  EmailSubjectConfig: {
    elements: [
      { id: "cols.Nombre", name: "tenderName" },
      { id: "cols.Id", name: "tenderNumber" },
      { id: "cols.Cierre", name: "endDate" },
      { id: "text.Tipo_Compra", name: "tenderTypeDesc" },
      { id: "cols.Portal", name: "portal" },
      { id: "cols.Entidad", name: "clientUnitName" },
      { id: "cols.Usuario", name: "userManage" },
    ],
  },
  TenderGeneralInfo: {
    elements: [
      {
        id: "text.Nomenclatura",
        value: "fileNumber",
        xs: 12,
        sm: 6,
        md: 4,
      },
      {
        id: "text.Fecha_Publicacion",
        value: "publishedDate",
        type: "date",
        xs: 12,
        sm: 6,
        md: 2,
      },
      {
        id: "text.Convocatoria",
        value: "tenderNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Tipo_Compra",
        value: "tenderTypeDesc",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Entidad",
        value: "clientUnitName",
        xs: 12,
        sm: 12,
        md: 4,
      },
      {
        id: "text.Descripcion",
        value: "tenderName",
        xs: 12,
        sm: 12,
        md: 8,
      },
    ],
  },
  ListCompGeneralInfo: {
    component: [
      {
        type: "Grid",
        id_ff: "TabGeneralInfoFirst",
      },
      {
        type: "Grid",
        id_ff: "TabGeneralInfoLast",
        styleParent: { marginTop: "2em" },
      },
    ],
  },
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            {
              key: "tenderDescription",
              intlLabelKey: "tabG.Objeto_Contratacion",
            },
            {
              key: "estimatedValue",
              intlLabelKey: "tabG.Valor_Referencial",
              render: (value, row) =>
                formatCurrencyLocale(value, 2, row.currency),
            },
            {
              key: "extension",
              intlLabelKey: "tabG.Valor_ReferencialUSD",
              render: (value, row) =>
                formatCurrencyLocale(
                  getDataJsonBucle(
                    "Info.EstimatedTotalBudgetUSD",
                    value,
                    "J.V",
                  ),
                  2,
                  "USD",
                ),
            },
            {
              key: "publishedDate",
              intlLabelKey: "tabG.Fecha_Publicacion",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabGeneralInfoLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "portalStatus", intlLabelKey: "tabG.EstadoConcurso" },
            {
              key: "extension",
              intlLabelKey: "tabG.TipoModalidad",
              render: (value) =>
                getDataJsonBucle("Info.ModalityType", value, "J.V"),
            },
            {
              key: "extension",
              intlLabelKey: "tabG.ExcepcionContratacion",
              render: (value) =>
                getDataJsonBucle(
                  "Info.DirectContractingException",
                  value,
                  "J.V",
                ),
            },
            {
              key: "extension",
              intlLabelKey: "tabG.PlazoAdjudicacion",
              render: (value) =>
                getDataJsonBucle("Info.AwardTerm", value, "J.V"),
            },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "name", intlLabelKey: "tabC.Etapa" },
            {
              key: "startDate",
              intlLabelKey: "tabC.Fecha_Inicio",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabItemListFirst: {
    element: {
      type: "FSTMRT",
      columns: [
        {
          accessorKey: "extension",
          header: "Partida",
          size: 130,
          Cell: ({ cell, column }) =>
            getDataJsonComplex("SicopBatch.Batch", cell.getValue()),
        },
        { accessorKey: "itemNumber", header: "Línea", size: 110 },
        { accessorKey: "code", header: "Código" },
        { accessorKey: "description", header: "Descripción", size: 220 },
        { accessorKey: "qty", header: "Cantidad" },
        {
          accessorKey: "estimatedValue",
          header: "Precio Unitario",
          Cell: ({ cell, column }) =>
            formatCurrencyLocale(
              cell.getValue(),
              2,
              cell.row.original.estimatedValueCurrency,
            ),
        },
        {
          accessorKey: "extension2", // Cant have two columns with the same accessorKey
          header: "Detalle de Partida",
          align: "center",
          disableTooltip: true,
          hideToExport: true,
          Cell: ({ cell, column }) => (
            <ButtonModalDetail
              queryClient={"MODAL_DETAIL"}
              detail={"Partida"}
              data={getDataJsonComplex(null, cell.row.original.extension)}
              result={{}}
              titleModal={"Detalle de la partida"}
              modalName={"MODAL_DETAIL"}
            />
          ),
        },
        {
          accessorKey: "extension3", // Cant have two columns with the same accessorKey
          header: "Detalle de Línea",
          align: "center",
          disableTooltip: true,
          hideToExport: true,
          Cell: ({ cell, column }) => (
            <ButtonModalDetail
              queryClient={"MODAL_DETAIL"}
              detail={"Línea"}
              data={getDataJsonComplex(null, cell.row.original.extension)}
              result={{}}
              titleModal={"Información detallada de línea"}
              modalName={"MODAL_DETAIL"}
            />
          ),
        },
        {
          accessorKey: "tenderExtension",
          header: "Detalle de Contratación",
          align: "center",
          disableTooltip: true,
          hideToExport: true,
          Cell: ({ cell, column }) => (
            <ButtonModalDetail
              queryClient={"MODAL_DETAIL"}
              detail={"Contratación"}
              data={getDataJsonBucle(
                "Info.RequestsInformation",
                cell.getValue(),
                "J.V",
              )}
              result={{
                RequestNumber: getDataJsonComplex(
                  "RequestNumber",
                  cell.row.original.extension,
                ),
              }}
              titleModal={"Solicitud de contratación"}
              modalName={"MODAL_DETAIL"}
            />
          ),
        },
      ],
    },
  },
  ListCompDocuments: {
    component: [
      {
        type: "Grid",
        id_ff: "TabProcedureDocument",
      },
    ],
  },
  TabProcedureDocument: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", intlLabelKey: "tabD.Nro" },
            { key: "type", intlLabelKey: "tabD.Tipo" },
            {
              key: "link",
              intlLabelKey: "tabD.Archivo",
              render: (value, row) =>
                row.link !== "" ? (
                  <a href={value} target="_blank">
                    {row.name}
                  </a>
                ) : (
                  row.name
                ),
            },
          ],
        },
      },
    ],
  },
  ListCompWarraty: {
    listData: "StructureDataWarrantyCR",
    component: [
      {
        type: "EtqH6",
        styleParent: {
          marginLeft: "0.25em",
          textTransform: "uppercase",
          paddingTop: "0.5rem",
        },
        idValue: "title",
      },
      {
        type: "EtqH6",
        styleParent: {
          marginLeft: "3em",
          marginBottom: "1em",
          marginTop: "2em",
        },
        style: { fontWeight: "500" },
        idValue: "description",
      },
      {
        type: "Grid",
        id_ff: "TabWarranty",
      },
    ],
  },
  TabWarranty: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "type", intlLabelKey: "tabGA.Tipo" },
            { key: "aplica", intlLabelKey: "tabGA.Aplica" },
            { key: "monto", intlLabelKey: "tabGA.Monto" },
            { key: "vigencia", intlLabelKey: "tabGA.Vigencia" },
          ],
        },
      },
    ],
  },
  TabOffersInfo: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "MaxOfferNumber", intlLabelKey: "tabOF.NroMaximo" },
            {
              key: "MaxAlternativeOfferNumber",
              intlLabelKey: "tabOF.NroMaximoAlt",
            },
            { key: "OfferValidity", intlLabelKey: "tabOF.Vigencia" },
          ],
        },
      },
    ],
  },
  TabContractConditions: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "ContractValidity", intlLabelKey: "tabCO.Vigencia" },
            { key: "Extension", intlLabelKey: "tabCO.Prorroga" },
            { key: "Fine", intlLabelKey: "tabCO.Multa" },
            { key: "PenaltyClause", intlLabelKey: "tabCO.Clausula" },
          ],
        },
      },
    ],
  },
  TabDelivery: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "AccordingToDemand", intlLabelKey: "tabE.SegunDemanda" },
            { key: "DeliveryDetail", intlLabelKey: "tabE.Detalle" },
            { key: "DeliveryObservations", intlLabelKey: "tabE.Observaciones" },
          ],
        },
      },
    ],
  },
  TabRelatedOfficials: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "Line", intlLabelKey: "tabF.Linea" },
            { key: "Role", intlLabelKey: "tabF.Rol" },
            { key: "Name", intlLabelKey: "tabF.Nombre" },
            { key: "Dependency", intlLabelKey: "tabF.Dependencia" },
          ],
        },
      },
    ],
  },
  TabAwardedContracts: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "contractNumber", intlLabelKey: "tabCONT.Nro" },
            {
              key: "",
              intlLabelKey: "tabCONT.Detalle",
              render: (value, row) => (
                <ButtonModalDetail
                  data={row}
                  queryClient={"MODAL_AWARDED"}
                  detail={"Contrato"}
                  result={{ contractNumber: row.contractNumber }}
                  titleModal={"Contratos"}
                  modalName={"MODAL_AWARDED"}
                />
              ),
            },
            {
              key: "date",
              intlLabelKey: "tabCONT.Fecha",
              cellType: "date",
            },
            { key: "contractTerm", intlLabelKey: "tabCONT.Vigencia" },
            { key: "status", intlLabelKey: "tabCONT.Estado" },
          ],
        },
      },
    ],
  },
  TabAwardedPurchaseOrders: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "OrderNumber", intlLabelKey: "tabOP.Nro" },
            {
              key: "",
              intlLabelKey: "tabOP.Detalle",
              render: (value, row) => (
                <ButtonModalDetail
                  data={row}
                  queryClient={"MODAL_AWARDED"}
                  detail={"Ordenes de Pedido"}
                  result={{}}
                  titleModal={"Ordenes de Pedido"}
                  modalName={"MODAL_AWARDED"}
                />
              ),
            },
            {
              key: "NotificationDate",
              intlLabelKey: "tabOP.Fecha",
              cellType: "date",
            },
          ],
        },
      },
    ],
  },

  ValueCmb_Carta: {
    objectName: "subjectsCR",
  },

  // From now on, not used
  TabDocumentsFolds: { elements: [] },
  TabDocumentsParticularClauses: { elements: [] },
  TabDocumentsAnnexes: { elements: [] },
  TabDocumentsTechnicalFolds: { elements: [] },
  TabGeneralProcedureActions: { elements: [] },
  TabSupervisingJob: { elements: [] },
  TabRequirement: { elements: [] },
  TabItemListLast: { elements: [] },
};
