import { post, get, put, Delete } from "config/axios";
import { getWithParams } from "./common";

const manage = {
  getManage: async (request) => {
    return await getWithParams(request, "manage");
  },
};

export default manage;
