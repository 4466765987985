import React, { Fragment } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";

import { IconButton, Box, Tooltip } from "@mui/material";

import { connect } from "react-redux";

import projectLogo from "../../assets/images/imgFalconRounded.png";

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx("app-header-logo", {
          "app-header-logo-close": sidebarToggle,
          "app-header-logo-open": sidebarHover,
        })}>
        <Box className="header-logo-wrapper">
          <Link to="/" className="header-logo-wrapper-link">
            <Tooltip title="Falconsoft Tenders" disableInteractive>
              <IconButton
                color="primary"
                size="medium"
                className="header-logo-wrapper-btn">
                <img className="app-header-logo-img" src={projectLogo} />
              </IconButton>
            </Tooltip>
          </Link>
          <Box className="header-logo-text">Tenders</Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(HeaderLogo);
