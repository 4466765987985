import { Business, InsertChart, Stars } from "@mui/icons-material";
import { Badge, Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Fragment, useState } from "react";
import { getCompetitors, getOrganisms } from "../analysisService";
import EntityTabContent from "./EntityTabContent";
import ProductsTabContent from "./ProductsTabContent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CenteredScrollableTabs = styled(Tabs)(({ theme }) => ({
  justifyContent: "center",
  "& .MuiTabs-scroller": {
    flexGrow: "0",
  },
}));

const ManageFilterTabs = ({
  filter,
  tabsCount,
  handleFilterToManageChange,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const organismsOptionsCallback = async (request, signal = null) => {
    return getOrganisms(request, signal);
  };

  const competitorsOptionsCallback = async (request, signal = null) => {
    return getCompetitors(request, signal);
  };

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        className="bg-white">
        <CenteredScrollableTabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}>
          <Tab
            label={
              <>
                <Typography variant="h6" component="div">
                  Productos
                </Typography>
                <Badge
                  badgeContent={tabsCount.products}
                  color="secondary"
                  sx={{ ml: 2, mt: -2 }}
                />
              </>
            }
            icon={<InsertChart />}
            iconPosition="start"
          />
          <Tab
            label={
              <>
                <Typography variant="h6" component="div">
                  Entidades
                </Typography>
                <Badge
                  badgeContent={tabsCount.organisms}
                  color="secondary"
                  sx={{ ml: 2, mt: -2 }}
                />
              </>
            }
            icon={<Business />}
            iconPosition="start"
          />
          <Tab
            label={
              <>
                <Typography variant="h6" component="div">
                  Competidores
                </Typography>
                <Badge
                  badgeContent={tabsCount.competitors}
                  color="secondary"
                  sx={{ ml: 2, mt: -2 }}
                />
              </>
            }
            icon={<Stars />}
            iconPosition="start"
          />
        </CenteredScrollableTabs>

        <TabPanel value={activeTab} index={0}>
          <ProductsTabContent
            filterToManage={filter}
            handleFilterToManageChange={handleFilterToManageChange}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <EntityTabContent
            filterToManage={filter}
            handleFilterToManageChange={handleFilterToManageChange}
            entityKey={"organisms"}
            entityIdKey={"clientUnitId"}
            loadOptionsCallback={organismsOptionsCallback}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <EntityTabContent
            filterToManage={filter}
            handleFilterToManageChange={handleFilterToManageChange}
            entityKey={"competitors"}
            entityIdKey={"competitorId"}
            loadOptionsCallback={competitorsOptionsCallback}
          />
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default ManageFilterTabs;
