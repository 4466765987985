import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { Fragment, useRef, useState } from "react";

const CustomButtonGroup = ({
  options,
  color = "primary",
  onClick,
  ...props
}) => {
  const [buttonOpen, setButtonOpen] = useState(null);
  const [selected, setSelected] = useState(options[0]);
  const anchorRef = useRef(null);

  const handleClick = (event) => {
    setButtonOpen(event.currentTarget);
  };

  const handleClose = () => {
    setButtonOpen(null);
  };

  const handleMenuItemClick = (option) => {
    setSelected(option);
    setButtonOpen(null);
    onClick(option);
  };

  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        size="small"
        aria-label="split button"
        disableElevation
        ref={anchorRef}
        color={color}
        {...props}>
        <Button
          sx={{
            fontSize: "0.75rem",
            textTransform: "uppercase",
          }}
          onClick={() => onClick(selected)}>
          <Box sx={{ height: 20, fontWeight: 500 }}>{selected.value}</Box>
        </Button>
        <Button size="small" aria-haspopup="true" onClick={handleClick}>
          {buttonOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </ButtonGroup>
      <Menu
        id="custom-button-group-menu"
        anchorEl={anchorRef.current}
        keepMounted
        open={Boolean(buttonOpen)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        {options.map((option) => (
          <MenuItem key={option.id} onClick={() => handleMenuItemClick(option)}>
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default CustomButtonGroup;
