import { DialogContentText } from "@mui/material";
import NumericField from "components/CustomFields/NumericField";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useEffect, useState } from "react";

const AdvancedOptionsModal = ({ open, onClose, onSave }) => {
  const defaultAdvancedOptions = {
    days: "",
    error: false,
    errorMessage: "",
  };
  const [advancedOptions, setAdvancedOptions] = useState(
    defaultAdvancedOptions,
  );
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (!open) {
      setAdvancedOptions(defaultAdvancedOptions);
      setSaveButtonDisabled(true);
    }
  }, [open]);

  const verifyError = (value) => {
    if (!value) {
      setSaveButtonDisabled(true);
      return true;
    } else {
      setSaveButtonDisabled(false);
      return false;
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    let error = verifyError(value);

    setAdvancedOptions({
      days: value,
      error: error,
      errorMessage: error ? "El campo no puede estar vacío" : "",
    });
  };

  const handleSave = () => {
    if (advancedOptions.error) return;
    onSave(+advancedOptions.days);
  };

  return (
    <GeneralPurposeModal
      open={open}
      onClose={onClose}
      title="Opciones avanzadas"
      closeText="Cancelar"
      onSave={handleSave}
      onSaveDisabled={saveButtonDisabled}
      maxWidth="xs">
      <DialogContentText sx={{ mb: 4, textAlign: "center" }}>
        Establecer últimos días para TODOS los filtros creados. Máximo 90 días.
      </DialogContentText>
      <NumericField
        name="days"
        label="Días"
        value={advancedOptions.days}
        onChange={handleChange}
        error={advancedOptions.error}
        helperText={advancedOptions.errorMessage}
        fullWidth
        minValue={1}
        maxValue={90}
      />
    </GeneralPurposeModal>
  );
};

export default AdvancedOptionsModal;
