export const Countries = [
  { id: "AE", label: "Emiratos Árabes Unidos" },
  { id: "AL", label: "Albania" },
  { id: "AR", label: "Argentina" },
  { id: "AT", label: "Austria" },
  { id: "AU", label: "Australia" },
  { id: "BA", label: "Bosnia y Hercegovina" },
  { id: "BE", label: "Bélgica" },
  { id: "BG", label: "Bulgaria" },
  { id: "BH", label: "Bahráin" },
  { id: "BO", label: "Bolivia" },
  { id: "BR", label: "Brasil" },
  { id: "BY", label: "Bielorrusia" },
  { id: "CA", label: "Canadá" },
  { id: "CH", label: "Suiza" },
  { id: "CL", label: "Chile" },
  { id: "CN", label: "China" },
  { id: "CO", label: "Colombia" },
  { id: "CR", label: "Costa Rica" },
  { id: "CS", label: "Serbia y Montenegro" },
  { id: "CY", label: "Chipre" },
  { id: "CZ", label: "Chequia" },
  { id: "DE", label: "Alemania" },
  { id: "DK", label: "Dinamarca" },
  { id: "DO", label: "República Dominicana" },
  { id: "DZ", label: "Argelia" },
  { id: "EC", label: "Ecuador" },
  { id: "EE", label: "Estonia" },
  { id: "EG", label: "Egipto" },
  { id: "ES", label: "España" },
  { id: "FI", label: "Finlandia" },
  { id: "FR", label: "Francia" },
  { id: "GB", label: "Reino Unido" },
  { id: "GR", label: "Grecia" },
  { id: "GT", label: "Guatemala" },
  { id: "HK", label: "Hong Kong" },
  { id: "HN", label: "Honduras" },
  { id: "HR", label: "Croacia" },
  { id: "HU", label: "Hungría" },
  { id: "ID", label: "Indonesia" },
  { id: "IE", label: "Irlanda" },
  { id: "IL", label: "Israel" },
  { id: "IN", label: "India" },
  { id: "IQ", label: "Iraq" },
  { id: "IS", label: "Islandia" },
  { id: "IT", label: "Italia" },
  { id: "JO", label: "Jordania" },
  { id: "JP", label: "Japón" },
  { id: "KR", label: "Corea del Sur" },
  { id: "KW", label: "Kuwait" },
  { id: "LB", label: "Líbano" },
  { id: "LT", label: "Lituania" },
  { id: "LU", label: "Luxemburgo" },
  { id: "LV", label: "Letonia" },
  { id: "LY", label: "Libia" },
  { id: "MA", label: "Marruecos" },
  { id: "ME", label: "Montenegro" },
  { id: "MK", label: "Macedonia" },
  { id: "MT", label: "Malta" },
  { id: "MX", label: "México" },
  { id: "MY", label: "Malasia" },
  { id: "NI", label: "Nicaragua" },
  { id: "NL", label: "Holanda" },
  { id: "NO", label: "Noruega" },
  { id: "NO-NY", label: "Noruega,Nynorsk" },
  { id: "NZ", label: "Nueva Zelanda" },
  { id: "OM", label: "Omán" },
  { id: "PA", label: "Panamá" },
  { id: "PE", label: "Perú" },
  { id: "PH", label: "Filipinas" },
  { id: "PL", label: "Polonia" },
  { id: "PR", label: "Puerto Rico" },
  { id: "PT", label: "Portugal" },
  { id: "PY", label: "Paraguay" },
  { id: "QA", label: "Qatar" },
  { id: "RO", label: "Rumania" },
  { id: "RS", label: "Serbia" },
  { id: "RU", label: "Rusia" },
  { id: "SA", label: "Arabia Saudita" },
  { id: "SD", label: "Sudán" },
  { id: "SE", label: "Suecia" },
  { id: "SG", label: "Singapur" },
  { id: "SI", label: "Eslovenia" },
  { id: "SK", label: "Eslovaquia" },
  { id: "SV", label: "El Salvador" },
  { id: "SY", label: "Siria" },
  { id: "TH", label: "Tailandia" },
  { id: "TH_TH", label: "Tailandia,TH" },
  { id: "TN", label: "Túnez" },
  { id: "TR", label: "Turquía" },
  { id: "TW", label: "Taiwán" },
  { id: "UA", label: "Ucrania" },
  { id: "US", label: "Estados Unidos" },
  { id: "UY", label: "Uruguay" },
  { id: "VE", label: "Venezuela" },
  { id: "VN", label: "Vietnam" },
  { id: "YE", label: "Yemen" },
  { id: "ZA", label: "Sudáfrica" },
];

export const Idiom = [
  { id: "ar", label: "Árabe" },
  { id: "be", label: "Bielorruso" },
  { id: "bg", label: "Búlgaro" },
  { id: "ca", label: "Catalán" },
  { id: "cs", label: "Checo" },
  { id: "da", label: "Danés" },
  { id: "de", label: "Alemán" },
  { id: "el", label: "Griego" },
  { id: "en", label: "Inglés" },
  { id: "es", label: "Español" },
  { id: "et", label: "Estonio" },
  { id: "fi", label: "Finés" },
  { id: "fr", label: "Francés" },
  { id: "ga", label: "Irlandés" },
  { id: "hi", label: "Hindú" },
  { id: "hr", label: "Croata" },
  { id: "hu", label: "Húngaro" },
  { id: "in", label: "Indonesio" },
  { id: "is", label: "Islandés" },
  { id: "it", label: "Italiano" },
  { id: "iw", label: "Hebreo" },
  { id: "ja", label: "Japonés" },
  { id: "ko", label: "Coreano" },
  { id: "lt", label: "Lituano" },
  { id: "lv", label: "Letón" },
  { id: "mk", label: "Macedonio" },
  { id: "ms", label: "Malayo" },
  { id: "mt", label: "Maltés" },
  { id: "nl", label: "Neerlandés" },
  { id: "no", label: "Noruego" },
  { id: "pl", label: "Polaco" },
  { id: "pt", label: "Portugués" },
  { id: "ro", label: "Rumano" },
  { id: "ru", label: "Ruso" },
  { id: "sk", label: "Eslovaco" },
  { id: "sl", label: "Eslovenio" },
  { id: "sq", label: "Albanés" },
  { id: "sr", label: "Serbio" },
  { id: "sv", label: "Sueco" },
  { id: "th", label: "Tailandés" },
  { id: "tr", label: "Turco" },
  { id: "uk", label: "Ucranio" },
  { id: "vi", label: "Vietnamita" },
  { id: "zh", label: "Chino" },
];
