import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useEffect, useState } from "react";
import FiltersPanelHeaderActions from "./FiltersPanelHeaderActions";
import FiltersPanelList from "./FiltersPanelList";

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  width: "100%",
  maxWidth: 300,

  bgcolor: "background.paper",
  overflow: "auto",
  // Custom scrollbar
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    WebkitAppearance: "none",
    width: "12px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f5f5f5",
  },
  // should only appear on hover
  "&:hover::-webkit-scrollbar": {
    WebkitAppearance: "none",
    width: "12px",
  },
  "&:hover::-webkit-scrollbar-thumb": {
    borderRadius: "4px",
    backgroundColor: "#a9a9cfc2",
    WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
  },
  "&:hover::-webkit-scrollbar-track": {
    backgroundColor: "#f5f5f5",
  },
}));

const FiltersPanel = ({
  filters,
  setFilters,
  addEditFilter,
  selectedFilters,
  handleSaveSelectedFilters,
}) => {
  const { height } = useGlobalStore((state) => ({
    height: state.height,
  }));
  const [filterPanelOpen, setFilterPanelOpen] = useState(true);

  const handleToggleFilter = (filter, index) => () => {
    const newFilters = [...filters];
    newFilters[index].checked = !filter.checked;
    setFilters(newFilters);
  };

  const handleToggleAllFilters = () => {
    const newFilters = [...filters];
    if (filters.every((item) => item.checked)) {
      newFilters.forEach((item) => (item.checked = false));
    } else {
      newFilters.forEach((item) => (item.checked = true));
    }
    setFilters(newFilters);
  };

  // -- Filter Panel Logic -- //
  function useIsWidthUp(breakpoint) {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(breakpoint));
  }
  const isMdUp = useIsWidthUp("md");
  useEffect(() => {
    if (isMdUp) setFilterPanelOpen(true);
    else setFilterPanelOpen(false);
  }, [isMdUp]);
  // --  -- //

  return (
    <>
      <Box
        sx={{
          display: filterPanelOpen ? "none" : "block",
          mr: 1,
          mt: 1,
        }}>
        <Tooltip title="Mostrar" placement="bottom">
          <IconButton
            edge="end"
            className="icon-size-filter"
            size="small"
            onClick={() => setFilterPanelOpen(true)}
            aria-label="comments">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        maxWidth={270}
        minWidth={220}
        sx={{ display: filterPanelOpen ? "block" : "none" }}>
        <Card
          sx={{
            maxHeight: "100%",
            height: height + 133,
            maxWidth: 270,
            bgcolor: "background.paper",
          }}>
          <FiltersPanelHeaderActions
            filters={filters}
            handleToggleAllFilters={handleToggleAllFilters}
            handleSaveSelectedFilters={handleSaveSelectedFilters}
            setFilterPanelOpen={setFilterPanelOpen}
            addEditFilter={addEditFilter}
            selectedFilters={selectedFilters}
          />
          <Divider sx={{ mb: "0.5px" }} />
          <StyledCardContent sx={{ height: height + 98, p: 0 }}>
            <FiltersPanelList
              filters={filters}
              handleToggleFilter={handleToggleFilter}
              addEditFilter={addEditFilter}
            />
          </StyledCardContent>
        </Card>
      </Box>
    </>
  );
};

export default FiltersPanel;
