import TenderGridMessage from "pages/TenderInformationContent/InfoContent/utils/TenderGridMessage";
import {
  capitalize,
  compareString,
  formatDate,
  formatValueLocale,
} from "utils/common";
import structureDataJS from "./structure-data/dataStructure-AR.js";
import ButtonModalDetail from "pages/TenderInformationContent/InfoContent/utils-info/ButtonModalDetail.js";
import { ConvertToDateLong } from "utils/DateHelper.js";

export default {
  ...structureDataJS,
  TenderContainer: {
    Filter: {
      Tab: [
        { id: "tab.Cronograma", keys: "tenderStages", types: "O" },
        { id: "tab.ListaItems", keys: "tenderDetails", types: "O" },
        { id: "tab.Documentos", keys: "tenderDocuments", types: "O" },
        { id: "tab.Requisitos", keys: "tenderRequirements", types: "O" },
        { id: "tab.Garantias", keys: "", types: "O" },
        {
          id: "tab.Contratos",
          keys: "extension.Info.Agreement",
          types: "J.J.O",
        },
      ],
    },
  },
  TenderGestion: {
    Filter: {
      menuInfo: [
        "tab.Cronograma",
        "tab.ListaItems",
        "tab.Documentos",
        "tab.Requisitos",
        "tab.Garantias",
        "tab.Contratos",
      ],
      menuGestion: [
        "tab.Asignaciones",
        "tab.ListaItems",
        "tab.CartasFianza",
        "tab.Comentarios",
      ],
    },
  },

  ManagementCalendarExportValues: {
    elements: [
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => item.toString(),
      },
      {
        id: "cols.Publicacion",
        value: "publishedDate",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Nomenclatura", value: "fileNumber" },
      { id: "cols.Convocatoria", value: "tenderNumber" },
      { id: "cols.Entidad", value: "clientUnitName" },
      { id: "cols.Nombre", value: "tenderName" },
      { id: "cols.Situacion", value: "manageInternalStatusDesc" },
      { id: "cols.Referencial", value: "estimatedValue" },
      { id: "cols.Moneda", value: "currency" },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      { id: "cols.Items", value: "detailsQty" },
      {
        id: "cols.Finconsultas",
        value: "questionsReceptionEnd",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Usuario", value: "userManage" },
      {
        id: "cols.Segmento",
        value: "categories",
        format: (item) => (item.length > 0 ? item[0] : ""),
      },
      {
        id: "cols.Gestionada",
        value: "dateCreationManage",
        format: (item) => ConvertToDateLong(item),
      },
    ],
  },
  ManagementCalendarValues: {
    elements: [
      { id: "cols.Convocatoria", value: "tenderNumber" },
      {
        id: "cols.Nombre",
        value: "tenderName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.Entidad",
        value: "clientUnitName",
        format: (item) => capitalize(item),
      },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      {
        id: "cols.Situacion",
        value: "manageInternalStatusDesc",
        format: (item) => (item ? item : "Sin Asignar"),
      },
      { id: "cols.Portal", value: "portal" },
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => (item.length > 0 ? item.toString() : "Sin Asignar"),
      },
    ],
  },
  EmailSubjectConfig: {
    elements: [
      { id: "cols.Nombre", name: "tenderName" },
      { id: "cols.Id", name: "tenderNumber" },
      { id: "cols.Cierre", name: "endDate" },
      { id: "text.Tipo_Compra", name: "tenderTypeDesc" },
      { id: "cols.Portal", name: "portal" },
      { id: "cols.Entidad", name: "clientUnitName" },
      { id: "cols.Usuario", name: "userManage" },
      { id: "cols.ClientName", name: "clientName" },
    ],
  },
  TenderGeneralInfo: {
    elements: [
      {
        id: "text.Convocatoria",
        value: "tenderNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Nomenclatura",
        value: "fileNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Fecha_Publicacion",
        value: "publishedDate",
        type: "date",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Tipo_Compra",
        value: "tenderTypeDesc",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Entidad",
        value: "clientUnitName",
        xs: 12,
        sm: 6,
        md: 4,
      },
      {
        id: "cols.Portal",
        value: "portal",
        xs: 12,
        sm: 6,
        md: 2,
      },
      {
        id: "text.Descripcion",
        value: "tenderName",
        template: (tenderData) => {
          if (
            tenderData?.portal === "MinisterioSaludPBA" ||
            tenderData?.portal === "PAMICentral" ||
            tenderData?.portal === "PAMIUgls" ||
            tenderData?.portal === "ComprasGarrahan"
          ) {
            return tenderData?.tenderDescription;
          }
          return tenderData?.tenderName;
        },
        xs: 12,
        sm: 12,
        md: 6,
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "name", intlLabelKey: "tabC.Etapa" },
            {
              key: "startDate",
              intlLabelKey: "tabC.Fecha_Inicio",
              render: (value, row) => {
                if (
                  row.startDate === row.endDate &&
                  !compareString(row.name, ["PUBLICACION", "APERTURA"])
                ) {
                  return "-";
                }
                return formatDate(value);
              },
            },
            {
              key: "endDate",
              intlLabelKey: "tabC.Fecha_Fin",
              render: (value, row) =>
                compareString(row.name, ["PUBLICACION", "APERTURA"])
                  ? "-"
                  : formatDate(value),
            },
          ],
        },
      },
    ],
  },
  TabItemListFirst: {
    element: {
      type: "FSTMRT",
      columns: [
        { accessorKey: "itemNumber", header: "#", size: "90" },
        { accessorKey: "description", header: "Descripción", size: "220" },
        { accessorKey: "code", header: "Código de item", size: "110" },
        {
          accessorKey: "qty",
          header: "Cantidad",
          size: "90",
          textAlign: "center",
          Cell: ({ cell }) => formatValueLocale(cell.getValue()),
        },
        { accessorKey: "package", header: "Tipo de Adjudicación", size: "90" },
        {
          accessorKey: "estimatedValue",
          header: "Valor de la Unidad de Compra",
          size: "110",
          textAlign: "center",
          Cell: ({ cell }) => formatValueLocale(cell.getValue(), 2),
        },
        { accessorKey: "observation", header: "Observaciones", size: "130" },
        {
          accessorKey: "specification",
          header: "Especificaciones",
          size: "130",
        },
        {
          accessorKey: "conditioning",
          header: "Acondicionamiento",
          size: "130",
        },
        {
          id: "tabL.DetalleEntrega",
          accessorKey: "extension",
          header: "Detalle de Entrega",
          size: "170",
          hideToExport: true,
          disableTooltip: true,
          Cell: ({ cell, column }) => {
            if (!cell.getValue()) return null;
            let extension = JSON.parse(cell.getValue());
            if (
              !extension.DeliveryDetail ||
              extension.DeliveryDetail.length === 0
            )
              return null;

            return (
              <ButtonModalDetail
                queryClient={"TabItemModalDeliveryDetail"}
                detail={"Detalle de Entrega"}
                data={extension.DeliveryDetail}
                result={{}}
                titleModal={"Detalle de Entrega"}
                modalName={"TabItemModalDeliveryDetail"}
              />
            );
          },
        },
        {
          id: "tabL.Adjudicaciones",
          accessorKey: "tenderDetailAwardeds",
          header: "Adjudicaciones/Ofertas",
          size: "100",
          hideToExport: true,
          disableTooltip: true,
          Cell: ({ cell, column }) => {
            if (!cell.getValue() || cell.getValue().length === 0) return null;
            return (
              <ButtonModalDetail
                queryClient={"TabItemListLast"}
                detail={"Adjudicaciones / Ofertas"}
                data={cell.getValue()}
                result={{}}
                titleModal={"Adjudicaciones / Ofertas"}
                modalName={"TabItemListLast"}
              />
            );
          },
        },
      ],
    },
  },
  TabItemModalDeliveryDetail: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "Quantity", label: "Cantidad a entregar" },
            { key: "DeliveryTerm", label: "Plazo de entrega" },
            { key: "DeliveryAddress", label: "Lugar de entrega" },
            { key: "Comments", label: "Observaciones" },
            { key: "DeliveryType", label: "Tipo de entrega" },
          ],
        },
      },
    ],
  },
  TabItemListLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "competitorName", intlLabelKey: "tabL.Adj.Postor" },
            {
              key: "qty",
              intlLabelKey: "tabL.Adj.Cantidad",
              render: (value) => formatValueLocale(value),
            },
            {
              key: "ammount",
              intlLabelKey: "tabL.Adj.Monto",
              render: (value) => formatValueLocale(value, 2),
            },
          ],
        },
      },
    ],
  },
  ListCompDocuments: {
    component: [
      {
        type: "Typography",
        label: "Pliego de bases y condiciones generales",
        props: {
          variant: "h5",
          style: {
            marginLeft: "0.25em",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            fontSize: "0.96rem",
            fontWeight: "bold",
          },
        },
      },
      {
        type: "Grid",
        id_ff: "TabDocumentsFolds",
        template: (props) => props.data?.tenderFolds ?? [],
      },
      {
        type: "Typography",
        label: "Cláusulas Particulares",
        props: {
          variant: "h5",
          style: {
            marginLeft: "0.25em",
            paddingTop: "2rem",
            paddingBottom: "1rem",
            fontSize: "0.96rem",
            fontWeight: "bold",
          },
        },
      },
      {
        type: "Grid",
        id_ff: "TabDocumentsParticularClauses",
        template: (props) => props.data?.tenderClauses ?? [],
      },
      {
        type: "Typography",
        label: "Anexos",
        props: {
          variant: "h5",
          style: {
            marginLeft: "0.25em",
            paddingTop: "2rem",
            paddingBottom: "1rem",
            fontSize: "0.96rem",
            fontWeight: "bold",
          },
        },
      },
      {
        type: "Grid",
        id_ff: "TabDocumentsAnnexes",
        template: (props) => props.data?.tenderDocuments ?? [],
      },
      {
        type: "Typography",
        label: "Posee pliego técnico",
        props: {
          variant: "h5",
          style: {
            marginLeft: "0.25em",
            paddingTop: "2rem",
            paddingBottom: "1rem",
            fontSize: "0.96rem",
            fontWeight: "bold",
          },
        },
      },
      {
        type: "Grid",
        id_ff: "TabDocumentsTechnicalFolds",
        template: (props) => props.data?.tenderTechnicalFolds ?? [],
      },
    ],
  },
  TabDocumentsFolds: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            {
              key: "document",
              label: "Documento",
              primary: true,
              render: (value, row) =>
                row.link.includes("comprar") ? row.name : row.document,
            },
            { key: "date", label: "Fecha creación", cellType: "date" },
            {
              key: "link",
              label: "Acciones",
              render: (value, row) => (
                <a href={value} target="_blank">
                  {row.link.includes("comprar") ? row.name : row.document}
                </a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabDocumentsParticularClauses: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "document", label: "Documento", primary: true },
            {
              key: "documentNumber",
              label: "Número GDE/GDEBA/SADE",
              primary: true,
            },
            { key: "specialNumber", label: "Número Especial" },
            {
              key: "date",
              label: "Fecha de vinculación",
              cellType: "date",
            },
            {
              key: "link",
              label: "Acciones",
              render: (value, row) => (
                <a href={value} target="_blank">
                  {row.documentNumber}
                </a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabDocumentsAnnexes: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", label: "Nro.", primary: true },
            { key: "name", label: "Nombre" },
            { key: "type", label: "Tipo", primary: true },
            { key: "description", label: "Descripción" },
            {
              key: "link",
              label: "Acciones",
              render: (value, row) => (
                <a href={value} target="_blank">
                  {row.name}
                </a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabDocumentsTechnicalFolds: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "document", label: "Documento", primary: true },
            {
              key: "documentNumber",
              label: "Número GDE/GDEBA/SADE",
              primary: true,
            },
            { key: "specialNumber", label: "Número Especial" },
            {
              key: "date",
              label: "Fecha de vinculación",
              cellType: "date",
            },
            {
              key: "link",
              label: "Acciones",
              render: (value, row) => (
                <a href={value} target="_blank">
                  {row.documentNumber}
                </a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabRequirement: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", intlLabelKey: "tabR.Nro" },
            {
              key: "description",
              intlLabelKey: "tabR.Descripcion",
              render: (value) => (
                <TenderGridMessage title={"Requisito"} message={value} />
              ),
            },
            { key: "documentType", intlLabelKey: "tabR.Tipo" },
          ],
        },
      },
    ],
  },
  ListCompWarraty: {
    listData: "StructureDataWarrantyAR",
    component: [
      {
        type: "Grid",
        id_ff: "TabWarranty",
      },
      {
        type: "EtqH6",
        styleParent: {
          marginLeft: "0.25em",
          textTransform: "uppercase",
          marginTop: "2em",
        },
        idValue: "validCurrencyTitle",
      },
      {
        type: "EtqH6",
        styleParent: {
          marginLeft: "2em",
          marginBottom: "2em",
        },
        style: { fontWeight: "500" },
        idValue: "validCurrencyDescription",
      },
    ],
  },
  TabWarranty: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            {
              key: "type",
              intlLabelKey: "tabGA.Tipo",
              render: (value) => <b>{value}</b>,
            },
            { key: "indicator", intlLabelKey: "tabGA.Indicador" },
            { key: "description", intlLabelKey: "tabGA.Descripcion" },
          ],
        },
      },
    ],
  },
  TabAwardedContracts: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "AcceptExtension", intlLabelKey: "tabCONT.Prorroga" },
            {
              key: "Duration",
              intlLabelKey: "tabCONT.Duracion",
            },
          ],
        },
      },
    ],
  },

  ValueCmb_Carta: {
    objectName: "subjectsAR",
  },

  // From now on, not used
  ListCompGeneralInfo: { component: [] },
  TabGeneralInfoFirst: { elements: [] },
  TabGeneralInfoLast: { elements: [] },
  TabProcedureDocument: { elements: [] },
  TabGeneralProcedureActions: { elements: [] },
  TabSupervisingJob: { elements: [] },
  TabOffersInfo: { elements: [] },
  TabContractConditions: { elements: [] },
  TabDelivery: { elements: [] },
  TabRelatedOfficials: { elements: [] },
  TabAwardedPurchaseOrders: { elements: [] },
};
