import { Fragment } from "react";

import { AppRegistration, FindInPage, Settings } from "@mui/icons-material";
import HomeLoadingState from "components/Home/HomeLoadingState";
import HomeTitle from "components/Home/HomeTitle";
import projectLogo from "../../assets/images/imgFalconRounded.png";
import { PageTitle } from "../../layout-components";

export default function HomePageLoadingState({ availableModules }) {
  return (
    <Fragment>
      <PageTitle
        icon={
          <img
            className="app-header-logo-img"
            src={projectLogo}
            style={{ marginRight: 6 }}
            alt="Inicio"
          />
        }
        titleHeading="Inicio"
        titleDescription="Falconsoft Tenders"
      />
      {availableModules.SS && (
        <>
          <HomeTitle title="Smart Search" TitleIcon={FindInPage} />
          <HomeLoadingState />
        </>
      )}
      {availableModules.GL && (
        <>
          <HomeTitle title="Gestión" TitleIcon={AppRegistration} />
          <HomeLoadingState />
        </>
      )}
      {availableModules.Settings && (
        <>
          <HomeTitle title="Ajustes" TitleIcon={Settings} />
          <HomeLoadingState />
        </>
      )}
    </Fragment>
  );
}
