import { AddCircle, Delete } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  deleteAllProducts,
  deleteProduct,
  getProducts,
  massivePostProduct,
  postProduct,
  putProduct,
} from "./administrationServices";

const messages = {
  saveSuccess: "El producto se almacenó correctamente.",
  deleteSuccess: "El producto se eliminó correctamente.",
  missingData: "Por favor ingrese el código y descripción del producto.",
  cannotDelete:
    "El producto no puede ser eliminado porque está siendo utilizado.",
  deleteConfirmation: (item) =>
    item && Array.isArray(item)
      ? `¿Está seguro que desea eliminar los ${item?.length} productos seleccionados?`
      : `¿Está seguro que desea eliminar el producto ${item?.productCode}?`,
  cannotDeleteSome:
    "Algunos productos no pueden ser eliminados porque están siendo utilizados.",
  deleteAllConfirmation: "¿Está seguro que desea eliminar TODOS los productos?",
  deleteAllConfirmationSubTitle:
    "Se eliminarán todos los productos que no estén siendo utilizados. Esta acción no se puede deshacer.",
};

const columns = [
  { accessorKey: "productCode", header: "Código", width: 160 },
  { accessorKey: "productDesc", header: "Descripción", width: 220 },
];

const ProductModal = ({ open, onClose, fetchFunction }) => {
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [modalDeleteAllConfirm, setModalDeleteAllConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [columnsTable, setColumnsTable] = useState(columns);

  const {
    showSavingStateSpinner,
    setShouldRefetch,
    setSnackbarErrorCustom,
    setSnackbarSaveCustom,
    height,
    hideLoadingSpinner,
    showSavingStateSpinnerNoTimeout,
    setSnackbarInfoCustom,
  } = useGlobalStore((state) => ({
    showSavingStateSpinner: state.showSavingStateSpinner,
    setShouldRefetch: state.setShouldRefetch,
    setSnackbarErrorCustom: state.setSnackbarErrorCustom,
    setSnackbarSaveCustom: state.setSnackbarSaveCustom,
    height: state.height,
    hideLoadingSpinner: state.hideLoadingSpinner,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    setSnackbarInfoCustom: state.setSnackbarInfoCustom,
  }));

  // Reset columnsTable when modal is closed
  useEffect(() => {
    if (!open) setColumnsTable(columns);
  }, [open]);

  const fetchTableData = async (
    pageNumber,
    pageSize,
    filter,
    sorting,
    exporting,
  ) => {
    let orderBy = sorting.length > 0 ? sorting[0].id : "";
    let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;

    const request = {
      orderBy: orderBy,
      pageSize: pageSize,
      filter: filter,
      pageNumber: pageNumber,
      orderAsc: orderAsc,
    };
    const results = await getProducts(request);

    let columnsCopy = [...columnsTable];
    let modifiedHeader = false;
    // flatten with json parse the "data" field in result
    // and add the keys of 'data' to the table columns
    let resultParsed = results.data?.currentPageItems?.map((item) => {
      if (item.data) {
        let dataParsed = JSON.parse(item.data);
        // add its keys to HeaderTable if they are not already there
        Object.keys(dataParsed).forEach((key) => {
          if (!columnsCopy.find((x) => x.accessorKey === key)) {
            columnsCopy.push({ accessorKey: key, header: key, width: 150 });
            modifiedHeader = true;
          }
        });
        return { ...item, ...dataParsed };
      } else {
        return { ...item };
      }
    });

    if (modifiedHeader && !exporting) setColumnsTable(columnsCopy);

    return {
      result: resultParsed,
      total: results.data.totalItems,
    };
  };

  const saveVerification = (data) => {
    return data.productCode && data.productDesc;
  };

  const onDelete = async (item) => {
    await deleteProduct(item.productId);
  };

  const handleDelete = async () => {
    showSavingStateSpinner();
    if (Array.isArray(itemToDelete)) {
      await handleDeleteMultiple();
    } else {
      await onDelete(itemToDelete);
    }
    setModalDeleteConfirm(false);
    setItemToDelete(null);
    setSnackbarSaveCustom(messages.deleteSuccess);
    setShouldRefetch();
  };

  const handleDeleteMultiple = async () => {
    let notDeleteable = itemToDelete.filter((x) => !x.disposable);
    if (notDeleteable.length > 0) {
      setSnackbarInfoCustom(messages.cannotDeleteSome);
    }
    for (let item of itemToDelete.filter((x) => x.disposable)) {
      await onDelete(item);
    }
  };

  const handleDeleteAll = async () => {
    showSavingStateSpinnerNoTimeout();
    handleCloseModalDeleteAllConfirm();
    await deleteAllProducts();
    hideLoadingSpinner();
    setSnackbarSaveCustom("Los productos han sido eliminados correctamente.");
    setShouldRefetch();
  };

  const handleSave = async (item) => {
    // Error checking
    if (!saveVerification(item)) {
      setSnackbarErrorCustom(messages.missingData);
      return;
    }
    // Fabricate the item to save, considering the columns in the table
    // if any data field is in headerTable, add it to data.data
    let dataToSave = { ...item };
    // convert data field to object
    if (!dataToSave.data) dataToSave.data = {};
    else dataToSave.data = JSON.parse(dataToSave.data);

    columnsTable.forEach((column) => {
      // if the field is as a standalone field, add it to data.data,
      // but only if it is not null and not from the products fixed fields
      if (
        dataToSave.hasOwnProperty(column.accessorKey) &&
        dataToSave[column.accessorKey] != null &&
        !columns
          .map((x) => x.accessorKey)
          .concat(["productId"])
          .includes(column.accessorKey)
      ) {
        dataToSave.data[column.accessorKey] = dataToSave[column.accessorKey];
        // if the user inputs an empty string, remove the field from data.data so data.data ends up being null
        if (dataToSave[column.accessorKey] === "")
          delete dataToSave.data[column.accessorKey];
        // also delete the field from the item to save
        delete dataToSave[column.accessorKey];
      }
    });

    // if data.data is empty, set it to null so it doesnt get saved as an empty object
    if (Object.keys(dataToSave.data).length > 0)
      dataToSave.data = JSON.stringify(dataToSave.data);
    else dataToSave.data = null;

    showSavingStateSpinner();
    // if the product is new, add it
    if (!dataToSave.productId) {
      try {
        await postProduct(dataToSave);
      } catch (error) {
        setSnackbarErrorCustom(
          `El producto "${item.productDesc}" no puede ser agregado porque ya existe.`,
        );
        return;
      }
    } else {
      // if the product is not new, update it
      await putProduct(dataToSave);
    }

    setSnackbarSaveCustom(messages.saveSuccess);
    return true;
  };

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };

  const handleCloseModalDeleteAllConfirm = () => {
    setModalDeleteAllConfirm(false);
  };

  //#region Excel Import
  // -- Excel Import -- //
  const excelDataOrgInitial = [
    {
      columnValue: "productCode",
      columnName: "Código",
      columnPosition: 0,
      modifiable: false,
    },
    // {
    //   columnValue: "productBarCode",
    //   columnName: "Código de barras",
    //   columnPosition: 1,
    //   modifiable: false,
    // },
    {
      columnValue: "productDesc",
      columnName: "Descripción",
      columnPosition: 1,
      modifiable: false,
    },
  ];
  const excelDataAdd = [
    {
      columnValue: "addButtonColumnExcel",
      columnName: "Agregar columna",
      modifiable: false,
    },
  ];

  const [excelDataOrganizationModal, setExcelDataOrganizationModal] =
    useState(false);
  const [excelDataOrganization, setExcelDataOrganization] = useState([
    ...excelDataOrgInitial,
    ...excelDataAdd,
  ]);
  const [duplicateExcelPositions, setDuplicateExcelPositions] = useState({
    value: false,
    message: "",
  });

  //function that returns excel columns available from a to z with format { id: 0, name: "A"}
  const getExcelColumns = () => {
    let columns = [];
    for (let i = 0; i < 26; i++)
      columns.push({ id: i, name: String.fromCharCode(65 + i) });
    return columns;
  };

  const [excelDataOrganizationHeader, setExcelDataOrganizationHeader] =
    useState(false);

  const handleImport = () => {
    setExcelDataOrganizationModal(true);
  };

  const handleSaveExcelDataOrganization = () => {
    if (verifyDuplicatePositions(excelDataOrganization)) return;
    if (verifyNullColumnNames(excelDataOrganization)) return;

    document.getElementById("import-file-xlsx-products").click();
    setExcelDataOrganizationModal(false);
  };

  const handleCloseExcelDataOrganization = () => {
    setExcelDataOrganizationModal(false);
    setExcelDataOrganization([...excelDataOrgInitial, ...excelDataAdd]);
    setExcelDataOrganizationHeader(false);
    setDuplicateExcelPositions({ value: false, message: "" });
  };

  const verifyNullColumnNames = (data) => {
    let nullColumnNames = false;
    data.forEach((item) => {
      if (item.columnName === "" || item.columnValue === "")
        nullColumnNames = true;
    });
    if (nullColumnNames)
      setSnackbarErrorCustom("No es posible importar con columnas sin nombre");

    return nullColumnNames;
  };

  const verifyDuplicatePositions = (data) => {
    let seen = new Set();
    let hasDuplicates = data.some(
      (currentObject) =>
        seen.size === seen.add(currentObject.columnPosition).size,
    );
    hasDuplicates |= data.some(
      (currentObject) => seen.size === seen.add(currentObject.columnValue).size,
    );
    if (hasDuplicates) {
      setDuplicateExcelPositions({
        value: true,
        message: "No debe haber columnas ni nombres repetidos",
      });
    } else {
      setDuplicateExcelPositions({ value: false, message: "" });
    }
    return hasDuplicates;
  };

  const handleChange = (event, item, mustModifyPosition = false) => {
    //find and modify the item in the excelDataOrganization array
    let excelDataOrganizationCopy = [...excelDataOrganization];
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );
    if (mustModifyPosition) {
      // modify the position of the item
      excelDataOrganizationCopy[itemIndex].columnPosition = event.target.value;
    } else {
      // modify the name of the item
      // remove dots from the string
      excelDataOrganizationCopy[itemIndex].columnValue =
        event.target.value.replace(/\./g, "");
      excelDataOrganizationCopy[itemIndex].columnName =
        event.target.value.replace(/\./g, "");
    }
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleAddColumn = () => {
    // max columnPosition + 1 (so if the user adds a columns and sets the position to 5, then the next column will be 6, even if there is no position 4)
    let maxColumnPosition = Math.max.apply(
      Math,
      excelDataOrganization.slice(0, -1).map((o) => o.columnPosition),
    );
    // this shouldn't happen, but just in case
    if (maxColumnPosition === -Infinity || maxColumnPosition === "NaN")
      maxColumnPosition = excelDataOrganization.length - 1;

    setExcelDataOrganization([
      ...excelDataOrganization.slice(0, -1),
      {
        columnName: "",
        columnValue: "",
        columnPosition: maxColumnPosition + 1,
        modifiable: true,
      },
      ...excelDataAdd,
    ]);
  };

  const handleRemoveColumn = (event, item) => {
    let excelDataOrganizationCopy = [...excelDataOrganization];
    // to find the item all three properties must match
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );
    excelDataOrganizationCopy.splice(itemIndex, 1);
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleChangeHeaderSwitch = (event) => {
    setExcelDataOrganizationHeader(event.target.checked);
  };

  const getDataExcel = (event) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(event.target.files[0]);
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workBook = XLSX.read(data, { type: "array" });
        const sheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[sheetName];

        // skip the first row if there is header
        if (excelDataOrganizationHeader) {
          let range = XLSX.utils.decode_range(sheet["!ref"]);
          range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
          sheet["!ref"] = XLSX.utils.encode_range(range);
        }

        // array of empty strings of the same length as the excel columns
        // this way we can use the excel specified by the user, with the columns in any order
        let header = Array.from({ length: 26 }, () => null);
        excelDataOrganization.forEach((item) => {
          if (item.columnValue !== "addButtonColumnExcel")
            header[item.columnPosition] = item.columnValue;
        });

        // to array of obj
        const dataParse = XLSX.utils.sheet_to_json(sheet, { header: header });

        resolve(dataParse);
      };
    });
  };

  const importFile = async (event) => {
    const _file = { FileName: event.target.files[0].name };
    if (_file.FileName.indexOf("xls") === -1) {
      setSnackbarErrorCustom("El archivo no es válido");
      return;
    }
    const data = await getDataExcel(event);
    // verify if there's one with no product code
    const noProductCode = data.find((item) => !item.productCode);
    if (noProductCode) {
      const productIndex = data.indexOf(noProductCode);
      const message = `El producto en la fila ${
        productIndex + 1
      } no posee código.`;
      setSnackbarErrorCustom("El código del producto es requerido. " + message);
      return;
    }
    // verify if there's one with no product desc
    const noProductDesc = data.find((item) => !item.productDesc);
    if (noProductDesc) {
      const productIndex = data.indexOf(noProductDesc);
      const message = `El producto en la fila ${
        productIndex + 1
      } no posee descripción.`;
      setSnackbarErrorCustom(
        "La descripción del producto es requerida. " + message,
      );
      return;
    }
    // verify if there's one with invalid json data for the Data column
    data.forEach((item, index) => {
      const { productCode, productDesc, productBarCode, ...rest } = item;
      try {
        let test = JSON.parse(JSON.stringify(rest));
      } catch (e) {
        const message = `El producto en la fila ${
          index + 1
        } posee datos extras inválidos.`;
        setSnackbarErrorCustom("Datos extras inválidos. " + message);
        return;
      }
    });

    // add all other keys that are not productCode, productDesc or productBarCode to a new key called 'data' with a JSON as value
    const dataWithJSON = data.map((item) => {
      const { productCode, productDesc, productBarCode, ...rest } = item;
      return {
        productCode,
        productDesc,
        productBarCode,
        Data: JSON.stringify(rest),
      };
    });
    showSavingStateSpinnerNoTimeout();
    try {
      await massivePostProduct(dataWithJSON);
    } catch (e) {
      setSnackbarErrorCustom(
        "No se han podido guardar los productos. Intente nuevamente.",
      );
      hideLoadingSpinner();
      return;
    }
    hideLoadingSpinner();
    setSnackbarSaveCustom("Los productos han sido importados correctamente.");

    handleCloseExcelDataOrganization();

    // reload table waiting a bit to apply changes in be
    showSavingStateSpinnerNoTimeout();
    setTimeout(() => {
      setShouldRefetch();
      hideLoadingSpinner();
    }, 1000);
  };

  // --  -- //
  //#endregion

  const options = {
    tableId: "gridProductModal",
    tableHeaderTitle: "Productos",
    header: columnsTable,
    dynamicHeader: true,

    enableEditing: true,
    enableDeleting: true,
    enableMultiRowSelection: true,
    saveFunc: handleSave,
    deleteFunc: (row) => {
      if (messages.cannotDelete && !row.disposable) {
        setSnackbarErrorCustom(messages.cannotDelete);
        return;
      }
      setItemToDelete(row);
      setModalDeleteConfirm(true);
    },

    toolbarButtons: {
      Add: true,
      Export: true,
      Import: true,
      ImportFunc: handleImport,
      ExtraButtons: [
        {
          key: "deleteSelected",
          title: "Eliminar seleccionados",
          icon: <Delete />,
          onClick: (selectedRows, table) => {
            // selectedRows can be an object if only one row is selected, or an array of objects if multiple rows are selected
            if (Object.keys(selectedRows).length === 0) {
              setSnackbarInfoCustom("No hay elementos seleccionados");
              return;
            }
            setItemToDelete(selectedRows);
            setModalDeleteConfirm(true);
          },
        },
        {
          key: "deleteAll",
          title: "Eliminar todos",
          icon: <Delete />,
          onClick: (selectedRows) => setModalDeleteAllConfirm(true),
        },
      ],
    },
  };

  return (
    <Fragment>
      <GeneralPurposeModal
        title={"Productos"}
        open={open}
        onClose={onClose}
        maxWidth="lg">
        <Box sx={{ height: height }}>
          <FSTMaterialReactTable options={options} getData={fetchTableData} />
        </Box>
      </GeneralPurposeModal>
      <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={messages.deleteConfirmation(itemToDelete)}
      />
      <DeleteDialog
        open={modalDeleteAllConfirm}
        onClose={handleCloseModalDeleteAllConfirm}
        onDelete={handleDeleteAll}
        title={messages.deleteAllConfirmation}
        subTitle={messages.deleteAllConfirmationSubTitle}
      />

      {/* -- Excel Import -- */}
      <GeneralPurposeModal
        title={"Interpretación del contenido del archivo Excel"}
        open={excelDataOrganizationModal}
        onClose={handleCloseExcelDataOrganization}
        saveText="Importar"
        onSave={handleSaveExcelDataOrganization}>
        <Box sx={{ p: 2 }}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              // overflowX: "scroll",
              // overflowY: "hidden",
              alignItems: "stretch",
            }}>
            {excelDataOrganization.map((item, index) => (
              <Grid
                item
                xs={4}
                key={index}
                style={{
                  flexBasis: "27%",
                  flexGrow: 0,
                  flexShrink: 0,
                  alignItems: "center",
                  alignContent: "center",
                  // width: 200,
                }}>
                <Card sx={{ alignItems: "center" }}>
                  {item.modifiable ? (
                    <Grid
                      container
                      sx={{
                        alignItems: "center",
                        pb: "0.75rem",
                        pt: "1.5rem",
                        justifyContent: "center",
                        width: "100%",
                        ml: 0,
                      }}
                      spacing={2}>
                      <TextField
                        id="outlined-basic"
                        label="Nombre"
                        variant="outlined"
                        name="columnName"
                        value={item.columnName}
                        onChange={(event) => handleChange(event, item)}
                        size="small"
                        sx={{ width: "75%" }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      sx={{
                        alignItems: "center",
                        py: "1.75rem",
                        justifyContent: "center",
                        width: "100%",
                        ml: 0,
                      }}
                      spacing={2}>
                      <Typography sx={{ fontSize: "medium" }} variant="h6">
                        {item.columnName}
                      </Typography>
                    </Grid>
                  )}

                  {item.columnValue === "addButtonColumnExcel" ? (
                    <Grid
                      container
                      sx={{
                        alignItems: "center",
                        pb: "1rem",
                        justifyContent: "center",
                        width: "100%",
                        ml: 0,
                      }}
                      spacing={2}>
                      <IconButton
                        onClick={handleAddColumn}
                        sx={{ mt: "0.85rem" }}>
                        <AddCircle />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      sx={{
                        alignItems: "center",
                        py: "1rem",
                        justifyContent: "center",
                        width: "100%",
                        ml: 0,
                      }}
                      spacing={2}>
                      <Typography sx={{ fontSize: "small", pr: "1rem" }}>
                        Columna:
                      </Typography>
                      <TextField
                        sx={{ width: "30%" }}
                        fullWidth
                        value={item.columnPosition}
                        variant="outlined"
                        size="small"
                        name={item.columnValue}
                        onChange={(event) => handleChange(event, item, true)}
                        select>
                        {getExcelColumns().map((excelColumns) => (
                          <MenuItem
                            key={excelColumns.id}
                            value={excelColumns.id}>
                            {excelColumns.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      {item.modifiable && (
                        <IconButton
                          onClick={(event) => handleRemoveColumn(event, item)}
                          sx={{ ml: "0.5rem" }}>
                          <Delete color="error" />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
          {duplicateExcelPositions.value && (
            <Typography
              sx={{ color: "red", pt: "1.5rem", textAlign: "center" }}
              variant="h5">
              {duplicateExcelPositions.message}
            </Typography>
          )}
          <Card sx={{ mt: "2rem" }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={4} sx={{ alignSelf: "center" }}>
                <Typography
                  sx={{ fontSize: "medium", marginLeft: "4%" }}
                  variant="h6">
                  Contiene encabezado
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <Switch
                  sx={{ whiteSpace: "nowrap" }}
                  color="primary"
                  checked={excelDataOrganizationHeader}
                  name={"hasHeader"}
                  onChange={handleChangeHeaderSwitch}
                  size="medium"
                />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </GeneralPurposeModal>

      <input
        accept=".xlsx,.xls"
        style={{ display: "none" }}
        id="import-file-xlsx-products"
        type="file"
        hidden
        onInput={(e) => {
          importFile(e);
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </Fragment>
  );
};

export default ProductModal;
