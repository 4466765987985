import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog } from "@mui/material";

const SaveDialog = ({
  open,
  onClose,
  onSave,
  title = "¿Está seguro?",
  subTitle = "Esta acción no se puede deshacer.",
  cancelText = "Cancelar",
  saveText = "Guardar",
}) => {
  const handleSave = () => {
    onSave();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
            <FontAwesomeIcon
              icon={["far", "lightbulb"]}
              className="d-flex align-self-center display-3"
            />
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">{title}</h4>
        <p className="mb-0 font-size-lg text-muted">{subTitle}</p>
        <div className="pt-4">
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className="mx-1">
            <span className="btn-wrapper--label">{cancelText}</span>
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            className="mx-1">
            <span className="btn-wrapper--label">{saveText}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SaveDialog;
