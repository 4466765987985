import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { TenderPanel } from "pages/TenderInformationContent/tenderPanelItems";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const TenderInformationSideTabs = ({
  menus,
  setCurrentMenu,
  currentMenu,
  height,
  setSidePanelOpen,
}) => {
  return (
    <Card
      sx={{
        height: "100%",
        maxWidth: 300,
        bgcolor: "background.paper",
        overflow: "auto",
        overflowY: "scroll",
        // Custom scrollbar
        "&::-webkit-scrollbar": {
          WebkitAppearance: "none",
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f5f5f5",
        },
        // should only appear on hover
        "&:hover::-webkit-scrollbar": {
          WebkitAppearance: "none",
          width: "8px",
        },
        "&:hover::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: "#a9a9cfc2",
          WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
        },
        "&:hover::-webkit-scrollbar-track": {
          backgroundColor: "#f5f5f5",
        },
      }}>
      <List
        sx={{
          width: "100%",
          maxWidth: 300,
          bgcolor: "background.paper",
          maxHeight: height,
          textAlign: "-webkit-center",
        }}
        subheader={
          <ListSubheader
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}>
            <span>{TenderPanel.PORTAL_INFO.toUpperCase()}</span>
            <Tooltip title="Ocultar Panel Lateral" placement="right">
              <IconButton
                edge="end"
                className="icon-size-filter"
                size="small"
                sx={{ mt: -0.5 }}
                onClick={() => setSidePanelOpen(false)}
                aria-label="comments">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </ListSubheader>
        }>
        <div
          className="divider border-2 mb-2 border-light rounded"
          style={{ width: "90%" }}
        />

        {!menus && (
          <Box
            sx={{
              display: "flex",
              mt: "4rem",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Skeleton animation="wave" width={"80%"} />
            <Skeleton animation="wave" width={"80%"} />
            <Skeleton animation="wave" width={"80%"} />
          </Box>
        )}
        {menus &&
          menus[TenderPanel.PORTAL_INFO].items.map((menu, idx) => {
            return (
              <ListItem
                key={idx}
                sx={{
                  height: menu.titlePanel.length > 20 ? "4rem" : "2.5rem",
                  mb: 0.35,
                }}>
                <ListItemButton
                  role={undefined}
                  onClick={() => setCurrentMenu(menu)}
                  sx={{ height: "inherit", borderRadius: "0.5rem" }}
                  selected={menu.title === currentMenu.title}
                  dense>
                  <ListItemText
                    id={menu.titlePanel}
                    primary={`${menu.titlePanel}`}
                    sx={{
                      mr: 1,
                      ml: -1,
                      mt: "0.375rem",
                      textOverflow: "ellipsis",
                      overflowWrap: "break-word",
                      opacity: 0.8,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}

        <ListSubheader
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            mt: 2,
          }}>
          <span>{TenderPanel.MANAGEMENT.toUpperCase()}</span>
        </ListSubheader>

        <div
          className="divider border-2 mb-2 border-light rounded"
          style={{ width: "90%" }}
        />

        {menus &&
          menus[TenderPanel.MANAGEMENT].items.map((menu, idx) => {
            return (
              <ListItem key={idx} sx={{ height: "2.5rem", mb: 0.5 }}>
                <ListItemButton
                  role={undefined}
                  onClick={() => setCurrentMenu(menu)}
                  sx={{ height: "inherit", borderRadius: "0.5rem" }}
                  selected={menu.title === currentMenu.title}
                  dense>
                  <ListItemText
                    id={menu.titlePanel}
                    primary={`${menu.titlePanel}`}
                    sx={{
                      mr: 1,
                      ml: -1,
                      mt: "0.375rem",
                      textOverflow: "ellipsis",
                      overflowWrap: "break-word",
                      opacity: 0.8,
                    }}
                    className="font-size-sm"
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
    </Card>
  );
};

export const TenderInformationTopTabs = ({
  menus,
  setCurrentMenu,
  currentMenu,
  height,
  setSidePanelOpen,
}) => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <Tooltip title="Mostrar Panel Lateral" placement="bottom">
        <IconButton
          edge="end"
          className="icon-size-filter"
          size="small"
          onClick={() => setSidePanelOpen(true)}
          aria-label="comments">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
      <Box display="flex" alignItems="center" minWidth={250}>
        {!menus ? (
          <Skeleton animation="wave" width="100%" />
        ) : (
          <Button
            color="secondary"
            size="small"
            onClick={handleClickMenu}
            className="bg-secondary"
            endIcon={<ArrowDropDownIcon sx={{ ml: 2 }} />}>
            <span className="font-size-md font-weight-bold">
              {currentMenu?.titlePanel}
            </span>
          </Button>
        )}
        <Menu
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          classes={{ list: "p-0" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}>
          <div className="overflow-hidden dropdown-menu-xl">
            <List className="nav-danger nav-pills flex-column p-3">
              <Typography
                className="text-black pb-2 px-3 font-weight-bold"
                component="div">
                {TenderPanel.PORTAL_INFO.toUpperCase()}
              </Typography>

              {menus &&
                menus[TenderPanel.PORTAL_INFO].items.map((menu, idx) => {
                  return (
                    <ListItemButton
                      key={`${menu.title}-${idx}`}
                      className="rounded"
                      sx={{ mb: 0.5 }}
                      onClick={() => {
                        setCurrentMenu(menu);
                        handleCloseMenu();
                      }}
                      selected={menu.title === currentMenu.title}>
                      <span>{menu.titlePanel}</span>
                    </ListItemButton>
                  );
                })}

              <Divider className="my-3" />

              <Typography
                className="text-black pb-2 px-3 font-weight-bold"
                component="div">
                {TenderPanel.MANAGEMENT.toUpperCase()}
              </Typography>

              {menus &&
                menus[TenderPanel.MANAGEMENT].items.map((menu, idx) => {
                  return (
                    <ListItemButton
                      key={`${menu.title}-${idx}`}
                      className="rounded"
                      sx={{ mb: 0.5 }}
                      onClick={() => {
                        setCurrentMenu(menu);
                        handleCloseMenu();
                      }}
                      selected={menu.title === currentMenu.title}>
                      <span>{menu.titlePanel}</span>
                    </ListItemButton>
                  );
                })}
            </List>
          </div>
        </Menu>
      </Box>
    </>
  );
};
