import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useIntl } from "react-intl";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import {
  dateTimeFormat,
  dateTimeShortFormat,
  formatCurrencyLocale,
} from "utils/common";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const ResponsiveTable = ({
  columns = [], // {key: <string>, label: <string>, render: <function>}
  data = [],
  loading = false,
  noContentText = "No hay resultados",
  rowOnClick = null,
  ...rest
}) => {
  const intl = useIntl();

  const getCellComponent = (type, value, row) => {
    switch (type) {
      case "datetime": {
        if (value == null) return "-"; // Checks for null or undefined
        return moment(value).format(dateTimeFormat);
      }
      case "date": {
        if (value == null) return "-"; // Checks for null or undefined
        if (moment(value).format(dateTimeShortFormat) === "01/01/0001")
          return "-";
        return moment(value).format(dateTimeShortFormat);
      }
      case "currency": {
        if (value == null) return "-"; // Checks for null or undefined
        return formatCurrencyLocale(value, 2, row.currency);
      }
      case "boolean": {
        if (value == null) return "-"; // Checks for null or undefined
        return value ? "Sí" : "No";
      }
      default:
        return value ?? "-";
    }
  };

  const handleRowOnClick = (row) => {
    if (!rowOnClick) return;
    rowOnClick(row);
  };

  return (
    <Table {...rest}>
      <Thead>
        <Tr>
          {columns.map((column, index) => (
            <Th
              key={column.id || index}
              style={{
                whiteSpace: "normal",
                ...(column.cellType && { minWidth: 157 }),
                ...(column.width && { width: column.width }),
                ...(column.textAlign && { textAlign: column.textAlign }),
              }}
              title={
                column.intlLabelKey
                  ? intl.formatMessage({
                      id: column.intlLabelKey,
                      defaultMessage: "N/A",
                    })
                  : column.label
              }>
              {column.intlLabelKey
                ? intl.formatMessage({
                    id: column.intlLabelKey,
                    defaultMessage: "N/A",
                  })
                : column.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {loading && (
          <Tr>
            <Td colSpan={columns.length}>
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            </Td>
          </Tr>
        )}
        {!loading && data.length === 0 && (
          <Tr>
            <Td colSpan={columns.length}>
              <div className="d-flex justify-content-center">
                {noContentText}
              </div>
            </Td>
          </Tr>
        )}
        {!loading &&
          data.length > 0 &&
          data.map((row, index) => (
            <Tr
              key={index}
              onClick={() => handleRowOnClick(row)}
              style={{ cursor: rowOnClick ? "pointer" : "default" }}>
              {columns.map((column, index) => (
                <Td
                  key={index}
                  style={{
                    ...(column.textAlign && { textAlign: column.textAlign }),
                  }}>
                  {column.render
                    ? column.render(row[column.key], row)
                    : getCellComponent(column.cellType, row[column.key], row)}
                </Td>
              ))}
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};

export default ResponsiveTable;
