import {
  AddBox,
  DriveFileRenameOutline,
  IndeterminateCheckBox,
  Mode,
  OpenInNew,
  Star,
  StarBorder,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useCallback } from "react";
import { isLangAR } from "utils/common";
import {
  addManagedTender,
  highlightTender,
  viewTender,
} from "../smartSearchService";
import {
  attachmentResultsHeader,
  SmartSearchHeader,
} from "./smartSearchHeader";

function SmartSearchResults({
  attachmentResultView,
  handleOpenInfoModal,
  fetchAllData,
}) {
  const { setSnackbarSaveChanges, setShouldRefetch } = useGlobalStore(
    (state) => ({
      setSnackbarSaveChanges: state.setSnackbarSaveChanges,
      setShouldRefetch: state.setShouldRefetch,
    }),
  );

  // #region Context menu
  const manageTender = useCallback(async (rows) => {
    const tenderNumbers = rows.map((row) => row.tenderNumber);
    const payload = tenderNumbers.map((tenderNumber) => {
      return {
        tenderNumber: tenderNumber,
      };
    });
    await addManagedTender(payload); // if result==="Error" then show error message
    setSnackbarSaveChanges(true);
    setShouldRefetch();
  }, []);

  const manageItem = useCallback(async (rows) => {
    let items = [];
    rows.forEach((row) => {
      const index = items.findIndex(
        (item) => item.itemNumber === row.itemNumber,
      );
      if (index === -1) {
        items.push({
          TenderNumber: row.tenderNumber,
          ItemNumbers: [row.itemNumber],
        });
      } else {
        items[index].ItemNumbers.push(row.itemNumber);
      }
    });
    await addManagedTender(items);
    setSnackbarSaveChanges(true);
    setShouldRefetch();
  }, []);

  const highlightAll = useCallback(async (rows, shouldHighlight) => {
    let items = [];
    rows.forEach((row) => {
      items.push({
        TenderNumber: row.tenderNumber,
        ItemNumbers: [0],
      });
    });
    await highlightTender(items, shouldHighlight);
    setSnackbarSaveChanges(true);
    setShouldRefetch();
  }, []);

  const highlightItem = useCallback(async (rows, shouldHighlight) => {
    let items = [];
    rows.forEach((row) => {
      const index = items.findIndex(
        (item) => item.tenderNumber === row.tenderNumber,
      );
      if (index === -1) {
        items.push({
          TenderNumber: row.tenderNumber,
          ItemNumbers: [row.itemNumber],
        });
      } else {
        items[index].ItemNumbers.push(row.itemNumber);
      }
    });
    await highlightTender(items, shouldHighlight);
    setSnackbarSaveChanges(true);
    setShouldRefetch();
  }, []);

  const markAsSeen = useCallback(async (rows, shouldMarkAsSeen) => {
    let items = [];
    rows.forEach((row) => {
      const index = items.findIndex(
        (item) => item.tenderNumber === row.tenderNumber,
      );
      if (index === -1) {
        if (!row.itemNumber) {
          items.push({
            TenderNumber: row.tenderNumber,
            ItemNumbers: [0],
          });
        } else {
          items.push({
            TenderNumber: row.tenderNumber,
            ItemNumbers: [row.itemNumber],
          });
        }
      } else {
        // If no item number, then add a 0 to view the whole tender
        if (!row.itemNumber) items[index].ItemNumbers.push(0);
        else items[index].ItemNumbers.push(row.itemNumber);
      }
    });
    await viewTender(items, shouldMarkAsSeen);
    setSnackbarSaveChanges(true);
    setShouldRefetch();
  }, []);
  // #endregion

  const contextMenuOptions = [
    {
      id: "manage-tender",
      title: "Gestionar licitación",
      icon: <Mode fontSize="small" />,
      onClick: async (rows) => {
        await manageTender(rows);
      },
    },
    {
      id: "manage-item",
      title: "Gestionar item(s)",
      icon: <DriveFileRenameOutline fontSize="small" />,
      onClick: async (rows) => {
        await manageItem(rows);
      },
    },
    {
      id: "highlight-all",
      title: "Destacar licitación",
      icon: <Star fontSize="small" />,
      onClick: async (rows) => {
        await highlightAll(rows, true);
      },
    },
    {
      id: "no-highlight-all",
      title: "No Destacar licitación",
      icon: <StarBorder fontSize="small" />,
      onClick: async (rows) => {
        await highlightAll(rows, false);
      },
    },
    {
      id: "highlight-item",
      title: "Destacar item(s)",
      icon: <AddBox fontSize="small" />,
      onClick: async (rows) => {
        await highlightItem(rows, true);
      },
    },
    {
      id: "no-highlight-item",
      title: "No Destacar item(s)",
      icon: <IndeterminateCheckBox fontSize="small" />,
      onClick: async (rows) => {
        await highlightItem(rows, false);
      },
    },
    {
      id: "mark-seen",
      title: "Marcar como visto",
      icon: <Visibility fontSize="small" />,
      onClick: async (rows) => {
        await markAsSeen(rows, true);
      },
    },
    {
      id: "no-mark-seen",
      title: "Marcar como no visto",
      icon: <VisibilityOff fontSize="small" />,
      onClick: async (rows) => {
        await markAsSeen(rows, false);
      },
    },
  ].concat(
    isLangAR()
      ? [
          {
            id: "open-link",
            title: "Abrir en el portal",
            icon: <OpenInNew fontSize="small" />,
            onClick: async (rows) => {
              if (rows[0].link) window.open(rows[0].link, "_blank");
            },
          },
        ]
      : [],
  );

  const options = {
    tableId: "gridSmartSearch",
    header: SmartSearchHeader,
    fullHeight: true,
    intlHeaderId: "app.columnsSS",
    tableHeaderTitle: "Smart Search",
    toolbarButtons: {
      Refresh: true,
    },
    enableMultiRowSelection: true,
    contextMenuOptions: contextMenuOptions,
    rowBackground: (row) => {
      if (row.userViewedName) {
        return "#d6def7"; // light blue -- change to: rgba(83, 131, 255, 0.38) ? or #a9a9cfc2
      }
      if (row.userManage) {
        return "#fcbc84"; // orange
      }
      if (row.userHighlightName) {
        return "#b1f76f"; // green
      }
    },
    rowSelectionFunc: (row) => {},
    rowDoubleClickFunc: (row) => {
      // open tender info modal
      handleOpenInfoModal(row);
    },
  };

  // #region Attachment Results
  const contextMenuOptionsAttachmentResults = [
    {
      id: "manage-tender",
      title: "Gestionar licitación",
      icon: <Mode fontSize="small" />,
      onClick: async (rows) => {
        await manageTender(rows);
      },
    },
    {
      id: "highlight-all",
      title: "Destacar licitación",
      icon: <Star fontSize="small" />,
      onClick: async (rows) => {
        await highlightAll(rows, true);
      },
    },
    {
      id: "no-highlight-all",
      title: "No Destacar licitación",
      icon: <StarBorder fontSize="small" />,
      onClick: async (rows) => {
        await highlightAll(rows, false);
      },
    },
    {
      id: "mark-seen",
      title: "Marcar como visto",
      icon: <Visibility fontSize="small" />,
      onClick: async (rows) => {
        await markAsSeen(rows, true);
      },
    },
    {
      id: "no-mark-seen",
      title: "Marcar como no visto",
      icon: <VisibilityOff fontSize="small" />,
      onClick: async (rows) => {
        await markAsSeen(rows, false);
      },
    },
    {
      id: "open-link",
      title: "Abrir documento",
      icon: <OpenInNew fontSize="small" />,
      onClick: async (rows) => {
        if (rows[0].link) window.open(rows[0].link, "_blank");
      },
    },
  ];

  const optionsAttachmentResults = {
    tableId: "gridSmartSearchAttachments",
    header: attachmentResultsHeader,
    fullHeight: true,
    intlHeaderId: "app.columnsSS",
    tableHeaderTitle: "Smart Search",
    toolbarButtons: {
      Refresh: true,
    },
    enableMultiRowSelection: true,
    contextMenuOptions: contextMenuOptionsAttachmentResults,
    rowBackground: (row) => {
      if (row.userViewedName) {
        return "#d6def7"; // light blue -- change to: rgba(83, 131, 255, 0.38) ? or #a9a9cfc2
      }
      if (row.userManage) {
        return "#fcbc84"; // orange
      }
      if (row.userHighlightName) {
        return "#b1f76f"; // green
      }
    },
    rowSelectionFunc: (row) => {},
    rowDoubleClickFunc: (row) => {
      // open tender info modal
      handleOpenInfoModal(row);
    },
  };
  // #endregion

  return (
    <Box
      display="flex"
      flex={1}
      sx={{
        // These are needed to make the Box fill the parent container and not the viewport. Prevents overflow of table.
        width: "0px",
        maxWidth: "-webkit-fill-available",
      }}>
      {!attachmentResultView && (
        <FSTMaterialReactTable options={options} getData={fetchAllData} />
      )}
      {attachmentResultView && isLangAR() && (
        <FSTMaterialReactTable
          options={optionsAttachmentResults}
          getData={fetchAllData}
        />
      )}
    </Box>
  );
}

export default SmartSearchResults;
