import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import React from "react";
import { formatShortDate } from "utils/common";

const createData = (name, data) => {
  return { name, data, id: `${name}${data}${Math.random()}` };
};

// Contract Info
export const getContractorInfo = (data) => {
  return [
    createData("Nombre del contratista", data.contractorName),
    createData("Tipo de oferta", data.offerType),
  ];
};

export const getContractInfo = (data) => {
  return [
    createData("Número de contrato", data.contractNumber),
    createData("Descripción de la contratación", data.description),
    createData("Fecha de elaboración", formatShortDate(data.elaborationDate)),
    createData("Fecha de notificación", formatShortDate(data.notificationDate)),
    createData("Tipo de procedimiento", data.processType),
    createData("Tipo de modalidad", data.modalityType),
    createData("Clasificación del objeto", data.objectClassification),
    createData("Vigencia del contrato", data.contractTerm),
    createData("Prórrogas", data.extension),
    createData("Multa", data.fine),
    createData("Cláusula penal", data.penalClause),
    createData("Según demanda", data.accordingToDemand),
    createData("Especies fiscales", data.fiscalSpecies),
    createData("Requiere recepción del objeto", data.requireObjectReception),
    createData("Observaciones", data.observations),
  ];
};

export const getOtherConditions = (data) => {
  return [
    createData("Modalidad de entrega", data.deliveryModality),
    createData("Condición de entrega (Incoterm)", data.deliveryCondition),
    createData("Detalle de entrega", data.deliveryDetail),
    createData("Forma de pago", data.paymentMethod),
    createData("Pago por adelantado", data.paymentInAdvance),
    createData("Modalidad de pago", data.paymentModality),
  ];
};

export const getWarrantyReception = (data) => {
  return createData("Recepción de garantías", data.guaranteeReception);
};

export const getWarrantyInfoHeader = () => {
  return ["", "Indicador", "% / monto", "Vigencia"];
};

export const getWarrantyInfoData = (data) => {
  return [
    [
      "Garantía de cumplimiento",
      data.complianceGuaranteeIndicator,
      data.complianceGuaranteeAmmount,
      data.complianceGuaranteeValidity,
    ],
    [
      "Colateral",
      data.colateralIndicator,
      data.colateralAmmount,
      data.colateralValidity,
    ],
  ];
};

export const getGoodsHeader = () => {
  return [
    "Línea",
    "Descripción del bien, servicio u obra",
    "Unidad",
    "Cantidad",
    "Precio unitario sin impuestos",
    "Precio Total sin impuestos",
    "Descuento",
    "Impuesto al valor agregado",
    "Otros Impuestos",
    "Costos por acarreos",
    "Precio Total",
  ];
};

export const getGoodsInfo = (data) => {
  return data.items?.map((item) => [
    item.line,
    item.description,
    item.uom,
    item.qty,
    item.unitPriceNoTax,
    item.totalPriceNoTax,
    item.discount,
    item.addedValueTax,
    item.otherTaxes,
    item.carryingCosts,
    item.totalPrice,
  ]);
};

export const getUsersHeader = () => {
  return ["Rol", "ID de usuario", "Departamento/Unidad", "Nombre y apellido"];
};

export const getUsersInfo = (data) => {
  return data.users?.map((user) => [
    user.role,
    user.userId,
    user.department,
    user.name,
  ]);
};

/*
export const getAttachments = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.C.AdjuntoNombre",
        defaultMessage: "Nombre",
      }),
      null
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.AdjuntoLink",
        defaultMessage: "Adjunto",
      }),
      null
    ),
  ];
};

export const getAttachmentsData = (data, intl) => {
  let arrayParsedData = data.Attachments?.map((item) => {
    return createData(item.Name, [
      item.Name,
      <IconButton>
        <a href={item.Link} target="_blank">
          <AttachFileIcon />
        </a>
      </IconButton>,
    ]);
  });
  return arrayParsedData;
};
*/

export const getContractorAnswer = (data) => {
  return [
    createData("Clasificación", data.classification),
    createData("Fecha de respuesta", formatShortDate(data.answerDate)),
    createData("Contenido de la respuesta", data.answerContent),
    createData("Archivo adjunto", data.attachment),
  ];
};

///////////////////////////////////////////////////////////////////////////////
// Purchase order info

export const getGeneralProcedureInfo = (data) => {
  return [
    createData("Número de SICOP", data.SicopNumber),
    createData("Número de procedimiento", data.ProcessNumber),
    createData("Tipo de procedimiento", data.ProcessType),
    createData("Descripción del procedimiento", data.ProcessDescription),
    createData("Número de contrato", data.ContractNumber),
  ];
};

export const getPurchaseOrderInfo = (data) => {
  return [
    createData("Número de orden de pedido", data.OrderNumber),
    createData("Fecha de elaboración", formatShortDate(data.ElaborationDate)),
    createData("Fecha de notificación", formatShortDate(data.NotificationDate)),
    createData("Modalidad de entrega", data.DeliveryMethod),
    createData("Incoterm", data.Incoterm),
    createData("Esquema de Pago", data.PaymentSchema),
    createData("Modalidad de pago", data.PaymentMethod),
    createData("Rechazo de líneas", data.LineRejection),
    createData("Observaciones", data.Observations),
  ];
};

export const getFiscalSpeciesInfo = (data) => {
  return [
    createData("Especies Fiscales", data.FiscalSpecies),
    createData("Porcentaje", data.Percentage),
    createData("Otros", data.Others),
    createData(
      "Fundamento jurídico para la exención",
      data.LegalBasisExemption,
    ),
  ];
};

export const getPurchaseOrderGoodsHeader = () => {
  return [
    "Línea",
    "Descripción del bien, servicio u obra",
    "Unidad",
    "Cantidad",
    "Precio unitario sin impuestos",
    "Precio Total sin impuestos",
    "Descuento",
    "Impuesto al valor agregado",
    "Otros Impuestos",
    "Costos por acarreos",
    "Precio Total",
  ];
};

export const getPurchaseOrderGoodsInfo = (data) => {
  return data.Items?.map((item) => [
    item.Line,
    item.Description,
    item.UOM,
    item.Ammount,
    item.UnintPriceNoTaxes,
    item.TotalPriceNoTaxes,
    item.Discount,
    item.AddedTaxValue,
    item.OtherTaxes,
    item.CarryingCosts,
    item.TotalPrice,
  ]);
};

export const getApprovalHistory = (data) => {
  return data.ApprovalHistories?.map((item) => [
    createData(
      "Fecha y hora de Procesamiento",
      formatShortDate(item.ProcessingDate),
    ),
    createData("Número de aprobación", item.ApprovalNumber),
    createData("Resultado de aprobación", item.ApprovalResult),
    createData(
      "Aprobador",
      `Area: ${item.Approver?.Area} - Nombre: ${item.Approver?.Name} - Mail: ${item.Approver?.Mail}`,
    ),
    createData("Contenido", item.Content),
    createData("Archivo adjunto", item.Attachment),
  ]);
};

export const getApprovalApplicationsHistory = (data) => {
  return data.ApprovalRequestHistories?.map((item) => {
    let approvers = item.Approvers;
    approvers = approvers.map((approver) =>
      createData(
        "Aprobador",
        `Area: ${approver?.Area} - Nombre: ${approver?.Name} - Mail: ${approver?.Mail}`,
      ),
    );
    return [
      createData(
        "Fecha y hora de solicitud",
        formatShortDate(item.RequestDate),
      ),
      createData(
        "Número de solicitud de aprobación",
        item.ApprovalRequestNumber,
      ),
      createData(
        "Solicitante",
        `Area: ${item.Applicant?.Area} - Nombre: ${item.Applicant?.Name} - Mail: ${item.Applicant?.Mail}`,
      ),
      ...approvers,
      createData("Descripción de solicitud", item.Description),
      createData("Contenido de solicitud", item.Content),
      createData("Archivo adjunto", item.Attachment),
    ];
  });
};

//checkBalance - Balance modal data
export const getAmountAwarded = (data) => {
  return [
    createData("Número de procedimiento", data.CheckBalance?.ProcedureNumber),
    createData("Tipo de procedimiento", data.CheckBalance?.ProcedureType),
    createData(
      "Descripción del procedimiento",
      data.CheckBalance?.ProcedureDescription,
    ),
    createData("Número de contrato", data.CheckBalance?.ContractNumber),
    createData(
      "* Tope de procedimiento",
      data.CheckBalance?.ProcedureThreshold,
    ),
    createData("* Tope máximo a superar", data.CheckBalance?.MaxThreshold),
  ];
};

export const getAwardedOrdersHeader = () => {
  return ["Número de documento", "Título", "Fecha de elaboración", "Estado"];
};

export const getAwardedOrdersInfo = (data) => {
  return data.CheckBalance?.Items?.map((item) => [
    item.DocumentNumber,
    item.Title,
    formatShortDate(item.ElaborationDate),
    item.Status,
  ]);
};

//checkBalance - Balance history modal data
export const getPurchaseOrderHistory = (data) => {
  return [
    createData("Número de procedimiento", data.CheckBalance?.ProcedureNumber),
    createData("Tipo de procedimiento", data.CheckBalance?.ProcedureType),
    createData(
      "Descripción del procedimiento",
      data.CheckBalance?.ProcedureDescription,
    ),
    createData("Número de contrato", data.CheckBalance?.ContractNumber),
  ];
};

export const getPurchaseOrderHistoryItemsHeader = () => {
  return ["Número de orden de pedido", "Monto total", "Fecha", "Estado"];
};

export const getPurchaseOrderHistoryItemsInfo = (data) => {
  return data.CheckBalance?.HistoryItems?.map((item) => [
    item.PurchaseOrderNumber,
    item.TotalAmmount,
    formatShortDate(item.Date),
    item.Status,
  ]);
};
