import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useEffect, useState } from "react";
import InfoItemsList from "./InfoContent/InfoItemsList";
import Assignments from "./ManagementContent/Assignments";
import Checklist, { baseChecklistData } from "./ManagementContent/Checklist";
import Comments from "./ManagementContent/Comments";
import LegalDocuments from "./ManagementContent/LegalDocuments/LegalDocuments";
import ManagedItemsList from "./ManagementContent/ManagedItemsList/ManagedItemsList";
import Sanctions from "./ManagementContent/Sanctions";
import {
  getManageData,
  listPenalties,
  listPenaltyReasons,
  manageTenderData,
} from "./tenderInformationContentService";
import { TenderPanel } from "./tenderPanelItems";
import { Card } from "@mui/material";
import { isLangCR } from "utils/common";
import { useHistory } from "react-router-dom";

const saveManageData = async (
  tenderData,
  managingTenderData,
  showLoadingStateSpinnerNoTimeout,
  hideLoadingSpinner,
  refetchData,
) => {
  showLoadingStateSpinnerNoTimeout("Inicializando datos por primera vez...");
  const request = {
    TenderNumber: tenderData.tenderNumber,
    ManageFiles: managingTenderData.files ?? [],
    SellerIds: managingTenderData.assignments.sellers.map(
      (item) => item.sellerId,
    ),
    TagIds: managingTenderData.assignments.tags.map((item) => item.tagId),
    CategoryIds: managingTenderData.assignments.categories.map(
      (item) => item.categoryId,
    ),
    UserIds: managingTenderData.assignments.users.map((item) => item.userId),
    LegalDocumentIds: managingTenderData.legalDocuments.map(
      (item) => item.legalDocumentId,
    ),
    ManageComments: managingTenderData.manageComments,
    ManageDetails: managingTenderData.manageDetails.map((item) => {
      const obj = {
        manageId: item.manageId,
        manageDetailId: item.manageDetailId,
        tenderDetailId: item.tenderDetailId,
        itemNumber: item.itemNumber,
        companyId: item.companyId,
        estimatedTotal: item.estimatedTotal,
        offered: item.offered,
        comments: item.comments,
        createdByUserId: item.createdByUserId,
        creationDate: item.creationDate,
        searchFilterResult: item.searchFilterResult,
        productId: item.productId,
        deliveryDate: item.deliveryDate,
        tagIds: item.tagIds,
        isPrimary: item.isPrimary,
      };
      return obj;
    }),
    ManageInternalStatusId: managingTenderData.manageInternalStatusId,
    ManageExtensions: managingTenderData.manageExtensions,
  };
  await manageTenderData(request);
  hideLoadingSpinner();
  setTimeout(() => {
    refetchData();
  }, 350);
};

const TenderManagementTabContent = ({
  tenderData,
  menu,
  managingTenderData,
  setManagingTenderData,
  discardChanges,
  setDiscardChanges,
}) => {
  const [tenderManageData, setTenderManageData] = useState(null);
  const {
    setShouldRefetch,
    showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner,
  } = useGlobalStore((state) => ({
    setShouldRefetch: state.setShouldRefetch,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
  }));
  const history = useHistory();

  const fetchTenderManageData = async () => {
    const response = await getManageData({
      tenderNumber: tenderData?.tenderNumber,
    });
    // If there is no response, redirect to /gestion. Means we have no access.
    if (!response) {
      hideLoadingSpinner();
      history.push("/gestion");
      return;
    }

    setTenderManageData(response.data);

    let newManagingData = {};
    let internalStatus = {};
    if (response.data.manageInternalStatusId) {
      internalStatus.manageInternalStatusId =
        response.data.manageInternalStatusId;
      internalStatus.description = response.data.manageInternalStatusDesc;
    }
    // Manage id and tender id
    newManagingData.manageId = response.data.manageId;
    newManagingData.tenderId = response.data.tenderId;

    // Manage details
    let tenderManageDetails = createManageDetailsList(response.data);
    newManagingData.manageDetails = tenderManageDetails;

    // Assignments
    newManagingData.assignments = {
      internalStatus,
      sellers: response.data.sellers,
      tags: response.data.tags,
      categories: response.data.categories,
      users: response.data.users,
    };

    // Files
    newManagingData.files = [...response.data.manageFiles];

    // Penalty reasons
    const penaltyReasonsResponse = await listPenaltyReasons();
    let penaltyReasons = penaltyReasonsResponse.data.map((item) => {
      return { id: item.penaltyReasonId, name: item.description };
    });
    newManagingData.penaltyReasons = penaltyReasons;

    // Penalties
    const penalties = await listPenalties();
    const data = penalties.data.map((item) => {
      return { ...item, ...JSON.parse(item.data) };
    });
    const filteredPenalties = data.filter(
      // filter items that have TenderId null
      (item) => item.tenderId === response.data.tenderId,
    );
    newManagingData.penalties = [...filteredPenalties];
    newManagingData.initialPenalties = [...filteredPenalties]; // Used later to compare if penalties were modified

    // Legal documents
    if (response.data.faithFulObservanceRequired) {
      newManagingData.configLegalDocument = { text: "Requerida", id: 1 };
    }
    newManagingData.legalDocuments = [...response.data.legalDocuments];

    // Manage comments
    newManagingData.manageComments = [...response.data.manageComments];

    // Manage extensions (used by Checklist)
    //filter to get manageExtensions that have the same manageId and have a manageExtensionId
    let manageExtensions = (response.data.manageExtensions || []).filter(
      (manageExtension) => {
        return manageExtension.manageId === response.data.manageId;
      },
    );
    newManagingData.manageExtensions = [...manageExtensions];

    if (manageExtensions.length === 0 && isLangCR()) {
      let data = JSON.parse(JSON.parse(tenderData?.extension)?.Info);
      let tenderDetailExtension =
        tenderData.tenderDetails.length > 0
          ? JSON.parse(tenderData.tenderDetails[0].extension)
          : {};
      let baseData = baseChecklistData(data, tenderDetailExtension);
      let newChecklistData = [
        { ManageId: newManagingData.manageId, Data: JSON.stringify(baseData) },
      ];
      newManagingData.manageExtensions = [...newChecklistData];
      setTimeout(() => {
        saveManageData(
          tenderData,
          {
            ...newManagingData,
            manageExtensions: newChecklistData,
          },
          showLoadingStateSpinnerNoTimeout,
          hideLoadingSpinner,
          fetchData,
        );
      }, 350);
    }

    newManagingData.isModified = false;
    setManagingTenderData(newManagingData);
  };

  const createManageDetailsList = (response) => {
    // TODO: refactor
    const listTenderDetails = [...tenderData.tenderDetails];

    // filter items that are in listTenderDetails but are not in response.manageDetails
    // (because only specific items can be managed)
    let managed = listTenderDetails.filter((tenderDataItem) => {
      return response.manageDetails.find(
        (manageDataItem) =>
          manageDataItem.tenderDetailId === tenderDataItem.tenderDetailId,
      );
    });

    // for each item in response.manageDetails, find the corresponding item in listTenderDetails and add its properties
    let manageDetailsCompleteInfo = response.manageDetails.map(
      (manageDataItem) => {
        const tenderDataItem = listTenderDetails.find(
          (tenderDataItem) =>
            tenderDataItem.tenderDetailId === manageDataItem.tenderDetailId,
        );
        if (!tenderDataItem) {
          return;
        }

        manageDataItem.estimatedTotal = manageDataItem.estimatedTotal || 0; // replace null with 0
        return {
          ...manageDataItem,
          ...tenderDataItem,
          managed: true, // maybe not used
        };
      },
    );

    if (response.faithFulObservanceRequired) {
      response.configLegalDocument = { text: "Requerida", id: 1 };
    }
    return manageDetailsCompleteInfo;
  };

  const fetchData = async () => {
    showLoadingStateSpinnerNoTimeout();
    await fetchTenderManageData();
    hideLoadingSpinner();
  };

  useEffect(() => {
    if (tenderData) fetchData();
  }, [tenderData]);

  const discardAndRefetch = async () => {
    // set default base empty values
    setManagingTenderData({
      manageId: managingTenderData.manageId,
      tenderId: managingTenderData.tenderId,
      manageDetails: [],
      assignments: {
        internalStatus: null,
        sellers: [],
        tags: [],
        categories: [],
        users: [],
      },
      files: [],
      penaltyReasons: [],
      penalties: [],
      initialPenalties: [],
      configLegalDocument: {},
      legalDocuments: [],
      manageComments: [],
      manageExtensions: [],
      isModified: false,
    });
    await fetchData();
    setTimeout(() => {
      setShouldRefetch();
      setDiscardChanges(false);
    }, 800);
  };

  useEffect(() => {
    if (discardChanges) discardAndRefetch();
  }, [discardChanges]);

  const handleManagingTenderData = (tenderManageData) => {
    setManagingTenderData({
      ...tenderManageData,
      isModified: true,
    });
  };

  const selectedCard = {
    [TenderPanel.INFO_ITEMS_LIST]: (
      <InfoItemsList
        data={tenderData}
        tenderData={tenderData}
        showManageInfo={true}
        discardAndRefetch={fetchData}
        managingTenderData={managingTenderData}
        handleManagingTenderData={handleManagingTenderData}
      />
    ),
    [TenderPanel.ASSIGNMENTS]: (
      <Assignments
        managingTenderData={managingTenderData}
        handleManagingTenderData={handleManagingTenderData}
      />
    ),
    [TenderPanel.MANAGED_ITEMS_LIST]: (
      <ManagedItemsList
        tenderData={tenderData}
        managingTenderData={managingTenderData}
        handleManagingTenderData={handleManagingTenderData}
        tenderManageData={tenderManageData}
      />
    ),
    [TenderPanel.LEGAL_DOCUMENTS]: (
      <LegalDocuments
        managingTenderData={managingTenderData}
        handleManagingTenderData={handleManagingTenderData}
        tenderManageData={tenderManageData}
      />
    ),
    [TenderPanel.COMMENTS]: (
      <Comments
        managingTenderData={managingTenderData}
        handleManagingTenderData={handleManagingTenderData}
        tenderManageData={tenderManageData}
      />
    ),
    [TenderPanel.SANCTIONS]: (
      <Sanctions
        managingTenderData={managingTenderData}
        handleManagingTenderData={handleManagingTenderData}
        tenderManageData={tenderManageData}
      />
    ),
    [TenderPanel.CHECKLIST]: (
      <Checklist
        managingTenderData={managingTenderData}
        handleManagingTenderData={handleManagingTenderData}
        tenderManageData={tenderManageData}
        tenderData={tenderData}
      />
    ),
  };

  return (
    <Fragment>
      {selectedCard[menu?.title] &&
        (menu?.hasManageTable ? (
          selectedCard[menu?.title]
        ) : (
          <Card
            className="card-box text-black-50 mb-4 p-3"
            sx={{
              height: "inherit",
              overflowY: "scroll",
              overflowX: "scroll",
              // Custom scrollbar
              "&::-webkit-scrollbar": {
                WebkitAppearance: "none",
                width: "10px",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: "rgba(118, 75, 162, 0.4)", // same color as gradient on the right, with alpha
                WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f5f5f5",
              },
            }}>
            {selectedCard[menu?.title]}
          </Card>
        ))}
    </Fragment>
  );
};

export default TenderManagementTabContent;
