import React from "react";
import { TextField, Grid, Typography } from "@mui/material";
import ResponsiveTable from "components/ResponsiveTable/ResponsiveTable";

const BuildElement = (element, props, children) => {
  let setElements;
  switch (element) {
    case "TextField":
      setElements = TextField;
      break;
    case "Grid":
      setElements = Grid;
      break;
    case "ResponsiveTable":
      setElements = ResponsiveTable;
      break;
    case "Typography":
      setElements = Typography;
      break;
    default:
      setElements = "div";
  }

  return React.createElement(setElements, props, children);
};

export default BuildElement;
