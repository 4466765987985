import { Card, CardContent, Divider, Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";
import NumericField from "components/CustomFields/NumericField";
import { Fragment, useEffect, useState } from "react";
import CriteriaWords from "./CriteriaWords";
import IncludeExcludeWordsHeader from "./IncludeExcludeWordsHeader";
import { useIntl } from "react-intl";

export const ScrollableCardContent = styled(CardContent)(({ theme }) => ({
  // Custom scrollbar
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    WebkitAppearance: "none",
    width: "12px",
    height: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "4px",
    backgroundColor: "#a9a9cfc2",
    WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f5f5f5",
  },
}));

export const emptyFilterContent = {
  name: "",
  days: "",
  includeWords: [{ word: "", words: [] }],
  includeWordsNames: [{ word: "", words: [] }],
  excludeWords: [{ word: "", words: [] }],
  excludeWordsNames: [{ word: "", words: [] }],
};

const FiltersManageModalContent = ({
  filter,
  displayDaysSelector = true,
  setSaveButtonDisabled,
  verifySaveButtonDisabled,
}) => {
  const intl = useIntl();
  const [filterToManage, setFilterToManage] = useState(
    filter || structuredClone(emptyFilterContent),
  );
  const [error, setError] = useState({
    errorName: false,
    errorDays: false,
    messageName: "",
    messageDays: "",
  });

  const validateErrors = (filterToValidate) => {
    let errorName = false;
    let errorDays = false;
    let messageName = "";
    let messageDays = "";

    if (!filterToValidate.name.trim()) {
      errorName = true;
      messageName = "El nombre del filtro no puede estar vacío";
    }
    if (filterToValidate.days.length === 0) {
      errorDays = true;
      messageDays = "El número de días no puede estar vacío";
    }
    setError({
      errorName,
      errorDays,
      messageName,
      messageDays,
    });
  };

  const adaptList = (list) => {
    const aux = [];
    for (let elem of list) {
      const word = {
        word: "",
        words: elem,
      };
      if (elem.length > 0) {
        aux.push(word);
      }
    }
    return aux.length > 0 ? aux : [];
  };

  const addElement = (key) => {
    const aux = { ...filterToManage };
    aux[key].push({ word: "", words: [] });
    setFilterToManage(aux);
  };

  const handleChange = (e) => {
    let newFilter = { ...filterToManage, [e.target.name]: e.target.value };
    setFilterToManage(newFilter);
    validateErrors(newFilter);
    setSaveButtonDisabled(false);
  };

  const handleWordsList = (key, value) => {
    const aux = { ...filterToManage };
    aux[key] = value.length > 0 ? value : [{ word: "", words: [] }];
    setFilterToManage(aux);
    setSaveButtonDisabled(false);
  };

  useEffect(() => {
    let errorOnTextFields = error.errorName || error.errorDays;
    verifySaveButtonDisabled(filterToManage, errorOnTextFields);
  }, [filterToManage]);

  useEffect(() => {
    if (filter) {
      const newFilter = {
        ...filter,
        includeWords: adaptList(filter.includeWords),
        includeWordsNames: adaptList(
          filter.includeWordsName || filter.includeWordsNames,
        ),
        excludeWords: adaptList(filter.excludeWords),
        excludeWordsNames: adaptList(
          filter.excludeWordsName || filter.excludeWordsNames,
        ),
      };
      setFilterToManage(newFilter);
    }
    setSaveButtonDisabled(true);
  }, [filter]);

  const [expand, setExpand] = useState({
    includeWords: true,
    includeWordsNames: true,
    excludeWords: false,
    excludeWordsNames: false,
  });

  const handleExpand = (id, value) => {
    setExpand({ ...expand, [id]: value });
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={displayDaysSelector ? 8 : 12}
          md={displayDaysSelector ? 8 : 12}>
          <TextField
            fullWidth
            autoFocus
            label={intl.formatMessage({
              id: "filtros.Nombre",
              defaultMessage: "Nombre del Filtro",
            })}
            variant="outlined"
            value={filterToManage?.name}
            name="name"
            onChange={handleChange}
            required
            error={error.errorName}
            helperText={error.messageName}
          />
        </Grid>
        {displayDaysSelector && (
          <Grid item xs={12} sm={4} md={4}>
            <NumericField
              fullWidth
              label={intl.formatMessage({
                id: "filtros.Dias",
                defaultMessage: "Lic. Últimos (Días)",
              })}
              variant="outlined"
              value={filterToManage?.days}
              name="days"
              onChange={handleChange}
              required
              error={error.errorDays}
              helperText={error.messageDays}
              maxValue={90}
              minValue={1}
            />
          </Grid>
        )}
      </Grid>
      <div
        className="divider border-2 border-light rounded"
        style={{
          width: "100%",
          display: "flex",
          alignSelf: "center",
          marginTop: "1rem",
        }}
      />
      <Grid container spacing={2} mt={0.5}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              <Card className="card-box text-black-50 bg-secondary">
                <IncludeExcludeWordsHeader
                  title={`Incluir por ${intl.formatMessage({
                    id: "filtros.IncluirExcluirItem",
                    defaultMessage: "Ítem",
                  })}`}
                  wordsList={filterToManage?.includeWords}
                  id="includeWords"
                  showExpand={true}
                  expand={expand.includeWords}
                  handleExpand={handleExpand}
                  addElement={addElement}
                />
                <Divider />
                <ScrollableCardContent
                  sx={{ height: expand.includeWords ? 320 : 0 }}>
                  {expand.includeWords && (
                    <CriteriaWords
                      wordsList={filterToManage?.includeWords}
                      id="includeWords"
                      handleWordsList={handleWordsList}
                    />
                  )}
                </ScrollableCardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card className="card-box text-black-50 bg-secondary">
                <IncludeExcludeWordsHeader
                  title={`Excluir por ${intl.formatMessage({
                    id: "filtros.IncluirExcluirItem",
                    defaultMessage: "Ítem",
                  })}`}
                  wordsList={filterToManage?.excludeWords}
                  id="excludeWords"
                  showExpand={true}
                  expand={expand.excludeWords}
                  handleExpand={handleExpand}
                  addElement={addElement}
                />
                <Divider />
                <ScrollableCardContent
                  sx={{ height: expand.excludeWords ? 320 : 0 }}>
                  {expand.excludeWords && (
                    <CriteriaWords
                      wordsList={filterToManage?.excludeWords}
                      id="excludeWords"
                      handleWordsList={handleWordsList}
                    />
                  )}
                </ScrollableCardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              <Card className="card-box text-black-50 bg-secondary">
                <IncludeExcludeWordsHeader
                  title={`Incluir por ${intl.formatMessage({
                    id: "filtros.IncluirExcluirNombre",
                    defaultMessage: "Nombre",
                  })}`}
                  wordsList={filterToManage?.includeWordsNames}
                  id="includeWordsNames"
                  showExpand={true}
                  expand={expand.includeWordsNames}
                  handleExpand={handleExpand}
                  addElement={addElement}
                />
                <Divider />
                <ScrollableCardContent
                  sx={{ height: expand.includeWordsNames ? 320 : 0 }}>
                  {expand.includeWordsNames && (
                    <CriteriaWords
                      wordsList={filterToManage?.includeWordsNames}
                      id="includeWordsNames"
                      handleWordsList={handleWordsList}
                    />
                  )}
                </ScrollableCardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card className="card-box text-black-50 bg-secondary">
                <IncludeExcludeWordsHeader
                  title={`Excluir por ${intl.formatMessage({
                    id: "filtros.IncluirExcluirNombre",
                    defaultMessage: "Nombre",
                  })}`}
                  wordsList={filterToManage?.excludeWordsNames}
                  id="excludeWordsNames"
                  showExpand={true}
                  expand={expand.excludeWordsNames}
                  handleExpand={handleExpand}
                  addElement={addElement}
                />
                <Divider />
                <ScrollableCardContent
                  sx={{ height: expand.excludeWordsNames ? 320 : 0 }}>
                  {expand.excludeWordsNames && (
                    <CriteriaWords
                      wordsList={filterToManage?.excludeWordsNames}
                      id="excludeWordsNames"
                      handleWordsList={handleWordsList}
                    />
                  )}
                </ScrollableCardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FiltersManageModalContent;
