import { Fragment, useEffect, useState } from "react";

import { Avatar, Box, Card, Grid } from "@mui/material";

import { AccountCircle, AppRegistration } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import { getManageStatusGroups } from "pages/TenderManagement/tenderManagementService";
import { formatDate } from "utils/common";
import HomeLoadingState from "../HomeLoadingState";
import HomeTitle from "../HomeTitle";

const LoadingState = () => {
  return (
    <Fragment>
      <HomeTitle title="Gestión" TitleIcon={AppRegistration} />
      <HomeLoadingState />
    </Fragment>
  );
};

export default function HomeManagementStats({ homeStats, sources, loading }) {
  const [isLoading, setIsLoading] = useState(true);

  // Cantidad de Lici x Ejecutivo
  const tendersBySeller =
    homeStats?.managedTendersBySeller.map((item) => {
      return {
        id: item.sellerId,
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        amount: item.tendersCount,
      };
    }) || [];
  // Cantidad de Lici Gestionadas (total) que no hayan cerrado.
  const [openManagedTenders, setOpenManagedTenders] = useState({
    ammount: 0,
    time: null,
  });
  // Cantidad de Lici x Status (el automatico)
  const [openTendersByPortal, setOpenTendersByPortal] = useState([]);

  const getManagedTendersByStatus = async () => {
    const response = await getManageStatusGroups();
    const result = response.data.statusGroups.map((item) => {
      return {
        id: item.statusId,
        name: item.statusDesc,
        amount: item.qtty,
      };
    });
    setOpenTendersByPortal(result);
    setOpenManagedTenders({
      ammount: response.data.statusGroups.find((x) => x.statusId === 1).qtty,
      time: `${formatDate(sources[0]?.lastScrapedDate)}hs`,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getManagedTendersByStatus();
  }, []);

  if (loading || isLoading) {
    return <LoadingState />;
  }

  return (
    <Fragment>
      <HomeTitle title="Gestión" TitleIcon={AppRegistration} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} flexDirection={"column"}>
          <Grid item xs={12}>
            <Card className="card-box border-0 card-shadow-first p-4 mb-4">
              <div className="d-flex align-items-center">
                <div className="d-40 rounded-circle bg-first text-white text-center font-size-lg mr-3">
                  <LanguageIcon />
                </div>
                <div className="text-black-50">Gestionadas abiertas</div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                <div>{openManagedTenders.ammount}</div>
              </div>
              <div className="text-black-50 text-center opacity-6 pt-3">
                Última actualización <b>{openManagedTenders.time}</b>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="card-box mb-4">
              <div className="card-header">
                <div className="card-header--title font-weight-bold">
                  Cantidad de Licitaciones por Ejecutivo
                </div>
                <div className="card-header--actions"></div>
              </div>
              <Box
                className="table-responsive"
                sx={{
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    WebkitAppearance: "none",
                    width: "10px",
                    height: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    backgroundColor: "#a9a9cfc2",
                    WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f5f5f5",
                  },
                  height: 336,
                }}>
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Ejecutivo</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tendersBySeller.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <Avatar className="mr-2">
                              <AccountCircle sx={{ width: 36, height: 36 }} />
                            </Avatar>
                            <div>
                              <span className="font-weight-bold text-black">
                                {item.name}
                              </span>
                              <span className="text-black-50 d-block">
                                {item.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center font-size-lg">
                            <small className="text-black-50 d-block">
                              {item.amount}
                            </small>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card className="card-box mb-4">
            <div className="card-header">
              <div className="card-header--title font-weight-bold">
                Cantidad de Licitaciones por Estado
              </div>
              <div className="card-header--actions"></div>
            </div>
            <Box
              className="table-responsive"
              sx={{
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  WebkitAppearance: "none",
                  width: "10px",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "4px",
                  backgroundColor: "#a9a9cfc2",
                  WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
                height: 544,
              }}>
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "65%" }}>Estado</th>
                    <th>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {openTendersByPortal.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="font-weight-bold text-black">
                            {item.name}
                          </span>
                          <span className="text-black-50 d-block">
                            {item.email}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center font-size-lg">
                          <small className="text-black-50 d-block">
                            {item.amount}
                          </small>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}
