import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useEffect, useState } from "react";
import FiltersManageModalContent from "./FiltersManageModalContent";

const ManageSmartSearchModal = ({ open, onClose, onSave, filter = null }) => {
  const [smartSearchFilter, setSmartSearchFilter] = useState(filter);
  const [filterToSave, setFilterToSave] = useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    setSmartSearchFilter(filter);
  }, [filter]);

  const cleanListEmpty = (list) => {
    return list
      .filter((item) => item.words.length > 0)
      .map((item) => item.words);
  };

  const verifySaveButtonDisabled = (modifiedFilter, errorOnTextFields) => {
    let active = false;
    let auxActive = true;

    active ||= saveButtonDisabled;
    active ||= errorOnTextFields;
    if (modifiedFilter?.name)
      active ||= !modifiedFilter.name || modifiedFilter.name.length === 0;
    if (modifiedFilter?.days)
      active ||= !modifiedFilter.days || modifiedFilter.days.length === 0;
    if (modifiedFilter?.includeWords)
      auxActive &&=
        cleanListEmpty(modifiedFilter.includeWords).length === 0 ||
        modifiedFilter.includeWords.some((item) => item.words.length === 0);
    if (modifiedFilter?.excludeWords)
      auxActive &&=
        cleanListEmpty(modifiedFilter.excludeWords).length === 0 ||
        modifiedFilter.excludeWords.some((item) => item.words.length === 0);
    if (modifiedFilter?.includeWordsNames)
      auxActive &&=
        cleanListEmpty(modifiedFilter.includeWordsNames).length === 0 ||
        modifiedFilter.includeWordsNames.some(
          (item) => item.words.length === 0,
        );
    if (modifiedFilter?.excludeWordsNames)
      auxActive &&=
        cleanListEmpty(modifiedFilter.excludeWordsNames).length === 0 ||
        modifiedFilter.excludeWordsNames.some(
          (item) => item.words.length === 0,
        );

    active ||= auxActive;

    // Validate includeWords and excludeWords char count
    const includeWordsCharacterCount = modifiedFilter.includeWords
      .reduce((acc, item) => [...acc, ...item.words], [])
      .reduce((acc, item) => acc + item.length, 0);
    const excludeWordsCharacterCount = modifiedFilter.excludeWords
      .reduce((acc, item) => [...acc, ...item.words], [])
      .reduce((acc, item) => acc + item.length, 0);
    active ||=
      includeWordsCharacterCount > 500 || excludeWordsCharacterCount > 500;

    setSaveButtonDisabled(active);
    setFilterToSave(modifiedFilter);
  };

  const handleSave = () => {
    const request = {
      name: filterToSave.name,
      days: +filterToSave.days,
      includeWords: cleanListEmpty(filterToSave.includeWords),
      excludeWords: cleanListEmpty(filterToSave.excludeWords),
      includeWordsNames: cleanListEmpty(filterToSave.includeWordsNames),
      excludeWordsNames: cleanListEmpty(filterToSave.excludeWordsNames),
    };
    if (filterToSave.searchFilterId) {
      request.searchFilterId = filterToSave.searchFilterId;
    }
    onSave([request]);
  };

  return (
    <GeneralPurposeModal
      open={open}
      onClose={onClose}
      title="Filtros Smart Search"
      onSave={handleSave}
      onSaveDisabled={saveButtonDisabled}
      maxWidth="lg"
      height={650}
      closeText="Cancelar">
      <FiltersManageModalContent
        filter={smartSearchFilter}
        displayDaysSelector={true}
        setSaveButtonDisabled={setSaveButtonDisabled}
        verifySaveButtonDisabled={verifySaveButtonDisabled}
      />
    </GeneralPurposeModal>
  );
};

export default ManageSmartSearchModal;
