import { get, post } from "config/axios";

export const addOrUpdateSearchFilter = async (request) => {
  return await post("/api/SearchFilter/AddOrUpdateSearchFilter", request);
};

export const deleteSearchFilter = async (request) => {
  return await post("/api/SearchFilter/DeleteSearchFilter", request);
};

export const listAllSearchFilters = async (pagination) => {
  const request = {
    OrderBy: null,
    PageSize: 9999,
    Filter: "",
    PageNumber: 1,
    OrderAsc: false,
  };
  return await post("/api/SearchFilter/ListSearchFilters", request);
};

export const listSearchFilters = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.filter,
    PageNumber: pagination.pageNumber,
    OrderAsc: pagination.orderAsc,
  };
  return await post("/api/SearchFilter/ListSearchFilters", request);
};

export const getFiltersCount = async (ids) => {
  return await post("/api/searchFilter/getFiltersCount", { FilterIds: ids });
};

export const getFilteredResult = async (request) => {
  return await post("/api/smartSearchFilter/getFilteredResult", request);
};

export const getFilteredAttachmentResult = async (request) => {
  return await post(
    "/api/smartSearchFromFile/getFilteredAttachmentResult",
    request,
  );
};

export const getFilteredAttachmentCount = async (ids) => {
  return await post("/api/smartSearchFromFile/getFiltersCount", {
    FilterIds: ids,
  });
};

export const addManagedTender = async (request) => {
  return await post("/api/Manage/ManageTender", request);
};

export const highlightTender = async (request, Highlighted) => {
  return await post(
    `/api/smartSearchFilter/highlightTender?highlighted=${Highlighted}`,
    request,
  );
};

export const viewTender = async (request, viewed) => {
  return await post(
    `/api/smartSearchFilter/viewTender?viewed=${viewed}`,
    request,
  );
};

export const getSources = async () => {
  return await get("api/smartSearchFilter/getSources");
};

export const downloadReport = async (request) => {
  return await post("/api/reportwriter/exportSmartSearch", request);
};

export const getTenderData = async (tenderNumber) => {
  return await post("/api/Tender/getTenderData", {
    tenderNumber: tenderNumber,
  });
};

export const updateDaysToAllFilters = async (request) => {
  return await post(`/api/SearchFilter/UpdateDaysToAllFilters/${request}`, {});
};
