export const legalDocumentSubjects = {
  subjectsAR: [
    { id: 0, description: "Impugnación al pliego" },
    { id: 1, description: "Impugnación a la preadjudicación" },
    { id: 2, description: "Impugnación a la pre selección" },
    { id: 3, description: "Mantenimiento de oferta" },
    { id: 4, description: "Cumplimiento de contrato" },
    {
      id: 5,
      description: "Monedas habilitadas para presentacion de Garantías",
    },
  ],
  subjectsBO: [
    { id: 0, description: "Garantia de cumplimiento de contrato" },
    { id: 1, description: "Garantia seriedad de propuesta" },
  ],
  subjectsCL: [],
  subjectsCO: [
    { id: 0, description: "Cumplimiento del contrato" },
    { id: 1, description: "Devolución del pago anticipado" },
    { id: 2, description: "Calidad y correcto funcionamiento de los bienes" },
    { id: 3, description: "Pago de salarios" },
    { id: 4, description: "Calidad del servicio" },
  ],
  subjectsCR: [
    { id: 0, description: "Garantía de participación" },
    { id: 1, description: "Garantía de cumplimiento" },
    { id: 2, description: "Colateral" },
  ],
  subjectsEC: [
    { id: 0, description: "Garantía de fiel cumplimiento" },
    { id: 1, description: "Garantía de buen uso del anticipo" },
    { id: 2, description: "Garantía Técnica" },
    { id: 3, description: "Garantía de Anticipo" },
  ],
  subjectsMX: [
    { id: 0, description: "Garantía de cumplimiento" },
    {
      id: 1,
      description: "Garantía sobre el bien o servicio y vicios ocultos",
    },
  ],
  subjectsPE: [{ id: 0, description: "Garantía de Fiel Cumplimiento" }],
};
