import {
  Box,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import EventNoteIcon from "@mui/icons-material/EventNote";
import GroupIcon from "@mui/icons-material/Group";
import LabelIcon from "@mui/icons-material/Label";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ShopIcon from "@mui/icons-material/Shop";

import CompanySettingModal from "pages/Settings/CompanySettingModal";
import EmailSubjectConfigModal from "pages/Settings/EmailSubjectConfigModal";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";

const COMPANY_SETTING_MODAL = "companySettingModal";
const EMAIL_SUBJECT_CONFIG_MODAL = "emailSubjectConfigModal";

const listItem = [
  {
    title: "Empresas",
    route: "/empresas",
    icon: <BusinessIcon />,
    showItem: (props) => props.isAdminGeneral,
  },
  {
    title: "Destinatarios",
    route: "/destinatarios",
    icon: <GroupIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    title: "Notificaciones y Alertas",
    route: "/notificaciones",
    icon: <NotificationsIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    title: "Usuarios",
    route: "/usuarios",
    icon: <PersonIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    title: "Competidores OC",
    route: "/competidores-oc",
    icon: <ShopIcon />,
    showItem: (props) =>
      props.applicationModules.some((item) => item.name === "OC"),
  },
  {
    title: "Entidades PAC",
    route: "/entidades-pac",
    icon: <EventNoteIcon />,
    showItem: (props) =>
      props.applicationModules.some((item) => item.name === "PAC"),
  },
  {
    title: "Configurar Empresa",
    route: COMPANY_SETTING_MODAL,
    modal: true,
    icon: <SettingsApplicationsIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    title: "Configuración Correo GL",
    route: EMAIL_SUBJECT_CONFIG_MODAL,
    modal: true,
    icon: <LabelIcon />,
    showItem: (props) => props.isAdmin,
  },
];

const SettingsMenu = ({ userInfo, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [openModal, setOpenModal] = useState({
    [COMPANY_SETTING_MODAL]: false,
    [EMAIL_SUBJECT_CONFIG_MODAL]: false,
  });
  const handleCloseModal = (modalName) => {
    setOpenModal({ ...openModal, [modalName]: false });
  };

  const history = useHistory();

  const goToRoute = (route, isModal) => {
    if (isModal) {
      setOpenModal({ ...openModal, [route]: true });
    } else if (!userInfo.pendingActive) {
      handleClose();
      // Timeout to prevent popover to remain open when redirecting because of lazy loading of pages
      setTimeout(() => {
        history.push(route);
      }, 400);
    } else {
      //TODO: this case doesnt happen because pendingActive is not implemented yet
      handleClose();
      props.OpenAlertPending();
      props.LinkRedirect(route);
    }
    handleClose();
  };

  useEffect(() => {
    handleClose();
  }, []);

  return (
    <Fragment>
      <Box component="span" pr="2">
        <Tooltip title="Ajustes" disableInteractive>
          <IconButton
            onClick={handleClick}
            color="inherit"
            className="btn-inverse mx-1 d-50"
            size="large">
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}>
          <div className="overflow-hidden dropdown-menu-xl">
            <List className="nav-danger nav-pills flex-column p-3">
              <Typography
                className="text-black pb-2 px-3 font-weight-bold"
                component="div">
                Ajustes
              </Typography>

              {listItem.map(
                (item, idx) =>
                  item.showItem(userInfo) && (
                    <ListItem
                      key={idx}
                      button
                      className="rounded-sm"
                      onClick={() => goToRoute(item.route, item.modal)}>
                      <div className="nav-link-icon opacity-6 mr-2">
                        {item.icon}
                      </div>
                      <span>{item.title}</span>
                    </ListItem>
                  ),
              )}
            </List>
          </div>
        </Popover>
      </Box>
      <CompanySettingModal
        open={openModal[COMPANY_SETTING_MODAL]}
        onClose={() => handleCloseModal(COMPANY_SETTING_MODAL)}
      />
      <EmailSubjectConfigModal
        open={openModal[EMAIL_SUBJECT_CONFIG_MODAL]}
        onClose={() => handleCloseModal(EMAIL_SUBJECT_CONFIG_MODAL)}
      />
    </Fragment>
  );
};

export default SettingsMenu;
