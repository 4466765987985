import { DEFAULT_STATUS_ID } from "pages/TenderManagement/TenderManagement";

const getSetStatusGroup = () => {
  // If no statusId is set in storage, set the default statusId
  if (
    localStorage.getItem("manageDefaultTab") == null ||
    localStorage.getItem("manageDefaultTab") == "null"
  ) {
    localStorage.setItem("manageDefaultTab", DEFAULT_STATUS_ID.toString());
    return DEFAULT_STATUS_ID;
  } else {
    return +localStorage.getItem("manageDefaultTab");
  }
};

const initialState = {
  manage: {
    currentStatusGroup: getSetStatusGroup(),
  },
};

const useViewsStateStore = (set, get) => ({
  viewsState: initialState,
  setViewsState: (viewsState) => {
    set({ viewsState: { ...viewsState } });
  },
  setManageViewsState: (manageViewsState) => {
    set({
      viewsState: { ...get().viewsState, manage: { ...manageViewsState } },
    });
  },
});

export default useViewsStateStore;
