import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useState } from "react";
import { syncCompanyUsers } from "../settingsService";

const SyncCompanyUsersModal = ({ open, onClose, onSave }) => {
  const {
    setSnackbarErrorCustom,
    setSnackbarSaveCustom,
    hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout,
  } = useGlobalStore((state) => ({
    setSnackbarErrorCustom: state.setSnackbarErrorCustom,
    setSnackbarSaveCustom: state.setSnackbarSaveCustom,
    hideLoadingSpinner: state.hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
  }));

  const [userSyncCompany, setUserSyncCompany] = useState("");
  const [invalidFormFields, setInvalidFormFields] = useState(false);

  const handleChange = (e) => {
    setUserSyncCompany(e.target.value);
  };

  const handleClose = () => {
    setInvalidFormFields(false);
    setUserSyncCompany("");
    onClose();
  };

  const getSyncUsersResponse = (response) => {
    if (response.includes("does not exist in MASTER")) {
      return {
        message: `La empresa ${userSyncCompany} no existe en la base de datos`,
        severity: "error",
      };
    } else if (response.includes("has missing parameters")) {
      return {
        message: `La empresa ${userSyncCompany} no tiene parametros en la base de datos`,
        severity: "error",
      };
    } else if (response.includes("has wrong parameters")) {
      return {
        message: `La empresa ${userSyncCompany} tiene parametros incorrectos en la base de datos`,
        severity: "error",
      };
    } else if (response.includes("Tenant")) {
      return {
        message: `La empresa ${userSyncCompany} no tiene usuarios creados aún`,
        severity: "error",
      };
    } else if (response.includes("does not exist in")) {
      return {
        message: `La empresa ${userSyncCompany} no existe en la base de datos a la que pertenece`,
        severity: "error",
      };
    } else if (response.includes("Company users successfully updated")) {
      return {
        message: `Usuarios de la empresa ${userSyncCompany} actualizados correctamente`,
        severity: "success",
      };
    } else {
      return {
        message: `Error al actualizar usuarios de la empresa ${userSyncCompany}`,
        severity: "error",
      };
    }
  };

  const handleSave = () => {
    if (!userSyncCompany.trim()) {
      setInvalidFormFields(true);
      return;
    }

    showLoadingStateSpinnerNoTimeout();
    const request = {
      tenantName: userSyncCompany,
    };
    syncCompanyUsers(request)
      .then((response) => {
        const toasterResult = getSyncUsersResponse(response.data.message);
        if (toasterResult.severity === "success") {
          setSnackbarSaveCustom(toasterResult.message);
        } else if (toasterResult.severity === "error") {
          setSnackbarErrorCustom(toasterResult.message);
        }

        hideLoadingSpinner();
        onSave();
      })
      .catch((error) => {
        hideLoadingSpinner();
        setSnackbarErrorCustom("Ocurrió un error al sincronizar la empresa");
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" sx={{ fontSize: 18 }}>
        Sincronizar Usuarios
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Especifique la empresa que desea sincronizar sus usuarios.
        </DialogContentText>
        <TextField
          variant="outlined"
          margin="dense"
          id="userSyncCompany"
          label="Empresa"
          fullWidth
          size="small"
          required
          value={userSyncCompany}
          onChange={handleChange}
        />

        {invalidFormFields && (
          <DialogContentText
            sx={{
              mt: 2,
              bgcolor: `rgba(248, 50, 69, 0.5)`,
              p: 2,
              borderRadius: 1,
              fontSize: 14,
              fontWeight: 500,
            }}>
            Complete el campo de empresa requerido.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          type="submit">
          Sincronizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncCompanyUsersModal;
