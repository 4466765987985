import CloseIcon from "@mui/icons-material/Close";
import {
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

const CriteriaWords = ({ id, wordsList, handleWordsList }) => {
  const [criteriaWordsList, setCriteriaWordsList] = useState(wordsList);

  useEffect(() => {
    setCriteriaWordsList(wordsList);
  }, [wordsList]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    const rowsAux = [...wordsList];
    rowsAux[index].word = value;
    setCriteriaWordsList(rowsAux);
    // validateInput(props.name, value);
  };

  const handleBlur = (index) => {
    const rowsAux = [...wordsList];
    rowsAux[index].word = "";
    handleWordsList(id, rowsAux);
    setCriteriaWordsList(rowsAux);
  };

  const handleKeyPress = (ev, index) => {
    const { value } = ev.target;
    if (ev.key === "Enter" && value.toString().trim().length > 1) {
      const rowsAux = [...wordsList];
      rowsAux[index].words.push(value.trim());
      rowsAux[index].word = "";
      handleWordsList(id, rowsAux);
    }
  };

  const deleteRow = (index) => {
    const rowsAux = [...wordsList];
    rowsAux.splice(index, 1);
    handleWordsList(id, rowsAux);
  };

  const deleteWord = (indexRow, indexWord) => {
    const rowsAux = [...wordsList];
    rowsAux[indexRow].words.splice(indexWord, 1);
    if (rowsAux[indexRow].words.length === 0) {
      deleteRow(indexRow);
    } else {
      handleWordsList(id, rowsAux);
    }
  };

  return (
    <TableContainer
      className="card-box text-black-50 bg-secondary"
      component={Paper}
      elevation={0}>
      <Table sx={{ minWidth: 500 }} aria-label="simple table">
        <TableBody>
          {criteriaWordsList.map((row, indexRow) => (
            <TableRow key={indexRow}>
              <TableCell
                component="th"
                scope="row"
                sx={{ width: "40%", p: 0.5 }}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  id={`criteria-${id}-${indexRow}`}
                  placeholder="Ingrese criterio *"
                  variant="outlined"
                  value={row.word}
                  onChange={(e) => handleChange(e, indexRow)}
                  onKeyDown={(e) => handleKeyPress(e, indexRow)}
                  onBlur={(e) => handleBlur(indexRow)}
                />
              </TableCell>
              <TableCell sx={{ width: "60%", p: 0.5 }} align="right">
                {row.words.map((word, indexWords) => (
                  <Chip
                    key={indexWords}
                    label={word}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onDelete={() => deleteWord(indexRow, indexWords)}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </TableCell>
              <TableCell sx={{ p: 0.5 }} align="right">
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => deleteRow(indexRow)}
                  aria-label="delete words">
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CriteriaWords;
