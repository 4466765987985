import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function ContractData(props) {
  return (
    <Card className="card-box text-black-50 bg-secondary p-2">
      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información del contratista"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {props.data.contractorInfo?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}>
                    {row.name}
                  </TableCell>
                  <TableCell>{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información Básica del Contrato"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {props.data.contractInfo?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}>
                    {row.name}
                  </TableCell>
                  <TableCell>{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Otras condiciones"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {props.data.otherConditions?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}>
                    {row.name}
                  </TableCell>
                  <TableCell>{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información de garantías"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}>
                  {props.data.warrantyReception.name}
                </TableCell>
                <TableCell>{props.data.warrantyReception.data}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          component={Paper}
          style={{ marginTop: "1%" }}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.data.warrantyInfoHeader.map((row, idx) => (
                  <TableCell key={idx}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.warrantyInfoData.map((row, idx2) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={`${row.id}${idx2}`}>
                  {row.map((row2, idx) => (
                    <TableCell
                      key={idx}
                      style={idx === 0 ? { fontWeight: "bold" } : {}}>
                      {row2}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información del bien, servicio u obra"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.data.goodsHeader.map((row, idx) => (
                  <TableCell
                    key={idx}
                    style={
                      idx === 1 ? { minWidth: "300px", maxWidth: "300px" } : {}
                    }>
                    {row}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.goodsInfo?.map((row, idx2) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={`${row}${idx2}`}>
                  {row.map((row2, idx) => (
                    <TableCell
                      key={idx}
                      style={
                        idx === 1
                          ? { minWidth: "300px", maxWidth: "300px" }
                          : {}
                      }>
                      {row2}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información de usuarios"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.data.usersHeader.map((row, idx) => (
                  <TableCell key={idx}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.usersInfo?.map((row, idx) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={`${row}${idx}`}>
                  {row.map((row2, idx2) => (
                    <TableCell key={idx2}>{row2}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Archivo Adjunto"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody></TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Respuesta del Contratista"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.data.contractorAnswer.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.data.contractorAnswer.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
