import { FormattedForm } from "intl/utils/format-elements/formattedForm";
import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import { Fragment, useEffect, useState } from "react";
import { isLangCO } from "utils/common";

export default function Schedule({ data }) {
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);

  const initData = () => {
    const result = handelData.getDataByCountry("tab.Cronograma");
    // sort by startDate if lang is CO
    if (isLangCO()) {
      result.sort((a, b) => {
        if (a.startDate < b.startDate) return -1;
        if (a.startDate > b.startDate) return 1;
        return 0;
      });
    }

    setData(result);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Fragment>
      {runInit && <FormattedForm id={"TabSchedule"} data={getData} />}
    </Fragment>
  );
}
