import { Card } from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { CircleLoader } from "react-spinners";

const LoadingSpinner = (props) => {
  const colors = ["secondary", "first", "primary", "info"];
  const [loaderColor, setLoaderColor] = useState(0);
  const { open, title } = useGlobalStore((state) => state.loadingSpinner);

  useEffect(() => {
    const intervalID = setTimeout(() => {
      setLoaderColor((loaderColor + 1) % colors.length);
    }, 900);
    return () => clearTimeout(intervalID);
  }, [loaderColor]);

  return (
    <LoadingOverlay
      active={open}
      spinner={
        <Card
          className="rounded-sm card-box p-3 m-3"
          sx={{ background: "rgba(0, 0, 0, 0.3)" }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ width: "150px", height: "80px" }}>
            <CircleLoader
              color={`var(--${colors[loaderColor]})`}
              loading={true}
            />
          </div>
          <p
            className="mb-0 pt-3 text-center"
            style={{ color: "rgba(255,255,255,0.8)" }}>
            {title}
          </p>
        </Card>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(0, 0, 0, 0.3)",
          zIndex: 9999,
        }),
        content: (base) => ({
          ...base,
          color: "black",
        }),
      }}>
      {props.children}
    </LoadingOverlay>
  );
};

export default LoadingSpinner;
