import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import { Fragment, useCallback, useRef, useState } from "react";
import {
  addOrUpdateSearchFilter,
  downloadReport,
  getFilteredAttachmentCount,
  getFilteredAttachmentResult,
  getFilteredResult,
  getFiltersCount,
  listAllSearchFilters,
} from "../smartSearchService";
import FiltersPanel from "./FiltersPanel/FiltersPanel";

import moment from "moment";
import { useIntl } from "react-intl";
import { convertTimezoneToTimezone } from "utils/DateHelper";
import { updateUserClientSettings } from "utils/clientSettings";
import { isLangAR, isLangCR } from "utils/common";
import { saveExcel } from "utils/excelExportService";
import ManageSmartSearchModal from "../FiltersAdmin/ManageSmartSearchModal";
import HeaderExportOptions from "./Header/HeaderExportOptions";
import HeaderLastUpdate from "./Header/HeaderLastUpdate";
import SmartSearchInfoModal from "./SmartSearchInfoModal";
import SmartSearchResults from "./SmartSearchResults";

const SmartSearch = (props) => {
  const intl = useIntl();
  const [filters, setFilters] = useState([]);
  const filtersRef = useRef(filters);
  filtersRef.current = filters;
  const [firstRender, setFirstRender] = useState(true);
  const firstRenderRef = useRef(firstRender);
  firstRenderRef.current = firstRender;
  const [tableSearchFilter, setTableSearchFilter] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(localStorage.getItem("selectedFilters")),
  );
  const [attachmentResultView, setAttachmentResultView] = useState(false);

  const {
    setSnackbarSaveChanges,
    showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    setShouldRefetch,
    setSnackbarInfoCustom,
    appData,
    setSnackbar,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setShouldRefetch: state.setShouldRefetch,
    setSnackbarInfoCustom: state.setSnackbarInfoCustom,
    appData: state.appData,
    setSnackbar: state.setSnackbar,
  }));
  const { sources } = appData;

  const getLastUpdateTime = () => {
    const portalName = intl.formatMessage({
      id: "portalNameSS",
      defaultMessage: "SEACE",
    });
    const source = sources.find((item) => item.name === portalName);
    if (source) {
      let time = moment(source.lastScrapedDate).format("DD/MM/YYYY HH:mm");
      if (isLangCR()) {
        time = convertTimezoneToTimezone(
          time,
          "DD/MM/YYYY HH:mm",
          "America/Santiago",
          "America/Costa_Rica",
        );
      }
      return `${time}hs.`;
    } else {
      return "--/--/---- --:--hs.";
    }
  };

  const lastUpdateTime = getLastUpdateTime();

  const getFilters = async (
    attachmentView = false,
    alwaysCheckedFilters = [],
  ) => {
    const response = await listAllSearchFilters();
    const ids = response.data.currentPageItems.map(
      (item) => item.searchFilterId,
    );
    let counts;
    if (attachmentView) {
      counts = await getFilteredAttachmentCount(ids);
    } else {
      counts = await getFiltersCount(ids);
    }
    const auxList = response.data.currentPageItems.map((item) => {
      if (alwaysCheckedFilters.includes(item.searchFilterId)) {
        return {
          ...item,
          checked: true,
          count: counts.data.find((obj) => obj.filterId === item.searchFilterId)
            .count,
        };
      }
      return {
        ...item,
        checked: selectedFilters
          ? selectedFilters.includes(item.searchFilterId)
          : true,
        count: counts.data.find((obj) => obj.filterId === item.searchFilterId)
          .count,
      };
    });
    setFilters(auxList);
    return [...auxList];
  };

  const fetchAllData = useCallback(
    async (pageNumber, pageSize, filter, sorting) => {
      let fetchedFilters = filtersRef.current;
      if (firstRenderRef.current) {
        fetchedFilters = await getFilters();
        setFirstRender(false);
      }
      let orderBy = sorting.length > 0 ? sorting[0].id : "publishedDate";
      let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;
      const request = {
        TenderId: 0,
        TenderName: "",
        TenderDescription: "",
        Organism: "",
        Filters: fetchedFilters
          ?.filter((item) => item.checked)
          .map((item) => item.searchFilterId),
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchFilter: filter,
        OrderBy: orderBy,
        OrderAsc: orderAsc,
        IsPaginated: true,
      };
      setTableSearchFilter(filter);
      let response;
      if (attachmentResultView) {
        response = await getFilteredAttachmentResult(request);
      } else {
        response = await getFilteredResult(request);
      }
      return {
        result: response.data.currentPageItems,
        total: response.data.totalItems,
      };
    },
    [attachmentResultView],
  );

  const handleChangeFilters = (newFilters) => {
    setFilters(newFilters);
    setTimeout(() => setShouldRefetch(), 0);
  };

  const handleSaveSelectedFilters = async () => {
    showSavingStateSpinnerNoTimeout();
    setSelectedFilters(
      filters.filter((item) => item.checked).map((item) => item.searchFilterId),
    );
    localStorage.setItem(
      "selectedFilters",
      JSON.stringify(
        filters
          .filter((item) => item.checked)
          .map((item) => item.searchFilterId),
      ),
    );
    await updateUserClientSettings();
    hideLoadingSpinner();
  };

  const handleExport = useCallback(
    async (exportType) => {
      setSnackbarInfoCustom(
        "En unos momentos se descargará el reporte.",
        "Generando resultados...",
      );
      const filename = attachmentResultView
        ? intl.formatMessage({
            id: "exportarSSDocsFileName",
            defaultMessage: "SmartSearchAttachmentsReport_AR.rdl",
          })
        : intl.formatMessage({
            id: "exportarSSFileName",
            defaultMessage: "SmartSearchReport.rdl",
          });
      const request = {
        IsPaginated: false,
        ReportId: 3,
        TenderId: 0,
        TenderName: "",
        TenderDescription: "",
        Organism: "",
        Filters: filters
          .filter((item) => item.checked)
          .map((item) => item.searchFilterId),
        SearchFilter: tableSearchFilter,
        ExportContentType: exportType, //1: All, 2: Managed, 3: Highlighted
        FileName: filename,
      };
      const result = await downloadReport(request);
      exportToExcel(result.data);
    },
    [attachmentResultView, filters, tableSearchFilter],
  );

  const exportToExcel = (value) => {
    const filename = attachmentResultView
      ? "SmartSearch - Documentos - Detalles de licitaciones.xlsx"
      : "SmartSearch - Detalles de licitaciones.xlsx";
    saveExcel(value, filename);
  };

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedTenderInfo, setSelectedTenderInfo] = useState({});

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  const handleOpenInfoModal = useCallback((row) => {
    setSelectedTenderInfo(row);
    setOpenInfoModal(true);
  }, []);

  // -- Filters Modal -- //
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [filterToModify, setFilterToModify] = useState(null);

  const addEditFilter = (filter) => {
    setFilterToModify(filter);
    setOpenFiltersModal(true);
  };
  const handleCloseFiltersModal = () => {
    setFilterToModify(null);
    setOpenFiltersModal(false);
  };
  const handleSaveFilter = async (request) => {
    showSavingStateSpinnerNoTimeout();

    addOrUpdateSearchFilter(request)
      .then((response) => {
        getFilters(attachmentResultView, [response.data[0].searchFilterId])
          .then(() => {
            hideLoadingSpinner();
            setSnackbarSaveChanges();
            handleCloseFiltersModal();
            setShouldRefetch();
          })
          .catch((error) => {
            hideLoadingSpinner();
            console.error(error);
          });
      })
      .catch((error) => {
        hideLoadingSpinner();
        if (error.msgErrorKey === "ENTITY_ALREADY_EXISTS") {
          setSnackbar({
            open: true,
            severity: "warning",
            title: "Advertencia",
            subTitle:
              "Ya existe un filtro con el mismo nombre. Por favor, elija otro.",
          });
        }
        console.error(error);
      });
  };
  // --   -- //

  //#region Attachments view logic
  // -- Attachments Config -- //
  const handleAttachmentResultView = async (event, newValue) => {
    setAttachmentResultView(newValue);
    await getFilters(newValue);
  };
  //#endregion

  return (
    <Fragment>
      <PageTitle
        titleHeading="Resultados Smart Search"
        icon={<FindInPageIcon />}
        startComponent={
          isLangAR() && (
            <Fragment>
              <ToggleButtonGroup
                value={attachmentResultView}
                exclusive
                onChange={handleAttachmentResultView}
                size="small"
                sx={{ height: 32 }}
                aria-label="attachment result view">
                <ToggleButton value={false} aria-label="list" color="primary">
                  Renglones
                </ToggleButton>
                <ToggleButton value={true} aria-label="grid" color="primary">
                  Documentos
                </ToggleButton>
              </ToggleButtonGroup>
            </Fragment>
          )
        }
        endComponent={
          <>
            <HeaderLastUpdate lastUpdateTime={lastUpdateTime} />
            <HeaderExportOptions handleExport={handleExport} />
          </>
        }
      />

      <Box display="flex" gap={2} height="100%">
        <FiltersPanel
          filters={filters}
          setFilters={handleChangeFilters}
          addEditFilter={addEditFilter}
          selectedFilters={selectedFilters}
          handleSaveSelectedFilters={handleSaveSelectedFilters}
        />
        <SmartSearchResults
          attachmentResultView={attachmentResultView}
          handleOpenInfoModal={handleOpenInfoModal}
          fetchAllData={fetchAllData}
        />
      </Box>

      <SmartSearchInfoModal
        open={openInfoModal}
        handleClose={handleCloseInfoModal}
        tenderInfo={selectedTenderInfo}
      />

      <ManageSmartSearchModal
        open={openFiltersModal}
        onClose={handleCloseFiltersModal}
        onSave={handleSaveFilter}
        filter={filterToModify}
      />
    </Fragment>
  );
};

export default SmartSearch;
