const initialState = {
  open: false,
  title: "",
  id: "",
  data: { detail: "", data: [] },
};

const useTenderInfoDetailModalStore = (set) => ({
  tenderInfoDetailModal: initialState,
  setTenderInfoDetailModal: (tenderInfoDetailModal) =>
    set({ tenderInfoDetailModal }),
  hideTenderInfoDetailModal: () => {
    set((state) => ({
      tenderInfoDetailModal: {
        ...state.tenderInfoDetailModal,
        open: false,
      },
    }));
  },
  showTenderInfoDetailModal: (id, title, data) => {
    set({
      tenderInfoDetailModal: { open: true, id: id, title: title, data: data },
    });
  },
});

export default useTenderInfoDetailModalStore;
