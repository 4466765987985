import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import NumericField from "components/CustomFields/NumericField";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { getDateRangeUnits } from "../analysisService";
import { CUSTOM_TIME_RANGE } from "./ManageAnalysisFiltersModal";

const MomentRange = {
  1: "days",
  2: "week",
  3: "month",
  4: "year",
};

const ManageFilterCustomRangeModal = ({
  filter,
  handleFilterToManageChange,
  open,
  onClose,
}) => {
  const [currentRange, setCurrentRange] = useState({
    quantity: 1,
    range: 1,
  });
  const [rangeOptions, setRangeOptions] = useState([]);

  const fetchOptions = async () => {
    if (filter && filter.dateRangeUnitId) {
      setCurrentRange({
        quantity: filter.dateRangeQty,
        range: filter.dateRangeUnitId,
      });
    }
    const response = await getDateRangeUnits();
    setRangeOptions(response.data);
  };

  useEffect(() => {
    if (open) {
      fetchOptions();
    }
  }, [open]);

  const handleSave = () => {
    const startDate = moment().subtract(
      currentRange.quantity,
      MomentRange[currentRange.range],
    );
    const endDate = moment().endOf("day");
    handleFilterToManageChange({
      ...filter,
      range: CUSTOM_TIME_RANGE,
      dateRangeUnitId: currentRange.range,
      dateRangeQty: currentRange.quantity,
      startDate,
      endDate,
    });
    onClose();
  };

  return (
    <Fragment>
      <GeneralPurposeModal
        open={open}
        onClose={onClose}
        title="Seleccionar rango personalizado"
        onSave={handleSave}
        maxWidth="xs"
        closeText="Cancelar">
        {rangeOptions.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <NumericField
                id="quantity"
                label="Cantidad"
                fullWidth
                value={currentRange.quantity}
                minValue={0}
                onChange={(event) => {
                  setCurrentRange({
                    ...currentRange,
                    quantity: event.target.value ? +event.target.value : 0,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Rango</FormLabel>
                <RadioGroup
                  name="range"
                  value={currentRange.range}
                  onChange={(event) => {
                    setCurrentRange({
                      ...currentRange,
                      range: +event.target.value,
                    });
                  }}>
                  {rangeOptions.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.key}
                        value={item.key}
                        control={<Radio />}
                        label={item.value}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </GeneralPurposeModal>
    </Fragment>
  );
};

export default ManageFilterCustomRangeModal;
