import { Sync } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import { Box, Button } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import { Fragment, useState } from "react";
import {
  enableDisableCompany,
  listCompanies,
  saveCompany,
} from "../settingsService";
import AddCompanyModal from "./AddCompanyModal";
import SyncCompanyUsersModal from "./SyncCompanyUsersModal";

// TODO: Add deleting company when it's implemented
const Companies = () => {
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const {
    setSnackbarSaveChanges,
    setSnackbarMissingData,
    showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    setShouldRefetch,
    userInfo,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    setSnackbarMissingData: state.setSnackbarMissingData,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setShouldRefetch: state.setShouldRefetch,
    userInfo: state.userInfo,
  }));

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };

  const handleDelete = async () => {
    showSavingStateSpinnerNoTimeout();
    setModalDeleteConfirm(false);
    setSnackbarSaveChanges();
    hideLoadingSpinner();
    setShouldRefetch();
  };

  const handleSave = async (item) => {
    showSavingStateSpinnerNoTimeout();
    const activeCompanyRequest = {
      companyId: item.companyId,
      name: item.name,
      isEnabled: item.isEnabled,
    };
    await enableDisableCompany(activeCompanyRequest);
    hideLoadingSpinner();
    setSnackbarSaveChanges();
    return true;
  };

  const handleAddNewCompany = async (company) => {
    const newCompany = {
      ...company,
      tenantName: "",
    };
    showSavingStateSpinnerNoTimeout();
    await saveCompany(newCompany);
    hideLoadingSpinner();
    setSnackbarSaveChanges();
    setShouldRefetch();
  };

  const fetchTableData = async (page, pageSize, filter) => {
    const companies = await listCompanies();

    return {
      result: companies.data,
      total: companies.data.length,
    };
  };

  const columns = [
    {
      accessorKey: "companyId",
      header: "ID",
      enableEditing: false,
      size: 80,
    },
    {
      accessorKey: "name",
      header: "Empresa",
      enableEditing: false,
    },
    {
      accessorKey: "isEnabled",
      header: "Estado",
      disableTooltip: true,
      //render a chip with 'activo' or 'inactivo' depending on the value, changes color depending on the value
      cellType: "active-inactive",
      editType: "active-inactive",
      enableEditing: userInfo.isAdmin,
    },
  ];

  const options = {
    tableId: "gridSettingsCompanies",
    header: columns,
    fullHeight: true,
    tableHeaderTitle: "Empresas",
    toolbarButtons: {
      Add: true,
      AddFunc: () => setModalAddOpen(true),
    },
    enableEditing: true,
    // enableDeleting: true,
    // deleteFunc: (row) => {
    //   setItemToDelete(row);
    //   setModalDeleteConfirm(true);
    // },
    saveFunc: handleSave,
  };

  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalSyncUsersOpen, setModalSyncUsersOpen] = useState(false);

  const handleSaveSyncUsers = () => {
    setShouldRefetch();
    setModalSyncUsersOpen(false);
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Empresas"
        titleDescription="Administrar empresas"
        icon={<BusinessIcon />}
        endComponent={
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => setModalSyncUsersOpen(true)}>
              <Sync sx={{ mr: 1 }} fontSize={"small"} />
              Sincronizar Usuarios
            </Button>
          </Box>
        }
      />
      <FSTMaterialReactTable options={options} getData={fetchTableData} />

      {/* <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={`¿Está seguro que desea eliminar la empresa ${itemToDelete?.firstName} ${itemToDelete?.lastName}?`}
      /> */}
      <AddCompanyModal
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
        onSave={(newCompanyForm) => {
          handleAddNewCompany(newCompanyForm);
        }}
        appModules={userInfo?.applicationModules}
      />

      <SyncCompanyUsersModal
        open={modalSyncUsersOpen}
        onClose={() => setModalSyncUsersOpen(false)}
        onSave={handleSaveSyncUsers}
      />
    </Fragment>
  );
};

export default Companies;
