import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  MRT_ActionMenuItem,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
  createRow,
  useMaterialReactTable,
} from "material-react-table";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// Material React Table Translations
import { MRT_Localization_ES } from "material-react-table/locales/es";
// Icons
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileDownload as FileDownloadIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
  Send as SendIcon,
  Settings as SettingsIcon,
  Upload as UploadIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

import { useGlobalStore } from "global-store/useGlobalStore";
import { useIntl } from "react-intl";

import { RingLoader } from "react-spinners";
import {
  getGridSettings,
  setGridSettings,
  updateUserClientSettings,
} from "utils/clientSettings";
import { saveNewExcel } from "utils/excelExportService";
import { getCellComponent } from "./components/cellComponents";
import { getEditComponent } from "./components/cellEditComponents";
import TooltipButton from "./components/tooltip";

// Toolbar button creation
const getInitialToolbarButtons = ({
  options,
  fetchData,
  handleDefaultAddRow,
  exportExcel,
}) => {
  let buttons = [];

  // Add extra buttons, set by the user
  /* Props:
        key: string
        title: string
        icon: ReactNode
        onClick: function
    */
  for (const item of options.toolbarButtons?.ExtraButtons || []) {
    buttons.push(item);
  }

  // Actualizar
  if (options.toolbarButtons?.Refresh) {
    buttons.push({
      key: "refresh",
      title: "Actualizar",
      icon: <RefreshIcon />,
      onClick: fetchData,
    });
  }
  // Agregar
  if (options.toolbarButtons?.Add) {
    buttons.push({
      key: "add",
      title: "Agregar",
      icon: <AddIcon />,
      onClick: options.toolbarButtons.AddFunc
        ? options.toolbarButtons.AddFunc
        : handleDefaultAddRow,
    });
  }
  // Modificar
  if (options.toolbarButtons?.Edit) {
    buttons.push({
      key: "edit",
      title: "Editar",
      icon: <EditIcon />,
      onClick: options.toolbarButtons.EditFunc,
    });
  }
  // Eliminar
  if (options.toolbarButtons?.Delete) {
    buttons.push({
      key: "delete",
      title: "Eliminar",
      icon: <DeleteIcon />,
      onClick: options.toolbarButtons.DeleteFunc,
    });
  }
  // Opciones avanzadas
  if (options.toolbarButtons?.AdvancedOptions) {
    buttons.push({
      key: "advancedOptions",
      title: "Opciones avanzadas",
      icon: <SettingsIcon />,
      onClick: options.toolbarButtons.AdvancedOptionsFunc,
    });
  }
  // Gestionar
  if (options.toolbarButtons?.Manage) {
    buttons.push({
      key: "manage",
      title: "Gestionar",
      icon: <SettingsIcon />,
      onClick: options.toolbarButtons.ManageFunc,
    });
  }
  // Enviar licitación
  if (options.toolbarButtons?.Send) {
    buttons.push({
      key: "send",
      title: "Enviar licitación",
      icon: <SendIcon />,
      onClick: options.toolbarButtons.SendFunc,
    });
  }
  // Exportar a excel
  if (options.toolbarButtons?.Export) {
    buttons.push({
      key: "export",
      title: "Exportar",
      icon: <FileDownloadIcon />,
      onClick: exportExcel,
    });
  }
  // Importar
  if (options.toolbarButtons?.Import) {
    buttons.push({
      key: "import",
      title: "Importar",
      icon: <UploadIcon />,
      onClick: options.toolbarButtons.ImportFunc,
    });
  }
  // Vista
  if (options.toolbarButtons?.View) {
    buttons.push({
      key: "view",
      title: "Vista",
      icon: <VisibilityIcon />,
      onClick: options.toolbarButtons.ViewFunc,
    });
  }

  return buttons;
};

const getToolbarInternalActions = ({ table, toolbarButtons }) => (
  <Grid
    container
    spacing={1}
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mt: 0,
    }}>
    {/* Custom Buttons */}
    {toolbarButtons?.map((button) => (
      <TooltipButton
        key={button.key}
        title={button.title}
        icon={button.icon}
        onClick={() =>
          // Returns the original row object
          // TODO: make array always
          button.onClick(
            table.getSelectedRowModel().rows.length > 1
              ? table.getSelectedRowModel().rows.map((row) => row.original)
              : table.getSelectedRowModel().rows.length === 1
              ? table.getSelectedRowModel().rows[0].original
              : {},
            table,
          )
        }
      />
    ))}

    {/* Built-in buttons */}
    <MRT_ToggleGlobalFilterButton
      table={table}
      sx={{ borderRadius: "0.5rem" }}
    />
    <MRT_ShowHideColumnsButton table={table} sx={{ borderRadius: "0.5rem" }} />
  </Grid>
);

function FSTMaterialReactTable({ options, getData }) {
  const addActionsColumn = (givenColumnOrder) => {
    if (options.enableEditing || options.enableDeleting) {
      return [
        "mrt-row-actions",
        ...givenColumnOrder.filter((col) => col !== "mrt-row-actions"),
      ];
    }
    return givenColumnOrder;
  };

  // Table basic data
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  // Table props options
  const [toolbarButtons, setToolbarButtons] = useState([]);

  // Table State
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [rowSelection, setRowSelection] = useState({});

  // Table persistent state
  const [columnVisibility, setColumnVisibility] = useState({});
  const [density, setDensity] = useState("compact");
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnSizing, setColumnSizing] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    addActionsColumn(options.header.map((col) => col.accessorKey)),
  );
  const isFirstRender = useRef(true);

  // -- Table Persistent State Functions -- //
  const updateUserSettings = async () => {
    if (isFirstRender.current) return;
    const id = options.tableId;
    const savedState = getGridSettings(id);

    if (savedState) {
      savedState.columnVisibility = persistentStateRef.current.columnVisibility;
      savedState.density = persistentStateRef.current.density;
      savedState.sorting = persistentStateRef.current.sorting;
      savedState.columnSizing = persistentStateRef.current.columnSizing;
      savedState.columnOrder = addActionsColumn(
        persistentStateRef.current.columnOrder,
      );
      setGridSettings(id, savedState);
    } else {
      const newSettings = {
        columnVisibility: persistentStateRef.current.columnVisibility,
        density: persistentStateRef.current.density,
        sorting: persistentStateRef.current.sorting,
        columnSizing: persistentStateRef.current.columnSizing,
        columnOrder: addActionsColumn(persistentStateRef.current.columnOrder),
      };
      setGridSettings(id, newSettings);
    }

    await updateUserClientSettings();
  };

  const getColumnsVisibility = (initialVisibility) => {
    const columnsVisibility = {};
    options.header.forEach((col) => {
      if (col.defaultHidden) columnsVisibility[col.accessorKey] = false;
    });
    if (initialVisibility) {
      Object.keys(initialVisibility).forEach((key) => {
        columnsVisibility[key] = initialVisibility[key];
      });
    }
    return columnsVisibility;
  };

  const initialStateSetup = () => {
    const id = options.tableId;
    const savedState = getGridSettings(id);
    let result = {
      columnVisibility: {},
      density: "compact",
      sorting: [],
      columnSizing: {},
      columnOrder: addActionsColumn(
        options.header.map((col) => col.accessorKey),
      ),
    };
    if (savedState) {
      setColumnVisibility(getColumnsVisibility(savedState.columnVisibility));
      setDensity(savedState.density);
      setSorting(savedState.sorting);
      setColumnSizing(savedState.columnSizing);
      setColumnOrder(
        savedState.columnOrder
          ? addActionsColumn(savedState.columnOrder)
          : addActionsColumn(result.columnOrder),
      );
      result = {
        columnVisibility: savedState.columnVisibility,
        density: savedState.density,
        sorting: savedState.sorting,
        columnSizing: savedState.columnSizing,
        columnOrder: savedState.columnOrder
          ? addActionsColumn(savedState.columnOrder)
          : addActionsColumn(result.columnOrder),
      };
    } else {
      setColumnVisibility(getColumnsVisibility());
      result.columnVisibility = getColumnsVisibility();
    }
    isFirstRender.current = false;

    return result;
  };

  useEffect(() => {
    persistentStateRef.current = {
      columnVisibility: columnVisibility,
      density: density,
      sorting: sorting,
      columnSizing: columnSizing,
      columnOrder: columnOrder,
    };
  }, [columnVisibility, density, sorting, columnSizing, columnOrder]);
  // --  -- //

  const [persistentState, setPersistentState] = useState(initialStateSetup);
  const persistentStateRef = useRef(persistentState);

  // Custom State
  const [firstRender, setFirstRender] = useState(true);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const {
    hideLoadingSpinner,
    shouldRefetch,
    tableHeight,
    setSnackbar,
    resetSnackbar,
    tenderInfoDetailModal,
  } = useGlobalStore((state) => ({
    hideLoadingSpinner: state.hideLoadingSpinner,
    shouldRefetch: state.shouldRefetch,
    tableHeight: state.height,
    setSnackbar: state.setSnackbar,
    resetSnackbar: state.resetSnackbar,
    tenderInfoDetailModal: state.tenderInfoDetailModal,
  }));
  //////////////////////////////////////////////

  // Initial table setup
  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setIsAddingRow(false);
    const buttons = getInitialToolbarButtons({
      options,
      fetchData,
      handleDefaultAddRow,
      exportExcel,
    });
    setToolbarButtons(buttons);
    const initialState = initialStateSetup();
    fetchData(initialState);

    // Used to prevent double fetching on first render because of the useEffect dependency array for the table state.
    // This happens because we update the state when loading the persistent state from the user.
    setTimeout(() => {
      setFirstRender(false);
    }, 500);

    return () => {
      updateUserSettings();
    };
  }, []);

  // -- Table UX -- //
  const renderTopToolbarCustomActions = useCallback(
    () =>
      options.tableHeaderTitle ? (
        <Box sx={{ display: "flex", mt: 0.4, mb: -1 }}>
          {typeof options.tableHeaderTitle === "string" ? (
            <Typography variant="h6" sx={{ fontSize: "1rem", mx: 2, my: 1 }}>
              {options.tableHeaderTitle}
            </Typography>
          ) : (
            options.tableHeaderTitle
          )}
        </Box>
      ) : null,
    [options.tableHeaderTitle],
  );

  const muiTableBodyProps = useCallback(
    ({ row, table }) => ({
      onDoubleClick: (event) => {
        // Typically used to enter a tender info page when in GL
        // Do something with the row when it's double clicked
        if (options?.rowDoubleClickFunc) {
          options.rowDoubleClickFunc(row.original);
        }
      },
      // Left click
      onClick: () => {
        // If editing or adding a row, don't do anything
        if (table.getState().editingRow || table.getState().creatingRow) return;
        // Only one row selection method
        // Enabled if enableRowSelection is true but not enableEditing (when editing is enabled, uses default row selection)
        if (options?.enableRowSelection && !options?.enableEditing) {
          // If the row is already selected, unselect it. Only one row can be selected at a time
          setRowSelection((prev) => {
            if (prev[row.id]) {
              return {};
            }
            return { [row.id]: true };
          });
        }
        // Multiple row selection method
        if (options?.enableMultiRowSelection) {
          setRowSelection((prev) => ({
            ...prev,
            [row.id]: !prev[row.id],
          }));
        }
        // Do something with the row when it's clicked
        if (options?.rowSelectionFunc) {
          options.rowSelectionFunc(row.original);
        }
      },
      selected: rowSelection[row.id],
      sx: {
        // Change background color of row if specified
        backgroundColor: options.rowBackground
          ? options.rowBackground(row.original)
          : null,
      },
    }),
    [isAddingRow, options],
  );

  const muiTableContainerProps = useCallback(({ table }) => {
    // This is done to make sure the table height is correct when resizing the window and correctly using the breakpoints of the component
    const {
      refs: { bottomToolbarRef, topToolbarRef },
    } = table;
    const topToolbarHeight =
      typeof document !== "undefined"
        ? topToolbarRef.current?.offsetHeight ?? 0
        : 0;

    const bottomToolbarHeight =
      typeof document !== "undefined"
        ? bottomToolbarRef?.current?.offsetHeight ?? 0
        : 0;

    let heightToSubtract = topToolbarHeight + bottomToolbarHeight;
    return {
      sx: {
        height: `calc(100% - ${heightToSubtract}px)`,
        // Custom scrollbar
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          WebkitAppearance: "none",
          width: "12px",
          height: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: "#a9a9cfc2",
          WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
        },
      },
    };
  }, []);

  const renderRowActions = ({ row, table }) => (
    <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "0.75rem" }}>
      {options?.enableEditing && (
        <Tooltip arrow placement="left" title="Editar" disableInteractive>
          <IconButton
            onClick={() => {
              setTimeout(() => setRowSelection({}), 1); // Used to deselect the row when clicking to edit
              if (options?.editFunc) {
                options.editFunc(JSON.parse(JSON.stringify(row.original)));
              } else {
                table.setEditingRow(row);
              }
            }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {options?.enableDeleting && (
        <Tooltip arrow placement="bottom" title="Eliminar" disableInteractive>
          <IconButton
            color="error"
            sx={{
              "&:hover": { backgroundColor: "error" },
              opacity: 0.8,
            }}
            onClick={() => {
              setTimeout(() => setRowSelection({}), 1); // Used to deselect the row when clicking to edit
              handleDeleteRow(row, table, () => table.setEditingRow(null));
            }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const renderCellActionMenuItems = useCallback(
    ({ closeMenu, cell, row, table }) =>
      options.contextMenuOptions?.map((option, idx) => {
        return (
          <MRT_ActionMenuItem
            key={option.id}
            onClick={() => {
              //if any row is selected returns the original row object (array of objects)
              const selectedRows = table
                .getSelectedRowModel()
                .rows.map((row) => row.original);
              //if no row is selected return the row from contextMenu
              if (selectedRows.length === 0) {
                selectedRows.push(row.original);
              }
              option.onClick(selectedRows, table);
              closeMenu();
            }}
            icon={option.icon}
            label={option.title}
            table={table}
          />
        );
      }),
    [options.contextMenuOptions],
  );

  // Refetch Logic
  // Used when the table needs to be refetched, for example, when a row is deleted with a confirmation modal.
  // As there is no way to know when the modal is closed, the refetch is done by the parent component.
  const refetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    await fetchData(); // might need a timeout
  };
  useEffect(() => {
    if (shouldRefetch) setTimeout(() => refetchData(), 0);
  }, [shouldRefetch]);

  // Save management (for example, when a row is added or edited)
  const handleSaveRowEdits = ({ row, table, values }) => {
    const exitEditingMode = () => {
      table.setCreatingRow(null);
      table.setEditingRow(null);
    }; //exit creating/editing mode

    // Call specific save function
    if (options.saveFunc) {
      // Make a copy of the row and delete 'creation' and 'lastUpdate' fields
      let rowCopy = { ...row.original };
      delete rowCopy.creation;
      delete rowCopy.lastUpdate;
      // Update each value in the row
      for (const [key, value] of Object.entries(values)) {
        rowCopy[key] = value;
      }

      return options.saveFunc(rowCopy, exitEditingMode).then((saveOk) => {
        if (saveOk) {
          setIsLoading(true);
          setIsRefetching(true);
          exitEditingMode();
          fetchData();
        }
      });
    }

    exitEditingMode(); //required to exit editing mode
  };
  // Default add row function (when no custom add function is provided)
  const handleDefaultAddRow = async (row, table) => {
    let newRow = table.options.columns
      .filter((col) => col.accessorKey)
      //get an object with the accessorKey as the key and null as value or false for checkboxes
      .reduce(
        (obj, col) => ({
          ...obj,
          [col.accessorKey]: col.editType === "checkbox" ? false : null,
        }),
        {},
      );

    table.setCreatingRow(createRow(table, newRow));
  };

  // Delete row function (as normally deleting implies a confirmation modal, the deletion is done by the parent component, calling the refetchData function)
  // If a custom delete function which returns true is provided, it is called here.
  const handleDeleteRow = async (row, table, exitEditingMode) => {
    if (options.deleteFunc) {
      let saveOk = await options.deleteFunc(row.original, exitEditingMode);
      if (saveOk) {
        setIsLoading(true);
        setIsRefetching(true);
        exitEditingMode();
        await fetchData();
      }
    } else {
      // This would only delete the row from the table, it would not set any new data to backend
      // Delete row from data based on row.index
      let newData = table.options.data.filter(
        (item, index) => index !== row.index,
      );
      setData(newData);
    }
  };

  // -- Data fetching and refetching -- //
  const fetchData = async (initialState = {}) => {
    let usedSorting = initialState?.sorting || sorting;

    setIsError(false);
    try {
      setIsLoading(true);
      // This is the function that gets the data from the backend. Must return an object like {result: array, total: int}
      const { result, total } = await getData(
        pagination.pageIndex + 1, //usedPagination.pageIndex + 1, // pageNumber
        pagination.pageSize, //usedPagination.pageSize, // pageSize
        globalFilter, //usedGlobalFilter, // filter
        usedSorting, // sorting
        false, // exporting
      );
      setData(result);
      setRowCount(total);
      setRowSelection({});
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // Refetch when pagination, sorting or filters change
  useEffect(() => {
    if (firstRender) return;
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  // If a filter is applied, the table goes to the first page
  useEffect(() => {
    if (firstRender) return;

    async function refetchData() {
      if (pagination.pageIndex !== 0) {
        // This triggers the fetch data effect that depends on pagination.pageIndex
        setPagination((old) => ({ ...old, pageIndex: 0 }));
      } else {
        await fetchData();
      }
    }
    refetchData();
  }, [globalFilter]);

  // -- Remove 'white-space: normal' and replace with 'nowrap' for headers -- //
  // This makes the headers not wrap when the text is too long and causing the column to be too tall
  // Also, given headers wrap and have ellipsis, set its title so the tooltip shows the header text
  let headCellElems = Array.from(
    document.getElementsByClassName("Mui-TableHeadCell-Content-Wrapper"),
  );
  headCellElems.forEach((elem) => {
    elem.style.whiteSpace = "nowrap";
    elem.title = elem.textContent || elem.innerText || elem.outerText || "";
  });
  // --  -- //

  // -- COLUMNS/HEADER -- //
  const intl = useIntl();
  const getIntlHeader = useCallback(() => {
    if (!options.intlHeaderId) return options.header;

    const headersFromIntlJson = intl.formatMessage({
      id: options.intlHeaderId,
    });
    const parsedHeader = options.header.filter((item, index) => {
      if (!item.intlId) return item;
      const format = { id: item.intlId, defaultMessage: item.header };
      if (headersFromIntlJson.indexOf(item.intlId) !== -1) {
        item.header = intl.formatMessage(format);
        return item;
      }
    });
    return parsedHeader;
  }, [options.header, options.intlHeaderId, intl]);

  const columns = useMemo(
    () =>
      getIntlHeader().map((headerColumn) => {
        return {
          ...headerColumn,
          muiTableBodyCellProps: {
            sx: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "13px",
              ...(headerColumn.align ? { textAlign: headerColumn.align } : {}),
            },
          },
          muiTableHeadCellProps: {
            ...(headerColumn.alignHeader ? { align: "center" } : {}),
          },
          ...getEditComponent(
            headerColumn.editType,
            headerColumn.editOptions,
            headerColumn.editOptionsLabel,
            headerColumn.editOptionsLoadCallback,
            headerColumn.Edit,
          ),
          ...getCellComponent(
            headerColumn.cellType,
            headerColumn.Cell,
            headerColumn.disableTooltip,
            headerColumn.excelExportFormat ?? null,
          ),
        };
      }),
    [options.header],
  );
  // --  -- //

  ///////////////////////////////////////////////////////////////////////////
  const mrtTable = useMaterialReactTable({
    // Basic data props
    columns,
    data,
    rowCount,
    localization: MRT_Localization_ES,
    layoutMode: "grid",
    // -- State props -- //
    enableRowSelection:
      (options?.enableRowSelection && options?.enableEditing) ?? false,
    enableMultiRowSelection: false,
    enableColumnOrdering: options?.enableColumnOrdering ?? true,
    enableColumnResizing: options?.enableColumnResizing ?? true,
    columnResizeMode: "onChange",
    enableEditing: (options?.enableEditing || options?.enableDeleting) ?? false,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enablePagination: options?.enablePagination ?? true,
    enableCellActions: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Ocurrió un error al cargar los datos",
          sx: { marginTop: "1px" },
        }
      : undefined,
    // -- State handlers -- //
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onColumnSizingChange: setColumnSizing,
    onDensityChange: setDensity,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    // Actions
    onEditingRowCancel: () => {},
    onEditingRowSave: handleSaveRowEdits,
    onCreatingRowSave: handleSaveRowEdits,
    onCreatingRowCancel: () => {},
    // -- State -- //
    state: {
      columnVisibility, // saves state
      density, // saves state TODO: remove
      globalFilter, // saves state
      sorting, // saves state
      columnSizing, // saves state
      columnOrder, // saves state
      pagination,
      rowSelection,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
    // -- UX props -- //
    // Table row event listeners for row clicks
    muiTableBodyRowProps: muiTableBodyProps,
    // Table title to the top-left of the top toolbar. Can be a string or JSX element
    renderTopToolbarCustomActions: renderTopToolbarCustomActions,
    // Linear progress only top toolbar
    muiLinearProgressProps: ({ isTopToolbar }) => ({
      sx: { display: isTopToolbar ? "block" : "none" },
    }),
    // Actions column: allows editing, deleting, etc.
    renderRowActions: renderRowActions,
    // Custom actions to the top-right of the top toolbar (Action buttons)
    renderToolbarInternalActions: ({ table }) =>
      getToolbarInternalActions({ table, toolbarButtons }),
    // Center search text field for global filter
    muiSearchTextFieldProps: {
      sx: { height: "40px", mr: 1, placeContent: "center" },
      size: "small",
    },
    // Enable row numbers (used in ManagedItemsList)
    enableRowNumbers: options?.enableRowNumbers ?? false,
    // -- UI props -- //
    // Loading overlay
    muiCircularProgressProps: {
      Component: <RingLoader color="#5383ff" />,
    },
    // Sticky header
    enableStickyHeader: options?.enableStickyHeader ?? true,
    // Custom table Height
    muiTableContainerProps: muiTableContainerProps,

    // Adds borderRadius to the table (via theme doesn't work) & makes toolbars transparent to match the table
    muiTablePaperProps: {
      sx: {
        borderRadius: "0.5rem",
        maxWidth: "inherit",
        height: options.fullHeight ? tableHeight + 133 : "100%",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        background: "transparent",
        borderRadius: "0 0 0.5rem 0.5rem",
      },
    },
    muiPaginationProps: { sx: { mt: "0.25rem" } },
    muiTopToolbarProps: { sx: { background: "transparent" } },
    // Toolbar alert banner bottom (selected rows and error messages)
    positionToolbarAlertBanner: "bottom",
    // Actions column width
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 96,
        minSize: 96,
        maxSize: 96,
      },
    },
    // Header text no wrap
    muiTableHeadProps: { sx: { whiteSpace: "nowrap" } },

    renderCellActionMenuItems: renderCellActionMenuItems,
  });

  // -- UX -- //
  // Used to reset the row selection when the modal is opened, because clicking the button does not mean select a row
  useEffect(() => {
    if (tenderInfoDetailModal.open) mrtTable.resetRowSelection();
  }, [tenderInfoDetailModal.open]);

  // -- Export to excel -- //
  const exportExcel = useCallback(async () => {
    setSnackbar({
      open: true,
      severity: "info",
      title: "Generando resultados...",
      subTitle: "En unos momentos se descargará el reporte.",
    });

    let data;
    if (options?.toolbarButtons.ExportFunc) {
      data = await options.toolbarButtons.ExportFunc(mrtTable.options.data);
    } else {
      const { result, total } = await getData(
        1, // pageNumber
        99999, // pageSize
        "", // filter
        [], // sorting
        true, // exporting
      );
      data = result;
    }
    if (!data) return;

    let columns = mrtTable.options.columns;
    // Check if any column is currently hidden in columnVisibility. If so, remove it from the columns array
    let columnsCopy = [
      ...columns.filter(
        (col) =>
          persistentStateRef.current.columnVisibility[col.accessorKey] !==
          false,
      ),
    ];
    // Remove columns without accessorKey or with hideToExport
    columnsCopy = [
      ...columnsCopy.filter((col) => col.accessorKey && !col.hideToExport),
    ];
    // flatten with json parse the "data" field in result
    // and add the keys of 'data' to the table columns
    let resultParsed = data.map((item) => {
      if (item.data) {
        let dataParsed = JSON.parse(item.data);
        // add its keys to HeaderTable if they are not already there
        Object.keys(dataParsed).forEach((key) => {
          if (!columnsCopy.find((x) => x.accessorKey === key)) {
            columnsCopy.push({ accessorKey: key, header: key, width: 150 });
          }
        });
        return { ...item, ...dataParsed };
      } else {
        return { ...item };
      }
    });

    // Apply the Cell function respectively to each result
    resultParsed = resultParsed.map((item) => {
      let newItem = { ...item };
      columnsCopy.forEach((col) => {
        if (col.Cell) {
          let cellFunction =
            col.excelExportFormat || col.getDefaultCellComponent || col.Cell;
          let finalCell = cellFunction({
            cell: {
              getValue: () => {
                // If an accessorKey has nested properties, get the value from the nested object
                if (col.accessorKey.includes(".")) {
                  let nestedKeys = col.accessorKey.split(".");
                  let nestedValue = item;
                  nestedKeys.forEach((key) => {
                    nestedValue = nestedValue[key];
                  });
                  return nestedValue;
                }
                return item[col.accessorKey];
              },
              row: { original: item },
            },
            column: { ...col },
          });

          // Only add the value if it is a string or number
          if (typeof finalCell === "string" || typeof finalCell === "number") {
            newItem[col.accessorKey] = finalCell;
          }
          //if it is a react component, get the text if exists
          else if (
            typeof finalCell === "object" &&
            finalCell?.props &&
            typeof finalCell.props.children === "string"
          ) {
            newItem[col.accessorKey] = finalCell.props.children;
          } else if (!finalCell) {
            newItem[col.accessorKey] = "";
          }
        }
      });
      return newItem;
    });

    //TODO: center the header and some fields
    await saveNewExcel(
      resultParsed,
      columnsCopy,
      options?.toolbarButtons.ExportFilename ||
        (options?.tableHeaderTitle &&
        typeof options.tableHeaderTitle === "string"
          ? options.tableHeaderTitle
          : "Exportación"),
      (options.tableHeaderTitle &&
        typeof options.tableHeaderTitle === "string" &&
        options.tableHeaderTitle) ||
        null,
    );
    resetSnackbar();
    hideLoadingSpinner();
  }, [options]);

  return <MaterialReactTable table={mrtTable} />;
}

FSTMaterialReactTable.propTypes = {
  getData: PropTypes.func.isRequired, // Receives pageNumber, pageSize, filter, sorting, exporting and returns data like {result: array, total: int}
  options: PropTypes.shape({
    header: PropTypes.arrayOf(
      PropTypes.shape({
        accessorKey: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        align: PropTypes.oneOf(["left", "center", "right"]),
        alignHeader: PropTypes.oneOf(["left", "center", "right"]),
        size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // width of the column
        disableTooltip: PropTypes.bool, // If true, it will not show the tooltip.
        hideToExport: PropTypes.bool, // If true, the column will not be exported to excel.
        defaultHidden: PropTypes.bool, // If true, the column will be hidden by default.
        excelExportFormat: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.func,
        ]), // If defined, it will use this format for the column in excel. Else, it will use the default format. Eg: "number", "date", etc
        excelFormat: PropTypes.string, // If defined, it will use this format for the column in excel. Else, it will use the default format. Eg: "number", "date", etc
        cellType: PropTypes.oneOf([
          // If not defined: text. Else: checkbox, date, datetime, active-inactive
          "text",
          "checkbox",
          "date",
          "datetime",
          "active-inactive",
        ]),
        Cell: PropTypes.func, // Use this or cellType. If this is defined, it will use this function to render the cell.
        editType: PropTypes.oneOf([
          "text",
          "checkbox",
          "select",
          "multiselect",
          "date",
          "datetime",
          "active-inactive",
          "autocomplete",
          "numeric",
          "autocomplete-paging",
          "colorpicker",
        ]),
        Edit: PropTypes.func, // Use this or editType. If this is defined, it will use this function to render the edit cell.
        editOptions: PropTypes.array, // If editType is select or multiselect, this must be defined.
        editOptionsLabel: PropTypes.func, // If editType is select or multiselect, this must be defined.
        editOptionsLoadCallback: PropTypes.func, // If editType is autocomplete-paging, this must be defined.
        // ...other MaterialReactTableColumnProps
      }),
    ).isRequired,
    intlHeaderId: PropTypes.string, // If this is defined, it will use the intlHeaderId to get the header from the intl JSON file.
    toolbarButtons: PropTypes.shape({
      // All funcs receive the selected rows as parameter or the single row (not array) if only one is selected. If none is selected, it will receive {}. The second parameter is the table obj.
      ExtraButtons: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          icon: PropTypes.object.isRequired,
          onClick: PropTypes.func.isRequired,
          key: PropTypes.string.isRequired,
        }),
      ),
      Refresh: PropTypes.bool, // Refetches data
      Send: PropTypes.bool,
      SendFunc: PropTypes.func,
      Export: PropTypes.bool, // Exports the table data to an Excel file.
      ExportFunc: PropTypes.func,
      ExportFilename: PropTypes.string, // If defined, it will use this as the filename of the exported file. Else, it will use the tableHeaderTitle if it is a string. Otherwise, "Exportación".
      View: PropTypes.bool,
      ViewFunc: PropTypes.func,
      Add: PropTypes.bool,
      AddFunc: PropTypes.func,
      Edit: PropTypes.bool,
      EditFunc: PropTypes.func,
      Delete: PropTypes.bool,
      DeleteFunc: PropTypes.func,
    }),
    tableHeaderTitle: PropTypes.oneOfType([
      //string || ReactComponent,
      PropTypes.string,
      PropTypes.elementType,
      PropTypes.element,
      PropTypes.node,
    ]),
    tableId: PropTypes.string,
    fullHeight: PropTypes.bool,

    enableRowSelection: PropTypes.bool,
    enableMultiRowSelection: PropTypes.bool,
    enableEditing: PropTypes.bool,
    enableColumnOrdering: PropTypes.bool,
    enableColumnResizing: PropTypes.bool,
    enableDeleting: PropTypes.bool,
    enableRowNumbers: PropTypes.bool, // Default: false
    enableStickyHeader: PropTypes.bool, // Default: true
    enablePagination: PropTypes.bool, // Default: true
    rowDoubleClickFunc: PropTypes.func, // Receives the row data as parameter.
    rowSelectionFunc: PropTypes.func,
    rowBackground: PropTypes.func, // Receives the row data as parameter. It must return a color string.
    saveFunc: PropTypes.func, // Used when saving row edits/adds. It must return true if the save was successful, or false if it failed.
    editFunc: PropTypes.func,
    deleteFunc: PropTypes.func,
    contextMenu: PropTypes.bool,
    // context menu options is func that receives the selected rows (array) and the table obj.
    contextMenuOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.object.isRequired,
      }),
    ),
  }),
};

export default FSTMaterialReactTable;
