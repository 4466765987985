import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useState } from "react";
import { BarLoader } from "react-spinners";
import { updateUserClientSettings } from "utils/clientSettings";
import useManageStatusGroups from "utils/hooks/useManageStatusGroups";

const EditViewModal = ({ open, onClose, onSave }) => {
  const { isLoading, data: statusGroups } = useManageStatusGroups();

  const [loading, setLoading] = useState(false);
  const {
    setSnackbarSaveChanges,
    viewsState,
    setManagePagingState,
    setShouldRefetch,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    viewsState: state.viewsState,
    setManagePagingState: state.setManagePagingState,
    setShouldRefetch: state.setShouldRefetch,
  }));
  const { manage: managePagingState } = viewsState;
  const { currentStatusGroup } = managePagingState;
  const [selectedStatus, setSelectedStatus] = useState(currentStatusGroup);

  const handleChange = (e) => {
    setSelectedStatus(+e.target.value);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = async () => {
    setLoading(true);
    setManagePagingState({
      ...managePagingState,
      currentStatusGroup: selectedStatus,
    });
    setTimeout(() => setShouldRefetch(), 0);
    localStorage.setItem("manageDefaultTab", selectedStatus); // TODO: make a function to get and set
    updateUserClientSettings();
    onSave();
    setSnackbarSaveChanges();
    setLoading(false);
  };

  return (
    <GeneralPurposeModal
      open={open}
      onClose={handleClose}
      title="Seleccione la vista a mostrar por defecto"
      closeText={"Cancelar"}
      maxWidth="xs"
      onSave={handleSave}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="position"
          name="position"
          value={selectedStatus}
          onChange={handleChange}>
          {statusGroups?.map((item, idx) => (
            <FormControlLabel
              value={item.statusId}
              control={<Radio color="primary" />}
              label={item.statusDesc}
              labelPlacement="end"
              key={idx}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {(loading || isLoading) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}>
          <BarLoader color={"var(--primary)"} loading={true} />
        </Box>
      )}
    </GeneralPurposeModal>
  );
};

export default EditViewModal;
