import { Card, Grid, Typography } from "@mui/material";

export default function HomeTitle({ title, TitleIcon }) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className="card-box border-0 card-shadow-success p-3 mb-4">
          <div className="d-flex align-items-center">
            <TitleIcon fontSize="medium" sx={{ mr: 2 }} />
            <Typography variant="h4">{title}</Typography>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
