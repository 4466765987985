// Sidebar

export const SET_SIDEBAR_SHADOW = "THEME_OPTIONS/SET_SIDEBAR_SHADOW";
export const SET_SIDEBAR_TOGGLE_MOBILE =
  "THEME_OPTIONS/SET_SIDEBAR_TOGGLE_MOBILE";
export const SET_SIDEBAR_FIXED = "THEME_OPTIONS/SET_SIDEBAR_FIXED";
export const SET_SIDEBAR_FOOTER = "THEME_OPTIONS/SET_SIDEBAR_FOOTER";
export const SET_SIDEBAR_TOGGLE = "THEME_OPTIONS/SET_SIDEBAR_TOGGLE";
export const SET_SIDEBAR_USERBOX = "THEME_OPTIONS/SET_SIDEBAR_USERBOX";
export const SET_SIDEBAR_HOVER = "THEME_OPTIONS/SET_SIDEBAR_HOVER";

export const setSidebarShadow = (sidebarShadow) => ({
  type: SET_SIDEBAR_SHADOW,
  sidebarShadow,
});
export const setSidebarFixed = (sidebarFixed) => ({
  type: SET_SIDEBAR_FIXED,
  sidebarFixed,
});
export const setSidebarToggleMobile = (sidebarToggleMobile) => ({
  type: SET_SIDEBAR_TOGGLE_MOBILE,
  sidebarToggleMobile,
});
export const setSidebarFooter = (sidebarFooter) => ({
  type: SET_SIDEBAR_FOOTER,
  sidebarFooter,
});
export const setSidebarToggle = (sidebarToggle) => ({
  type: SET_SIDEBAR_TOGGLE,
  sidebarToggle,
});
export const setSidebarHover = (sidebarHover) => ({
  type: SET_SIDEBAR_HOVER,
  sidebarHover,
});
export const setSidebarUserbox = (sidebarUserbox) => ({
  type: SET_SIDEBAR_USERBOX,
  sidebarUserbox,
});

// Main content

export const SET_CONTENT_BACKGROUND = "THEME_OPTIONS/SET_CONTENT_BACKGROUND";
export const SET_THEME_CONFIGURATOR_TOGGLE =
  "THEME_OPTIONS/SET_THEME_CONFIGURATOR_TOGGLE";

export const setContentBackground = (contentBackground) => ({
  type: SET_CONTENT_BACKGROUND,
  contentBackground,
});
export const setThemeConfiguratorToggle = (themeConfiguratorToggle) => ({
  type: SET_THEME_CONFIGURATOR_TOGGLE,
  themeConfiguratorToggle,
});
// Footer

export const SET_FOOTER_FIXED = "THEME_OPTIONS/SET_FOOTER_FIXED";
export const SET_FOOTER_SHADOW = "THEME_OPTIONS/SET_FOOTER_SHADOW";
export const setFooterFixed = (footerFixed) => ({
  type: SET_FOOTER_FIXED,
  footerFixed,
});
export const setFooterShadow = (footerShadow) => ({
  type: SET_FOOTER_SHADOW,
  footerShadow,
});

export default function reducer(
  state = {
    // Sidebar

    sidebarShadow: false,
    sidebarFixed: true,
    sidebarToggleMobile: false,
    sidebarFooter: true,
    sidebarUserbox: true,
    sidebarToggle: false,
    sidebarHover: false,

    // Main content

    contentBackground: "",
    themeConfiguratorToggle: false,
    // Footer

    footerShadow: false,
  },
  action,
) {
  switch (action.type) {
    // Sidebar

    case SET_SIDEBAR_SHADOW:
      return {
        ...state,
        sidebarShadow: action.sidebarShadow,
      };
    case SET_SIDEBAR_FIXED:
      return {
        ...state,
        sidebarFixed: action.sidebarFixed,
      };
    case SET_SIDEBAR_TOGGLE_MOBILE:
      return {
        ...state,
        sidebarToggleMobile: action.sidebarToggleMobile,
      };
    case SET_SIDEBAR_FOOTER:
      return {
        ...state,
        sidebarFooter: action.sidebarFooter,
      };
    case SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.sidebarToggle,
      };
    case SET_SIDEBAR_HOVER:
      return {
        ...state,
        sidebarHover: action.sidebarHover,
      };
    case SET_SIDEBAR_USERBOX:
      return {
        ...state,
        sidebarUserbox: action.sidebarUserbox,
      };

    // Main content

    case SET_CONTENT_BACKGROUND:
      return {
        ...state,
        contentBackground: action.contentBackground,
      };
    case SET_THEME_CONFIGURATOR_TOGGLE:
      return {
        ...state,
        themeConfiguratorToggle: action.themeConfiguratorToggle,
      };
    // Footer

    case SET_FOOTER_SHADOW:
      return {
        ...state,
        footerShadow: action.footerShadow,
      };

    default:
      break;
  }
  return state;
}
