import { ArrowBack, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import {
  listCategories,
  listSellers,
  listTags,
} from "pages/Administration/administrationServices";
import { getSources, getTypes } from "pages/Analysis/analysisService";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isLangAR, isLangCL, isLangCR, isLangMX, isLangPE } from "utils/common";
import OutsiderTendersExcelInsert from "./OutsiderTendersExcelInsert";
import OutsiderTendersManualInsert from "./OutsiderTendersManualInsert";
import { getScrapingStatus } from "./outsiderTendersService";

const OutsiderTenders = (props) => {
  const history = useHistory();
  const { height, hideLoadingSpinner, showLoadingStateSpinnerNoTimeout } =
    useGlobalStore((state) => ({
      height: state.height,
      hideLoadingSpinner: state.hideLoadingSpinner,
      showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    }));

  const manageModes = {
    NONE: 0,
    MANUAL: 1,
    FILE: 2,
  };
  const [manageMode, setManageMode] = useState(manageModes.NONE);

  const [sources, setSources] = useState([]);
  const [tenderTypes, setTenderTypes] = useState([]);
  const [tendersStatus, setTendersStatus] = useState([]);

  const [sellers, setSellers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const fetchInitialData = async () => {
    showLoadingStateSpinnerNoTimeout();

    const [
      sourcesResponse,
      tenderTypesResponse,
      tendersStatusResponse,
      sellersResponse,
      categoriesResponse,
      tagsResponse,
    ] = await Promise.all([
      getSources(),
      getTypes(),
      getScrapingStatus(),
      listSellers(),
      listCategories(),
      listTags(),
    ]);

    setSources(sourcesResponse.data);
    let tenderTypesMapped = tenderTypesResponse.data.map((item) => {
      return {
        id: item.entityTypeId,
        name: item.name,
      };
    });
    setTenderTypes(tenderTypesMapped);
    let tendersStatusMapped = tendersStatusResponse.data.map((item, idx) => {
      return {
        id: idx + 1,
        name: item,
      };
    });
    setTendersStatus(tendersStatusMapped);
    setSellers(sellersResponse.data);
    setCategories(categoriesResponse.data);
    setTags(tagsResponse.data);

    hideLoadingSpinner();
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const getSourceId = () => {
    if (isLangCR()) {
      return sources.find((source) => source.name === "ExtCR").id;
    }
    if (isLangPE()) {
      return sources.find((source) => source.name === "ExtPeru").id;
    }
    if (isLangAR()) {
      return sources.find((source) => source.name === "ExtArg").id;
    }
    if (isLangMX()) {
      return sources.find((source) => source.name === "ExtMX").id;
    }
    if (isLangCL()) {
      return sources.find((source) => source.name === "Convenio Marco CL").id;
    }
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Agregar licitaciones externas"
        icon={<Info />}
        startComponent={
          <>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Tooltip title="Volver">
              <IconButton
                color="secondary"
                size="small"
                onClick={history.goBack}
                sx={{ ml: 1 }}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          </>
        }
      />

      <Card
        style={{
          padding: "1rem",
          // height: `calc(${height}px - 7rem)`,
          height: "100%",
        }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.7rem",
            height: "2rem",
          }}>
          {manageMode !== manageModes.NONE && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setManageMode(manageModes.NONE)}>
              <ArrowBack />
            </Button>
          )}

          <Typography
            variant="h6"
            style={{
              marginLeft: manageMode === manageModes.NONE ? "0" : "1rem",
              fontSize: 16,
            }}>
            {manageMode === manageModes.NONE
              ? "Seleccione el modo de ingreso de licitaciones"
              : manageMode === manageModes.MANUAL
              ? "Ingrese manualmente las licitaciones"
              : "Importe las licitaciones desde un archivo"}
          </Typography>
        </div>
        {manageMode === manageModes.NONE && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              paddingLeft: "1rem",
              gap: "1rem",
            }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "20%",
              }}
              onClick={() => setManageMode(manageModes.MANUAL)}>
              Ingresar manualmente
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{
                width: "20%",
              }}
              onClick={() => setManageMode(manageModes.FILE)}>
              Importar desde archivo
            </Button>
          </div>
        )}
        {manageMode === manageModes.FILE && (
          <Box
            sx={{
              mt: 1,
              height: "calc(100% - 2rem)",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                WebkitAppearance: "none",
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f5f5f5",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: "#a9a9cfc2",
                WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
              },
            }}>
            <OutsiderTendersExcelInsert
              sources={sources}
              tenderTypes={tenderTypes}
              tendersStatus={tendersStatus}
              sellers={sellers}
              categories={categories}
              tags={tags}
              getSourceId={getSourceId}
            />
          </Box>
        )}
        {manageMode === manageModes.MANUAL && (
          <Box
            sx={{
              mt: 1,
              height: "calc(100% - 2rem)",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                WebkitAppearance: "none",
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f5f5f5",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: "#a9a9cfc2",
                WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
              },
            }}>
            <OutsiderTendersManualInsert
              height={height}
              tenderTypes={tenderTypes}
              tendersStatus={tendersStatus}
              sellers={sellers.map((s) => ({
                ...s,
                id: s.sellerId,
                name: `${s.firstName} ${s.lastName}`,
              }))}
              categories={categories.map((c) => ({
                ...c,
                id: c.categoryId,
                name: c.name,
              }))}
              tags={tags.map((t) => ({
                ...t,
                id: t.tagId,
                name: t.name,
              }))}
              getSourceId={getSourceId}
            />
          </Box>
        )}
      </Card>
    </Fragment>
  );
};

export default OutsiderTenders;
