import { CalendarToday } from "@mui/icons-material";
import { Card } from "@mui/material";
import { L10n, loadCldr } from "@syncfusion/ej2-base";
import {
  Day,
  DragAndDrop,
  ExcelExport,
  Inject,
  Month,
  Resize,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import { useGlobalStore } from "global-store/useGlobalStore";
import { LangContext } from "intl/context/langContext";
import { PageTitle } from "layout-components";
import moment from "moment";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { AddHourToDate, formatDate } from "utils/DateHelper";
import { formatShortDate } from "utils/common";
import manage from "services/manage";

const numberingSystems = require("cldr-data/supplemental/numberingSystems.json");
const gregorian = require("cldr-data/main/es/ca-gregorian.json");
const numbers = require("cldr-data/main/es/numbers.json");
const timeZoneNames = require("cldr-data/main/es/timeZoneNames.json");

const itemDetailsValues = [
  {
    id: "tabL.Descripcion",
    value: "ItemDescription",
    format: (item) => (item ? item : "-"),
  },
  {
    id: "tabL.Codigo_Cubso",
    value: "ItemCode",
    format: (item) => (item ? item : "-"),
  },
  {
    id: "tabL.FechaEntrega",
    value: "ItemDeliveryDate",
    format: (item) => (item ? formatShortDate(item) : "-"),
  },
  {
    id: "tabL.Cantidad",
    value: "ItemQuantity",
    format: (item) => (item ? item : "-"),
  },
  {
    id: "tabL.Unidad",
    value: "ItemUOM",
    format: (item) => (item ? item : "-"),
  },
];

const TenderManagementCalendar = () => {
  let scheduleObj;
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());

  const history = useHistory();
  const intl = useIntl();
  const langChange = useContext(LangContext);
  const langChangeRef = useRef(langChange);
  langChangeRef.current = langChange;

  const { height } = useGlobalStore((state) => ({
    height: state.height,
  }));

  const getData = (start, end, pageSize) => {
    const request = {
      OrderBy: "tenderNumber",
      PageSize: pageSize,
      Filter: "",
      PageNumber: 1,
      OrderAsc: false,
      StatusId: 0,
      StartDate: start,
      EndDate: end,
    };
    return manage.getManage(request);
  };

  const fetchData = async (date, pageSize) => {
    const start = date
      .clone()
      .add(-1, "M")
      .startOf("month")
      .toDate()
      .toISOString();
    const end = date.clone().add(1, "M").endOf("month").toDate().toISOString();

    const res = await getData(start, end, pageSize);
    const wrapper = res.data.currentPageItems.map((item) => {
      const parser = {
        ...item,
        Id: item.tenderId,
        Subject: item.fileNumber,
        StartTime: AddHourToDate(item.offersReceptionEnd, -1),
        EndTime: AddHourToDate(item.offersReceptionEnd, 0),
        CategoryColor: item.manageInsternalStatusColor
          ? item.manageInsternalStatusColor
          : "#3f51b5",
      };
      return parser;
    });

    //Flatten manageDetails field and add it to the wrapper if deliveryDate is not null, just like in the previous lines
    let wrapperWithManageDetails = [];
    wrapper.forEach((item) => {
      if (item.manageDetails?.length > 0) {
        item.manageDetails.forEach((detail) => {
          if (detail.deliveryDate) {
            const parser = {
              ...detail,
              ...item,
              Id: detail.tenderDetailId,
              Subject: `${item.fileNumber} - ${detail.code}`,
              ItemDescription: detail.description,
              ItemQuantity: detail.qty,
              ItemUOM: detail.uom,
              ItemCode: detail.code,
              ItemDeliveryDate: detail.deliveryDate,
              StartTime: AddHourToDate(detail.deliveryDate, -1),
              EndTime: AddHourToDate(detail.deliveryDate, 0),
              CategoryColor: "#f7b74f", // Arbitrary color: Light orange
            };
            wrapperWithManageDetails.push(parser);
          }
        });
      }
    });

    setData([...wrapper, ...wrapperWithManageDetails]);
  };

  useEffect(() => {
    fetchData(selectedDate, 1000);
  }, []);

  const applyCategoryColor = (args, currentView) => {
    let categoryColor = args.data.CategoryColor;
    if (!args.element || !categoryColor) {
      return;
    }
    if (currentView === "Agenda") {
      args.element.firstChild.style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }
  };

  const onExportClick = async () => {
    const { formatMessage } = intl;
    if (!scheduleObj) return;
    const total = scheduleObj.activeView.renderDates.length - 1;
    const start = moment(scheduleObj.activeView.renderDates[0])
      .toDate()
      .toISOString();
    const end = moment(scheduleObj.activeView.renderDates[total])
      .toDate()
      .toISOString();
    const rest = await getData(start, end, 10000);
    const exportValuesFields =
      langChangeRef.current.configForm["ManagementCalendarExportValues"]
        .elements; // [ {id, value, format} ]
    // wrapper (customData) must be  [{id: value}]
    // fields must be [id, id, id, ...]
    const wrapper = rest.data.currentPageItems.map((item) => {
      const parser = {};
      exportValuesFields.forEach((field) => {
        const name = formatMessage({ id: field.id, defaultMessage: "N/A" });
        if (field.format) {
          parser[name] = field.format(item[field.value]);
        } else {
          parser[name] = item[field.value];
        }
      });
      return parser;
    });

    let exportValues = {
      fields: exportValuesFields.map((field) =>
        formatMessage({ id: field.id, defaultMessage: "N/A" }),
      ),
      customData: wrapper,
      fileName: "Calendario de Gestión",
    };

    scheduleObj.exportToExcel(exportValues);
  };

  const onPopupOpen = (args) => {
    // Remove the delete behaviour. TODO: remove delete icon
    if (args.type === "DeleteAlert") {
      args.cancel = true;
    }
    if (args.type === "Editor") {
      args.cancel = true;
      history.push(
        "/gestion-informacion/" + encodeURIComponent(args.data.tenderNumber),
      );
    }

    // Given there's no direct way to remove the delete icon, search its classname
    // Then remove the one with title 'Borrar'. Also change the title of the edit icon (as it's not 'edit')
    const icons = document.getElementsByClassName("e-header-icon-wrapper");
    Array.from(icons.length > 0 ? icons[0].children : []).forEach((child) => {
      if (child.title === "Borrar") child.style.display = "none";
      if (child.title === "Editar") child.title = "Ir a la información";
    });
  };

  const onEventRendered = (args) => {
    if (scheduleObj) {
      applyCategoryColor(args, scheduleObj.currentView);
    }
  };

  const onActionBegin = (args) => {
    if (args.requestType === "toolbarItemRendering") {
      let exportItem = {
        align: "Right",
        text: "Exportar",
        cssClass: "e-excel-export",
        click: onExportClick,
      };
      args.items.push(exportItem);
    }
  };

  const onActionComplete = (args) => {
    const currentDate = moment(scheduleObj?.selectedDate);
    if (currentDate.month() === selectedDate.month()) return;
    fetchData(currentDate, 1000);
    setSelectedDate(currentDate);
  };

  const getContent = (props) => {
    const { formatMessage } = intl;
    const valuesFields =
      langChangeRef.current.configForm["ManagementCalendarValues"].elements; // [ {id, value, format} ]

    return (
      <div className="container-root-sheduler">
        {props.elementType === "cell" ? (
          <div className="e-cell-content e-template"></div>
        ) : (
          <div className="e-event-content e-template">
            <div className="e-subject-wrap">
              {valuesFields.map((field) => {
                return (
                  <div key={field.id}>
                    <label>
                      <strong className="labelModal">
                        {formatMessage({ id: field.id, defaultMessage: "N/A" })}
                        :
                      </strong>{" "}
                      <span>
                        {field.format
                          ? field.format(props[field.value])
                          : props[field.value]}
                      </span>
                    </label>
                  </div>
                );
              })}

              {props.tenderDetailId && (
                <>
                  <div>
                    <h5 style={{ marginBottom: 4 }}>
                      <strong className="labelModal">
                        {`Información de ${formatMessage({
                          id: "cols.Item",
                          defaultMessage: "Item",
                        })}`}
                      </strong>
                    </h5>
                  </div>

                  {itemDetailsValues.map((field) => {
                    return (
                      <div key={field.id}>
                        <label>
                          <strong className="labelModal">
                            {formatMessage({
                              id: field.id,
                              defaultMessage: "N/A",
                            })}
                            :
                          </strong>{" "}
                          <span>
                            {field.format
                              ? field.format(props[field.value])
                              : props[field.value]}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
  L10n.load(require("@syncfusion/ej2-locale/src/es.json"));
  return (
    <Fragment>
      <PageTitle titleHeading="Calendario" icon={<CalendarToday />} />
      <Card className="card-box mb-4" sx={{ height: "100%" }}>
        <ScheduleComponent
          width="100%"
          locale="es"
          popupOpen={onPopupOpen}
          height={height + 117}
          style={{ borderRadius: "inherit" }}
          dateFormat={formatDate.es.SHORT}
          ref={(schedule) => {
            if (!schedule) return;
            scheduleObj = schedule;
          }}
          eventSettings={{ dataSource: data }}
          quickInfoTemplates={{
            content: (itemContent) => getContent(itemContent),
          }}
          eventRendered={onEventRendered}
          actionBegin={onActionBegin}
          actionComplete={onActionComplete}
          cellClick={(args) => (args.cancel = true)}
          cellDoubleClick={(args) => (args.cancel = true)}
          currentView="Month">
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Month" />
          </ViewsDirective>

          <Inject
            services={[
              Day,
              Week,
              WorkWeek,
              Month,
              Resize,
              DragAndDrop,
              ExcelExport,
            ]}
          />
        </ScheduleComponent>
      </Card>
    </Fragment>
  );
};

export default TenderManagementCalendar;
