import { get, post } from "config/axios";
import { PURCHASE_ORDER_ANALYSIS_MODULE_ID } from "utils/constants";

export const getAnalysisFilters = async (moduleId) => {
  return await get(
    `/api/analysisFilter/getListIdName?analysisFilterModuleId=${moduleId}`,
  );
};

export const getReportCategories = async (moduleId) => {
  return await get(
    `api/reporting/getReportCategories?analysisFilterModuleId=${moduleId}`,
  );
};

export const downloadReport = async (request) => {
  return await post("/api/reportwriter/export", request);
};

export const getAnalysisFiltersList = async (pagination, moduleId) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.filter,
    PageNumber: pagination.pageNumber,
    OrderAsc: pagination.orderAsc,
    AnalysisFilterModuleId: moduleId,
  };
  return await post("/api/analysisFilter/getList", request);
};

export const addOrUpdateAnalysisFilter = async (request) => {
  return await post("api/analysisFilter/addOrUpdateFilter", request);
};

export const getAnalysisFilterById = async (EntityId) => {
  return await post("/api/analysisFilter/getForm", { EntityId });
};

export const deleteAnalysisFilter = async (EntityId) => {
  return await post("/api/analysisFilter/deleteFilter", { EntityId });
};

export const getDateRanges = async () => {
  return await post("/api/analysisFilter/getDateRanges");
};

export const getDateRangeUnits = async () => {
  return await post("api/analysisFilter/getDateRangeUnits");
};

export const getTypes = async (moduleId) => {
  if (moduleId === PURCHASE_ORDER_ANALYSIS_MODULE_ID) {
    //Obtiene los tipos para OC
    return await get("api/purchaseOrder/getTypes");
  }
  //Obtiene los tipos para Licitaciones
  return await post("api/analysisFilter/getTypes");
};

export const getSources = async () => {
  return await get("api/smartSearchFilter/getSources");
};

export const getTenderDetailDescription = async (include, exclude) => {
  let params = "";
  if (include.length > 0) {
    params = `include=${include}`;
  }

  if (include.length > 0 && exclude.length > 0) {
    params = `${params}&`;
  }

  if (exclude.length > 0) {
    params = params.concat(`exclude=${exclude}`);
  }

  return await get(`/api/analysisFilter/getTenderDetailDescription?${params}`);
};

/// Organisms
export const getOrganisms = async (request, signal = null) => {
  return await post(
    "/api/analysisFilter/getClientUnitsByCompanyId",
    request,
    signal,
  );
};

/// Competitors
export const getCompetitors = async (request, signal = null) => {
  return await post(
    "/api/analysisfilter/getcompetitorsbycompanyid",
    request,
    signal,
  );
};
