import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, IconButton, Tooltip, alpha, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  addOrUpdateSearchFilter,
  getSources,
  listAllSearchFilters,
} from "../smartSearchService";

import { CalendarMonth } from "@mui/icons-material";
import CustomButtonGroup from "components/CustomButtons/CustomButtonGroup";
import moment from "moment";
import { useIntl } from "react-intl";
import { convertTimezoneToTimezone } from "utils/DateHelper";
import { updateUserClientSettings } from "utils/clientSettings";
import { isLangCR } from "utils/common";
import { saveExcel } from "utils/excelExportService";
import ManageSmartSearchModal from "../FiltersAdmin/ManageSmartSearchModal";
import FiltersPanel from "../SmartSearch/FiltersPanel/FiltersPanel";
import { pacHeader } from "./pacHeader";
import {
  downloadExcelPac,
  getFilteredResultPac,
  getFiltersCountPac,
} from "./pacService";
import HeaderLastUpdate from "../SmartSearch/Header/HeaderLastUpdate";
import PACResults from "./PACResults";

const isSummonedOptions = [
  { id: 0, value: "Todas", isSummoned: null },
  { id: 1, value: "Convocadas", isSummoned: true },
  { id: 2, value: "No Convocadas", isSummoned: false },
];
const monthOptions = [
  { id: 0, value: "Todos los meses" },
  { id: 1, value: "Enero" },
  { id: 2, value: "Febrero" },
  { id: 3, value: "Marzo" },
  { id: 4, value: "Abril" },
  { id: 5, value: "Mayo" },
  { id: 6, value: "Junio" },
  { id: 7, value: "Julio" },
  { id: 8, value: "Agosto" },
  { id: 9, value: "Septiembre" },
  { id: 10, value: "Octubre" },
  { id: 11, value: "Noviembre" },
  { id: 12, value: "Diciembre" },
];
const yearOptions = [
  { id: 0, value: "Todos los años", year: 0 },
  { id: 1, value: "Año actual", year: moment().year() },
  { id: 2, value: "Año anterior", year: moment().year() - 1 },
  { id: 3, value: "Año próximo", year: moment().year() + 1 },
];

const PAC = () => {
  const intl = useIntl();
  const [filters, setFilters] = useState([]);
  const filtersRef = useRef(null);
  filtersRef.current = filters;
  const [firstRender, setFirstRender] = useState(true);
  const firstRenderRef = useRef(firstRender);
  firstRenderRef.current = firstRender;
  const [tableSearchFilter, setTableSearchFilter] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(localStorage.getItem("selectedFilters")),
  );

  const {
    setSnackbarSaveChanges,
    setSnackbarInfoCustom,
    showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    setShouldRefetch,
    appData,
    setSnackbar,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    setSnackbarInfoCustom: state.setSnackbarInfoCustom,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setShouldRefetch: state.setShouldRefetch,
    appData: state.appData,
    setSnackbar: state.setSnackbar,
  }));
  const { sources } = appData;

  const [optionsSelected, setOptionsSelected] = useState({
    month: 0,
    year: 0,
    isSummoned: null,
  });
  const optionsSelectedRef = useRef(optionsSelected);
  optionsSelectedRef.current = optionsSelected; // TODO: prevent using ref

  const getLastUpdateTime = () => {
    const portalName = intl.formatMessage({
      id: "portalNamePAC",
      defaultMessage: "PAC",
    });
    const source = sources.find((item) => item.name === portalName);
    if (source) {
      let time = moment(source.lastScrapedDate).format("DD/MM/YYYY HH:mm");
      if (isLangCR()) {
        time = convertTimezoneToTimezone(
          time,
          "DD/MM/YYYY HH:mm",
          "America/Santiago",
          "America/Costa_Rica",
        );
      }
      return `${time}hs.`;
    } else {
      return "--/--/---- --:--hs.";
    }
  };

  const lastUpdateTime = getLastUpdateTime();

  const getFilters = async (alwaysCheckedFilters = []) => {
    const response = await listAllSearchFilters();
    const ids = response.data.currentPageItems.map(
      (item) => item.searchFilterId,
    );
    const counts = await getFiltersCountPac({
      FilterIds: ids,
      Year: optionsSelectedRef.current.year,
      Month: optionsSelectedRef.current.month,
      IsSummoned: optionsSelectedRef.current.isSummoned,
    });
    const auxList = response.data.currentPageItems.map((item) => {
      if (alwaysCheckedFilters.includes(item.searchFilterId)) {
        return {
          ...item,
          checked: true,
          count: counts.data.find((obj) => obj.filterId === item.searchFilterId)
            .count,
        };
      }
      return {
        ...item,
        checked: selectedFilters
          ? selectedFilters.includes(item.searchFilterId)
          : true,
        count: counts.data.find((obj) => obj.filterId === item.searchFilterId)
          .count,
      };
    });
    setFilters(auxList);
    return [...auxList];
  };

  const fetchAllData = useCallback(
    async (pageNumber, pageSize, filter, sorting) => {
      let fetchedFilters = filtersRef.current;
      if (firstRenderRef.current) {
        fetchedFilters = await getFilters();
        setFirstRender(false);
      }
      let orderBy = sorting.length > 0 ? sorting[0].id : null;
      let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;
      const request = {
        ScrapId: 0,
        Name: "",
        Description: "",
        Organism: "",
        Filters: fetchedFilters
          ?.filter((item) => item.checked)
          .map((item) => item.searchFilterId),
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchFilter: filter,
        OrderBy: orderBy,
        OrderAsc: orderAsc,
        IsPaginated: true,

        Year: optionsSelectedRef.current.year,
        Month: optionsSelectedRef.current.month,
        IsSummoned: optionsSelectedRef.current.isSummoned, // "Convocadas", "No convocadas", "Todas"
      };
      setTableSearchFilter(filter);
      const response = await getFilteredResultPac(request);
      return {
        result: response.data.currentPageItems,
        total: response.data.totalItems,
      };
    },
    [optionsSelectedRef, filtersRef, firstRenderRef],
  );

  const handleChangeFilters = (newFilters) => {
    setFilters(newFilters);
    setTimeout(() => setShouldRefetch(), 0);
  };

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
      setShouldRefetch();
    }
  }, [optionsSelected]);

  const handleSaveSelectedFilters = async () => {
    showSavingStateSpinnerNoTimeout();
    setSelectedFilters(
      filters.filter((item) => item.checked).map((item) => item.searchFilterId),
    );
    localStorage.setItem(
      "selectedFilters",
      JSON.stringify(
        filters
          .filter((item) => item.checked)
          .map((item) => item.searchFilterId),
      ),
    );
    await updateUserClientSettings();
    hideLoadingSpinner();
  };

  const handleExport = async () => {
    setSnackbarInfoCustom(
      "En unos momentos se descargará el reporte.",
      "Generando resultados...",
    );
    const request = {
      IsPaginated: false,
      ReportId: 3,
      ScrapId: 0,
      Name: "",
      Description: "",
      Organism: "",
      Filters: filtersRef.current
        .filter((item) => item.checked)
        .map((item) => item.searchFilterId),
      SearchFilter: tableSearchFilter,
      PageNumber: 1,
      PageSize: 99999,
      Year: optionsSelectedRef.current.year,
      Month: optionsSelectedRef.current.month,
      IsSummoned: optionsSelectedRef.current.isSummoned, // "Convocadas", "No convocadas", "Todas"
    };
    const result = await downloadExcelPac(request);
    exportToExcel(result.data);
  };

  const exportToExcel = (value) => {
    const fileName = "PAC - Detalles de licitaciones.xlsx";
    saveExcel(value, fileName);
  };

  // -- Filters Modal -- //
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [filterToModify, setFilterToModify] = useState(null);

  const addEditFilter = (filter) => {
    setFilterToModify(filter);
    setOpenFiltersModal(true);
  };
  const handleCloseFiltersModal = () => {
    setFilterToModify(null);
    setOpenFiltersModal(false);
  };
  const handleSaveFilter = async (request) => {
    showSavingStateSpinnerNoTimeout();

    addOrUpdateSearchFilter(request)
      .then((response) => {
        getFilters([response.data[0].searchFilterId])
          .then(() => {
            hideLoadingSpinner();
            setSnackbarSaveChanges();
            handleCloseFiltersModal();
            setShouldRefetch();
          })
          .catch((error) => {
            hideLoadingSpinner();
            console.error(error);
          });
      })
      .catch((error) => {
        hideLoadingSpinner();
        if (error.msgErrorKey === "ENTITY_ALREADY_EXISTS") {
          setSnackbar({
            open: true,
            severity: "warning",
            title: "Advertencia",
            subTitle:
              "Ya existe un filtro con el mismo nombre. Por favor, elija otro.",
          });
        }
        console.error(error);
      });
  };
  // --   -- //

  return (
    <Fragment>
      <PageTitle
        titleHeading="Resultados PAC"
        icon={<CalendarMonth />}
        endComponent={
          <Fragment>
            <HeaderLastUpdate lastUpdateTime={lastUpdateTime} />

            <CustomButtonGroup
              options={monthOptions}
              onClick={(value) =>
                setOptionsSelected((prev) => ({ ...prev, month: value.id }))
              }
            />
            <CustomButtonGroup
              options={yearOptions}
              onClick={(value) =>
                setOptionsSelected((prev) => ({ ...prev, year: value.year }))
              }
              sx={{ ml: 2 }}
            />
            <CustomButtonGroup
              options={isSummonedOptions}
              onClick={(value) =>
                setOptionsSelected((prev) => ({
                  ...prev,
                  isSummoned: value.isSummoned,
                }))
              }
              sx={{ ml: 2 }}
            />
          </Fragment>
        }
      />

      <Box display="flex" gap={2} height="100%">
        <FiltersPanel
          filters={filters}
          setFilters={handleChangeFilters}
          addEditFilter={addEditFilter}
          selectedFilters={selectedFilters}
          handleSaveSelectedFilters={handleSaveSelectedFilters}
        />
        <PACResults fetchAllData={fetchAllData} handleExport={handleExport} />
      </Box>

      <ManageSmartSearchModal
        open={openFiltersModal}
        onClose={handleCloseFiltersModal}
        onSave={handleSaveFilter}
        filter={filterToModify}
      />
    </Fragment>
  );
};

export default PAC;
