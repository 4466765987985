import { Refresh } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  CardHeader,
  Divider,
  IconButton,
  List,
  Skeleton,
  Typography,
} from "@mui/material";
import { ScrollableCardContent } from "pages/SmartSearch/FiltersAdmin/FiltersManageModalContent";
import { Fragment, useState } from "react";
import { getTenderDetailDescription } from "../analysisService";
import { convertCriteriaWordsToString } from "utils/common";

const FilteredProductsPreview = ({
  id,
  showExpand,
  expand,
  handleExpand,
  filterToManage,
}) => {
  const [loadingResults, setLoadingResults] = useState(false);
  const [results, setResults] = useState([]);

  const handleExpandChange = () => {
    handleExpand(id, !expand);
  };

  const getFilteredProducts = async () => {
    setLoadingResults(true);
    const includeWords = convertCriteriaWordsToString(
      filterToManage.includeWords,
    );
    const excludeWords = convertCriteriaWordsToString(
      filterToManage.excludeWords,
    );
    const response = await getTenderDetailDescription(
      includeWords,
      excludeWords,
    );
    setResults(response.data);
    setLoadingResults(false);
  };

  const verifyDisabled = () => {
    const included = filterToManage.includeWords.map(
      (item) => item.words.length > 0,
    );
    const excluded = filterToManage.excludeWords.map(
      (item) => item.words.length > 0,
    );
    const itemValidate = included.concat(excluded).some((item) => item);
    return !itemValidate;
  };

  return (
    <Fragment>
      <CardHeader
        sx={{
          padding: "0.5rem",
          //change action css
          "& .MuiCardHeader-action": {
            flex: "0 0 auto",
            m: 0,
          },
        }}
        title={
          <Box
            display="flex"
            alignItems="center"
            flexDirection={"row"}
            gap={"1rem"}>
            <Button
              color="primary"
              onClick={getFilteredProducts}
              disabled={verifyDisabled()}
              startIcon={<Refresh />}>
              Actualizar
            </Button>
            <Typography variant="h5" component="div">
              Previsualización de productos filtrados
            </Typography>
          </Box>
        }
        action={
          showExpand && (
            <IconButton onClick={handleExpandChange}>
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )
        }
      />
      <Divider component="div" />
      <ScrollableCardContent sx={{ height: expand ? 320 : 0 }}>
        {expand &&
          (loadingResults ? (
            <>
              <Skeleton animation="wave" width={"100%"} />
              <Skeleton animation="wave" width={"100%"} />
            </>
          ) : (
            <>
              <List sx={{ width: "100%" }}>
                {results.map((item, index) => (
                  <>
                    <Typography key={index} variant="subtitle2" component="div">
                      {item}
                    </Typography>
                    <Divider sx={{ my: 0.75 }} />
                  </>
                ))}
              </List>
              {results.length === 0 && (
                <>
                  <Typography variant="subtitle1" component="div">
                    No se encontraron resultados para la búsqueda realizada.
                  </Typography>
                  <Divider sx={{ my: 0.75 }} />
                </>
              )}
            </>
          ))}
      </ScrollableCardContent>
    </Fragment>
  );
};

export default FilteredProductsPreview;
