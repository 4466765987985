import { Fragment, useContext, useRef, useState } from "react";

import { Edit } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { LangContext } from "intl/context/langContext";
import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import { isLangPE } from "utils/common";
import { manageTender } from "pages/TenderManagement/tenderManagementService";
import { useIntl } from "react-intl";
import { manageTenderData } from "../tenderInformationContentService";

const getFSTMRTIntlColumns = (id, langChange) => {
  const config = langChange.configForm[id];
  if (config.element.type !== "FSTMRT") {
    return [];
  }

  return config.element.columns;
};

export default function InfoItemsList({
  data,
  tenderData,
  showManageInfo,
  discardAndRefetch = null,
  managingTenderData = null,
  handleManagingTenderData = null,
}) {
  const langChange = useContext(LangContext);
  const intl = useIntl();
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const managingTenderDataRef = useRef(managingTenderData);
  managingTenderDataRef.current = managingTenderData;

  const infoItemsListColumns = [
    ...(showManageInfo
      ? [
          {
            intlId: "tabL.Gestionado",
            accessorKey: "tenderDetailId",
            header: "Gestionado",
            size: "110",
            disableTooltip: true,
            hideToExport: true,
            Cell: ({ cell, column }) => {
              return (
                <Checkbox
                  checked={Boolean(
                    managingTenderData?.manageDetails.find(
                      (item) =>
                        item.tenderDetailId ===
                        cell.row.original.tenderDetailId,
                    ),
                  )}
                  size="small"
                  disabled
                  sx={{ py: 0 }}
                />
              );
            },
          },
        ]
      : []),
    ...getFSTMRTIntlColumns("TabItemListFirst", langChange),
  ];

  const {
    height,
    setShouldRefetch,
    showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    setSnackbarInfoCustom,
  } = useGlobalStore((state) => ({
    height: state.height,
    setShouldRefetch: state.setShouldRefetch,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setSnackbarInfoCustom: state.setSnackbarInfoCustom,
  }));

  const fetchData = async (pageNumber, pageSize, filter, sorting) => {
    let result = handelData.getDataByCountry("tab.ListaItems");
    result = result.map((item) => {
      return {
        ...item,
        tenderExtension: data.extension,
      };
    });

    return {
      result: result.slice((pageNumber - 1) * pageSize, pageNumber * pageSize),
      total: result.length,
    };
  };

  const handleManageItem = async (item) => {
    if (
      managingTenderDataRef.current?.manageDetails?.find(
        (x) => x.tenderDetailId === item.tenderDetailId,
      )
    ) {
      setSnackbarInfoCustom("Ya está gestionado.");
      return;
    }
    showLoadingStateSpinnerNoTimeout();
    await manageTender([
      {
        TenderNumber: data.tenderNumber,
        ItemNumbers: [item.itemNumber],
      },
    ]);
    await discardAndRefetch();
    hideLoadingSpinner();
    setShouldRefetch();
  };

  const handleUnmanageItem = async (item) => {
    if (
      !managingTenderData?.manageDetails?.find(
        (x) => x.tenderDetailId === item.tenderDetailId,
      )
    ) {
      setSnackbarInfoCustom("Ya fue removido.");
      return;
    }
    showLoadingStateSpinnerNoTimeout();
    let newManageDetails = managingTenderData.manageDetails.filter(
      (detail) => detail.tenderDetailId !== item.tenderDetailId,
    );
    const manageDetails = newManageDetails.map((item) => {
      item.estimatedTotal = +item.estimatedTotal;
      return item;
    });

    const request = {
      TenderNumber: tenderData.tenderNumber,
      ManageFiles: managingTenderData.files ?? [],
      SellerIds: managingTenderData.assignments.sellers.map(
        (item) => item.sellerId,
      ),
      TagIds: managingTenderData.assignments.tags.map((item) => item.tagId),
      CategoryIds: managingTenderData.assignments.categories?.categoryId
        ? [managingTenderData.assignments.categories.categoryId]
        : [],
      LegalDocumentIds: managingTenderData.legalDocuments.map(
        (item) => item.legalDocumentId,
      ),
      ManageComments: managingTenderData.manageComments,
      ManageDetails: manageDetails.map((item) => {
        const obj = {
          manageId: item.manageId,
          manageDetailId: item.manageDetailId,
          tenderDetailId: item.tenderDetailId,
          itemNumber: item.itemNumber,
          companyId: item.companyId,
          estimatedTotal: item.estimatedTotal,
          offered: item.offered,
          comments: item.comments,
          createdByUserId: item.createdByUserId,
          creationDate: item.creationDate,
          searchFilterResult: item.searchFilterResult,
          productId: item.productId,
          deliveryDate: item.deliveryDate,
          tagIds: item.tagIds,
          isPrimary: item.isPrimary,
        };
        return obj;
      }),
      ManageInternalStatusId: managingTenderData.manageInternalStatusId,
      ManageExtensions: managingTenderData.manageExtensions,
    };
    await manageTenderData(request);
    await discardAndRefetch();
    hideLoadingSpinner();
    setShouldRefetch();
  };

  const handleDelete = async (item) => {
    handleUnmanageItem(item);
  };

  const options = {
    tableId: "gridInfoItemsList",
    header: infoItemsListColumns,
    height: height - 217, // Aribitrary height to fit exactly the content
    tableHeaderTitle: intl.formatMessage({
      id: "tab.ListaItems",
      defaultMessage: "Lista de Items",
    }),
    toolbarButtons: {
      ...(showManageInfo
        ? {
            Export: true,
            ExportFilename: `Info ${intl.formatMessage({
              id: "tab.ListaItems",
              defaultMessage: "Lista de Items",
            })} - ${isLangPE() ? data.fileNumber : data.tenderNumber}`,
            ExtraButtons: [
              {
                key: "manageTender",
                title: "Gestionar",
                icon: <Edit />,
                onClick: (item) => {
                  if (!Object.keys(item).length) return;
                  handleManageItem(item);
                },
              },
              {
                key: "unmanageTender",
                title: "Remover",
                icon: <Edit />,
                onClick: (item) => {
                  if (!Object.keys(item).length) return;
                  setItemToDelete(item);
                  setDeleteConfirm(true);
                },
              },
            ],
          }
        : {}),
    },
    enableEditing: false,
    enableRowSelection: true,
  };

  return (
    <Fragment>
      <FSTMaterialReactTable options={options} getData={fetchData} />

      <DeleteDialog
        open={deleteConfirm}
        onClose={() => {
          setItemToDelete(null);
          setDeleteConfirm(false);
        }}
        onDelete={() => handleDelete(itemToDelete)}
        title="Remover ítem gestionado"
        subTitle={`¿Está seguro que desea remover el ítem gestionado ${itemToDelete?.description}?`}
        deleteText="Remover"
      />
    </Fragment>
  );
}
