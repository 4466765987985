import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

const NumericField = ({ maxValue = null, minValue = null, ...other }) => {
  return (
    <NumericFormat
      customInput={TextField}
      isAllowed={(values) => {
        const { floatValue } = values;
        return (
          !(floatValue > maxValue || floatValue < minValue) ||
          !maxValue ||
          !minValue
        );
      }}
      {...other}
    />
  );
};

export default NumericField;
