import { Typography } from "@mui/material";
import { FormattedForm } from "intl/utils/format-elements/formattedForm";
import { injectIntl } from "react-intl";

const GetSelectComponent = (props) => {
  const { item, data, dataExt } = props;

  const handleData = () => {
    if (Object.keys(item).some((key) => key === "template")) {
      return item.template(props);
    } else if (Object.keys(item).some((key) => key === "idValue")) {
      return dataExt[item.idValue];
    } else {
      return item.label;
    }
  };

  const handleDataGrid = () => {
    if (Object.keys(item).some((key) => key === "template")) {
      return item.template(props);
    } else {
      return data;
    }
  };

  switch (item.type) {
    case "Grid":
      return (
        <div style={item.styleParent}>
          <FormattedForm id={item.id_ff} data={handleDataGrid()} />
        </div>
      );
    case "EtqH3":
      return (
        <div style={item.styleParent}>
          <h3>{handleData()}</h3>
        </div>
      );
    case "EtqH4":
      return (
        <div style={item.styleParent}>
          <h4 style={item.style}>{handleData()}</h4>
        </div>
      );
    case "EtqH5":
      return (
        <div style={item.styleParent}>
          <h5 style={item.style}>{handleData()}</h5>
        </div>
      );
    case "EtqH6":
      return (
        <div style={item.styleParent}>
          <h6 style={item.style}>{handleData()}</h6>
        </div>
      );
    case "Span":
      return (
        <div style={item.styleParent}>
          <span style={item.style}>{handleData()}</span>
        </div>
      );
    case "Typography":
      return <Typography {...item.props}>{handleData()}</Typography>;
  }
};

export default injectIntl(GetSelectComponent);
