import { get, post, put } from "config/axios";

// -- Recipients -- //
export const listRecipients = async () => {
  return await post("/api/Notification/ListRecipients");
};

export const addOrUpdateRecipient = async (row) => {
  return await post("/api/Notification/AddOrUpdateRecipient", [row]);
};

export const deleteRecipient = async (row) => {
  return await post("/api/Notification/DeleteRecipient", [
    {
      RecipientId: row.recipientId,
    },
  ]);
};

// -- Notifications & Alerts -- //
export const getGeneralDataNotifications = async () => {
  return await post("/api/Notification/GeneralDataNotifications");
};

export const getGeneralDataAlerts = async () => {
  return await post("/api/Alert/GeneralDataAlerts");
};

export const listNotifications = async () => {
  return await post("/api/Notification/ListNotification");
};

export const listAlerts = async () => {
  return await post("/api/Alert/ListAlerts");
};

export const addOrUpdateNotification = async (row) => {
  return await post("/api/Notification/AddOrUpdateNotification", [row]);
};

export const addOrUpdateAlert = async (row) => {
  return await post("/api/Alert/AddOrUpdateAlert", [row]);
};

export const deleteNotification = async (row) => {
  return await post("/api/Notification/DeleteNotification", [
    {
      NotificationId: row.notificationId,
    },
  ]);
};

// -- Analysis reports for notifications and alerts -- //
export const getReportCategories = async (moduleId) => {
  return await get(
    `api/reporting/getReportCategories?analysisFilterModuleId=${moduleId}`,
  );
};
export const getAnalysisFilter = async (moduleId) => {
  return await get(
    `/api/analysisFilter/getListIdName?analysisFilterModuleId=${moduleId}`,
  );
};

// -- Users -- //
export const listUsers = async () => {
  return await post("/api/User/ListUser", {});
};

export const listNonAdminUsers = async () => {
  return await get("/api/User/nonAdminUsers");
};

export const listAppModules = async () => {
  return await get("/api/applicationmodule/getlist");
};

export const addOrUpdateUserAppModules = async (request) => {
  return await post("/api/user/addorupdateuserapplicationmodules", request);
};

export const enableDisableUser = async (request) => {
  return await post("/api/user/enabledisableuser", request);
};

export const updateUser = async (request) => {
  return await put("/api/user", request);
};

// -- Companies -- //
export const saveCompany = async (request) => {
  return await post("/api/masterconfig/SyncCompany", request);
};

export const listCompanies = async () => {
  return await get("/api/company");
};

export const enableDisableCompany = async (request) => {
  return await post("/api/company/enabledisablecompany", request);
};

export const syncCompanyUsers = async (request) => {
  return await post("/api/masterconfig/SyncCompanyUsers", request);
};

// -- Company Settings -- //
export const listCompanySettings = async (pagination) => {
  return await post("/api/companysetting/listcompanysetting", {});
};

export const addOrUpdateCompanySetting = async (request) => {
  return await post("/api/companysetting/addorupdatecompanysetting", request);
};

export const deleteCompanySetting = async (request) => {
  return await post("/api/companysetting/deletecompanysetting", request);
};

// -- Competitors -- //
export const getCompetitors = async (request) => {
  return await post("/api/analysisfilter/getcompetitorsbycompanyid", request);
};

export const putCompetitors = async (request) => {
  return await put("/api/competitorcompany", request);
};

export const getCompetitorsByUniqueId = async (request) => {
  return await post("/api/competitorcompany/getcompetitorsbyrucs", request);
};

// -- Organisms -- //
export const getOrganisms = async (request) => {
  return await post("/api/analysisFilter/getClientUnitsByCompanyId", request);
};

export const getOrganismsByUniqueId = async (request) => {
  return await post("/api/clientunitcompany/getclientunitsbyrucs", request);
};

export const putOrganisms = async (request) => {
  return await put("/api/ClientUnitCompany", request);
};
