import useGetFormConf from "intl/utils/hooks/useGetFormConf";
import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import { useEffect, useState } from "react";

import GetSelectComponent from "./GetSelectComponent";

const GeneralInfo = ({ data }) => {
  const getForm = useGetFormConf("ListCompGeneralInfo");
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);

  const initData = () => {
    const result = handelData.getDataByCountry("tab.General");
    setData(result);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {runInit &&
        getForm
          .GetFormConf()
          .map((item, index) => (
            <GetSelectComponent
              key={index}
              item={item}
              data={[getData]}
              dataExt={getData}
            />
          ))}
    </>
  );
};

export default GeneralInfo;
