const initialState = {
  open: false,
  title: "",
};

const loadingState = {
  open: true,
  title: "Cargando...",
};

const savingState = {
  open: true,
  title: "Guardando...",
};

const useLoadingSpinnerStore = (set) => ({
  loadingSpinner: initialState,
  setLoadingSpinner: (loadingSpinner) => set({ loadingSpinner }),
  hideLoadingSpinner: () => set({ loadingSpinner: initialState }),
  showSavingStateSpinner: () => {
    set({ loadingSpinner: savingState });
    setTimeout(() => {
      set({ loadingSpinner: initialState });
    }, 2000);
  },
  showLoadingStateSpinner: (customMessage = null) => {
    set({
      loadingSpinner: customMessage
        ? {
            open: true,
            title: customMessage,
          }
        : loadingState,
    });
    setTimeout(() => {
      set({ loadingSpinner: initialState });
    }, 2000);
  },
  showSavingStateSpinnerNoTimeout: () => {
    set({ loadingSpinner: savingState });
  },
  showLoadingStateSpinnerNoTimeout: (customMessage = null) => {
    set({
      loadingSpinner: customMessage
        ? {
            open: true,
            title: customMessage,
          }
        : loadingState,
    });
  },
});

export default useLoadingSpinnerStore;
