import administration from "services/administration";

const useSellersStore = (set) => ({
  sellers: [],
  fetchSellers: async () => {
    const response = await administration.getSellers({ active: true });
    set({ sellers: response.data.currentPageItems });
  },
});

export default useSellersStore;
