import {
  Card,
  CardContent,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function LineData(props) {
  return (
    <Card className="card-box text-black-50 bg-secondary p-2">
      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información de solicitud de contratación"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}>
                  {props.allData.requestNumber.name}
                </TableCell>
                <TableCell>{props.allData.requestNumber.data}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Entrega"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.delivery.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.allData.delivery.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <CardContent>
          <TableContainer
            component={Paper}
            className="card-box text-black-50 bg-secondary">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  {props.allData.deliveryItems.map((row) => (
                    <TableCell key={row.id}>{row.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.allData.deliveryItemsList.map((row) => (
                  <TableRow
                    key={row.length !== 0 ? row[0] + 100 : 1}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                    {row.map((row2) => (
                      <TableCell key={row2}>{row2}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </CardContent>
      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Garantía técnica"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}>
                  {props.allData.guarrantee.name}
                </TableCell>
                <TableCell>{props.allData.guarrantee.data}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Presupuesto por línea"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.budgetFirstLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.allData.budgetFirstLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Divider />
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.budgetSecondLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={12}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.allData.budgetSecondLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Muestra"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.sampleFirstLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.allData.sampleFirstLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Divider />
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.sampleSecondLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.allData.sampleSecondLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
