import { Fragment } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";

import { AppBar, Box, IconButton, Tooltip } from "@mui/material";

import { connect } from "react-redux";

import projectLogo from "../../assets/images/imgFalconRounded.png";
import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from "../../reducers/ThemeOptions";

import HeaderLogo from "../../layout-components/HeaderLogo";
import HeaderUserbox from "../../layout-components/HeaderUserbox";
import HeaderMenus from "../HeaderMenus";

import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { logout } from "config/msalConfig";

const Header = (props) => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    userInfo,
  } = props;

  const userNameInfo =
    userInfo.fullName.length > 0
      ? `${userInfo.fullName} - ${userInfo.companyName}`
      : "";

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx("app-header", {
          "app-header-collapsed-sidebar": props.isCollapsedLayout,
        })}
        position={"fixed"}
        elevation={4}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Box
            sx={{ display: { lg: "none", xs: "block" } }}
            className="align-self-center">
            <Box className="app-logo-wrapper" title="Falconsoft Tenders">
              <Link to="/" className="app-logo-link">
                <IconButton
                  color="primary"
                  size="medium"
                  className="app-logo-btn">
                  <img
                    className="app-logo-img"
                    alt="Falconsoft Tenders"
                    src={projectLogo}
                  />
                </IconButton>
              </Link>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Box className="app-logo-text">Tenders</Box>
              </Box>
              <Box className="toggle-sidebar-btn-mobile">
                <Tooltip
                  title="Abrir/Cerrar Barra lateral"
                  placement="right"
                  disableInteractive>
                  <IconButton
                    color="inherit"
                    onClick={toggleSidebarMobile}
                    size="medium">
                    {sidebarToggleMobile ? (
                      <MenuOpenRoundedIcon />
                    ) : (
                      <MenuRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: { xs: "none", lg: "block" } }}
            className="align-self-center">
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx("btn-toggle-collapse", {
                    "btn-toggle-collapse-closed": sidebarToggle,
                  })}>
                  <Tooltip
                    title="Abrir/Cerrar Barra lateral"
                    placement="right"
                    disableInteractive>
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse">
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {/* TODO: Implement all page search */}
              {/* <HeaderSearch /> */}
            </Box>
          </Box>
          <Box className="d-flex align-items-center">
            <HeaderMenus userInfo={userInfo} />
            <HeaderUserbox
              userNameInfo={userNameInfo}
              loginPending={userInfo.loginPending}
              fullName={userInfo.fullName}
              companyName={userInfo.companyName}
              logout={logout}
            />
            {/* TODO: Implement Header right side drawer */}
            {/* <HeaderDrawer /> */}
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
