import { Autocomplete, TextField } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import {
  addOrUpdateLegalDocument,
  deleteLegalDocuments,
  getLegalDocuments,
} from "pages/TenderInformationContent/tenderInformationContentService";
import { useGlobalStore } from "global-store/useGlobalStore";
import { legalDocumentSubjects } from "intl/tenderInformation/js/legalDocumentSubjects";
import useSelectObject from "intl/utils/hooks/useTranslatorCommon";
import moment from "moment/moment";
import { Fragment, useRef, useState } from "react";
import { useIntl } from "react-intl";
import LegalDocumentsHeader from "./legalDocumentsHeader";
import LegalDocumentsManageForm from "./LegalDocumentsManageForm";
import { formatShortDate } from "utils/common";

const LegalDocuments = ({
  managingTenderData,
  handleManagingTenderData,
  tenderManageData,
  isInTenderRoute = false, // if true, means we are in standalone route (TODO: change var name)
}) => {
  const {
    userInfo,
    setShouldRefetch,
    showSavingStateSpinner,
    setSnackbarSaveCustom,
  } = useGlobalStore((state) => ({
    userInfo: state.userInfo,
    setShouldRefetch: state.setShouldRefetch,
    showSavingStateSpinner: state.showSavingStateSpinner,
    setSnackbarSaveCustom: state.setSnackbarSaveCustom,
  }));
  const intl = useIntl();
  const selectObject = useSelectObject();

  const legalDocumentAddDefault = {
    legalDocumentId: null,
    status: null,
    statusId: null,
    statusDescription: "",
    bankId: null,
    bankName: "",
    bank: null,
    reference: "",
    subject: null, //default
    value: "",
    startDate: null, // moment().format("L"), //.format("DD/MM/YYYY"),
    endDate: null, //moment().format("L"), //.format("DD/MM/YYYY"),
    documentTypeId: 1, //WT - WarrantyTicket
    number: "",
    comments: "",
    active: true,
    documentComments: [],
    documentFiles: [],
    currencyId: null,
    currency: userInfo.currencyDefault,
    document: { fileName: "" },
    objectSubject: selectObject.ObjectFilter(
      "ValueCmb_Carta",
      legalDocumentSubjects,
    ), // TODO: hacer esto mas facil...
  };

  const [manageLegalDocumentsModalOpen, setManageLegalDocumentsModalOpen] =
    useState(false);
  const [legalDocumentToManage, setLegalDocumentToManage] = useState(
    legalDocumentAddDefault,
  );

  const setEditLegalDocumentData = (item) => {
    const parsedLegalDocument = {
      legalDocumentId: item.legalDocumentId,
      statusId: item.statusId,
      status: {
        id: item.statusId,
        description: item.statusDescription,
      },
      bankId: item.bankId,
      bank: {
        bankId: item.bankId,
        bankName: item.bankName,
      },
      reference: item.reference,
      subject: item.subject
        ? selectObject
            .ObjectFilter("ValueCmb_Carta", legalDocumentSubjects)
            .find((x) => x.description === item.subject)
        : item.subject, // item comes as string (the description), so we need to find the object in the subject array and get the description
      value: item.value,
      startDate: moment(item.startDate),
      endDate: moment(item.endDate),
      documentTypeId: item.documentTypeId,
      number: item.number,
      comments: "",
      active: item.active,
      documentComments: item.documentComments,
      documentFiles: item.documentFiles,
      document: item.documentFiles?.length ? item.documentFiles[0] : {},
      currencyId: item.currencyId,
      currency: {
        active: item.currencyActive,
        code: item.currencyCode,
        currencyId: item.currencyId,
        default: item.currencyDefault,
        description: item.currencyDescription,
        name: item.currencyName,
        symbol: item.currencySymbol,
      },
      objectSubject: selectObject.ObjectFilter(
        "ValueCmb_Carta",
        legalDocumentSubjects,
      ),
    };
    setLegalDocumentToManage(parsedLegalDocument);
  };

  const handleCloseModal = () => {
    setManageLegalDocumentsModalOpen(false);
    setLegalDocumentToManage(legalDocumentAddDefault);
  };

  const fetchData = async (pageNumber, pageSize, filter, sorting) => {
    if (isInTenderRoute) {
      let orderBy = sorting.length > 0 ? sorting[0].id : "";
      let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;

      const request = {
        orderBy: orderBy,
        pageSize: pageSize,
        filter: filter,
        pageNumber: pageNumber,
        orderAsc: orderAsc,
      };
      let response = await getLegalDocuments(request);
      return {
        result: response.data.currentPageItems,
        total: response.data.totalItems,
      };
    } else {
      return {
        result: managingTenderData.legalDocuments,
        total: managingTenderData.legalDocuments.length,
      };
    }
  };

  const handleSave = async () => {
    // TODO: validate data
    const request = {
      number: legalDocumentToManage.number,
      subject: legalDocumentToManage.subject.description,
      statusId: legalDocumentToManage.status
        ? legalDocumentToManage.status.id
        : null,
      statusDescription: legalDocumentToManage.status
        ? legalDocumentToManage.status.description
        : "",
      bankId: legalDocumentToManage.bank.bankId,
      bankName: legalDocumentToManage.bank.bankName,
      reference: legalDocumentToManage.reference,
      value: +legalDocumentToManage.value,
      documentTypeId: 1,
      active: legalDocumentToManage.active,
      startDate: moment(legalDocumentToManage.startDate, "L")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      endDate: moment(legalDocumentToManage.endDate, "L")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      documentComments: legalDocumentToManage.documentComments,
      documentFiles: legalDocumentToManage.document.content
        ? [legalDocumentToManage.document]
        : [],
      currencyId: legalDocumentToManage.currency.currencyId,
      currency: legalDocumentToManage.currency,
      currencyActive: legalDocumentToManage.currency.active,
      currencyCode: legalDocumentToManage.currency.code,
      currencyDefault: legalDocumentToManage.currency.default,
      currencyDescription: legalDocumentToManage.currency.description,
      currencyName: legalDocumentToManage.currency.name,
      currencySymbol: legalDocumentToManage.currency.symbol,
    };

    if (legalDocumentToManage.comments?.length) {
      const commentToAdd = {
        comment: legalDocumentToManage.comments,
        lastUpdate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        updatedByUserName: userInfo.fullName,
      };
      request.documentComments.push(commentToAdd);
    }

    if (legalDocumentToManage.legalDocumentId) {
      request.legalDocumentId = legalDocumentToManage.legalDocumentId;
    }

    if (isInTenderRoute) {
      showSavingStateSpinner();
      await addOrUpdateLegalDocument([request]);
    } else {
      // remove the old document from the managingTenderData.legalDocuments array, then add the new one
      let newLegalDocuments = [
        ...managingTenderData.legalDocuments.filter(
          (item) => item.legalDocumentId !== request.legalDocumentId,
        ),
        request,
      ];

      handleManagingTenderData({
        ...managingTenderData,
        legalDocuments: newLegalDocuments,
      });
      showSavingStateSpinner();
      const response = await addOrUpdateLegalDocument([request]);
      // Receive the new legal document and replace it in the array, so we can obtain the new legalDocumentId
      let newLegalDocument = response.data[0];
      let newLegalDocumentsWithId = [
        ...newLegalDocuments.slice(0, -1),
        { ...request, legalDocumentId: newLegalDocument.legalDocumentId },
      ];
      handleManagingTenderData({
        ...managingTenderData,
        legalDocuments: newLegalDocumentsWithId,
      });
    }

    handleCloseModal();
    setShouldRefetch();
  };

  const managingTenderDataRef = useRef(managingTenderData);
  managingTenderDataRef.current = managingTenderData;
  const exportExcel = async (data) => {
    if (isInTenderRoute) {
      const request = {
        orderBy: "",
        pageSize: 99999,
        filter: "",
        pageNumber: 1,
        orderAsc: false,
      };
      const response = await getLegalDocuments(request);
      return [...response.data.currentPageItems];
    }

    return [...managingTenderDataRef.current.legalDocuments];
  };

  const options = {
    tableId: "gridManagementLegalDocuments",
    header: LegalDocumentsHeader(intl),
    fullHeight: isInTenderRoute,
    tableHeaderTitle: isInTenderRoute ? (
      intl.formatMessage({
        id: "tab.CartasFianza",
        defaultMessage: "Cartas Fianza",
      })
    ) : (
      <Autocomplete
        id="tags"
        size="small"
        openOnFocus
        fullWidth
        sx={{ width: 200, ml: 1 }}
        disabled={tenderManageData?.faithFulObservanceRequired}
        value={managingTenderData?.configLegalDocument}
        options={[
          { text: "No Requerida", id: 0 },
          { text: "Requerida", id: 1 },
        ]}
        onChange={(event, newValue) => {
          handleManagingTenderData({
            ...managingTenderData,
            configLegalDocument: newValue,
          });
        }}
        getOptionLabel={(option) => option.text}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: "etq_CartaFianza",
              defaultMessage: "Carta Fianza",
            })}
            margin="dense"
            sx={{ m: 0, p: 0 }}
          />
        )}
      />
    ),
    toolbarButtons: {
      Add: true,
      AddFunc: () => {
        setLegalDocumentToManage(legalDocumentAddDefault);
        setManageLegalDocumentsModalOpen(true);
      },
      Delete: true,
      DeleteFunc: (row, table) => {
        if (Object.keys(row).length === 0) return;
        setLegalDocumentToDelete(row);
        setDeleteDialogOpen(true);
        table.resetRowSelection();
      },
      Export: true,
      ExportFunc: exportExcel,
      ExportFilename: `${intl.formatMessage({
        id: "tab.CartasFianza",
        defaultMessage: "Cartas Fianza",
      })}${
        tenderManageData?.tenderNumber
          ? ` - ${tenderManageData.tenderNumber}`
          : ""
      }`,
      Edit: !isInTenderRoute,
      EditFunc: (row, table) => {
        if (Object.keys(row).length === 0) return;
        setEditLegalDocumentData(row);
        setManageLegalDocumentsModalOpen(true);
        table.resetRowSelection();
      },
    },
    enableRowSelection: true,
    rowDoubleClickFunc: (row) => {
      setEditLegalDocumentData(row);
      setManageLegalDocumentsModalOpen(true);
    },
  };

  // -- Delete Dialog -- //
  const [legalDocumentToDelete, setLegalDocumentToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleCloseDeleteDialog = () => {
    setLegalDocumentToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteLegalDocument = async () => {
    if (isInTenderRoute) {
      const request = [
        { LegalDocumentId: legalDocumentToDelete.legalDocumentId },
      ];
      showSavingStateSpinner();
      await deleteLegalDocuments(request);
      setSnackbarSaveCustom(
        intl.formatMessage({
          id: "etq_CartaFianza",
          defaultMessage: "Carta Fianza",
        }) + " eliminada exitosamente",
      );
    } else {
      handleManagingTenderData({
        ...managingTenderData,
        legalDocuments: managingTenderData.legalDocuments.filter(
          (item) =>
            item.legalDocumentId !== legalDocumentToDelete.legalDocumentId,
        ),
      });
    }
    handleCloseDeleteDialog();
    setShouldRefetch();
  };
  // --  -- //

  return (
    <Fragment>
      <GeneralPurposeModal
        open={manageLegalDocumentsModalOpen}
        onClose={handleCloseModal}
        closeText={"Cancelar"}
        title={intl.formatMessage({
          id: "etq_CartaFianza",
          defaultMessage: "Carta Fianza",
        })}
        maxWidth="md"
        onSave={handleSave}
        onSaveDisabled={
          !legalDocumentToManage?.startDate ||
          !legalDocumentToManage?.endDate ||
          !legalDocumentToManage?.status ||
          !legalDocumentToManage?.subject ||
          !legalDocumentToManage?.currency ||
          !legalDocumentToManage?.value ||
          !legalDocumentToManage?.bank
        }>
        <LegalDocumentsManageForm
          legalDocument={legalDocumentToManage}
          setLegalDocumentToManage={setLegalDocumentToManage}
          isViewMode={legalDocumentToManage?.legalDocumentId && isInTenderRoute} // if it has legalDocumentId, it means it's in view mode, otherwise it's in add mode
        />
      </GeneralPurposeModal>
      <FSTMaterialReactTable options={options} getData={fetchData} />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteLegalDocument}
        title={`¿Confirma que desea eliminar ${intl.formatMessage({
          id: "etq_CartaFianza",
          defaultMessage: "Carta Fianza",
        })} número ${legalDocumentToDelete?.number}?`}
        subTitle=" "
      />
    </Fragment>
  );
};

export default LegalDocuments;
