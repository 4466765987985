import ModalCRUD from "./ModalCRUD";
import { addOrUpdateTag, deleteTag, getTags } from "./administrationServices";

const columns = [{ accessorKey: "name", header: "Nombre", size: 375 }];

const messages = {
  saveSuccess: "La etiqueta se almacenó correctamente.",
  deleteSuccess: "La etiqueta se eliminó correctamente.",
  missingData: "Por favor ingrese el nombre de la etiqueta.",
  cannotDelete:
    "La etiqueta no puede ser eliminada porque está siendo utilizada.",
  deleteConfirmation: (item) =>
    `¿Está seguro que desea eliminar la etiqueta ${item?.name}?`,
};

const TagModal = ({ open, onClose }) => {
  const saveVerification = (data) => {
    return data.name;
  };

  const handleDelete = async (item) => {
    const request = [{ tagId: item.tagId }];
    await deleteTag(request);
  };

  const handleSave = async (data) => {
    const request = [data];
    await addOrUpdateTag(request);
  };

  return (
    <ModalCRUD
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      columns={columns}
      tableInfo={{ id: "gridTagModal", header: "Etiquetas" }}
      modalTitle={"Etiquetas"}
      fetchFunction={getTags}
      saveVerification={saveVerification}
      messages={messages}
    />
  );
};

export default TagModal;
