import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Card, Divider, IconButton, Tooltip } from "@mui/material";
import TenderGeneralInfo from "pages/TenderInformation/TenderGeneralInfo";
import TenderInfoTabContent from "pages/TenderInformationContent/TenderInfoTabContent";
import TenderManagementTabContent from "pages/TenderInformationContent/TenderManagementTabContent";
import tenderMenuObject from "pages/TenderInformationContent/tenderMenuObject";
import { TenderPanel } from "pages/TenderInformationContent/tenderPanelItems";
import { useGlobalStore } from "global-store/useGlobalStore";
import useLangForm from "intl/utils/hooks/useLangForm";
import { PageTitle } from "layout-components/index";
import { Fragment, useCallback, useEffect, useState } from "react";
import { isLangCR } from "utils/common";
import TenderInformationHeaderActions from "./TenderInformationContentHeaderActions";
import {
  TenderInformationSideTabs,
  TenderInformationTopTabs,
} from "./TenderInformationContentTabs";
import { getTenderData } from "./tenderInformationService";

const TenderInformation = ({ history, location, match }) => {
  const [tenderData, setTenderData] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [menus, setMenus] = useState(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(true);
  const [managingTenderData, setManagingTenderData] = useState(null);
  const [discardChanges, setDiscardChanges] = useState(false);
  const filterForm = useLangForm();
  const height = useGlobalStore((state) => state.height);

  const setMenuData = (tenderInfoData) => {
    let allMenus = JSON.parse(JSON.stringify(applyIntlToMenuList())); // Copy list to prevent filtering and then not being able to restore them
    // filter "Contratos" menu if tender status is not "Contrato"
    // and also "Ordenes de pedido" if modalityType is not "Según demanda", otherwise there wouldn't be data to show
    if (isLangCR() && tenderInfoData) {
      if (tenderInfoData.portalStatus !== "Contrato") {
        allMenus[TenderPanel.PORTAL_INFO].items = allMenus[
          TenderPanel.PORTAL_INFO
        ].items.filter((item) => item.etq_title !== "Contratos");
      }
      if (
        tenderInfoData.portalStatus !== "Contrato" ||
        tenderInfoData.modalityType !== "Según demanda"
      ) {
        allMenus[TenderPanel.PORTAL_INFO].items = allMenus[
          TenderPanel.PORTAL_INFO
        ].items.filter((item) => item.etq_title !== "Ordenes de Pedido");
      }
    }
    //
    setMenus(allMenus);
    setCurrentMenu(allMenus[TenderPanel.PORTAL_INFO].items[0]);
  };

  const applyIntlToMenuList = () => {
    tenderMenuObject[TenderPanel.PORTAL_INFO].items =
      filterForm.FormatFilterForm(
        "TenderGestion",
        "menuInfo",
        tenderMenuObject[TenderPanel.PORTAL_INFO].items,
      );
    tenderMenuObject[TenderPanel.MANAGEMENT].items =
      filterForm.FormatFilterForm(
        "TenderGestion",
        "menuGestion",
        tenderMenuObject[TenderPanel.MANAGEMENT].items,
      );
    return tenderMenuObject;
  };

  const fetchTenderData = async (tenderNumber) => {
    const response = await getTenderData(tenderNumber);
    setTenderData(response.data);
    setMenuData(response.data);
  };

  useEffect(() => {
    const { tenderNumber } = match.params;
    if (decodeURIComponent(tenderNumber) === tenderData?.tenderNumber) return; // Used to prevent fetching the same data multiple times because params refresh when hovering sidebar navigation menu
    fetchTenderData(decodeURIComponent(tenderNumber));
  }, [match.params]);

  // -- Height handling for content card -- //
  // This is used to make the content card height dynamic, so it can be resized when the window is resized
  // it also takes into account the height of the general info card, so it can be resized accordingly
  const [contentHeight, setContentHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const handleResize = useCallback(() => setWidth(window.innerWidth), []);
  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const generalInfo = document.getElementById("generalInfo");
    if (generalInfo) {
      setContentHeight(height - generalInfo.clientHeight + 117);
    }
  }, [height, width, tenderData]);
  // --  -- //

  return (
    <Fragment>
      <PageTitle
        titleHeading="Información y Gestión de Licitación"
        icon={<InfoIcon />}
        startComponent={
          <>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Tooltip title="Volver">
              <IconButton
                color="secondary"
                size="small"
                onClick={history.goBack}
                sx={{ ml: 1 }}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            {sidePanelOpen ? (
              <span className="font-size-md font-weight-bold color-primary">
                {currentMenu?.titlePanel}
              </span>
            ) : (
              <TenderInformationTopTabs
                menus={menus}
                currentMenu={currentMenu}
                setCurrentMenu={setCurrentMenu}
                height={height}
                setSidePanelOpen={setSidePanelOpen}
              />
            )}
          </>
        }
        endComponent={
          <TenderInformationHeaderActions
            tenderData={tenderData}
            managingTenderData={managingTenderData}
            currentMenu={currentMenu}
            menus={menus}
            setDiscardChanges={setDiscardChanges}
          />
        }
      />
      <Box display="flex" gap={2}>
        <Box
          maxWidth={200}
          minWidth={200}
          sx={{ display: sidePanelOpen ? "block" : "none" }}>
          <TenderInformationSideTabs
            menus={menus}
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            height={height}
            setSidePanelOpen={setSidePanelOpen}
          />
        </Box>
        <Box
          display="flex"
          flex={1}
          sx={{
            // These are needed to make the Box fill the parent container and not the viewport. Prevents overflow of table.
            width: "0px",
            maxWidth: "-webkit-fill-available",
          }}>
          <Box
            display="flex"
            gap={2}
            flexDirection="column"
            sx={{
              height: height + 131,
              maxWidth: "-webkit-fill-available",
              width: "-webkit-fill-available",
            }} // TODO: make 133 a constant
            id="tenderInfo">
            <Box id="generalInfo">
              <Card>
                <TenderGeneralInfo
                  tenderData={tenderData}
                  finishedLoading={tenderData !== null}
                />
              </Card>
            </Box>
            <Box flex={1} sx={{ height: contentHeight }}>
              <TenderInfoTabContent
                tender={tenderData}
                menu={currentMenu}
                itemListShouldShowInfo={false}
              />
              <TenderManagementTabContent
                tenderData={tenderData}
                menu={currentMenu}
                managingTenderData={managingTenderData}
                setManagingTenderData={setManagingTenderData}
                discardChanges={discardChanges}
                setDiscardChanges={setDiscardChanges}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default TenderInformation;
