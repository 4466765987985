import { Grid, Paper } from "@mui/material";
import { setLoadingMessage } from "actions/appActions";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ClimbingBoxLoader } from "react-spinners";
import { isMsalUserLoggedIn, selectAccount } from "config/msalConfig";

function SuspenseLoading(props) {
  const verifyLogin = () => {
    const { loginMessageError, dispatch } = props;
    const loginError = !!loginMessageError && loginMessageError.length > 0;
    if (loginError) {
      setTimeout(async () => {
        await dispatch(setLoadingMessage(""));
        selectAccount().then();
      }, 3000);
    }
  };

  useEffect(() => {
    verifyLogin();
  }, []);

  const errorMsg = props.msg;
  const min = 1;
  const max = 10;
  const imgName = `background_${
    Math.floor(Math.random() * (+max - +min)) + +min
  }.jpg`;
  const {
    loginMessageError,
    finishedUserLogin,
    checkFinishedLoading,
    finishedLoadingInitialData,
    betweenPagesLoading,
    finishedConfig,
  } = props;
  const loginError = !!loginMessageError && loginMessageError.length > 0;
  const showLoading =
    !isMsalUserLoggedIn() ||
    loginError ||
    (!!checkFinishedLoading &&
      (!finishedUserLogin || !finishedLoadingInitialData));

  if (showLoading || (betweenPagesLoading && !finishedConfig)) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url("assets/${imgName}")`,
          backgroundSize: "100% 100%",
        }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ minHeight: "100vh" }}>
          <Grid item xs={4} sx={{ minWidth: "20vw", textAlign: "center" }}>
            <Paper
              sx={{
                display: "inline-block",
                padding: "0.7em",
                textAlign: "center",
                backgroundColor: "rgba(255,255,255,0.8)",
              }}>
              <div className="font-size-xl text-center pt-3">
                Falconsoft Tenders
                <span className="font-size-lg d-block text-dark">
                  Espere mientras se carga la aplicación
                </span>
              </div>
              <div className="d-flex align-items-center flex-column px-4">
                <ClimbingBoxLoader color={"#5383ff"} loading={true} />
              </div>
              <p>{loginError ? loginMessageError : `Cargando...`}</p>
              {!!errorMsg && errorMsg.length > 0 && <p>{errorMsg}</p>}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  return props.children;
}

const mapStateToProps = (state, ownProps) => {
  const { app, user } = state;

  return {
    ...ownProps,
    loginMessageError: app.loginMessageError,
    finishedLoadingInitialData: app.finishedLoadingInitialData,
    finishedUserLogin: user.finishedUserLogin,
  };
};

export default connect(mapStateToProps)(SuspenseLoading);
