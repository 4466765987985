import { Box } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useGlobalStore } from "global-store/useGlobalStore";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

const ModalCRUD = ({
  open,
  onClose,
  onSave,
  onDelete,
  columns,
  tableInfo,
  modalTitle,
  fetchFunction,
  saveVerification,
  messages,
  ...otherProps
}) => {
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const {
    showSavingStateSpinnerNoTimeout,
    setShouldRefetch,
    setSnackbarWarningCustom,
    setSnackbarErrorCustom,
    setSnackbarSaveCustom,
    height,
    setSettingsChanged,
    hideLoadingSpinner,
  } = useGlobalStore((state) => ({
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    setShouldRefetch: state.setShouldRefetch,
    setSnackbarWarningCustom: state.setSnackbarWarningCustom,
    setSnackbarErrorCustom: state.setSnackbarErrorCustom,
    setSnackbarSaveCustom: state.setSnackbarSaveCustom,
    height: state.height,
    setSettingsChanged: state.setSettingsChanged,
    hideLoadingSpinner: state.hideLoadingSpinner,
  }));

  const fetchTableData = async (pageNumber, pageSize, filter, sorting) => {
    let orderBy = sorting.length > 0 ? sorting[0].id : "";
    let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;

    const request = {
      orderBy: orderBy,
      pageSize: pageSize,
      filter: filter,
      pageNumber: pageNumber,
      orderAsc: orderAsc,
    };
    const results = await fetchFunction(request);
    if (tableInfo.id === "gridSellerModal") {
      return {
        result: results.data.currentPageItems,
        total: results.data.totalItems,
      };
    }
    return {
      result: results.data,
      total: results.data.length,
    };
  };

  const handleDelete = async () => {
    showSavingStateSpinnerNoTimeout();
    await onDelete(itemToDelete);
    hideLoadingSpinner();
    setSettingsChanged();
    setModalDeleteConfirm(false);
    setSnackbarSaveCustom(messages.deleteSuccess);
    setShouldRefetch();
  };

  const newHandleSave = async (item) => {
    // Error checking
    if (saveVerification && !saveVerification(item)) {
      setSnackbarWarningCustom(messages.missingData);
      return;
    }
    showSavingStateSpinnerNoTimeout();
    const { success, message } = await onSave(item);
    hideLoadingSpinner();
    if (!success) {
      setSnackbarErrorCustom(message);
      return false;
    }
    setSettingsChanged();
    setSnackbarSaveCustom(messages.saveSuccess);
    return true;
  };

  const handleSave = async (item) => {
    if (tableInfo.id === "gridSellerModal") {
      return newHandleSave(item);
    }
    // Error checking
    if (saveVerification && !saveVerification(item)) {
      setSnackbarWarningCustom(messages.missingData);
      return;
    }
    showSavingStateSpinnerNoTimeout();
    await onSave(item);
    hideLoadingSpinner();
    setSettingsChanged();
    setSnackbarSaveCustom(messages.saveSuccess);
    return true;
  };

  const options = {
    tableId: tableInfo.id,
    tableHeaderTitle: tableInfo.header,
    header: columns,

    enableEditing: true,
    enableDeleting: true,
    saveFunc: handleSave,
    deleteFunc: (row) => {
      if (messages.cannotDelete && !row.disposable) {
        setSnackbarWarningCustom(messages.cannotDelete);
        return;
      }
      setItemToDelete(row);
      setModalDeleteConfirm(true);
    },

    toolbarButtons: {
      Add: true,
      Export: true,
    },
  };

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };

  return (
    <Fragment>
      <GeneralPurposeModal title={modalTitle} open={open} onClose={onClose}>
        <Box sx={{ height: height }}>
          <FSTMaterialReactTable options={options} getData={fetchTableData} />
        </Box>
      </GeneralPurposeModal>
      <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={messages.deleteConfirmation(itemToDelete)}
      />
    </Fragment>
  );
};

// Proptypes
ModalCRUD.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  tableInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
  }),
  modalTitle: PropTypes.string.isRequired,
  fetchFunction: PropTypes.func.isRequired,
  saveVerification: PropTypes.func.isRequired,
  messages: PropTypes.shape({
    saveSuccess: PropTypes.string.isRequired,
    deleteSuccess: PropTypes.string.isRequired,
    missingData: PropTypes.string.isRequired,
    cannotDelete: PropTypes.string.isRequired,
    deleteConfirmation: PropTypes.func.isRequired,
  }),
};

export default ModalCRUD;
