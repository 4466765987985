import { CloudDownload, CloudUpload } from "@mui/icons-material";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import NumericField from "components/CustomFields/NumericField";
import { listAllBanks } from "pages/TenderInformationContent/tenderInformationContentService";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { formatDate } from "utils/common";
import { downloadBase64File, validateFileSize } from "utils/fileHelper";

//TODO: improve the handlers of onChange, abstract them
const LegalDocumentsManageForm = ({
  legalDocument,
  setLegalDocumentToManage,
  isViewMode,
}) => {
  const [currencies, setCurrencies] = useState([]);
  const [banks, setBanks] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const userInfo = useGlobalStore((state) => state.userInfo);
  const intl = useIntl();

  const getBanks = async () => {
    const result = await listAllBanks();
    setBanks(result.data);
  };

  const getDocumentsStatus = () => {
    const list = [];
    const objectArray = Object.entries(userInfo.legalDocumentWTStatus);
    objectArray.forEach(([key, value]) => {
      list.push({ id: key, description: value });
    });
    setStatusList(list);
  };

  const getSubjects = () => {
    setSubjects(legalDocument.objectSubject);
  };

  useEffect(() => {
    const currencyListActives = userInfo.currencyList.filter(
      (item) => item.active,
    );
    setCurrencies(currencyListActives);
    getBanks();
    getDocumentsStatus();
    getSubjects();
  }, []);

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;
    const _file = {
      fileName: event.target.files[0].name,
    };
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      _file.content = e.target.result.split(",")[1];
      setLegalDocumentToManage({
        ...legalDocument,
        document: _file,
      });
    };
  };

  const handleFileDownload = (event) => {
    const _file = {
      fileName: legalDocument.document.fileName,
      base64Data: legalDocument.document.content,
    };
    downloadBase64File(_file.fileName, _file.base64Data);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            id="number"
            label="Numero"
            autoComplete="off"
            fullWidth
            value={legalDocument.number}
            onChange={(event) => {
              const { value } = event.target;
              setLegalDocumentToManage({ ...legalDocument, number: value });
            }}
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            label="Emisión"
            value={legalDocument.startDate}
            format="DD/MM/YYYY"
            onChange={(newValue) => {
              setLegalDocumentToManage({
                ...legalDocument,
                startDate: newValue,
              });
            }}
            disabled={isViewMode}
            slotProps={{ textField: { required: true } }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            label="Vencimiento"
            value={legalDocument.endDate}
            format="DD/MM/YYYY"
            onChange={(newValue) => {
              setLegalDocumentToManage({
                ...legalDocument,
                endDate: newValue,
              });
            }}
            disabled={isViewMode}
            slotProps={{ textField: { required: true } }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={statusList}
            fullWidth
            id="status"
            openOnFocus
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={legalDocument.status}
            onChange={(event, newValue) => {
              setLegalDocumentToManage({ ...legalDocument, status: newValue });
            }}
            disabled={isViewMode}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                required
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={subjects}
            id="subject"
            openOnFocus
            fullWidth
            getOptionLabel={(option) => option.description}
            value={legalDocument.subject}
            noOptionsText="Sin motivos"
            onChange={(event, newValue) => {
              setLegalDocumentToManage({ ...legalDocument, subject: newValue });
            }}
            disabled={isViewMode}
            renderInput={(params) => (
              <TextField {...params} required label="Motivo" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={currencies}
            fullWidth
            id="currency"
            openOnFocus
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={(option, value) =>
              option.currencyId === value.currencyId
            }
            value={legalDocument.currency}
            onChange={(event, newValue) => {
              setLegalDocumentToManage({
                ...legalDocument,
                currency: newValue,
              });
            }}
            disabled={isViewMode}
            renderInput={(params) => (
              <TextField {...params} required label="Moneda" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <NumericField
            label="Monto"
            prefix={legalDocument.currency ? legalDocument.currency.symbol : ""}
            decimalSeparator="."
            thousandSeparator=","
            decimalScale={2}
            value={legalDocument.value}
            required
            fullWidth
            onValueChange={(values, sourceInfo) =>
              setLegalDocumentToManage({
                ...legalDocument,
                value: values.floatValue,
              })
            }
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="Reference"
            label="Referencia"
            fullWidth
            autoComplete="off"
            value={legalDocument.reference}
            onChange={(event) => {
              setLegalDocumentToManage({
                ...legalDocument,
                reference: event.target.value,
              });
            }}
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={banks}
            id="bank"
            openOnFocus
            fullWidth
            autoComplete={false}
            getOptionLabel={(option) => option.bankName}
            isOptionEqualToValue={(option, value) =>
              option.bankId === value.bankId
            }
            value={legalDocument.bank}
            onChange={(event, newValue) => {
              setLegalDocumentToManage({ ...legalDocument, bank: newValue });
            }}
            disabled={isViewMode}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={intl.formatMessage({
                  id: "title.Banco.ModalGestionGarantia",
                  defaultMessage: "Banco Emisor",
                })}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="fileName"
            placeholder="Adjunto"
            value={legalDocument.document.fileName}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <TextField
            id="comments"
            label="Nuevo comentario..."
            fullWidth
            value={legalDocument.comments}
            autoComplete="off"
            multiline
            onChange={(event) => {
              setLegalDocumentToManage({
                ...legalDocument,
                comments: event.target.value,
              });
            }}
            disabled={isViewMode}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <input
            accept="image/*,.pdf,.doc,.docx"
            style={{ display: "none" }}
            id="upload-button-file"
            type="file"
            onInput={(e) => handleFileUpload(e)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <Tooltip id="upload-button-file" title="Adjuntar">
            <label htmlFor="upload-button-file" style={{ margin: 0 }}>
              <IconButton
                color="secondary"
                aria-label="upload picture"
                component="span"
                disabled={isViewMode}>
                <CloudUpload />
              </IconButton>
            </label>
          </Tooltip>

          <Tooltip
            id="download-button-file"
            className="download-button-file-class"
            title="Descargar">
            <label htmlFor="download-button-file" style={{ margin: 0 }}>
              <IconButton
                onClick={handleFileDownload}
                color="primary"
                aria-label="download picture"
                component="span"
                disabled={!legalDocument.document?.content || isViewMode}>
                <CloudDownload />
              </IconButton>
            </label>
          </Tooltip>
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <Switch
                size="medium"
                checked={legalDocument.active}
                onChange={(event) => {
                  setLegalDocumentToManage({
                    ...legalDocument,
                    active: event.target.checked,
                  });
                }}
                name="active"
                color="primary"
                disabled={isViewMode}
              />
            }
            label="Activa"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      {legalDocument.documentComments.length > 0 && (
        <Grid container spacing={2} sx={{ mt: "1.5rem" }}>
          <Grid item xs={12} md={7}>
            <Typography variant="h6">Comentarios</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6">Usuario</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="h6">Fecha</Typography>
          </Grid>
          {legalDocument.documentComments.map((item, idx) => (
            <Fragment key={item.comment}>
              <Grid item xs={12} md={7}>
                <TextField
                  disabled
                  fullWidth
                  value={item.comment}
                  multiline
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled
                  fullWidth
                  value={item.updatedByUserName}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  disabled
                  fullWidth
                  value={formatDate(item.lastUpdate)}
                  variant="standard"
                />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      )}
    </>
  );
};

export default LegalDocumentsManageForm;
