import { post, get, put, Delete } from "config/axios";
import { getWithParams } from "./common";

const administration = {
  // Sellers
  getSellers: async (request) => {
    return await getWithParams(request, "seller");
  },
  postSeller: async (request) => {
    const result = { success: true, message: "" };
    return post("/api/seller", request)
      .then(() => result)
      .catch((e) => {
        result.success = false;
        if (e.msgErrorKey === "ENTITY_ALREADY_EXISTS") {
          result.message = "El ejecutivo ya existe en el sistema.";
        }
        return result;
      });
  },
  putSeller: async (request, id) => {
    const result = { success: true, message: "" };
    return put(`/api/seller/${id}`, request)
      .then(() => result)
      .catch((e) => {
        result.success = false;
        result.message =
          "No hemos podido guardar los cambios. Intente nuevamente o póngase en contacto con el administrador.";
        return result;
      });
  },
  deleteSeller: async (id) => {
    return await Delete(`/api/seller/${id}`);
  },
  listActiveSellers: async () => {
    const request = {
      Filter: "",
      OrderBy: "email",
      OrderAsc: false,
      Active: true,
    };
    return getWithParams(request, "seller")
      .then((res) => res.data.currentPageItems)
      .catch(() => []);
  },
};

export default administration;
