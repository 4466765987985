import moment from "moment";
import "moment-timezone";
export const formatISO = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
export const formatISOLarge = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

export const dateTimeFormat = "DD/MM/YYYY HH:mm";
export const formatDateshortPicker = "";
export const formatDate = {
  es: {
    SHORT: "dd/MM/yyyy",
    SHORT_UPPER: "DD/MM/YYYY",
    SHORT_PICKER: "dd/MM/yyyy",
    LONG: "DD/MM/YYYY HH:mm",
  },
  en: {
    SHORT: "MM/DD/YYYY",
  },
};

export const GetDateTime = () => {
  return moment();
};

export const convertStartDateToISOLarge = (date) => {
  return moment(date).startOf("day").format(formatISOLarge);
};

export const convertEndDateToISOLarge = (date) => {
  return moment(date).startOf("day").format(formatISOLarge);
};

export const ConvertDateStartToISO = (_date, format = "L") => {
  return moment(_date, format).startOf("day").format(formatISO);
};

export const ConvertDateStartToISOLarge = (_date, format = "L") => {
  return moment(_date, format).startOf("day").format(formatISOLarge);
};

export const ConvertDateEndToISO = (_date, format = "L") => {
  return moment(_date, format).endOf("day").format(formatISO);
};

export const ConvertDateEndToISOLarge = (_date, format = "L") => {
  const setDate = setHourNow(moment(_date, format).endOf("day"));
  return setDate.format(formatISOLarge);
};

export const DateSubtractDay = (num) => {
  return moment().subtract(num, "days").format("L");
};

export const ConvertToDateShort = (str) => {
  return moment(str, formatDate.es.SHORT).format(formatDate.en.SHORT);
};

export const ConvertToDateShortUPPER = (str) => {
  return moment(str, formatDate.es.SHORT_UPPER).format(formatDate.en.SHORT);
};

export const ConvertToDateLong = (str) => {
  return str ? moment(str).format(formatDate.es.LONG) : null;
};

export const AddHourToDate = (str, qty) => {
  return moment(str, formatISO).add(qty, "hours").toDate();
};

export const setHourNow = (date) => {
  const hours = moment().get("h");
  const mins = moment().get("m");
  const sec = moment().get("s");
  let hourNow = date.set({ hour: hours, minute: mins, second: sec });
  return hourNow;
};

export const convertTimezoneToTimezone = (
  date,
  format,
  prevTimezone,
  nextTimezone,
) => {
  const dateTime = moment
    .tz(date, format, prevTimezone)
    .tz(nextTimezone)
    .format(format);
  return dateTime;
};
