import { FormattedForm } from "intl/utils/format-elements/formattedForm";
import BatchData from "./BatchModalData";
import ContractData from "./ContractModalData";
import EngagementData from "./EngagementModalData";
import LineData from "./LineModalData";
import PurchaseOrderData from "./PurchaseOrderModalData";
import {
  CR_BATCH_MODAL,
  CR_CONTRACT_MODAL,
  CR_ENGAGEMENT_MODAL,
  CR_LINE_MODAL,
  CR_PURCHASE_ORDER_MODAL,
  AR_DELIVERY_DETAIL_MODAL,
  DETAIL_AWARDEDS_MODAL,
} from "./modalDataGenerator";

export default function ModalData({ data, modalName }) {
  switch (modalName) {
    case CR_BATCH_MODAL:
      return <BatchData allData={data} />;
    case CR_LINE_MODAL:
      return <LineData allData={data} />;
    case CR_ENGAGEMENT_MODAL:
      return <EngagementData allData={data} />;
    case CR_CONTRACT_MODAL:
      return <ContractData data={data} />;
    case CR_PURCHASE_ORDER_MODAL:
      return <PurchaseOrderData data={data} />;
    case AR_DELIVERY_DETAIL_MODAL:
      return <FormattedForm id={"TabItemModalDeliveryDetail"} data={data} />;
    case DETAIL_AWARDEDS_MODAL:
      return <FormattedForm id={"TabItemListLast"} data={data} />;
    default:
      return null;
  }
}
