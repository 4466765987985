export const ADMIN_ROLE_ID = 1;
export const ADMIN_GENERAL_ROLE_ID = 99;

export const PURCHASE_ORDER_ANALYSIS_MODULE_ID = 4;
export const TENDER_ANALYSIS_MODULE_ID = 3;

export const SOURCES_LINKS = {
  MinisterioSaludPBA:
    "https://sistemas.ms.gba.gov.ar/LicitacionesyContrataciones/web/app.php/publico/licitacion/lista",
  PAMICentral: "https://prestadores.pami.org.ar/result.php?c=7-5&par=1",
  PAMIUgls: "https://prestadores.pami.org.ar/result.php?c=7-5&par=2",
  ComprasGarrahan: "https://compras.garrahan.gov.ar/Licitaciones/Llamado",
  BAC: "https://www.buenosairescompras.gob.ar/",
  PBAC: "https://pbac.cgp.gba.gov.ar",
  ComprarMzaOsep: "https://comprarosep.mendoza.gov.ar/",
  ComprarMza: "https://comprar.mendoza.gov.ar/",
  Comprar: "https://comprar.gob.ar/",
  SEACE:
    "https://prodapp2.seace.gob.pe/seacebus-uiwd-pub/buscadorPublico/buscadorPublico.xhtml",
  SICOP: "https://www.sicop.go.cr/index.jsp",
  SECOP: "https://community.secop.gov.co/",
};
