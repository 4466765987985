import rootReducer from "reducers/rootReducer";
import { loadState } from "utils/statePersistence";
import { configureStore } from "@reduxjs/toolkit";

const persistedData = loadState();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedData,
});
