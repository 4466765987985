import { Card, Divider, Grid } from "@mui/material";
import CriteriaWords from "pages/SmartSearch/FiltersAdmin/CriteriaWords";
import { ScrollableCardContent } from "pages/SmartSearch/FiltersAdmin/FiltersManageModalContent";
import IncludeExcludeWordsHeader from "pages/SmartSearch/FiltersAdmin/IncludeExcludeWordsHeader";
import { Fragment, useState } from "react";
import FilteredProductsPreview from "./FilteredProductsPreview";
import { useIntl } from "react-intl";

const ProductsTabContent = ({ filterToManage, handleFilterToManageChange }) => {
  const intl = useIntl();
  const [expand, setExpand] = useState({
    includeWords: true,
    excludeWords: true,
    preview: false,
  });

  const handleExpand = (id, value) => {
    setExpand({ ...expand, [id]: value });
  };

  const addElement = (key) => {
    const aux = { ...filterToManage };
    aux[key].push({ word: "", words: [] });
    handleFilterToManageChange(aux);
  };

  const handleWordsList = (key, value) => {
    const aux = { ...filterToManage };
    aux[key] = value.length > 0 ? value : [{ word: "", words: [] }];
    handleFilterToManageChange(aux);
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: -2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card className="card-box text-black-50 bg-secondary">
              <IncludeExcludeWordsHeader
                title={`Incluir por ${intl.formatMessage({
                  id: "filtros.IncluirExcluirItem",
                  defaultMessage: "Ítem",
                })}`}
                wordsList={filterToManage?.includeWords}
                id="includeWords"
                showExpand={true}
                expand={expand.includeWords}
                handleExpand={handleExpand}
                addElement={addElement}
              />
              <Divider component="div" />
              <ScrollableCardContent
                sx={{ height: expand.includeWords ? 320 : 0 }}>
                {expand.includeWords && (
                  <CriteriaWords
                    wordsList={filterToManage?.includeWords}
                    id="includeWords"
                    handleWordsList={handleWordsList}
                  />
                )}
              </ScrollableCardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card className="card-box text-black-50 bg-secondary">
              <IncludeExcludeWordsHeader
                title={`Excluir por ${intl.formatMessage({
                  id: "filtros.IncluirExcluirItem",
                  defaultMessage: "Ítem",
                })}`}
                wordsList={filterToManage?.excludeWords}
                id="excludeWords"
                showExpand={true}
                expand={expand.excludeWords}
                handleExpand={handleExpand}
                addElement={addElement}
              />
              <Divider component="div" />
              <ScrollableCardContent
                sx={{ height: expand.excludeWords ? 320 : 0 }}>
                {expand.excludeWords && (
                  <CriteriaWords
                    wordsList={filterToManage?.excludeWords}
                    id="excludeWords"
                    handleWordsList={handleWordsList}
                  />
                )}
              </ScrollableCardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="card-box text-black-50 bg-secondary">
              <FilteredProductsPreview
                id="preview"
                showExpand={true}
                expand={expand.preview}
                handleExpand={handleExpand}
                filterToManage={filterToManage}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProductsTabContent;
