import {
  getManageInternalStatus,
  getSellersReduced,
  getTagsNames,
} from "pages/Administration/administrationServices";
import { listNonAdminUsers } from "pages/Settings/settingsService";
import services from "services/services";

const initialState = {
  filters: {
    sellers: [],
    tags: [],
    sources: [],
    users: [],
    manageInternalStatus: [],
  },
  sellers: [],
  tags: [],
  sources: [],
  users: [],
  manageInternalStatus: [],
};

// Remove empty lists from the request
const cleanRequest = (request) => {
  const cleanedRequest = {};
  Object.keys(request).forEach((key) => {
    if (request[key].length > 0) {
      cleanedRequest[key] = request[key];
    }
  });
  return cleanedRequest;
};

const useManageFiltersStore = (set, get) => ({
  manageFilters: initialState,
  fetchManageAvailableFilters: async () => {
    try {
      const [sellers, tags, sources, users, manageInternalStatus] =
        await Promise.all([
          getSellersReduced(),
          getTagsNames(),
          services.common.getSource(),
          listNonAdminUsers(),
          getManageInternalStatus({}),
        ]);
      set({
        manageFilters: {
          ...get().manageFilters,
          sellers: sellers.data,
          tags: tags.data,
          sources: sources.data,
          users: users.data,
          manageInternalStatus: manageInternalStatus.data,
        },
      });
    } catch (error) {
      console.error("Error fetching manage filters", error);
    }
  },
  setManageFilters: (filters) => {
    set({
      manageFilters: {
        ...get().manageFilters,
        filters: { ...get().manageFilters.filters, ...filters },
      },
    });
  },

  getManageFiltersRequest: () => {
    const { manageFilters } = get();
    const { filters } = manageFilters;

    const request = {
      sellers: filters.sellers.join(","),
      tags: filters.tags.join(","),
      sources: filters.sources.join(","),
      createdBy: filters.users.join(","),
      manageInternalStatusIds: filters.manageInternalStatus.join(","),
    };
    return cleanRequest(request);
  },

  resetAppliedManageFilters: () => {
    set({
      manageFilters: {
        ...get().manageFilters,
        filters: { ...initialState.filters },
      },
    });
  },

  areManageFiltersApplied: () => {
    const { manageFilters } = get();
    const { filters } = manageFilters;
    return Object.values(filters).some((filter) => filter.length > 0);
  },
});

export default useManageFiltersStore;
