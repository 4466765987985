import { capitalize, formatValueLocale } from "utils/common";
import { ConvertToDateLong } from "utils/DateHelper";

export default {
  TenderContainer: {
    Filter: {
      Tab: [
        { id: "tab.General", keys: "", types: "O" },
        { id: "tab.Cronograma", keys: "tenderStages", types: "O" },
        { id: "tab.ListaItems", keys: "tenderDetails", types: "O" },
      ],
    },
  },
  TenderGestion: {
    Filter: {
      menuInfo: ["tab.General", "tab.Cronograma", "tab.ListaItems"],
      menuGestion: ["tab.Asignaciones", "tab.ListaItems", "tab.Comentarios"],
    },
  },

  ManagementCalendarExportValues: {
    elements: [
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => item.toString(),
      },
      {
        id: "cols.Publicacion",
        value: "publishedDate",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Convocatoria", value: "tenderNumber" },
      { id: "cols.Entidad", value: "clientUnitName" },
      { id: "cols.Nombre", value: "tenderName" },
      { id: "cols.Situacion", value: "manageInternalStatusDesc" },
      { id: "cols.Referencial", value: "estimatedValue" },
      { id: "cols.OContratacion", value: "tenderDescription" },
      { id: "cols.Moneda", value: "currency" },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      { id: "cols.Items", value: "detailsQty" },
      {
        id: "cols.Cierre",
        value: "endDate",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Usuario", value: "userManage" },
      {
        id: "cols.Segmento",
        value: "categories",
        format: (item) => (item.length > 0 ? item[0] : ""),
      },
      {
        id: "cols.Gestionada",
        value: "dateCreationManage",
        format: (item) => ConvertToDateLong(item),
      },
    ],
  },
  ManagementCalendarValues: {
    elements: [
      { id: "cols.Convocatoria", value: "tenderNumber" },
      {
        id: "cols.Nombre",
        value: "tenderName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.Entidad",
        value: "clientUnitName",
        format: (item) => capitalize(item),
      },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      {
        id: "cols.Situacion",
        value: "manageInternalStatusDesc",
        format: (item) => (item ? item : "Sin Asignar"),
      },
      { id: "cols.Portal", value: "portal" },
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => (item.length > 0 ? item.toString() : "Sin Asignar"),
      },
    ],
  },
  EmailSubjectConfig: {
    elements: [
      { id: "cols.Nombre", name: "tenderName" },
      { id: "cols.Convocatoria", name: "tenderNumber" },
      { id: "cols.Cierre", name: "endDate" },
      { id: "text.Tipo_Compra", name: "tenderTypeDesc" },
      { id: "cols.Entidad", name: "clientUnitName" },
      { id: "cols.Usuario", name: "userManage" },
      { id: "cols.ClientName", name: "clientName" },
    ],
  },
  TenderGeneralInfo: {
    elements: [
      {
        id: "text.Convocatoria",
        value: "tenderNumber",
        xs: 12,
        sm: 6,
        md: 4,
      },
      {
        id: "text.Fecha_Publicacion",
        value: "publishedDate",
        type: "date",
        xs: 12,
        sm: 6,
        md: 4,
      },
      {
        id: "text.Tipo_Compra",
        value: "tenderTypeDesc",
        xs: 12,
        sm: 6,
        md: 4,
      },
      {
        id: "text.ClientName",
        value: "clientName",
        xs: 12,
        sm: 6,
        md: 6,
      },
      {
        id: "text.Entidad",
        value: "clientUnitName",
        xs: 12,
        sm: 6,
        md: 6,
      },
    ],
  },
  ListCompGeneralInfo: {
    component: [
      {
        type: "Grid",
        id_ff: "TabGeneralInfoFirst",
      },
    ],
  },
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            {
              key: "tenderDescription",
              intlLabelKey: "tabG.Objeto_Contratacion",
            },
            {
              key: "estimatedValue",
              intlLabelKey: "tabG.Valor_Referencial",
              cellType: "currency",
            },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "name", intlLabelKey: "tabC.Etapa" },
            {
              key: "startDate",
              intlLabelKey: "tabC.Fecha_Inicio",
              cellType: "datetime",
            },
            {
              key: "endDate",
              intlLabelKey: "tabC.Fecha_Fin",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabItemListFirst: {
    element: {
      type: "FSTMRT",
      columns: [
        { accessorKey: "itemNumber", header: "Nro. de Ítem", size: 100 },
        {
          accessorKey: "extension",
          header: "Producto a Cotizar",
          size: 220,
          Cell: ({ cell }) => JSON.parse(cell.getValue())?.OfferedProduct,
        },
        { accessorKey: "code", header: "ID Producto", size: 120 },
        { accessorKey: "description", header: "Descripción", size: 220 },
        {
          accessorKey: "qty",
          header: "Cantidad",
          size: 70,
          textAlign: "center",
        },
        { accessorKey: "uom", header: "Unidad de Medida", size: 100 },
        {
          accessorKey: "estimatedValue",
          header: "Presupuesto Máximo",
          size: 110,
          textAlign: "center",
          Cell: ({ cell }) => formatValueLocale(cell.getValue()),
        },
        {
          accessorKey: "estimatedValueCurrency",
          header: "Moneda",
          size: 110,
        },
      ],
    },
  },

  ValueCmb_Carta: {
    objectName: "subjectsCL",
  },

  // From now on, not used.
  TabGeneralInfoLast: { elements: [] },
  TabItemListLast: { elements: [] },
  ListCompDocuments: { component: [] },
  TabProcedureDocument: { elements: [] },
  TabDocumentsFolds: { elements: [] },
  TabDocumentsParticularClauses: { elements: [] },
  TabDocumentsAnnexes: { elements: [] },
  TabDocumentsTechnicalFolds: { elements: [] },
  TabGeneralProcedureActions: { elements: [] },
  TabSupervisingJob: { elements: [] },
  TabRequirement: { elements: [] },
  ListCompWarraty: {
    listData: "StructureDataWarrantyXX",
    component: [],
  },
  TabWarranty: { elements: [] },
  TabOffersInfo: { elements: [] },
  TabContractConditions: { elements: [] },
  TabDelivery: { elements: [] },
  TabRelatedOfficials: { elements: [] },
  TabAwardedContracts: { elements: [] },
  TabAwardedPurchaseOrders: { elements: [] },
  TabItemModalDeliveryDetail: { elements: [] },
};
