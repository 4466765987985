import { create } from "zustand";
import useAxiosConfigStore from "./useAxiosConfigStore";
import useHeightStore from "./useHeightStore";
import useIntlConfigStore from "./useIntlConfigStore";
import useLoadingSpinnerStore from "./useLoadingSpinnerStore";
import useRefetchTableDataStore from "./useRefetchTableDataStore";
import useSellersStore from "./useSellersStore";
import useSnackbarStore from "./useSnackbarStore";
import useTenderInfoDetailModalStore from "./useTenderInfoDetailModalStore";
import useTenderSentEmailsStore from "./useTenderSentEmailsStore";
import useUserInfoStore from "./useUserInfoStore";
import useSettingsChangeStore from "./useSettingsChangeStore";
import useManageFiltersStore from "./useManageFiltersStore";
import useAppDataStore from "./useAppDataStore";
import useViewsStateStore from "./useViewsStateStore";

export const useGlobalStore = create((...a) => ({
  ...useSnackbarStore(...a),
  ...useLoadingSpinnerStore(...a),
  ...useRefetchTableDataStore(...a),
  ...useHeightStore(...a),
  ...useAxiosConfigStore(...a),
  ...useUserInfoStore(...a),
  ...useIntlConfigStore(...a),
  ...useTenderInfoDetailModalStore(...a),
  ...useTenderSentEmailsStore(...a),
  ...useSellersStore(...a),
  ...useSettingsChangeStore(...a),
  ...useManageFiltersStore(...a),
  ...useAppDataStore(...a),
  ...useViewsStateStore(...a),
}));

let hasFetchedData = false;

useGlobalStore.subscribe((state) => {
  if (
    state.fetchAppData &&
    state.fetchManageAvailableFilters &&
    state.fetchSellers &&
    state.fetchTenderSentEmails &&
    !hasFetchedData
  ) {
    hasFetchedData = true;
    state.fetchAppData();
    state.fetchManageAvailableFilters();
    state.fetchSellers();
    state.fetchTenderSentEmails();
  }
});
