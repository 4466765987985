import AddIcon from "@mui/icons-material/Add";
import Info from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, CardHeader, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const IncludeExcludeWordsHeader = ({
  title,
  wordsList,
  id,
  showExpand,
  expand,
  handleExpand,
  addElement,
}) => {
  const handleExpandChange = () => {
    handleExpand(id, !expand);
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    const result = wordsList.filter((item) => item.words.length > 0).length;
    setCount(result);
  }, [wordsList]);

  // Concat all words arrays and count characters
  const allWords = wordsList?.reduce((acc, item) => {
    return [...acc, ...item.words];
  }, []);
  const characterCount = allWords?.reduce((acc, item) => {
    return acc + item.length;
  }, 0);

  return (
    <CardHeader
      sx={{
        padding: "0.5rem",
        //change action css
        "& .MuiCardHeader-action": {
          flex: "0 0 auto",
          m: 0,
        },
      }}
      component="div"
      titleTypographyProps={{ component: "div" }}
      title={
        <Box
          display="flex"
          alignItems="center"
          flexDirection={"row"}
          gap={"1rem"}>
          <IconButton
            color="primary"
            onClick={() => addElement(id)}
            disabled={
              wordsList.filter((item) => item.words.length === 0).length > 0
            }>
            <AddIcon />
          </IconButton>
          <Typography variant="h6" component="span">
            {title}
            {count > 0 && (
              <span style={{ color: "red", marginLeft: "0.5rem" }}>
                ({count})
              </span>
            )}
          </Typography>
          {characterCount > 500 && (
            <Stack direction="row" alignItems="center" gap={1}>
              <Info color="error" sx={{ fontSize: "1.25rem" }} />
              <Typography variant="body2" style={{ color: "red" }}>
                {"Ha excedido el máximo de 500 caracteres"}
              </Typography>
            </Stack>
          )}
        </Box>
      }
      action={
        <>
          {showExpand && (
            <IconButton onClick={handleExpandChange}>
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </>
      }
    />
  );
};

export default IncludeExcludeWordsHeader;
