import administration from "services/administration";
import ModalCRUD from "./ModalCRUD";
import { useGlobalStore } from "global-store/useGlobalStore";

const columns = [
  {
    accessorKey: "active",
    header: "Activo",
    disableTooltip: true,
    cellType: "checkbox",
    editType: "checkbox",
    size: 104,
    excelExportFormat: ({ cell }) => (cell.getValue() ? "Sí" : "No"),
  },
  { accessorKey: "lastName", header: "Apellido" },
  { accessorKey: "firstName", header: "Nombre" },
  { accessorKey: "email", header: "Email" },
];

const messages = {
  saveSuccess: "El ejecutivo se almacenó correctamente.",
  deleteSuccess: "El ejecutivo se eliminó correctamente.",
  missingData: "Por favor ingrese el nombre y apellido del ejecutivo.",
  cannotDelete:
    "El ejecutivo no puede ser eliminado porque está siendo utilizado.",
  deleteConfirmation: (item) =>
    `¿Está seguro que desea eliminar el ejecutivo ${item?.firstName} ${item?.lastName}?`,
};

const SellerModal = ({ open, onClose }) => {
  const { fetchSellers } = useGlobalStore((state) => ({
    fetchSellers: state.fetchSellers,
  }));
  const saveVerification = (data) => {
    return data.firstName && data.lastName;
  };

  const handleDelete = async (item) => {
    const response = await administration.deleteSeller(item.sellerId);
    fetchSellers();
    return response;
  };

  const handleSave = async (data) => {
    let response = null;
    if (data.sellerId) {
      response = await administration.putSeller(data, data.sellerId);
    } else {
      response = await administration.postSeller(data);
    }
    fetchSellers();
    return response;
  };

  return (
    <ModalCRUD
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      columns={columns}
      tableInfo={{ id: "gridSellerModal", header: "Ejecutivos" }}
      modalTitle={"Ejecutivos"}
      fetchFunction={administration.getSellers}
      saveVerification={saveVerification}
      messages={messages}
    />
  );
};

export default SellerModal;
