import {
  AccessTimeOutlined,
  ErrorOutlineOutlined,
  ForumOutlined,
  PlayCircleFilledWhiteOutlined,
} from "@mui/icons-material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HistoryIcon from "@mui/icons-material/History";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { getManageStatusGroups } from "pages/TenderManagement/tenderManagementService";
import { useQuery } from "react-query";

const statusGroupsIcons = {
  0: <ViewHeadlineIcon />,
  1: <EventAvailableIcon />,
  2: <CloseIcon />,
  3: <CheckIcon />,
  4: <CheckBoxOutlineBlankIcon />,
  5: <CancelPresentationIcon />,
  6: <HistoryIcon />,
  7: <ErrorOutlineOutlined />,
  8: <ForumOutlined />,
  9: <AccessTimeOutlined />,
  10: <PlayCircleFilledWhiteOutlined />,
  99: <RemoveCircleOutlineIcon />,
};

const fetchStatusGroups = async () => {
  const response = await getManageStatusGroups();
  let statusGroupsWithIcons = response.data?.statusGroups.map((item) => ({
    ...item,
    icon: statusGroupsIcons[item.statusId],
  }));
  return statusGroupsWithIcons;
};

const useManageStatusGroups = () => {
  return useQuery({
    queryKey: "manageStatusGroups",
    queryFn: fetchStatusGroups,
    staleTime: Infinity, // The data will never go stale
    cacheTime: Infinity, // The data will never be garbage collected
  });
};

export default useManageStatusGroups;
