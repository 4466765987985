import PersonIcon from "@mui/icons-material/Person";
import { Box, Button } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import { Fragment, useState } from "react";
import AddUserModal from "./AddUserModal";
import {
  addOrUpdateUserAppModules,
  enableDisableUser,
  listUsers,
  updateUser,
} from "../settingsService";

const Users = (props) => {
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const {
    setSnackbarSaveChanges,
    setSnackbarErrorCustom,
    showSavingStateSpinner,
    setShouldRefetch,
    userInfo,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    setSnackbarErrorCustom: state.setSnackbarErrorCustom,
    showSavingStateSpinner: state.showSavingStateSpinner,
    setShouldRefetch: state.setShouldRefetch,
    userInfo: state.userInfo,
  }));

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };

  const handleDelete = async () => {
    showSavingStateSpinner();
    // TODO: Delete User when implemented
    setModalDeleteConfirm(false);
    setSnackbarSaveChanges();
    setShouldRefetch();
  };

  const validateEmail = (email) => {
    const emailValid =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      );
    return emailValid;
  };

  const handleSave = async (item) => {
    // if (item.applicationModulesIds.length === 0) {
    //   setSnackbarErrorCustom("Debe seleccionar al menos un módulo");
    //   return false;
    // }
    if (!validateEmail(item.email)) {
      setSnackbarErrorCustom("El correo electrónico no es válido");
      return false;
    }

    showSavingStateSpinner();
    const appModulesRequest = {
      userId: item.userId,
      applicationModuleIds: item.applicationModulesIds,
    };
    await addOrUpdateUserAppModules(appModulesRequest);

    const activeUserRequest = {
      userId: item.userId,
      isEnabled: item.active,
    };
    await enableDisableUser(activeUserRequest);

    const editMailRequest = {
      active: item.active,
      email: item.email,
      firstName: item.firstName,
      lastName: item.lastName,
      login: item.login,
      phone: item.phone,
      roleId: item.roleId,
      userId: item.userId,
    };
    await updateUser(editMailRequest);

    setSnackbarSaveChanges();
    return true;
  };

  const handleAddNewUser = async (user) => {
    user.applicationModules = user.applicationModulesIds.map((id) => {
      return userInfo.applicationModules?.find(
        (appModule) => appModule.id === id,
      );
    });
    delete user.applicationModulesIds;
    // password and confPass?

    //TODO: Enable when implemented.
    // showSavingStateSpinner();
    // const response = await addOrUpdateUser(user);
    // setSnackbarSaveChanges();
    // setShouldRefetch();
  };

  const fetchTableData = async (page, pageSize, filter) => {
    const users = await listUsers();
    // add ApplicationModulesIds to the users (used for the edit cell)
    users.data = users.data.map((user) => {
      user.applicationModulesIds = user.applicationModules.map(
        (appModule) => appModule.id,
      );
      return user;
    });
    return {
      result: users.data,
      total: users.data.length,
    };
  };

  const columns = [
    {
      accessorKey: "firstName",
      header: "Nombre",
      enableEditing: false,
    },
    {
      accessorKey: "lastName",
      header: "Apellido",
      enableEditing: false,
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "active",
      header: "Estado",
      disableTooltip: true,
      cellType: "active-inactive",
      editType: "active-inactive",
      enableEditing: userInfo.isAdmin,
      excelExportFormat: ({ cell }) =>
        cell.getValue() ? "Activo" : "Inactivo",
    },
    {
      accessorKey: "applicationModulesIds",
      header: "Módulos",
      disableTooltip: true,
      Cell: ({ cell }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}>
          {cell.getValue()?.map((module, idx) => (
            <span
              className="btn-pill badge badge-info"
              key={idx}
              style={{ fontSize: 14 }}>
              {
                cell.row.original.applicationModules.find(
                  (appModule) => appModule.id === module,
                )?.name
              }
            </span>
          ))}
        </Box>
      ),
      editType: "multiselect",
      editOptions: userInfo?.applicationModules,
      excelExportFormat: ({ cell }) => {
        return cell
          .getValue()
          ?.map((module) => {
            return cell.row.original.applicationModules.find(
              (appModule) => appModule.id === module,
            )?.name;
          })
          .join(", ");
      },
    },
  ];

  const options = {
    tableId: "gridSettingsUsers",
    header: columns,
    fullHeight: true,
    tableHeaderTitle: "Usuarios",
    toolbarButtons: {
      // Add: true, // TODO: Enable when add user is implemented. Currently the flow is create in Azure first then Sync. Once the user is created in Azure and synced, it appears here.
      // AddFunc: () => setModalAddOpen(true),
      Export: true,
    },
    enableEditing: true,
    // enableDeleting: true, //TODO: Enable when delete user is implemented.
    // deleteFunc: (row) => {
    //   setItemToDelete(row);
    //   setModalDeleteConfirm(true);
    // },
    saveFunc: handleSave,
  };

  const [modalAddOpen, setModalAddOpen] = useState(false);

  return (
    <Fragment>
      <PageTitle
        titleHeading="Usuarios"
        titleDescription="Administrar usuarios"
        icon={<PersonIcon />}
      />
      <FSTMaterialReactTable options={options} getData={fetchTableData} />

      {/* <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={`¿Está seguro que desea eliminar el usuario ${itemToDelete?.firstName} ${itemToDelete?.lastName}?`}
      /> */}
      <AddUserModal
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
        onSave={(newUserForm) => {
          handleAddNewUser(newUserForm);
        }}
        appModules={userInfo?.applicationModules}
      />
    </Fragment>
  );
};

export default Users;
