import {
  newFulfilledAction,
  newPendingAction,
  newRejectedAction,
} from "../utils/asyncActionGenerator";
import { post } from "config/axios";

export const userActionTypes = {
  LOGIN: "LOGIN",
};

const logToInsights = (insights, data) => {
  const { clientSettings, ...rest } = data;
  insights.trackEvent({
    name: "User Login/Page reload",
    properties: { ...rest, url: window.location.href },
  });
};

export function setupUserData(insights) {
  return async (dispatch) => {
    dispatch(newPendingAction(userActionTypes.LOGIN));
    try {
      const axiosResult = await post("/api/Login/Login");
      setLocalStore(axiosResult.data);
      const { status } = axiosResult;
      if (status !== 401) {
        logToInsights(insights, axiosResult.data);
        return dispatch(
          newFulfilledAction(userActionTypes.LOGIN, axiosResult.data),
        );
      } else {
        throw new Error(`/api/Login/Login returned status code ${status}`);
      }
    } catch (error) {
      return dispatch(
        newRejectedAction(
          userActionTypes.LOGIN,
          `Error getting user data. ${error}`,
        ),
      );
    }
  };
}

export function setLocalStore(result) {
  const settingClient = result.clientSettings;
  if (settingClient !== null) {
    Object.keys(settingClient).filter((key) => {
      const value = settingClient[key].map((item) => JSON.stringify(item));
      localStorage.setItem(key, value);
    });
  }
}
