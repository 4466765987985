import { useIntl } from "react-intl";

export const useOutsiderTenderIntlColumnsCL = () => {
  const intl = useIntl();

  const getTenderValues = () => [
    // tender
    {
      columnValue: "tenderNumber",
      columnName: `${intl.formatMessage({
        id: "cols.Convocatoria",
        defaultMessage: "ID Convenio",
      })}`,
      required: true,
    },
    {
      columnValue: "tenderName",
      columnName: `${intl.formatMessage({
        id: "cols.Nombre",
        defaultMessage: "Nombre",
      })}`,
      required: true,
    },
    {
      columnValue: "publishedDate",
      columnName: `${intl.formatMessage({
        id: "cols.Publicacion",
        defaultMessage: "Publicación",
      })}`,
      required: true,
      type: "date",
    },
    {
      columnValue: "endDate",
      columnName: `${intl.formatMessage({
        id: "cols.Cierre",
        defaultMessage: "Cierre",
      })}`,
      required: true,
      type: "date",
    },
    {
      columnValue: "tenderDescription",
      columnName: `${intl.formatMessage({
        id: "cols.OContratacion",
        defaultMessage: "Objetivo de la Contratación",
      })}`,
      required: true,
    },
    {
      columnValue: "link",
      columnName: `${intl.formatMessage({
        id: "cols.Link",
        defaultMessage: "Link",
      })}`,
      required: false,
    },
    {
      columnValue: "currency",
      columnName: `${intl.formatMessage({
        id: "cols.Moneda",
        defaultMessage: "Moneda",
      })}`,
      required: true,
      type: "select",
      options: [],
    },
    {
      columnValue: "estimatedValue",
      columnName: `${intl.formatMessage({
        id: "cols.Referencial",
        defaultMessage: "Presupuesto máximo",
      })} (Número)`,
      type: "number",
      required: true,
    },
    // {
    //   columnValue: "clientUnitUniqueId",
    //   columnName: `ID único de ${intl.formatMessage({
    //     id: "cols.Entidad",
    //     defaultMessage: "Organismo Público Solicitante",
    //   })}`,
    //   required: true,
    // },
    {
      columnValue: "clientName",
      columnName: `${intl.formatMessage({
        id: "cols.ClientName",
        defaultMessage: "Organismo Público Solicitante",
      })}`,
      required: true,
    },
    // {
    //   columnValue: "clientUniqueId",
    //   columnName: `ID único de ${intl.formatMessage({
    //     id: "cols.ClientName",
    //     defaultMessage: "Unidad de Compra Solicitante",
    //   })}`,
    //   required: true,
    // },
    {
      columnValue: "clientUnitName",
      columnName: `${intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Unidad de Compra Solicitante",
      })}`,
      required: true,
    },
    {
      columnValue: "mail",
      columnName: `Mail`,
      required: false,
    },
    {
      columnValue: "tenderStatus",
      columnName: `${intl.formatMessage({
        id: "cols.Estado",
        defaultMessage: "Estado",
      })}`,
      required: true,
      type: "select",
      options: [],
    },
    // {
    //   columnValue: "", //??
    //   columnName: `${intl.formatMessage({id: "tabC.PlazoMaxEmpezarTrabajo",defaultMessage: "Plazo máximo para empezar trabajos"})}`,
    //   required: true,
    // },
    // {
    //   columnValue: "", //??
    //   columnName: `${intl.formatMessage({id: "tabC.PlazoMaxEjecucionTrabajo",defaultMessage: "Plazo máximo para ejecución de los trabajos"})}`,
    //   required: true,
    // },

    // {
    //   columnValue: "fileNumber",
    //   columnName: `${intl.formatMessage({id: "cols.Numenclatura",defaultMessage: "Nomenclatura"})}`,
    //   required: true,
    // },

    //estos no irian?
    // {
    //   columnValue: "tenderType",
    //   columnName: `${intl.formatMessage({id: "cols.Tipo",defaultMessage: "Tipo"})}`,
    //   required: true,
    //   type: "select",
    //   options: [],
    // },
    // {
    //   columnValue: "clientUnitUniqueId",
    //   columnName: `ID único de ${intl.formatMessage({id: "cols.Entidad",defaultMessage: "Entidad"})} (CUIT)`, // (CUIT) es solo ARG, para otros paises luego ver
    //   required: true,
    // },
    // {
    //   columnValue: "awardDate",
    //   columnName: `Fecha de Adjudicación`,
    //   required: false,
    //   type: "date",
    // },
  ];

  const getTenderDetailValues = () => [
    //tender details
    {
      columnValue: "tenderDetailItemNumber",
      columnName: `${intl.formatMessage({
        id: "tabL.Nro",
        defaultMessage: "Nro. de Item",
      })} (Número)`,
      type: "number",
      required: true, // must be true
    },
    {
      // Producto a cotizar , req:true, tenderdetailExtension
      columnValue: "tenderDetailOfferedProduct",
      columnName: `${intl.formatMessage({
        id: "tabL.Producto",
        defaultMessage: "Producto a Cotizar",
      })}`,
      required: true,
    },
    {
      columnValue: "tenderDetailCode",
      columnName: `${intl.formatMessage({
        id: "tabL.Codigo_Cubso",
        defaultMessage: "Código CUBSO",
      })}`,
      required: false,
    },
    {
      columnValue: "tenderDetailQty",
      columnName: `${intl.formatMessage({
        id: "tabL.Cantidad",
        defaultMessage: "Cantidad",
      })} (Número)`,
      type: "number",
      required: true, // must be true
    },
    {
      columnValue: "tenderDetailUom",
      columnName: `${intl.formatMessage({
        id: "tabL.Unidad",
        defaultMessage: "Unidad de Medida",
      })}`,
      required: false, // must be true
    },
    {
      columnValue: "tenderDetailDescription",
      columnName: `${intl.formatMessage({
        id: "tabL.Descripcion",
        defaultMessage: "Descripción",
      })}`,
      required: true, // must be true
    },
    {
      columnValue: "tenderDetailEstimatedValue",
      columnName: `${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })} (Número)`,
      type: "number",
      required: false,
    },
    {
      columnValue: "tenderDetailEstimatedValueCurrency",
      columnName: `Moneda del ${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })}`,
      required: false,
      type: "select",
      options: [],
    },
  ];

  //tender manage
  const getTenderManageValues = () => [
    // EJECUTIVO  (debe existir en el sistema) --> Sellers
    {
      columnValue: "tenderManageSeller",
      columnName: "Ejecutivo",
      required: false,
      type: "select",
      options: [],
    },
    // SEGMENTO DE MERCADO (debe existir en el sistema) --> Categories
    {
      columnValue: "tenderManageCategory",
      columnName: "Segmento de Mercado",
      required: false,
      type: "select",
      options: [],
    },
  ];

  const getTenderManageDetailValues = () => [
    //tender manage detail
    {
      columnValue: "tenderManageDetailOffered",
      columnName: `Cotizado`,
      required: true,
      type: "select",
      options: [
        { id: true, name: "Si" },
        { id: false, name: "No" },
      ],
    },
    // FECHA DE COTIZACION
    {
      columnValue: "tenderManageDetailOfferedDate",
      columnName: `Fecha de Cotización`,
      required: false,
      type: "date",
    },
    // PRODUCT ID (debe existir en el catalogo del cliente) (usamos code)
    {
      columnValue: "tenderManageDetailProductCode",
      columnName: `Código de Ítem`,
      required: false,
      type: "productAutocomplete",
    },
    {
      columnValue: "tenderManageDetailEstimatedTotal",
      columnName: `${intl.formatMessage({
        id: "tabL.Estimado",
        defaultMessage: "Precio Presupuestado",
      })}`,
      type: "number",
      required: true,
    },
  ];

  const getMinimumColumns = () => [
    // tender
    {
      columnValue: "publishedDate",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Publicacion",
        defaultMessage: "Publicación",
      })} (Fecha)`,
      columnPosition: 0,
      modifiable: false,
    },
    {
      columnValue: "endDate",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Cierre",
        defaultMessage: "Cierre",
      })} (Fecha)`,
      columnPosition: 1,
      modifiable: false,
    },
    {
      columnValue: "tenderNumber",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Convocatoria",
        defaultMessage: "ID Convenio",
      })} (Texto)`,
      columnPosition: 2,
      modifiable: false,
    },
    {
      columnValue: "tenderName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Nombre",
        defaultMessage: "Nombre",
      })} (Texto)`,
      columnPosition: 3,
      modifiable: false,
    },
    {
      columnValue: "tenderDescription",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Descripcion",
        defaultMessage: "Descripción",
      })} (Texto)`,
      columnPosition: 4,
      modifiable: false,
    },
    {
      columnValue: "clientUnitName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Unidad de Compra Solicitante",
      })} (Texto)`,
      columnPosition: 5,
      modifiable: false,
    },
    {
      columnValue: "clientName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.ClientName",
        defaultMessage: "Organismo Público Solicitante",
      })} (Texto)`,
      columnPosition: 6,
      modifiable: false,
    },
    {
      columnValue: "estimatedValue",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Referencial",
        defaultMessage: "Valor Estimado",
      })} (Número)`,
      columnPosition: 7,
      modifiable: false,
    },
    {
      columnValue: "currency",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Moneda",
        defaultMessage: "Moneda",
      })} (Texto, opciones provistas)`,
      columnPosition: 8,
      modifiable: false,
    },
    {
      columnValue: "tenderStatus",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Estado",
        defaultMessage: "Estado",
      })} (Texto, opciones provistas)`,
      columnPosition: 9,
      modifiable: false,
    },
    {
      columnValue: "link",
      columnName: "Licitación: Link (Texto)",
      columnPosition: 10,
      modifiable: false,
    },
    // {
    //   columnValue: "clientUnitUniqueId",
    //   columnName: `Licitación: ID único de ${intl.formatMessage({
    //     id: "cols.Entidad",
    //     defaultMessage: "Organismo Público Solicitante",
    //   })} (Texto/Número)`,
    //   columnPosition: 6,
    //   modifiable: false,
    // },
    // {
    //   columnValue: "clientUniqueId",
    //   columnName: `Licitación: ID único de ${intl.formatMessage({
    //     id: "cols.ClientName",
    //     defaultMessage: "Unidad de Compra Solicitante",
    //   })} (Texto/Número)`,
    //   columnPosition: 8,
    //   modifiable: false,
    // },

    // {
    //   columnValue: "", //??
    //   columnName: `${intl.formatMessage({id: "tabC.PlazoMaxEmpezarTrabajo",defaultMessage: "Plazo máximo para empezar trabajos"})}`,
    //   columnPosition: 10,
    //   modifiable: false,
    // },
    // {
    //   columnValue: "", //??
    //   columnName: `${intl.formatMessage({id: "tabC.PlazoMaxEjecucionTrabajo",defaultMessage: "Plazo máximo para ejecución de los trabajos"})}`,
    //   columnPosition: 11,
    //   modifiable: false,
    // },

    //tender details
    {
      columnValue: "tenderDetailOfferedProduct",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "tabL.Producto",
        defaultMessage: "Producto a Cotizar",
      })} (Texto)`,
      columnPosition: 11,
      modifiable: false,
    },
    {
      columnValue: "tenderDetailQty",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "cols.Cantidad",
        defaultMessage: "Cantidad",
      })} (Número)`,
      columnPosition: 12,
      modifiable: false,
    },
    {
      columnValue: "tenderDetailDescription",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "tabL.Descripcion",
        defaultMessage: "Descripción",
      })} (Texto)`,
      columnPosition: 13,
      modifiable: false,
    },

    //tender manage detail
    {
      columnValue: "tenderManageDetailEstimatedTotal",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Precio Presupuestado (Número)`,
      columnPosition: 14,
      modifiable: false,
    },
  ];

  const getExtraColumns = () => [
    //tender

    // {
    //   columnValue: "sourceName",
    //   columnName: `Licitación: ${intl.formatMessage({
    //     id: "cols.Portal",
    //     defaultMessage: "Portal",
    //   })} (Texto)`,
    // },
    {
      columnValue: "mail",
      columnName: `Licitación: Mail (Texto)`,
    },

    //tender detail
    {
      columnValue: "tenderDetailItemNumber",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "cols.Item",
        defaultMessage: "Item",
      })} (Número)`,
    },

    {
      columnValue: "tenderDetailCode",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "tabL.Codigo_Cubso",
        defaultMessage: "Código CUBSO",
      })} (Texto)`,
    },

    {
      columnValue: "tenderDetailUom",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "cols.Unidad",
        defaultMessage: "Unidad",
      })} (Texto)`,
    },

    {
      columnValue: "tenderDetailEstimatedValue",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })} (Número)`,
    },
    {
      columnValue: "tenderDetailEstimatedValueCurrency",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Moneda del ${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })} (Texto, opciones provistas)`,
    },

    //manage
    // EJECUTIVO  (debe existir en el sistema) --> Sellers
    {
      columnValue: "tenderManageSeller",
      columnName: "Gestión: Ejecutivo (Texto)",
    },
    // SEGMENTO DE MERCADO (debe existir en el sistema) --> Categories
    {
      columnValue: "tenderManageCategory",
      columnName: "Gestión: Segmento de Mercado (Texto)",
    },

    //manage detail
    {
      columnValue: "tenderManageDetailOffered",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Cotizado (Si/No)`,
    },
    // FECHA DE COTIZACION
    {
      columnValue: "tenderManageDetailOfferedDate",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Fecha de Cotización (Fecha)`,
    },
    {
      columnValue: "tenderManageDetailProductCode",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Código de Ítem (Texto)`,
    },
  ];

  const tenderValues = getTenderValues();
  const tenderDetailValues = getTenderDetailValues();
  const tenderDetailAwardValues = [];
  const tenderManageValues = getTenderManageValues();
  const tenderManageDetailValues = getTenderManageDetailValues();
  const minimumColumns = getMinimumColumns();
  const extraColumns = getExtraColumns();

  return {
    tenderValues,
    tenderDetailValues,
    tenderDetailAwardValues,
    tenderManageValues,
    tenderManageDetailValues,
    minimumColumns,
    extraColumns,
  };
};
