import { Fragment, useEffect, useState } from "react";

import { PageTitle } from "../../layout-components";

import HomeManagementStats from "../../components/Home/HomeManagementStats";
import HomeSettingsStats from "../../components/Home/HomeSettingsStats";
import HomeSmartSearchStats from "../../components/Home/HomeSmartSearchStats";

import HomeIcon from "@mui/icons-material/Home";
import { useGlobalStore } from "global-store/useGlobalStore";
import { getSources } from "pages/SmartSearch/smartSearchService";
import HomePageLoadingState from "./HomePageLoadingState";
import { getHomeStats } from "./homeService";

export default function Home() {
  const userInfo = useGlobalStore((state) => state.userInfo);
  const { applicationModules } = userInfo;
  let availableModules = { SS: false, GL: false, Settings: true, AL: false };
  applicationModules?.forEach((module) => {
    availableModules[module.name] = true;
  });

  const [homeStats, setHomeStats] = useState(null);
  const [sources, setSources] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchInitialData = async () => {
    const [homeStats, sources] = await Promise.all([
      getHomeStats(),
      getSources(),
    ]);

    setHomeStats(homeStats.data);
    setSources(sources.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  let isLoading = (!homeStats || !sources) && loading;

  if (isLoading) {
    return <HomePageLoadingState availableModules={availableModules} />;
  }

  return (
    <Fragment>
      <PageTitle
        icon={<HomeIcon />}
        titleHeading="Inicio"
        titleDescription="Falconsoft Tenders"
      />
      {availableModules.SS && (
        <HomeSmartSearchStats
          homeStats={homeStats}
          sources={sources}
          loading={isLoading}
        />
      )}
      {availableModules.GL && (
        <HomeManagementStats
          homeStats={homeStats}
          sources={sources}
          loading={isLoading}
        />
      )}
      {availableModules.Settings && (
        <HomeSettingsStats
          sources={sources}
          loading={isLoading}
          ss={availableModules.SS}
        />
      )}
    </Fragment>
  );
}
