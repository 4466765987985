import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import NumericField from "components/CustomFields/NumericField";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { listPenaltyReasons } from "pages/TenderInformationContent/tenderInformationContentService";
import { Fragment, useEffect, useState } from "react";

const penaltyDefault = {
  customerName: "",
  customerCode: "",
  saleOrder: "",
  purchaseOrderCode: "",
  invoiceNumber: "",
  invoiceDate: new Date(),
  invoiceAmount: "",
  penaltyAmount: "",
  responsible: "",
  caseNumber: "",
  reason: null,
  isTender: false,
  observations: "",
};

const PenaltiesManageFormModal = ({ open, onClose, onSave }) => {
  const [penalty, setPenalty] = useState(penaltyDefault);
  const [penaltyReasons, setPenaltyReasons] = useState([]); // [{penaltyReasonId, description}]

  const initPenaltyReasons = async () => {
    const response = await listPenaltyReasons();
    setPenaltyReasons(response.data);
  };

  useEffect(() => {
    if (open) initPenaltyReasons();
  }, [open]);

  const handlePenaltyChange = (event) => {
    const { name, value } = event.target;
    setPenalty({ ...penalty, [name]: value });
  };

  const handleSave = async () => {
    const request = {
      clientUnitId: null,
      penaltyReasonId: penalty.reason ? penalty.reason.penaltyReasonId : null,
      TenderId: penalty.isTender ? 1 : 0,
      Data: JSON.stringify({
        customerName: penalty.customerName,
        customerCode: penalty.customerCode,
        saleOrder: penalty.saleOrder,
        purchaseOrderCode: penalty.purchaseOrderCode,
        invoiceNumber: penalty.invoiceNumber,
        invoiceDate: penalty.invoiceDate
          ? penalty.invoiceDate.toISOString()
          : null,
        invoiceAmount: penalty.invoiceAmount,
        penaltyAmount: penalty.penaltyAmount,
        responsible: penalty.responsible,
        caseNumber: penalty.caseNumber,
        description: penalty.observations,
      }),
    };

    onSave([request]);
  };

  const handleClose = () => {
    setPenalty(penaltyDefault);
    onClose();
  };

  return (
    <Fragment>
      <GeneralPurposeModal
        open={open}
        onClose={handleClose}
        closeText="Cancelar"
        title={"Agregar Penalidad"}
        maxWidth="md"
        onSave={handleSave}
        onSaveDisabled={
          !penalty.reason || !penalty.penaltyAmount || !penalty.invoiceDate
        }>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Cliente"
              name="customerName"
              value={penalty.customerName}
              onChange={handlePenaltyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Cod. Cliente"
              name="customerCode"
              value={penalty.customerCode}
              onChange={handlePenaltyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="SO"
              name="saleOrder"
              value={penalty.saleOrder}
              onChange={handlePenaltyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Orden de Compra"
              name="purchaseOrderCode"
              value={penalty.purchaseOrderCode}
              onChange={handlePenaltyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Número de Factura"
              name="invoiceNumber"
              value={penalty.invoiceNumber}
              onChange={handlePenaltyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Fecha de Factura"
                value={penalty.invoiceDate}
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setPenalty({ ...penalty, invoiceDate: newValue });
                }}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumericField
              label="Valor de Factura"
              name="invoiceAmount"
              value={penalty.invoiceAmount}
              onValueChange={(values) => {
                setPenalty({ ...penalty, invoiceAmount: values.floatValue });
              }}
              fullWidth
              prefix="$"
              decimalSeparator="."
              thousandSeparator=","
              decimalScale={2}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumericField
              label="Valor de Penalidad"
              name="penaltyAmount"
              value={penalty.penaltyAmount}
              onValueChange={(values) => {
                setPenalty({ ...penalty, penaltyAmount: values.floatValue });
              }}
              fullWidth
              prefix="$"
              decimalSeparator="."
              thousandSeparator=","
              decimalScale={2}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Responsable"
              name="responsible"
              value={penalty.responsible}
              onChange={handlePenaltyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Case # SF"
              name="caseNumber"
              value={penalty.caseNumber}
              onChange={handlePenaltyChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={penaltyReasons}
              value={penalty.reason}
              getOptionLabel={(option) => option.description}
              isOptionEqualToValue={(option, value) =>
                option.penaltyReasonId === value.penaltyReasonId
              }
              onChange={(event, newValue) => {
                setPenalty({ ...penalty, reason: newValue });
              }}
              renderInput={(params) => <TextField {...params} label="Motivo" />}
            />
          </Grid>
          <Grid item xs={12} sm={4} alignSelf="center" textAlign="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={penalty.isTender}
                  onChange={(event) => {
                    setPenalty({ ...penalty, isTender: event.target.checked });
                  }}
                  name="isTender"
                  color="primary"
                />
              }
              label="Licitación"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Observaciones"
              name="observations"
              value={penalty.observations}
              onChange={handlePenaltyChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </GeneralPurposeModal>
    </Fragment>
  );
};

export default PenaltiesManageFormModal;
