import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components/index";
import { Fragment, useState } from "react";
import {
  addOrUpdateSearchFilter,
  deleteSearchFilter,
  listSearchFilters,
  updateDaysToAllFilters,
} from "../smartSearchService";
import AdvancedOptionsModal from "./AdvancedOptionsModal";
import ManageSmartSearchModal from "./ManageSmartSearchModal";
import { useIntl } from "react-intl";

const SmartSearchFiltersAdmin = () => {
  const intl = useIntl();

  const handleList = (list) => {
    let aux = "";
    for (let elem of list) {
      if (aux.length > 0) {
        aux = ` ${aux}@${elem.join("|")}`;
      } else {
        aux = elem.join("|");
      }
    }
    return aux;
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Nombre",
      size: 200,
      allowSorting: true,
      showInColumnChooser: false,
    },
    {
      accessorKey: "includeWords",
      header: `Incluir por ${intl.formatMessage({
        id: "filtros.IncluirExcluirItem",
        defaultMessage: "Ítem",
      })}`,
      size: 200,
      Cell: ({ cell, column }) => handleList(cell.getValue()),
    },
    {
      accessorKey: "excludeWords",
      header: `Excluir por ${intl.formatMessage({
        id: "filtros.IncluirExcluirItem",
        defaultMessage: "Ítem",
      })}`,
      size: 200,
      Cell: ({ cell, column }) => handleList(cell.getValue()),
    },
    {
      accessorKey: "includeWordsName",
      header: `Incluir por ${intl.formatMessage({
        id: "filtros.IncluirExcluirNombre",
        defaultMessage: "Nombre",
      })}`,
      size: 200,
      Cell: ({ cell, column }) => handleList(cell.getValue()),
    },
    {
      accessorKey: "excludeWordsName",
      header: `Excluir por ${intl.formatMessage({
        id: "filtros.IncluirExcluirNombre",
        defaultMessage: "Nombre",
      })}`,
      size: 200,
      Cell: ({ cell, column }) => handleList(cell.getValue()),
    },
    {
      accessorKey: "days",
      header: "Cantidad de días",
      size: 140,
      textAlign: "Center",
    },
    {
      accessorKey: "creation",
      header: "Fecha de creación",
      size: 170,
      cellType: "datetime",
    },
    {
      accessorKey: "createdBy",
      header: "Creado por",
      size: 140,
    },
    {
      accessorKey: "lastUpdate",
      header: "Fecha de modificación",
      size: 170,
      cellType: "datetime",
    },
    {
      accessorKey: "updatedBy",
      header: "Modificado por",
      size: 140,
    },
  ];

  const {
    setSnackbarSaveChanges,
    showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    setShouldRefetch,
    setSnackbar,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setShouldRefetch: state.setShouldRefetch,
    setSnackbar: state.setSnackbar,
  }));

  const fetchTableData = async (page, pageSize, filter, sorting) => {
    let orderBy = sorting.length > 0 ? sorting[0].id : "lastUpdate";
    let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;
    const response = await listSearchFilters({
      pageNumber: page,
      pageSize: pageSize,
      orderBy: orderBy,
      orderAsc: orderAsc,
      filter: filter,
    });

    return {
      result: response.data.currentPageItems,
      total: response.data.totalItems,
    };
  };

  const [modalFilterManageOpen, setModalFilterManageOpen] = useState(false);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToModify, setItemToModify] = useState(null);

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };
  const handleCloseModalFilterManage = () => {
    setModalFilterManageOpen(false);
    setItemToModify(null);
  };

  const handleDelete = async () => {
    const request = [{ SearchFilterId: itemToDelete.searchFilterId }];
    showSavingStateSpinnerNoTimeout();
    await deleteSearchFilter(request);
    hideLoadingSpinner();
    setSnackbarSaveChanges();
    handleCloseModalDeleteConfirm();
    setShouldRefetch();
  };

  const handleSave = async (request) => {
    showSavingStateSpinnerNoTimeout();
    return addOrUpdateSearchFilter(request)
      .then(() => {
        hideLoadingSpinner();
        setSnackbarSaveChanges();
        handleCloseModalFilterManage();
        setShouldRefetch();
        return true;
      })
      .catch((error) => {
        hideLoadingSpinner();
        if (error.msgErrorKey === "ENTITY_ALREADY_EXISTS") {
          setSnackbar({
            open: true,
            severity: "warning",
            title: "Advertencia",
            subTitle:
              "Ya existe un filtro con el mismo nombre. Por favor, elija otro.",
          });
        }
        return false;
      });
  };

  const options = {
    tableId: "gridSmartSearchFiltersAdmin",
    header: columns,
    fullHeight: true,
    tableHeaderTitle: "Administración de Filtros",
    toolbarButtons: {
      Add: true,
      AddFunc: () => setModalFilterManageOpen(true),
      Export: true,
      ExportFilename: "SmartSearch - Filtros",
      AdvancedOptions: true,
      AdvancedOptionsFunc: () => setModalAdvancedOptionsOpen(true),
    },
    enableEditing: true,
    enableDeleting: true,
    editFunc: (row) => {
      setItemToModify(row);
      setModalFilterManageOpen(true);
    },
    deleteFunc: (row) => {
      setItemToDelete(row);
      setModalDeleteConfirm(true);
    },
  };

  // -- Advanced Options Modal -- //
  const [modalAdvancedOptionsOpen, setModalAdvancedOptionsOpen] =
    useState(false);

  const handleCloseModalAdvancedOptions = () => {
    setModalAdvancedOptionsOpen(false);
  };

  const handleSaveAdvancedOptions = async (request) => {
    showSavingStateSpinnerNoTimeout();
    await updateDaysToAllFilters(request);
    hideLoadingSpinner();
    setSnackbarSaveChanges();
    handleCloseModalAdvancedOptions();
    setShouldRefetch();
  };
  // --  -- //

  return (
    <Fragment>
      <PageTitle
        titleHeading="Administración de Filtros"
        titleDescription="Aquí puede administrar los filtros de búsqueda."
        icon={<FilterAltIcon />}
      />
      <FSTMaterialReactTable options={options} getData={fetchTableData} />

      <ManageSmartSearchModal
        open={modalFilterManageOpen}
        onClose={handleCloseModalFilterManage}
        onSave={handleSave}
        filter={itemToModify}
      />

      <AdvancedOptionsModal
        open={modalAdvancedOptionsOpen}
        onClose={handleCloseModalAdvancedOptions}
        onSave={handleSaveAdvancedOptions}
      />

      <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={`¿Está seguro que desea eliminar el filtro ${itemToDelete?.name}?`}
      />
    </Fragment>
  );
};

export default SmartSearchFiltersAdmin;
