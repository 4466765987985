import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import hero1 from "../../../assets/images/hero-bg/hero-1.jpg";
import TenderGeneralInfo from "../../TenderInformation/TenderGeneralInfo";
import { getTenderData } from "../smartSearchService";
import SmartSearchTabsContainer from "./SmartSearchTabsContainer";

const SmartSearchInfoModal = ({ open, handleClose, tenderInfo }) => {
  const [tenderData, setTenderData] = useState({});
  const [finishedLoading, setFinishedLoading] = useState(false);
  const intl = useIntl();

  const getTenderDataInfo = async () => {
    setFinishedLoading(false);
    const response = await getTenderData(tenderInfo.tenderNumber);
    setTenderData(response.data);
    setFinishedLoading(true);
  };

  useEffect(() => {
    if (tenderInfo && open) {
      getTenderDataInfo();
    }
  }, [open]);

  return (
    <Fragment>
      <Dialog
        scroll="body"
        maxWidth="lg" // == 1280px
        open={open}
        onClose={handleClose}
        fullWidth>
        <DialogTitle
          id="form-dialog-title"
          className="bg-composed-wrapper bg-plum-plate h-100"
          sx={{ justifyContent: "space-between" }}>
          <div
            className="bg-composed-wrapper--image "
            style={{ backgroundImage: "url(" + hero1 + ")", zIndex: "0" }}
          />
          <span className="display-4 font-weight-bold text-white pb-0">
            Info del Portal
          </span>
          <DialogActions sx={{ height: "33px" }}>
            <IconButton
              onClick={handleClose}
              className="text-white"
              sx={{ height: "inherit" }}>
              <FontAwesomeIcon icon={["fas", "times"]} />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <Grid container spacing={0} direction="column" maxWidth={"1280px"}>
          <Grid item xs={12} lg={5} width={"100%"}>
            <TenderGeneralInfo
              tenderData={tenderData}
              finishedLoading={finishedLoading}
            />
          </Grid>
          <div
            className="divider border-2 my-2 border-light rounded"
            style={{ width: "95%", display: "flex", alignSelf: "center" }}
          />
          <Grid item xs={12} lg={7}>
            <SmartSearchTabsContainer
              tenderData={tenderData}
              finishedLoading={finishedLoading}
            />
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default SmartSearchInfoModal;
