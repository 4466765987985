import {
  Box,
  DialogContentText,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { getDeleteReasons } from "pages/Administration/administrationServices";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import { deleteTender } from "./tenderManagementService";

const MAX_CHARS = 500;

const DeleteTenderModal = ({ open, onClose, tenderToDelete }) => {
  const [loading, setLoading] = useState(false);
  const [deleteReasons, setDeleteReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState({
    deleteReasonId: 0,
    description: "",
  });
  const setSnackbarSaveChanges = useGlobalStore(
    (state) => state.setSnackbarSaveChanges,
  );

  const handleChange = (e) => {
    setSelectedReason({
      ...selectedReason,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    onClose(false);
    setSelectedReason({ deleteReasonId: 0, description: "" });
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const payload = {
      tenderNumber: tenderToDelete?.tenderNumber,
      deleteReason: selectedReason.deleteReasonId,
      deleteComment: selectedReason.description,
    };
    await deleteTender([payload]);
    setSnackbarSaveChanges();
    onClose(true);
    setLoading(false);
  };

  const fetchDeleteReasons = async () => {
    const response = await getDeleteReasons();
    setDeleteReasons(response.data);
  };

  useEffect(() => {
    if (open) fetchDeleteReasons();
    // TODO: Maybe add this check as well: && deleteReasons.length === 0)
  }, [open]);

  return (
    <GeneralPurposeModal
      open={open}
      onClose={handleClose}
      title="Confirmar eliminación"
      closeText={"Cancelar"}
      maxWidth="sm"
      onSave={handleSave}
      onSaveDisabled={loading || !selectedReason.deleteReasonId}
      saveText="Confirmar">
      <DialogContentText sx={{ mb: 2 }}>
        Ha seleccionado eliminar la licitación "{tenderToDelete?.fileNumber}"
      </DialogContentText>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{ mb: 2 }}>
        <Grid item xs={4}>
          <DialogContentText>Seleccione el motivo:</DialogContentText>
        </Grid>
        <Grid item xs={8}>
          <TextField
            margin="dense"
            id="deleteReasonId"
            name="deleteReasonId"
            fullWidth
            onChange={handleChange}
            value={selectedReason.deleteReasonId}
            select>
            {deleteReasons?.map((option) => (
              <MenuItem
                key={option.deleteReasonId}
                value={option.deleteReasonId}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Stack spacing={2} direction="column">
        <DialogContentText>Comentario:</DialogContentText>
        <TextField
          margin="dense"
          id="description"
          name="description"
          fullWidth
          onChange={handleChange}
          multiline
          rows={8}
          value={selectedReason.description}
          inputProps={{ maxLength: MAX_CHARS }}
        />
        <DialogContentText sx={{ textAlign: "right" }}>
          {selectedReason.description.length}/{MAX_CHARS}
        </DialogContentText>
      </Stack>

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}>
          <BarLoader color={"var(--primary)"} loading={true} />
        </Box>
      )}
    </GeneralPurposeModal>
  );
};

export default DeleteTenderModal;
