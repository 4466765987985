import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useState } from "react";

export const ChecklistHeader = [
  {
    accessorKey: "admissibilityRequirements",
    header: "Requisitos de admisibilidad",
    enableEditing: false,
  },
  { accessorKey: "description", header: "Descripción del documento" },
  {
    accessorKey: "status",
    header: "Status",
    editType: "checkbox",
    cellType: "checkbox",
    disableTooltip: true,
  },
  { accessorKey: "manager", header: "Encargado" },
];

const getEvaluationFactors = (data) => {
  let evaluationFactors = "";
  if (data)
    data.forEach((item) => {
      evaluationFactors += `${item.Factor}: ${item.Percentage}% \n`;
    });
  return evaluationFactors;
};

// Base data needed for the first time the user enters the page, because the checklist data is empty and there is no data in backend
export const baseChecklistData = (data, tenderDetailExtension) => [
  {
    id: 1,
    admissibilityRequirements: "Garantia de participación",
    description: `${data.Participation}${
      data.ParticipationAmmount
        ? ` - Monto: ${data.ParticipationAmmount}${
            data.ParticipationIsPercentage ? "%" : ""
          }${
            data.ParticipationValidity
              ? ` - Vigencia: ${data.ParticipationValidity}`
              : ""
          }`
        : ""
    }`,
    status: false,
    manager: null,
  },
  {
    id: 2,
    admissibilityRequirements: "Vigencia de la oferta",
    description: `${data.OfferValidity ?? ""}`,
    status: false,
    manager: null,
  },
  {
    id: 3,
    admissibilityRequirements: "Plazo de entrega",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 4,
    admissibilityRequirements: "Precio",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 5,
    admissibilityRequirements: "Marca y modelo",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 6,
    admissibilityRequirements: "Garantía del producto",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 7,
    admissibilityRequirements: "Muestra",
    description: `${tenderDetailExtension.SampleRequest ?? ""}`,
    status: false,
    manager: null,
  },
  {
    id: 8,
    admissibilityRequirements: "Sistema de evaluación",
    description: `Contenido: ${
      data.EvaluationContent?.trim().length > 0
        ? `${data.EvaluationContent}`
        : "-"
    } | Factores de evaluación: ${
      getEvaluationFactors(data.EvaluationFactors).trim().length > 0
        ? getEvaluationFactors(data.EvaluationFactors)
        : "-"
    }`,
    status: false,
    manager: null,
  },
  {
    id: 9,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 10,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 11,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 12,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 13,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 14,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 15,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 16,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 17,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 18,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 19,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 20,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 21,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 22,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 23,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
];

const Checklist = ({
  managingTenderData,
  handleManagingTenderData,
  tenderManageData,
  tenderData,
}) => {
  const [parsedChecklistData, setParsedChecklistData] = useState([]);
  const {
    height,
    setShouldRefetch,
    hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout,
  } = useGlobalStore((state) => ({
    height: state.height,
    setShouldRefetch: state.setShouldRefetch,
    hideLoadingSpinner: state.hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
  }));
  let data = JSON.parse(JSON.parse(tenderData?.extension)?.Info);
  let tenderDetailExtension =
    tenderData.tenderDetails.length > 0
      ? JSON.parse(tenderData.tenderDetails[0].extension)
      : {};
  let baseData = baseChecklistData(data, tenderDetailExtension);

  const fetchData = async (pageNumber, pageSize, filter, sorting) => {
    // parse data
    let parsedData = [];
    if (managingTenderData.manageExtensions.length > 0) {
      parsedData = JSON.parse(managingTenderData.manageExtensions[0]?.data);
      setParsedChecklistData(parsedData);
    } else {
      // TODO: Later remove because it is done previously, keeping it until Checklist is tested completely
      handleManagingTenderData({
        ...managingTenderData,
        manageExtensions: [
          {
            manageId: tenderManageData?.manageId,
            data: JSON.stringify(baseData),
          },
        ],
      });
      showLoadingStateSpinnerNoTimeout();
      setTimeout(() => {
        setShouldRefetch();
        hideLoadingSpinner();
      }, 500);
    }

    return { result: parsedData, total: parsedData.length };
  };

  const handleSave = (row, exitEditingMode) => {
    let checklistModified = parsedChecklistData.map((item) => {
      // the first verification is for backwards compatibility as the id field was added later
      if (
        (!item.id &&
          !row.id &&
          item.admissibilityRequirements === row.admissibilityRequirements) ||
        (item.id && row.id && item.id === row.id)
      )
        return row;
      else return item;
    });
    // Somehow .map adds these fields thats need to be removed
    checklistModified.forEach((item) => {
      delete item.result;
      delete item.aggregates;
      delete item.count;
    });

    let checkListExtensionData = [
      {
        manageId: tenderManageData?.manageId,
        data: JSON.stringify(checklistModified),
        ...(managingTenderData.manageExtensions[0]?.manageExtensionId && {
          manageExtensionId:
            managingTenderData.manageExtensions[0]?.manageExtensionId,
        }),
      },
    ];

    handleManagingTenderData({
      ...managingTenderData,
      manageExtensions: checkListExtensionData,
    });
    exitEditingMode();
    setShouldRefetch();
    return false;
  };

  const options = {
    tableId: "gridManagementChecklist",
    header: ChecklistHeader,
    height: height - 217, // Aribitrary height to fit exactly the content
    tableHeaderTitle: "Checklist",
    enableEditing: true,
    saveFunc: handleSave,
    toolbarButtons: {
      Export: true,
    },
    enablePagination: false,
  };

  return (
    <Fragment>
      <FSTMaterialReactTable getData={fetchData} options={options} />
    </Fragment>
  );
};

export default Checklist;
