import { useContext } from "react";
import { LangContext } from "../../context/langContext";

const useSelectObject = () => {
  const langChange = useContext(LangContext);

  const ObjectFilter = (id, data) => {
    let newData = [];
    try {
      const idfilter = langChange.configForm[id];
      newData = data[idfilter.objectName];
    } catch (e) {
      console.error("id de objecto no encontrado " + id);
    }
    return newData;
  };

  return {
    ObjectFilter,
  };
};

export default useSelectObject;
