import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { useState } from "react";

const TenderGridMessage = ({ title, message }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const cutString = (str) => {
    let sub = str;
    if (str.length <= 120) return <span>{sub}</span>;

    const length = 120;
    const subStrEnd = str.substring(length, str.length);
    const nSpace = subStrEnd.indexOf(" ") > 0 ? subStrEnd.indexOf(" ") : 0;
    sub = str.substring(0, length + nSpace) + "...";
    return addDialog(sub);
  };

  const addDialog = (child) => {
    return (
      <>
        <Tooltip title="Haga click aquí para ver más detalles">
          <span style={{ cursor: "pointer" }} onClick={handleOpen}>
            {child}
          </span>
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return <div>{cutString(message)}</div>;
};

export default TenderGridMessage;
