import { formatCurrencyFixed, getDataJson } from "utils/common";

export const pacHeader = [
  {
    intlId: "cols.Filtro",
    accessorKey: "searchFilterName",
    header: "Filtro",
    size: "150",
    enableSorting: true,
  },
  {
    intlId: "cols.NroPac",
    accessorKey: "number",
    header: "Nro. PAC",
    size: "120",
    align: "center",
    enableSorting: false,
  },
  {
    intlId: "cols.Entidad",
    accessorKey: "clientUnitName",
    header: "Entidad",
    size: "350",
    enableSorting: true,
  },
  {
    intlId: "cols.Ruc",
    accessorKey: "clientUnitUniqueId",
    header: "RUC",
    size: "150",
    enableSorting: true,
    align: "center",
  },
  {
    intlId: "cols.Descripcion",
    accessorKey: "description",
    header: "Descripción/Objeto",
    size: "250",
    enableSorting: true,
  },
  {
    intlId: "cols.TipoProceso",
    accessorKey: "ProcessType",
    header: "Tipo Proceso",
    size: "250",
    enableSorting: true,
    Cell: ({ cell, column }) =>
      getDataJson("ProcessType", cell.row.original.scrapInfo),
    // colExcel: (props) =>
    // getDataJson("ProcessType", cell.row.original.scrapInfo),
  },
  {
    intlId: "cols.CantidadItem",
    accessorKey: "ItemCount",
    header: "Cantidad de Items",
    size: "150",
    enableSorting: true,
    align: "center",
    Cell: ({ cell, column }) =>
      getDataJson("ItemCount", cell.row.original.scrapInfo),
    // colExcel: (props) => getDataJson("ItemCount", cell.row.original.scrapInfo),
  },
  {
    intlId: "cols.Mes",
    accessorKey: "ScheduledMonth",
    header: "Mes Programado",
    size: "150",
    enableSorting: true,
    Cell: ({ cell, column }) =>
      getDataJson("ScheduledMonth", cell.row.original.scrapInfo),
    // colExcel: (props) =>
    // getDataJson("ScheduledMonth", cell.row.original.scrapInfo),
  },
  {
    intlId: "cols.Fuente",
    accessorKey: "FinanceSource",
    header: "Fuente de Financiamiento",
    enableSorting: true,
    Cell: ({ cell, column }) =>
      getDataJson("FinanceSource", cell.row.original.scrapInfo),
    // colExcel: (props) =>
    // getDataJson("FinanceSource", cell.row.original.scrapInfo),
  },
  {
    intlId: "cols.NroItem",
    accessorKey: "itemNumber",
    header: "Nro. de Item",
    align: "center",
    enableSorting: false,
  },
  {
    intlId: "cols.CodigoCusbo",
    accessorKey: "code",
    header: "Código Cubso",
    size: "150",
    enableSorting: true,
  },
  {
    intlId: "cols.DescripcioItem",
    accessorKey: "itemDescription",
    header: "Descripción de Item",
    size: "250",
    enableSorting: true,
  },
  {
    intlId: "cols.UnidadMedida",
    accessorKey: "UOM",
    header: "Unidad de Medida",
    size: "150",
    enableSorting: true,
    Cell: ({ cell, column }) =>
      getDataJson("UOM", cell.row.original.scrapDetailInfo),
    excelExportFormat: ({ cell }) =>
      getDataJson("UOM", cell.row.original.scrapDetailInfo),
  },
  {
    intlId: "cols.Cantidad",
    accessorKey: "Ammount",
    header: "Cantidad",
    size: "130",
    enableSorting: true,
    textAlign: "Right",
    Cell: ({ cell, column }) =>
      formatCurrencyFixed(
        getDataJson("Ammount", cell.row.original.scrapDetailInfo),
        2,
      ),
    excelExportFormat: ({ cell }) => {
      const value = getDataJson("Ammount", cell.row.original.scrapDetailInfo);
      return value ? value.toFixed(2) : value;
    },
  },
  {
    intlId: "cols.TipoMoneda",
    accessorKey: "Currency",
    header: "Tipo de Moneda",
    size: "150",
    enableSorting: true,
    Cell: ({ cell, column }) =>
      getDataJson("Currency", cell.row.original.scrapDetailInfo),
    excelExportFormat: ({ cell }) =>
      getDataJson("Currency", cell.row.original.scrapDetailInfo),
  },
  {
    intlId: "cols.ProcesoDesierto",
    accessorKey: "Process1",
    header: "Proceso desierto del que procede",
    size: "300",
    enableSorting: true,
    Cell: ({ cell, column }) =>
      getDataJson("Process1", cell.row.original.scrapDetailInfo),
    excelExportFormat: ({ cell }) =>
      getDataJson("Process1", cell.row.original.scrapDetailInfo),
  },
  {
    intlId: "cols.ProcesoDConvocado",
    accessorKey: "process2",
    header: "Proceso Convocado",
    size: "250",
    enableSorting: true,
  },
  {
    intlId: "cols.Estado",
    accessorKey: "statusName",
    header: "Estado",
    size: "150",
    enableSorting: true,
    Cell: ({ cell, column }) =>
      cell.row.original.process2 ? "Convocadas" : "No convocadas",
    excelExportFormat: ({ cell }) =>
      cell.row.original.process2 ? "Convocadas" : "No convocadas",
  },
];
