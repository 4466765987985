import { Box, Button, IconButton, Tooltip, alpha } from "@mui/material";
import { styled } from "@mui/system";

const TopToolbarButton = styled(Button)(({ theme }) => ({
  color: alpha("rgb(0,0,0)", 0.54),
  borderRadius: "0.5rem",
  fontSize: "0.7rem",
  marginLeft: "0.2rem",
  textTransform: "uppercase",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
}));

const TopToolbarIconButton = styled(IconButton)(({ theme }) => ({
  color: alpha("rgb(0,0,0)", 0.54),
  borderRadius: "0.5rem",
  marginLeft: "0.5rem",
}));

export default function TooltipButton(props) {
  let { title, icon, onClick, disabled } = props;
  return (
    <>
      {true ? ( //check if it can change based on width
        <Tooltip title={title} disableInteractive>
          <span>
            <TopToolbarButton
              startIcon={icon}
              size="small"
              onClick={onClick}
              disabled={disabled}>
              <Box sx={{ height: 18, fontWeight: 500 }}>{title}</Box>
            </TopToolbarButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title={title} disableInteractive>
          <span>
            <TopToolbarIconButton
              size="small"
              onClick={onClick}
              disabled={disabled}>
              {icon}
            </TopToolbarIconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
}
