import { useOutsiderTenderIntlColumnsAR } from "intl/outsiderTenders/AR";
import { useOutsiderTenderIntlColumnsCL } from "intl/outsiderTenders/CL";
import { useIntl } from "react-intl";
import { isLangCR, isLangAR, getLang } from "utils/common";

const getOutsiderTenderIntlColumns = () => {
  switch (getLang()) {
    case "es-AR":
      return useOutsiderTenderIntlColumnsAR();
    case "es-CL":
      return useOutsiderTenderIntlColumnsCL();
    default:
      return useOutsiderTenderIntlColumnsAR();
  }
};

//TODO: improve and make it more generic, remove all intl to use .json
const useOutsiderTenderColumns = () => {
  const intl = useIntl();

  const {
    tenderValues,
    tenderDetailValues,
    tenderDetailAwardValues,
    tenderManageValues,
    tenderManageDetailValues,
    minimumColumns,
    extraColumns,
  } = getOutsiderTenderIntlColumns();

  const getMinimumColumns = () => {
    return [...minimumColumns];
  };

  const getExtraColumns = () => {
    return [...extraColumns];
  };

  const getAllColumns = () => {
    let allColumns = [
      ...minimumColumns,
      // add 'columnPosition' to extraColumns
      ...extraColumns.map((column, index) => {
        return {
          ...column,
          columnPosition: minimumColumns.length + index,
          modifiable: true,
        };
      }),
    ];
    return allColumns;
  };

  const getTenderValues = (tenderTypes, tendersStatus, currencyList) => {
    // add 'tenderType' and 'tenderStatus' to tenderValues (type=select)
    let filledTenderValues = tenderValues.map((tender) => {
      if (tender.columnValue === "tenderType") {
        return { ...tender, options: tenderTypes };
      } else if (tender.columnValue === "tenderStatus") {
        return { ...tender, options: tendersStatus };
      } else if (tender.columnValue === "currency") {
        return { ...tender, options: currencyList };
      } else {
        return tender;
      }
    });

    return filledTenderValues;
  };

  const getTenderDetailValues = (currencyList) => {
    let filledTenderDetailValues = tenderDetailValues.map((tender) => {
      if (tender.columnValue === "tenderDetailEstimatedValueCurrency") {
        return { ...tender, options: currencyList };
      } else {
        return tender;
      }
    });

    return filledTenderDetailValues;
  };

  const getTenderDetailAwardValues = (currencyList) => {
    let filledTenderDetailAwardValues = tenderDetailAwardValues.map(
      (tender) => {
        if (tender.columnValue === "tenderDetailAwardedCurrency") {
          return { ...tender, options: currencyList };
        } else {
          return tender;
        }
      },
    );

    return filledTenderDetailAwardValues;
  };

  const getTenderManageValues = (sellers, tags, categories) => {
    // add 'tenderManageSeller', 'tenderManageTag' and 'tenderManageCategory' to tenderManageValues (type=select)
    let filledTenderManageValues = tenderManageValues.map((tender) => {
      if (tender.columnValue === "tenderManageSeller") {
        return { ...tender, options: sellers };
      } else if (tender.columnValue === "tenderManageTag") {
        return { ...tender, options: tags };
      } else if (tender.columnValue === "tenderManageCategory") {
        return { ...tender, options: categories };
      } else {
        return tender;
      }
    });

    return filledTenderManageValues;
  };

  const getTenderManageDetailValues = () => {
    return [...tenderManageDetailValues];
  };

  return {
    getMinimumColumns,
    getExtraColumns,
    getAllColumns,
    getTenderValues,
    getTenderDetailValues,
    getTenderDetailAwardValues,
    getTenderManageValues,
    getTenderManageDetailValues,
  };
};

export default useOutsiderTenderColumns;
