import { Autocomplete, TextField, Tooltip, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";

const AutocompletePaging = ({
  value,
  onChange,
  loadOptionsCallback,
  getOptionLabel = null,
  id = "autocomplete",
  textFieldVariant = "standard",
  textFieldLabel = "",
  textFieldSize = "medium",
  textFieldPlaceholder = "Buscar...",
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentResults, setCurrentResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const previousController = useRef();

  const handleLoadOptions = async (inputValue, page) => {
    // Signal to cancel previous request from previous typings
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    setFilter(inputValue);
    setCurrentPage(page);
    setIsLoading(true);
    if (inputValue === "" || inputValue.length <= 1) {
      // TODO: change to 3 and add helper text
      setCurrentResults([]);
      setIsLoading(false);
      return;
    }

    setTimeout(() => {
      loadOptionsCallback(
        {
          PageSize: 15 * page,
          Filter: inputValue,
          PageNumber: 1,
        },
        signal,
      )
        .then((results) => {
          setCurrentResults(results.data.currentPageItems);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }, 1000);
  };

  const handleOnScrollToBottom = (event) => {
    setCurrentPage(currentPage + 1);
    handleLoadOptions(filter, currentPage + 1);
  };

  const nearBottom = (target = {}) => {
    const diff = Math.round(target.scrollHeight - target.scrollTop);
    return diff - 25 <= target.clientHeight;
  };

  return (
    <Autocomplete
      id={id}
      value={value}
      options={currentResults}
      disableListWrap
      getOptionLabel={
        getOptionLabel
          ? (option) => getOptionLabel(option)
          : (option) => option.label
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={textFieldVariant}
          label={textFieldLabel}
          size={textFieldSize}
          placeholder={textFieldPlaceholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <PulseLoader
                    color={"var(--primary)"}
                    loading={true}
                    size={6}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => {
        let renderString = getOptionLabel
          ? getOptionLabel(option)
          : option.label;
        return (
          <li {...props}>
            <Tooltip title={renderString} placement="left" disableInteractive>
              <Typography>{renderString}</Typography>
            </Tooltip>
          </li>
        );
      }}
      onInputChange={(event, newInputValue) => {
        handleLoadOptions(newInputValue, 1);
      }}
      closeText="Cerrar"
      openText="Abrir"
      loading={isLoading}
      loadingText="Cargando..."
      noOptionsText="No hay resultados"
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (
            Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
            listboxNode.scrollHeight
          ) {
            if (nearBottom(listboxNode)) handleOnScrollToBottom();
          }
        },
      }}
      {...props}
    />
  );
};

export default AutocompletePaging;
