import NotificationsIcon from "@mui/icons-material/Notifications";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { dateTimeFormat, strcmpWithoutAccent } from "utils/common";
import {
  addOrUpdateAlert,
  addOrUpdateNotification,
  deleteNotification,
  getAnalysisFilter,
  getGeneralDataAlerts,
  getGeneralDataNotifications,
  getReportCategories,
  listAlerts,
  listNotifications,
  listRecipients,
} from "./settingsService";

const AnalysisFilterModuleId = 3;
const EXPORT_REPORT_NOTIFICATION_ID = 25;

const reportExportTypes = [
  { id: 1, name: "PDF" },
  { id: 2, name: "Excel" },
];

const NotificationsAlerts = (props) => {
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const {
    setSnackbarSaveChanges,
    setSnackbarMissingData,
    showSavingStateSpinnerNoTimeout,
    showLoadingStateSpinnerNoTimeout,
    setShouldRefetch,
    hideLoadingSpinner,
    setSnackbarWarningCustom,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    setSnackbarMissingData: state.setSnackbarMissingData,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    setShouldRefetch: state.setShouldRefetch,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setSnackbarWarningCustom: state.setSnackbarWarningCustom,
  }));

  const [alertsData, setAlertsData] = useState({
    data: [],
    page: 0,
    totalCount: 0,
  });

  const invertObjectKeyValue = (name, generalData) => {
    const obj = {};
    for (let key in generalData) {
      if (generalData.hasOwnProperty(key)) {
        obj[generalData[key]] = key;
      }
    }
    return obj;
  };

  const getLabelFromId = (name, lookup, id) => {
    let result = "Desconocido";
    for (let key in lookup) {
      if (lookup.hasOwnProperty(key)) {
        if (lookup[key] === id) {
          result = key;
          break;
        }
      }
    }
    return result;
  };

  const getDataForSelect = (data) => {
    return Object.entries(data).map(([key, value]) => {
      return { id: +key, name: value };
    });
  };

  // function that returns an object with keys as the ids in the first array of objects and values as the ids in the second array of objects that match the 'name' key
  // used to match the recipients of a notification with the alerts that they are associated with
  const mapNotifIdtoAlertId = (notifIds, alertIds) => {
    return notifIds.reduce((obj, notifId) => {
      for (const element of alertIds) {
        if (strcmpWithoutAccent(notifId.name, element.name)) {
          obj[notifId.id] = element.id;
          break;
        }
      }
      return obj;
    }, {});
  };
  const [generalData, setGeneralData] = useState({
    data: null,
    processed: null,
    mappedToSelect: null,
  });
  const [availableRecipients, setAvailableRecipients] = useState([]);
  const [notifIdToAlertId, setNotifIdToAlertId] = useState({});
  const [availableReports, setAvailableReports] = useState([]);
  const [analysisFilters, setAnalysisFilters] = useState([]);

  const fetchAllData = async (pageInfo) => {
    showLoadingStateSpinnerNoTimeout();
    const data = await getGeneralDataNotifications();
    const alertsData = await getGeneralDataAlerts();

    const reportCategories = await getReportCategories(AnalysisFilterModuleId);
    const reports = reportCategories.data.map((reportCategory) => {
      return reportCategory.reports.map((report) => {
        return {
          id: report.id,
          name: `${reportCategory.name} - ${report.name}`,
        };
      });
    });
    const reportsFlat = [].concat.apply([], reports);
    setAvailableReports(reportsFlat);

    const analysisFilters = await getAnalysisFilter(AnalysisFilterModuleId);
    setAnalysisFilters(analysisFilters.data);

    if (!!data.error || !!alertsData.error) {
      // TODO: set error to table
      return;
    }
    const recipientsInfo = await listRecipients();
    const activeRecipients = recipientsInfo.data.filter((r) => r.active);
    const mappedRecipients = activeRecipients.map((r) => {
      return { id: r.recipientId, name: `${r.firstName} ${r.lastName}` };
    });
    setAvailableRecipients(mappedRecipients);

    data.processed = {};
    data.mappedToSelect = {};
    let name = "notificationType";
    data.processed[name] = invertObjectKeyValue(name, data.data[name]);
    data.mappedToSelect[name] = getDataForSelect(data.processed[name]);
    name = "notificationChannel";
    data.processed[name] = invertObjectKeyValue(name, data.data[name]);
    data.mappedToSelect[name] = getDataForSelect(data.processed[name]);
    name = "sendDay";
    data.processed[name] = invertObjectKeyValue(name, data.data[name]);
    data.mappedToSelect[name] = getDataForSelect(data.processed[name]);

    alertsData.processed = {};
    alertsData.mappedToSelect = {};
    name = "alertTypes";
    alertsData.processed[name] = invertObjectKeyValue(
      name,
      alertsData.data[name],
    );
    alertsData.mappedToSelect[name] = getDataForSelect(
      alertsData.processed[name],
    );
    setNotifIdToAlertId(
      mapNotifIdtoAlertId(
        data.mappedToSelect["notificationType"],
        alertsData.mappedToSelect["alertTypes"],
      ),
    );

    setGeneralData(data);
    hideLoadingSpinner();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };

  const handleDelete = async () => {
    showSavingStateSpinnerNoTimeout();
    await deleteNotification(itemToDelete);
    if (itemToDelete.alert) {
      itemToDelete.alert.active = false;
      await addOrUpdateAlert(itemToDelete.alert);
    }
    setModalDeleteConfirm(false);
    setSnackbarSaveChanges();
    hideLoadingSpinner();
    setShouldRefetch();
  };

  const handleSave = async (item) => {
    // Error checking
    if (
      !item.channelId ||
      !item.notificationTypeId ||
      !item.sendDayId ||
      !item.recipientsIds
    ) {
      setSnackbarMissingData();
      return false;
    }
    if (item.notificationTypeId === EXPORT_REPORT_NOTIFICATION_ID) {
      if (!item.ReportId || !item.ExportType || !item.AnalysisFilterId) {
        setSnackbarWarningCustom(
          "Seleccione un reporte, formato y filtro para el tipo de notificación Exportación de reporte",
        );
        return false;
      }
    }
    // Remove unnecessary fields
    delete item.creation;
    delete item.lastUpdate;

    if (item.notificationTypeId === EXPORT_REPORT_NOTIFICATION_ID) {
      item.reportParam = JSON.stringify({
        ReportId: item.ReportId,
        ExportType: item.ExportType,
        AnalysisFilterId: +item.AnalysisFilterId,
      });
    }
    delete item.ReportId;
    delete item.ExportType;
    delete item.AnalysisFilterId;

    showSavingStateSpinnerNoTimeout();
    await addOrUpdateNotification(item);
    if (item.alert && item.alert.active !== item.alertActive) {
      item.alert.active = item.alertActive;
      await addOrUpdateAlert(item.alert);
    } else if (
      !item.alert &&
      notifIdToAlertId[item.notificationTypeId] &&
      item.alertActive
    ) {
      let alertId = notifIdToAlertId[item.notificationTypeId];
      let alert = alertsData.data.find((a) => a.alertTypeId === alertId);
      if (!alert) {
        alert = {
          alertTypeId: alertId,
          active: item.alertActive,
        };
      } else alert.active = item.alertActive;
      await addOrUpdateAlert(alert);
    }
    setSnackbarSaveChanges();
    hideLoadingSpinner();
    return true;
  };

  const fetchTableData = async (page, pageSize, filter) => {
    const notifications = await listNotifications();
    const alerts = await listAlerts();
    setAlertsData({
      page: page,
      data: alerts.data,
      totalCount: alerts.data.length,
    });
    notifications.data.map((item) => {
      if (notifIdToAlertId[item.notificationTypeId]) {
        let alertId = notifIdToAlertId[item.notificationTypeId];
        let alert = alerts.data.find((a) => a.alertTypeId === alertId);
        item.alert = alert ? alert : null;
        item.alertActive = alert ? alert.active : false;
      } else {
        item.alert = null;
        item.alertActive = false;
      }
      return item;
    });

    notifications.data = notifications.data.map((item) => {
      if (item.reportParam) {
        let reportParam = JSON.parse(item.reportParam);
        return {
          ...item,
          ReportId: reportParam.ReportId,
          ExportType: reportParam.ExportType,
          AnalysisFilterId: +reportParam.AnalysisFilterId,
        };
      }
      return {
        ...item,
        ReportId: null,
        ExportType: null,
        AnalysisFilterId: null,
      };
    });

    if (notifications) {
      return {
        result: notifications.data,
        total: notifications.data.length,
      };
    }
  };

  const columns = [
    {
      header: "Tipo",
      accessorKey: "notificationTypeId",
      Cell: ({ cell }) => {
        return getLabelFromId(
          "notificationType",
          generalData?.data?.notificationType,
          cell.row.original["notificationTypeId"],
        );
      },
      editType: "select",
      editOptions: generalData?.mappedToSelect?.notificationType,
    },
    {
      header: "Canal",
      accessorKey: "channelId",
      Cell: ({ cell }) =>
        getLabelFromId(
          "notificationChannel",
          generalData?.data?.notificationChannel,
          cell.row.original["channelId"],
        ),
      editType: "select",
      editOptions: generalData?.mappedToSelect?.notificationChannel,
    },
    {
      header: "Notificación",
      accessorKey: "active",
      disableTooltip: true,
      cellType: "checkbox",
      editType: "checkbox",
      size: 110,
    },
    {
      header: "Alerta",
      accessorKey: "alertActive",
      disableTooltip: true,
      cellType: "checkbox",
      editType: "checkbox",
      size: 110,
    },
    {
      header: "Dia de envío",
      accessorKey: "sendDayId",
      Cell: ({ cell }) =>
        getLabelFromId(
          "sendDay",
          generalData?.data?.sendDay,
          cell.row.original["sendDayId"],
        ),
      editType: "select",
      editOptions: generalData?.mappedToSelect?.sendDay,
    },
    {
      header: "Creación",
      accessorKey: "creation",
      Cell: ({ cell }) =>
        cell.row.original.creation
          ? moment(cell.row.original.creation).format(dateTimeFormat)
          : "",
      enableEditing: false,
    },
    {
      header: "Última actualización",
      accessorKey: "lastUpdate",
      Cell: ({ cell }) =>
        cell.row.original.lastUpdate
          ? moment(cell.row.original.lastUpdate).format(dateTimeFormat)
          : "",
      enableEditing: false,
      size: 235,
    },
    {
      header: "Destinatarios",
      accessorKey: "recipientsIds",
      Cell: ({ cell }) =>
        cell.row.original["recipientsIds"]
          .map(
            (ri) => availableRecipients.filter((ar) => ar.id === ri)[0]?.name,
          )
          .join(", "),
      editType: "multiselect",
      editOptions: availableRecipients,
    },
    {
      header: "Tipo de reporte",
      accessorKey: "ReportId",
      Cell: ({ cell }) =>
        availableReports.find((ar) => ar.id === cell.row.original["ReportId"])
          ?.name,
      editType: "select",
      editOptions: availableReports,
    },
    {
      header: "Formato del reporte",
      accessorKey: "ExportType",
      Cell: ({ cell }) =>
        reportExportTypes.find(
          (ar) => ar.id === cell.row.original["ExportType"],
        )?.name,
      editType: "select",
      editOptions: reportExportTypes,
    },
    {
      header: "Filtro de análisis",
      accessorKey: "AnalysisFilterId",
      Cell: ({ cell }) =>
        analysisFilters.find(
          (ar) => +ar.uniqueId === +cell.row.original["AnalysisFilterId"],
        )?.name,
      editType: "select",
      editOptions: analysisFilters.map((af) => {
        return { id: +af.uniqueId, name: af.name };
      }),
    },
  ];

  const options = {
    tableId: "gridSettingsNotificationsAlerts",
    header: columns,
    fullHeight: true,
    tableHeaderTitle: "Notificaciones y Alertas",
    toolbarButtons: {
      Add: true,
    },
    enableEditing: true,
    enableDeleting: true,
    deleteFunc: (row) => {
      setItemToDelete(row);
      setModalDeleteConfirm(true);
    },
    saveFunc: handleSave,
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Notificaciones y Alertas"
        titleDescription="Administrar notificaciones y alertas."
        icon={<NotificationsIcon />}
      />
      <FSTMaterialReactTable options={options} getData={fetchTableData} />
      <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={`¿Está seguro que desea eliminar la notificación de tipo ${getLabelFromId(
          "notificationType",
          generalData?.data?.notificationType,
          itemToDelete ? itemToDelete.notificationTypeId : "",
        )} y que se envía ${getLabelFromId(
          "sendDay",
          generalData?.data?.sendDay,
          itemToDelete ? itemToDelete.sendDayId : "",
        )}?`}
      />
    </Fragment>
  );
};

export default NotificationsAlerts;
