import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const createData = (name, data) => {
  return { name, data, id: `${name}${data}${Math.random()}` };
};

// Batch data

export const getBatchDetails = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.P.ModalidadEntrega",
        defaultMessage: "Modalidad de entrega",
      }),
      data.DeliveryMethod,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.P.EsquemaPago",
        defaultMessage: "Esquema de pago",
      }),
      data.PaymentScheme,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.P.PagoAdelantado",
        defaultMessage: "Pago por adelantado",
      }),
      data.PaymentInAdvance,
    ),
  ];
};

export const getPaymentMethod = (data, intl) => {
  return createData(
    intl.formatMessage({
      id: "tabL.P.ModalidadPago",
      defaultMessage: "Modalidad de pago",
    }),
    <FormGroup>
      {data.PaymentMethods.map((item, index) => {
        return (
          <FormControlLabel
            disabled
            key={index}
            control={<Checkbox checked={item.IsChecked} size="small" />}
            label={
              <Typography style={{ fontSize: 14 }}>{item.Name}</Typography>
            }
          />
        );
      })}
    </FormGroup>,
  );
};

export const getIncoterms = (data, intl) => {
  return createData(
    intl.formatMessage({ id: "tabL.P.Incoterm", defaultMessage: "Incoterm" }),
    data.Incoterms?.length === 0 ? null : (
      <FormGroup>
        {data.Incoterms?.map((item, index) => {
          return (
            <FormControlLabel
              disabled
              key={index}
              control={<Checkbox checked={item.IsChecked} size="small" />}
              label={
                <Typography style={{ fontSize: 14 }}>{item.Name}</Typography>
              }
            />
          );
        })}
      </FormGroup>
    ),
  );
};

export const getPolicy = (data, intl) => {
  return [
    [
      createData(
        intl.formatMessage({
          id: "tabL.P.ResponsabilidadCivil",
          defaultMessage: "Responsabilidad Civil",
        }),
        data.CivilLiability,
      ),
      createData(
        intl.formatMessage({
          id: "tabL.P.MontoRespCivil",
          defaultMessage: "Monto",
        }),
        data.CivilLiabilityAmmount,
      ),
    ],
    [
      createData(
        intl.formatMessage({
          id: "tabL.P.RiesgosTrabajo",
          defaultMessage: "Riesgos de trabajo",
        }),
        data.WorkRisks,
      ),
      createData(
        intl.formatMessage({
          id: "tabL.P.MontoRiesgosTrabajo",
          defaultMessage: "Monto",
        }),
        data.WorkRisksAmmount,
      ),
    ],
    [
      createData(
        intl.formatMessage({
          id: "tabL.P.Fidelidad",
          defaultMessage: "Fidelidad",
        }),
        data.Fidelity,
      ),
      createData(
        intl.formatMessage({
          id: "tabL.P.MontoFidelidad",
          defaultMessage: "Monto",
        }),
        data.FidelityAmmount,
      ),
    ],
  ];
};

export const getCollege = (data, intl) => {
  return [
    createData(
      intl.formatMessage({ id: "tabL.P.Colegio", defaultMessage: "Colegio" }),
      data.School,
    ),
    createData(
      intl.formatMessage({ id: "tabL.P.Grado", defaultMessage: "Grado" }),
      data.Grade,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.P.TituloDeGrado",
        defaultMessage: "Título",
      }),
      data.Title,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.P.EnfasisTitulo",
        defaultMessage: "Enfasis del título",
      }),
      data.TitleEmphasis,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.P.CantProfesionalesRequeridos",
        defaultMessage: "Cantidad de profesionales requeridos",
      }),
      data.AmmountOfRequiredProfessionals,
    ),
  ];
};

// Line data

export const getRequestNumber = (data, intl) => {
  return createData(
    intl.formatMessage({
      id: "tabL.L.NroSolicitud",
      defaultMessage: "Número de solicitud de contratación",
    }),
    data.RequestNumber,
  );
};

export const getDelivery = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.L.FormaEntrega",
        defaultMessage: "Forma de entrega",
      }),
      data.DeliveryMethod,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.LugarEntrega",
        defaultMessage: "Lugar de entrega/bien, servicio u obra",
      }),
      data.DeliveryPlace,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.ObservacionesEntrega",
        defaultMessage: "Observaciones",
      }),
      data.Observations,
    ),
  ];
};

export const getDeliveryItems = (data, intl) => {
  return [
    createData(
      intl.formatMessage({ id: "tabL.L.Numero", defaultMessage: "Número" }),
      null,
    ),
    createData(
      `${intl.formatMessage({
        id: "tabL.L.CantidadEntrega",
        defaultMessage: "Cantidad",
      })}${
        data.DeliveryItems.length === 0
          ? ``
          : ` (${
              data.DeliveryItems.every((x) => x.IsPercentage) ? "%" : "numérico"
            })`
      }`,
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.PlazoEntrega",
        defaultMessage: "El plazo de entrega",
      }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.FechaEntrega",
        defaultMessage: "Fecha de entrega",
      }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.PlazoMaxEntrega",
        defaultMessage: "Plazo máximo de entrega",
      }),
      null,
    ),
  ];
};

export const getDeliveryItemsList = (data, intl) => {
  return data.DeliveryItems.map((x) => [
    x.Number,
    x.Ammount,
    x.DeliveryTime,
    x.DeliveryDate,
    x.MaxDeliveryTime,
  ]);
};

export const getGuarrantee = (data, intl) => {
  return createData(
    intl.formatMessage({
      id: "tabL.L.GarantiaTecnica",
      defaultMessage: "Garantía técnica",
    }),
    data.TechnicalGuarantee,
  );
};

export const getBudgetFirstLine = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.L.ValidacionPresupuesto",
        defaultMessage: "Validación de presupuesto",
      }),
      data.BudgetValidation,
    ),
    createData(
      intl.formatMessage({ id: "tabL.L.Motivo", defaultMessage: "Motivo" }),
      data.Motive,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.FuenteFinanciamiento",
        defaultMessage: "Fuente de financiamiento",
      }),
      data.FinanceSource,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.CreditoFiscal",
        defaultMessage: "Crédito fiscal",
      }),
      data.FiscalCredit,
    ),
  ];
};

export const getBudgetSecondLine = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.L.MontoPresupuesto",
        defaultMessage: "Monto de presupuesto",
      }),
      data.BudgetAmmount,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.SubPartida",
        defaultMessage: "Sub partida/Objeto Gasto",
      }),
      data.SubItemExpense,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.EstructuraPresup",
        defaultMessage: "Centro de costos/Estructura presupuestaria",
      }),
      data.CostCenter,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.ReservaPresup",
        defaultMessage: "Reserva presupuestaria",
      }),
      data.BudgetReserve,
    ),
  ];
};

export const getSampleFirstLine = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.L.SolicitudMuestra",
        defaultMessage: "Solicitud de Muestra",
      }),
      data.SampleRequest,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.AutoridadEncargada",
        defaultMessage: "Autoridad encargada del estudio",
      }),
      data.StudyAuthority,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.PlazoDevolucion",
        defaultMessage: "Plazo de devolución de la muestra",
      }),
      data.SampleReturnTime,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.ObservacionesMuestra",
        defaultMessage: "Observaciones",
      }),
      data.SampleObservations,
    ),
  ];
};

export const getSampleSecondLine = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.L.CantidadMuestra",
        defaultMessage: "Cantidad",
      }),
      data.SampleAmmount,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.TipoPrueba",
        defaultMessage: "Tipo de prueba",
      }),
      data.TestType,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.TipoValoracion",
        defaultMessage: "Tipo de valoración",
      }),
      data.ValuationType,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.ParticipacionOferentes",
        defaultMessage: "Participación de los oferentes en el análisis",
      }),
      data.BiddersParticipation,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.FechaHoraEntrega",
        defaultMessage: "Fecha y hora de entrega",
      }),
      data.SampleDeliveryDate,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.L.LugarEntregaMuestra",
        defaultMessage: "Lugar de entrega",
      }),
      data.SampleDeliveryPlace,
    ),
  ];
};

// Engagement data

export const getEngagement = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.C.NroSolicitudContratacion",
        defaultMessage: "Número de solicitud de contratación",
      }),
      data.RequestNumber,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.JustificacionProcedencia",
        defaultMessage: "Justificación de la procedencia de la contratación",
      }),
      data.ContractOriginJustification,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.FinalidadPublica",
        defaultMessage:
          "Finalidad pública que se persigue satisfacer con el concurso",
      }),
      data.PublicPurpose,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.JustificacionEscogencia",
        defaultMessage:
          "Justificación de la escogencia de la solución técnica para satisfacer la necesidad",
      }),
      data.TechnicalSolutionJustification,
    ),
  ];
};

export const getUsersInfo = (data, intl) => {
  return [
    createData(
      intl.formatMessage({ id: "tabL.C.Rol", defaultMessage: "Rol" }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.IDUsuario",
        defaultMessage: "ID de usuario",
      }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.Departamento",
        defaultMessage: "Departamento/Unidad",
      }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.NombreApellido",
        defaultMessage: "Nombre y apellido",
      }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.TelefonoUsuario",
        defaultMessage: "Teléfono",
      }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.CorreoElectronico",
        defaultMessage: "Correo electrónico",
      }),
      null,
    ),
  ];
};

export const getUsersData = (data, intl) => {
  let arrayParsedData = data.UsersInfo?.map((item) => {
    return createData(item.UserId, [
      item.Role,
      item.UserId,
      item.Department,
      item.Name,
      item.Phone,
      item.Email,
    ]);
  });
  return arrayParsedData;
};

export const getAttachments = (data, intl) => {
  return [
    createData(
      intl.formatMessage({
        id: "tabL.C.AdjuntoNombre",
        defaultMessage: "Nombre",
      }),
      null,
    ),
    createData(
      intl.formatMessage({
        id: "tabL.C.AdjuntoLink",
        defaultMessage: "Adjunto",
      }),
      null,
    ),
  ];
};

export const getAttachmentsData = (data, intl) => {
  let arrayParsedData = data.Attachments?.map((item) => {
    return createData(item.Name, [
      item.Name,
      <IconButton>
        <a href={item.Link} target="_blank">
          <AttachFileIcon />
        </a>
      </IconButton>,
    ]);
  });
  return arrayParsedData;
};
