import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  Button,
} from "@mui/material";
import hero1 from "assets/images/hero-bg/hero-1.jpg";

const GeneralPurposeModal = ({
  open,
  title,
  onClose,
  onSave = null,
  children,
  maxWidth = "md",
  height = null,
  onSaveDisabled = false,
  closeText = "Cerrar",
  saveText = "Guardar",
}) => {
  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      scroll="paper"
      fullWidth
      maxWidth={maxWidth}>
      <DialogTitle
        id="form-dialog-title"
        className="bg-composed-wrapper bg-plum-plate h-100"
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}>
        <div
          className="bg-composed-wrapper--image "
          style={{ backgroundImage: "url(" + hero1 + ")", zIndex: "0" }}
        />
        <span
          className="font-weight-bold text-white m-0"
          style={{
            fontSize: "1.1875rem",
            lineHeight: 1.2,
            fontWeight: 700,
          }}>
          {title}
        </span>
        <DialogActions sx={{ height: "33px" }}>
          <IconButton
            onClick={onClose}
            sx={{ height: "inherit" }}
            className="text-white">
            <FontAwesomeIcon icon={["fas", "times"]} />
          </IconButton>
        </DialogActions>
      </DialogTitle>
      <div
        className="divider border-2 border-light rounded"
        style={{
          width: "100%",
          display: "flex",
          alignSelf: "center",
        }}
      />
      <DialogContent
        sx={{
          // Custom scrollbar
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            WebkitAppearance: "none",
            width: "12px",
            height: "12px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(118, 75, 162, 0.4)",
            WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f5f5f5",
          },
          ...(height && { height: height }),
        }}>
        {children}
      </DialogContent>
      <div
        className="divider border-2 border-light rounded"
        style={{
          width: "100%",
          display: "flex",
          alignSelf: "center",
        }}
      />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {closeText}
        </Button>
        {onSave && (
          <Button
            onClick={onSave}
            color="primary"
            variant="contained"
            disabled={onSaveDisabled}>
            {saveText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GeneralPurposeModal;
