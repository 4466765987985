import { Fragment } from "react";

import AdministrationMenu from "./AdministrationMenu";
import SettingsMenu from "./SettingsMenu";

export default function HeaderMenus({ userInfo }) {
  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
        <AdministrationMenu />
        {userInfo.isAdmin && <SettingsMenu userInfo={userInfo} />}
      </div>
    </Fragment>
  );
}
