import administration from "./administration";
import common from "./common";
import manage from "./manage";

const services = {
  common,
  manage,
  administration,
};

export default services;
