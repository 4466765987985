import { capitalize, formatValueLocale } from "utils/common";
import ResponsiveTable from "components/ResponsiveTable/ResponsiveTable";
import { ConvertToDateLong } from "utils/DateHelper";

export default {
  TenderContainer: {
    Filter: {
      Tab: [
        { id: "tab.General", keys: "", types: "O" },
        { id: "tab.Cronograma", keys: "tenderStages", types: "O" },
        { id: "tab.ListaItems", keys: "tenderDetails", types: "O" },
        { id: "tab.Documentos", keys: "tenderDocuments", types: "O" },
        { id: "tab.Contratos", keys: "extension.Info", types: "J.J" },
      ],
    },
  },
  TenderGestion: {
    Filter: {
      menuInfo: [
        "tab.General",
        "tab.Cronograma",
        "tab.ListaItems",
        "tab.Documentos",
        "tab.Contratos",
      ],
      menuGestion: [
        "tab.Asignaciones",
        "tab.ListaItems",
        "tab.CartasFianza",
        "tab.Comentarios",
      ],
    },
  },
  ManagementCalendarExportValues: {
    elements: [
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => item.toString(),
      },
      {
        id: "cols.Publicacion",
        value: "publishedDate",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Nomenclatura", value: "fileNumber" },
      { id: "cols.Convocatoria", value: "tenderNumber" },
      { id: "cols.Entidad", value: "clientUnitName" },
      { id: "cols.Nombre", value: "tenderName" },
      { id: "cols.Situacion", value: "manageInternalStatusDesc" },
      { id: "cols.OContratacion", value: "tenderDescription" },
      { id: "cols.Moneda", value: "currency" },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      { id: "cols.Items", value: "detailsQty" },
      { id: "cols.Usuario", value: "userManage" },
      {
        id: "cols.Segmento",
        value: "categories",
        format: (item) => (item.length > 0 ? item[0] : ""),
      },
      {
        id: "cols.Gestionada",
        value: "dateCreationManage",
        format: (item) => ConvertToDateLong(item),
      },
    ],
  },
  ManagementCalendarValues: {
    elements: [
      { id: "cols.Convocatoria", value: "tenderNumber" },
      {
        id: "cols.Nombre",
        value: "tenderName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.Entidad",
        value: "clientUnitName",
        format: (item) => capitalize(item),
      },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      {
        id: "cols.Situacion",
        value: "manageInternalStatusDesc",
        format: (item) => (item ? item : "Sin Asignar"),
      },
      { id: "cols.Portal", value: "portal" },
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => (item.length > 0 ? item.toString() : "Sin Asignar"),
      },
    ],
  },
  EmailSubjectConfig: {
    elements: [
      { id: "cols.Nombre", name: "tenderName" },
      { id: "cols.Id", name: "tenderNumber" },
      { id: "cols.Cierre", name: "endDate" },
      { id: "text.Tipo_Compra", name: "tenderTypeDesc" },
      { id: "cols.Portal", name: "portal" },
      { id: "cols.Entidad", name: "clientUnitName" },
      { id: "cols.Usuario", name: "userManage" },
    ],
  },
  TenderGeneralInfo: {
    elements: [
      {
        id: "text.Nomenclatura",
        value: "fileNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Convocatoria",
        value: "tenderNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Fecha_Publicacion",
        value: "publishedDate",
        type: "date",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Tipo_Compra",
        value: "tenderTypeDesc",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Entidad",
        value: "clientUnitName",
        xs: 12,
        sm: 6,
        md: 6,
      },
      {
        id: "text.Descripcion",
        value: "tenderName",
        xs: 12,
        sm: 12,
        md: 6,
      },
    ],
  },
  ListCompGeneralInfo: {
    component: [
      {
        type: "Grid",
        id_ff: "TabGeneralInfoFirst",
      },
      {
        type: "EtqH5",
        styleParent: { marginTop: "1rem" },
      },
      {
        type: "Grid",
        id_ff: "TabGeneralInfoLast",
      },
    ],
  },
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            {
              key: "tenderDescription",
              intlLabelKey: "tabG.Objeto_Contratacion",
            },
            { key: "estimatedValue", intlLabelKey: "tabG.Valor_Referencial" },
            {
              key: "publishedDate",
              intlLabelKey: "tabG.Fecha_Publicacion",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabGeneralInfoLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "clientUnitName", intlLabelKey: "tabG.Entidad_Contratante" },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "name", intlLabelKey: "tabC.Etapa" },
            {
              key: "endDate",
              intlLabelKey: "tabC.Fecha_Fin",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabItemListFirst: {
    element: {
      type: "FSTMRT",
      columns: [
        {
          accessorKey: "itemNumber",
          header: "#",
          size: "60",
          enableSorting: false,
        },
        {
          accessorKey: "description",
          header: "Descripción",
          size: "220",
          enableSorting: false,
        },
        {
          accessorKey: "code",
          header: "Código UNSPSC",
          size: "110",
          enableSorting: false,
        },
        {
          accessorKey: "qty",
          header: "Cantidad",
          size: "60",
          textAlign: "center",
          enableSorting: false,
        },
        {
          accessorKey: "estimatedValue",
          header: "Precio Unitario Estimado",
          size: "100",
          textAlign: "center",
          enableSorting: false,
          Cell: ({ cell }) => formatValueLocale(cell.getValue()),
        },
      ],
    },
  },
  ListCompDocuments: {
    component: [
      {
        type: "Grid",
        id_ff: "TabProcedureDocument",
      },
    ],
  },
  TabProcedureDocument: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", intlLabelKey: "tabD.Nro" },
            { key: "name", intlLabelKey: "tabD.Documento" },
            {
              key: "link",
              intlLabelKey: "tabD.Archivo",
              render: (value, row) => (
                <a href={value} target="_blank">
                  {row.name}
                </a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabAwardedContracts: {
    elements: [
      {
        type: "Grid",
        props: {
          component: (item) => (
            <ResponsiveTable
              columns={[
                { key: "ContractType", intlLabelKey: "tabCONT.Tipo" },
                { key: "ContractTerm", intlLabelKey: "tabCONT.Vigencia" },
                {
                  key: "ContractEndDate",
                  intlLabelKey: "tabCONT.FechaFin",
                },
              ]}
              data={item.data.length > 0 ? [item.data[0]?.Contract] : []}
            />
          ),
        },
      },
      {
        type: "Grid",
        props: {
          component: (item) => (
            <ResponsiveTable
              columns={[
                {
                  key: "ContractAddress",
                  intlLabelKey: "tabCONT.Direccion",
                },
                { key: "UNSPSCCode", intlLabelKey: "tabCONT.Codigo" },
                {
                  key: "Lot",
                  intlLabelKey: "tabCONT.Lote",
                  cellType: "boolean",
                },
              ]}
              style={{ marginTop: "1rem" }}
              data={item.data.length > 0 ? [item.data[0]?.Contract] : []}
            />
          ),
        },
      },
      {
        type: "Grid",
        props: {
          component: (item) => (
            <ResponsiveTable
              columns={[
                {
                  key: "MiPyme",
                  intlLabelKey: "tabCONT.Empresa",
                  render: (value) =>
                    value === "true" ? "Sí" : value === "false" ? "No" : "-",
                },
              ]}
              style={{ marginTop: "1rem" }}
              data={item.data}
            />
          ),
        },
      },
    ],
  },

  ValueCmb_Carta: {
    objectName: "subjectsCO",
  },

  // From now on, not used.
  TabItemListLast: { elements: [] },
  TabDocumentsFolds: { elements: [] },
  TabDocumentsParticularClauses: { elements: [] },
  TabDocumentsAnnexes: { elements: [] },
  TabDocumentsTechnicalFolds: { elements: [] },
  TabGeneralProcedureActions: { elements: [] },
  TabSupervisingJob: { elements: [] },
  TabRequirement: { elements: [] },
  ListCompWarraty: {
    listData: "StructureDataWarrantyXX",
    component: [],
  },
  TabWarranty: { elements: [] },
  TabOffersInfo: { elements: [] },
  TabContractConditions: { elements: [] },
  TabDelivery: { elements: [] },
  TabRelatedOfficials: { elements: [] },
  TabAwardedPurchaseOrders: { elements: [] },
  TabItemModalDeliveryDetail: { elements: [] },
};
