import { useGlobalStore } from "global-store/useGlobalStore";

import { Typography } from "@mui/material";

import imgFalcon from "../../assets/images/imgFalcon.png";

export default function SidebarFooter() {
  const { userInfo } = useGlobalStore((state) => ({
    userInfo: state.userInfo,
  }));

  return (
    <div className="app-sidebar-footer-wrapper">
      <ul
        className="app-sidebar-footer"
        style={{ justifyContent: "space-around" }}>
        <img src={imgFalcon} style={{ width: "120px" }} alt="" />
        <Typography variant="h6">{`${userInfo.appVersion}`}</Typography>
      </ul>
    </div>
  );
}
