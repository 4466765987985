import { Box, Skeleton } from "@mui/material";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useGlobalStore } from "global-store/useGlobalStore";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import {
  convertEndDateToISOLarge,
  convertStartDateToISOLarge,
} from "utils/DateHelper";
import { TENDER_ANALYSIS_MODULE_ID } from "utils/constants";
import { addOrUpdateAnalysisFilter } from "../analysisService";
import ManageFilterBasicData from "./ManageFilterBasicData";
import ManageFilterCustomRangeModal from "./ManageFilterCustomRange";
import ManageFilterTabs from "./ManageFilterTabs";
import { convertCriteriaWordsToString } from "utils/common";

const LoadingSkeleton = (props) => {
  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        {...props}>
        <Skeleton animation="wave" width={"100%"} />
        <Skeleton animation="wave" width={"100%"} />
        <Skeleton animation="wave" width={"100%"} />
        <Skeleton animation="wave" width={"100%"} />
      </Box>
    </Fragment>
  );
};

const setDates = (startDate, endDate) => {
  return { startDate, endDate };
};

export const FIXED_TIME_RANGE = { key: "1", value: "Fijo" };
export const CUSTOM_TIME_RANGE = { key: "14", value: "Personalizado" };
export const defaultTimeRange = { key: "8", value: "Trimestre actual" };

export const timeRangePresets = {
  //FIJO
  1: () => {},
  //ayer
  3: () => {
    const aux = moment().subtract(1, "days");
    return setDates(aux, aux);
  },
  //hoy
  2: () => {
    const aux = moment();
    return setDates(aux, aux);
  },
  //SEMANA_ACTUAL
  4: () => {
    const start = moment().startOf("week");
    const end = moment();
    return setDates(start, end);
  },
  //SEMANA_ANTERIOR
  5: () => {
    const start = moment().subtract(1, "week").startOf("week");
    const end = moment().subtract(1, "week").endOf("week");
    return setDates(start, end);
  },
  //MES_ACTUAL
  6: () => {
    const start = moment().startOf("month");
    const end = moment();
    return setDates(start, end);
  },
  // MES_ANTERIOR
  7: () => {
    const start = moment().subtract(1, "month").startOf("month");
    const end = moment().subtract(1, "month").endOf("month");
    return setDates(start, end);
  },
  //TRIMESTRE_ACTUAL
  8: () => {
    const start = moment().quarter(moment().quarter()).startOf("quarter");
    const end = moment().quarter(moment().quarter());
    return setDates(start, end);
  },
  //TRIMESTRE_ANTERIOR
  9: () => {
    const start = moment()
      .quarter(moment().quarter() - 1)
      .startOf("quarter");
    const end = moment()
      .quarter(moment().quarter() - 1)
      .endOf("quarter");
    return setDates(start, end);
  },
  //SEMESTRE_ACTUAL
  10: () => {
    const currentMonth = moment().month();
    const semesterStart = currentMonth > 5 ? 6 : 0;
    const startDate = moment().month(semesterStart);
    const start = startDate.startOf("month");
    const end = moment();
    return setDates(start, end);
  },
  //SEMESTRE_ANTERIOR
  11: () => {
    const currentMonth = moment().subtract(6, "months");
    const currentYear = moment(currentMonth).year();
    const semesterStart = currentMonth.month() > 5 ? 6 : 0;
    const semesterEnd = currentMonth.month() > 5 ? 11 : 5;
    const startDate = moment().month(semesterStart).year(currentYear);
    const endDate = moment().month(semesterEnd).year(currentYear);
    const start = startDate.startOf("month");
    const end = endDate.endOf("month");
    return setDates(start, end);
  },
  //ANIO_ACTUAL
  12: () => {
    const start = moment().startOf("year");
    const end = moment();
    return setDates(start, end);
  },
  //ANIO_ANTERIOR
  13: () => {
    const start = moment().startOf("year").subtract(1, "year").startOf("month");
    const end = moment().subtract(1, "year").month(11).endOf("month");
    return setDates(start, end);
  },
  //PERSONALIZADO
  14: () => {},
};

export const filterDefault = {
  analysisFilterName: "",
  rangeFrom: "",
  rangeTo: "",
  range: { key: "8", value: "Trimestre actual" },
  startDate: timeRangePresets[8]().startDate,
  endDate: timeRangePresets[8]().endDate,
  types: [],
  includeWords: [{ word: "", words: [] }],
  excludeWords: [{ word: "", words: [] }],
  allowDatePicker: true,
  result: false,
  sourceIds: "",
  sources: [],
  organisms: [],
  competitors: [],
};

const ManageAnalysisFiltersModal = ({
  open,
  onClose,
  onSave,
  filter = null,
  analysisFilterModuleId,
}) => {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [filterToManage, setFilterToManage] = useState(null);
  const [tabsCount, setTabsCount] = useState({
    products: 0,
    organisms: 0,
    competitors: 0,
  });
  const [openRangeCustom, setOpenRangeCustom] = useState(false);

  const {
    setSnackbarSaveChanges,
    showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    setSnackbar,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    setSnackbar: state.setSnackbar,
  }));

  const setCriteriaWords = (words) => {
    if (!words || words.length < 1) return [];
    const list = words.split("@");
    return list
      .filter((item) => item.length > 0)
      .map((item) => {
        return {
          word: "",
          words: item.split("|"),
        };
      });
  };

  const updateTabsCount = (newFilter) => {
    const newTabsCount = { ...tabsCount };
    newTabsCount.products =
      newFilter.includeWords.filter((items) => items.words.length > 0).length +
      newFilter.excludeWords.filter((items) => items.words.length > 0).length;
    newTabsCount.organisms = newFilter.organisms.length;
    newTabsCount.competitors = newFilter.competitors.length;
    setTabsCount(newTabsCount);
    return newTabsCount;
  };

  const initEmptyData = () => {
    let newFilterDefault = JSON.parse(JSON.stringify(filterDefault));
    setFilterToManage({
      ...newFilterDefault,
      startDate: timeRangePresets[8]().startDate,
      endDate: timeRangePresets[8]().endDate,
    });
    let newTabsCount = updateTabsCount(newFilterDefault);
    verifySaveButtonDisabled(newFilterDefault, newTabsCount);
  };

  const fillFilterData = () => {
    const analysisFilter = {
      analysisFilterId: filter.analysisFilterId,
      // Basic filter data
      analysisFilterName: filter.analysisFilterName,
      range: {
        key: filter.dateRangeId,
        value: filter.dateRangeName,
      },
      dateRangeUnitId: filter.dateRangeUnitId,
      dateRangeQty: filter.dateRangeQty,
      startDate: moment(filter.startDate),
      endDate: moment(filter.endDate),
      allowDatePicker:
        filter.dateRangeId.toString() === FIXED_TIME_RANGE.key ? false : true,
      types: filter.types,
      sourceIds: filter.sourceIds,
      sources: [],
      rangeFrom: filter.priceRangeFrom, // "Valor desde"
      rangeTo: filter.priceRangeTo, // "Valor hasta"
      result: filter.result === 1 ? true : false, // "Solo adjudicadas"
      applyToCompetitorDescription: filter.applyToCompetitorDescription, // "Aplicar en desc. vendedor"
      // Products
      includeWords: setCriteriaWords(filter.includeWords),
      excludeWords: setCriteriaWords(filter.excludeWords),
      // Organisms
      organisms: filter.organisms.map((item) => {
        //TBD
        return {
          id: item.clientUnitId,
          entity: { ...item },
          selected: item.excludeClientUnit,
        };
      }),
      // Competitors
      competitors: filter.competitors.map((item) => {
        // TBD
        return {
          id: item.competitorId,
          entity: { ...item },
          selected: item.excludeCompetitor,
        };
      }),
    };
    setFilterToManage(analysisFilter);
    let newTabsCount = updateTabsCount(analysisFilter);
    verifySaveButtonDisabled(analysisFilter, newTabsCount);
  };

  useEffect(() => {
    if (open) {
      if (filter) {
        fillFilterData();
      } else {
        initEmptyData();
      }
    }
  }, [open]);

  const handleFilterToManageChange = (newFilter) => {
    setFilterToManage(newFilter);
    let newTabsCount = updateTabsCount(newFilter);
    verifySaveButtonDisabled(newFilter, newTabsCount);
  };

  // TODO: revisar si queda asi o se lleva al hijo
  const handleBasicDataDateRangeChange = (value) => {
    if (!value) return;
    const aux = { ...filterToManage };
    const key = value.key.toString();
    aux["range"] = value;
    if (value && CUSTOM_TIME_RANGE.key === key) {
      setOpenRangeCustom(true);
      handleFilterToManageChange(aux);
      return;
    }
    if (value && FIXED_TIME_RANGE.key !== key) {
      const dates = timeRangePresets[key]();
      aux["startDate"] = dates.startDate;
      aux["endDate"] = dates.endDate;
    } else {
      aux["startDate"] = moment();
      aux["endDate"] = moment();
    }
    handleFilterToManageChange(aux);
  };

  const handleSave = async () => {
    if (verifySaveButtonDisabled(filterToManage, tabsCount)) return;
    const request = {
      AnalysisFilterName: filterToManage.analysisFilterName,
      StartDate: convertStartDateToISOLarge(filterToManage.startDate),
      EndDate: convertEndDateToISOLarge(filterToManage.endDate),
      IncludeWords: convertCriteriaWordsToString(filterToManage.includeWords),
      ExcludeWords: convertCriteriaWordsToString(filterToManage.excludeWords),
      IsPreview: 0, // TODO: ??????
      AnalysisFilterModuleId: analysisFilterModuleId,
      DateRangeId: filterToManage.range.key,
      Organisms: filterToManage.organisms.map((item) => {
        return { ...item.entity, excludeClientUnit: item.selected };
      }),
      Competitors: filterToManage.competitors.map((item) => {
        return { ...item.entity, excludeCompetitor: item.selected };
      }),
      PriceRangeFrom: filterToManage.rangeFrom,
      PriceRangeTo: filterToManage.rangeTo,
      types: filterToManage.types,
      Result: filterToManage.result ? 1 : null,
      ApplyToCompetitorDescription: filterToManage.applyToCompetitorDescription,
      SourceIds: filterToManage.sources.map((item) => item.id).join(","),
    };

    if (
      parseInt(filterToManage.range.key) === parseInt(CUSTOM_TIME_RANGE.key)
    ) {
      request.DateRangeUnitId = filterToManage.dateRangeUnitId;
      request.DateRangeQty = filterToManage.dateRangeQty;
    }
    if (filterToManage.analysisFilterId) {
      request.AnalysisFilterId = filter.analysisFilterId;
    }

    showSavingStateSpinnerNoTimeout();
    addOrUpdateAnalysisFilter(request)
      .then((response) => {
        hideLoadingSpinner();
        setSnackbarSaveChanges();
        setFilterToManage(null);
        onSave(request);
      })
      .catch((error) => {
        hideLoadingSpinner();
        const snackbarMessage = {
          severity: "error",
          title: "Error",
          subTitle:
            "Ha ocurrido un error de nuestra parte al crear el filtro. Intente nuevamente o comuníquese con soporte.",
        };
        if (error.msgErrorKey === "ENTITY_ALREADY_EXISTS") {
          snackbarMessage.severity = "warning";
          snackbarMessage.title = "Advertencia";
          snackbarMessage.subTitle =
            "Ya existe un filtro con el mismo nombre. Por favor, elija otro.";
        }
        if (error.msgErrorKey === "ENTITY_TOO_LONG") {
          snackbarMessage.severity = "warning";
          snackbarMessage.title = "Advertencia";
          snackbarMessage.subTitle =
            "El nombre del filtro supera el límite de 50 caracteres. Por favor, elija un nombre más corto.";
        }
        setSnackbar({
          open: true,
          severity: snackbarMessage.severity,
          title: snackbarMessage.title,
          subTitle: snackbarMessage.subTitle,
        });
      });
  };

  const verifySaveButtonDisabled = (newFilter, newTabsCount) => {
    let error = false;
    if (analysisFilterModuleId === TENDER_ANALYSIS_MODULE_ID) {
      error ||=
        newTabsCount.products === 0 &&
        newTabsCount.organisms === 0 &&
        newTabsCount.competitors === 0;
    }

    error ||= newFilter.analysisFilterName.trim().length === 0;
    error ||= newFilter.startDate === null;
    error ||= newFilter.endDate === null;
    error ||=
      newFilter.startDate !== null &&
      newFilter.endDate !== null &&
      newFilter.startDate > newFilter.endDate;

    // Validate includeWords and excludeWords char count
    const includeWordsCharacterCount = newFilter.includeWords
      .reduce((acc, item) => [...acc, ...item.words], [])
      .reduce((acc, item) => acc + item.length, 0);
    const excludeWordsCharacterCount = newFilter.excludeWords
      .reduce((acc, item) => [...acc, ...item.words], [])
      .reduce((acc, item) => acc + item.length, 0);
    error ||=
      includeWordsCharacterCount > 500 || excludeWordsCharacterCount > 500;

    setSaveButtonDisabled(error);
    return error;
  };

  return (
    <Fragment>
      <GeneralPurposeModal
        open={open}
        onClose={() => {
          setFilterToManage(null);
          onClose();
        }}
        title="Filtros de previsualización de Reporte"
        onSave={handleSave}
        onSaveDisabled={saveButtonDisabled}
        maxWidth="lg"
        height={650}
        closeText="Cancelar">
        <ManageFilterCustomRangeModal
          open={openRangeCustom}
          onClose={() => setOpenRangeCustom(false)}
          filter={filterToManage}
          handleFilterToManageChange={handleFilterToManageChange}
        />
        {!filterToManage && <LoadingSkeleton />}
        {filterToManage && (
          <ManageFilterBasicData
            filter={filterToManage}
            handleFilterToManageChange={handleFilterToManageChange}
            handleBasicDataDateRangeChange={handleBasicDataDateRangeChange}
            analysisFilterModuleId={analysisFilterModuleId}
          />
        )}
        <div
          className="divider border-2 border-light rounded"
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
            marginTop: "1rem",
          }}
        />
        {!filterToManage && <LoadingSkeleton sx={{ marginTop: "4rem" }} />}
        {filterToManage && (
          <ManageFilterTabs
            filter={filterToManage}
            tabsCount={tabsCount}
            handleFilterToManageChange={handleFilterToManageChange}
          />
        )}
      </GeneralPurposeModal>
    </Fragment>
  );
};

export default ManageAnalysisFiltersModal;
