import { Close } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AutocompletePaging from "components/CustomFields/AutocompletePaging";
import { ScrollableCardContent } from "pages/SmartSearch/FiltersAdmin/FiltersManageModalContent";
import { Fragment, useEffect, useState } from "react";

const EntityTabContent = ({
  filterToManage,
  handleFilterToManageChange,
  entityKey,
  entityIdKey,
  loadOptionsCallback,
}) => {
  const [entity, setEntity] = useState(null);
  const handleChange = (value) => {
    let newEntity = {
      entity: value,
      selected: false,
    };
    let newEntities = [newEntity, ...filterToManage[entityKey]];
    handleFilterToManageChange({ ...filterToManage, [entityKey]: newEntities });
    setEntity(value);
  };

  useEffect(() => {
    setEntity(null);
  }, [entity]);

  const handleCheck = (e, index) => {
    let newEntities = [...filterToManage[entityKey]];
    newEntities[index].selected = e.target.checked;
    handleFilterToManageChange({
      ...filterToManage,
      [entityKey]: newEntities,
    });
  };

  const handleRemoveItem = (index) => {
    let newEntities = [...filterToManage[entityKey]];
    newEntities.splice(index, 1);
    handleFilterToManageChange({
      ...filterToManage,
      [entityKey]: newEntities,
    });
  };

  return (
    <Fragment>
      <Card className="card-box text-black-50 bg-secondary">
        <CardHeader
          sx={{
            padding: "0.5rem",
            //change action css
            "& .MuiCardHeader-action": {
              flex: "0 0 auto",
              m: 0,
            },
          }}
          title={
            <Box
              display="flex"
              alignItems="center"
              flexDirection={"row"}
              gap={"1rem"}>
              <AutocompletePaging
                value={entity}
                onChange={handleChange}
                loadOptionsCallback={loadOptionsCallback}
                getOptionLabel={(option) =>
                  option ? `${option.name}  |  ${option.uniqueId}` : ""
                }
                textFieldPlaceholder="Seleccione..."
                fullWidth
                textFieldVariant="outlined"
                getOptionDisabled={(option) =>
                  filterToManage[entityKey].some(
                    (item) =>
                      item.entity.uniqueId === option.uniqueId ||
                      +item.entity[entityIdKey] === +option[entityIdKey],
                  )
                }
              />
            </Box>
          }
        />

        <ScrollableCardContent sx={{ p: 0, height: 340 }}>
          <List className="p-2">
            <ListItem>
              <ListItemText primary="Nombre" sx={{ width: "90%" }} />
              <ListItemText primary="Excluir" sx={{ width: "10%" }} />
              <ListItemText primary="" />
            </ListItem>
            <Divider />
          </List>

          <List className="p-2" dense>
            {filterToManage[entityKey].map((item, index) => (
              <ListItem key={item.entity.uniqueId} sx={{ py: 0 }}>
                <ListItemText
                  primary={`${item.entity.name}${
                    item.entity.uniqueId ? `  |  ${item.entity.uniqueId}` : ""
                  }`}
                  sx={{ width: "90%" }}
                />
                <ListItemText
                  primary={
                    <>
                      <Checkbox
                        checked={item.selected}
                        onChange={(e) => handleCheck(e, index)}
                      />
                      <IconButton
                        color="primary"
                        sx={{ ml: 2.5 }}
                        onClick={() => handleRemoveItem(index)}>
                        <Close />
                      </IconButton>
                    </>
                  }
                  sx={{ width: "10%" }}
                />
              </ListItem>
            ))}
          </List>
        </ScrollableCardContent>
      </Card>
    </Fragment>
  );
};

export default EntityTabContent;
