import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { LangContext } from "intl/context/langContext";
import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import moment from "moment";
import {
  ChecklistHeader,
  baseChecklistData,
} from "pages/TenderInformationContent/ManagementContent/Checklist";
import LegalDocumentsHeader from "pages/TenderInformationContent/ManagementContent/LegalDocuments/legalDocumentsHeader";
import {
  fillItemsListSheet,
  getItemsListData,
  getItemsListFormattedInitialData,
} from "pages/TenderInformationContent/ManagementContent/ManagedItemsList/managedItemsListExcelService";
import managedItemsListHeader from "pages/TenderInformationContent/ManagementContent/ManagedItemsList/managedItemsListHeader";
import { SanctionsHeader } from "pages/TenderInformationContent/ManagementContent/Sanctions";
import {
  addOrUpdatePenalty,
  deletePenalties,
  listPenalties,
  listPenaltyReasons,
  listTags,
  manageTenderData,
} from "pages/TenderInformationContent/tenderInformationContentService";
import { TenderPanel } from "pages/TenderInformationContent/tenderPanelItems";
import SendTenderByEmailForm from "pages/TenderManagement/SendTenderByEmailForm";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { formatDate, formatShortDate, isLangPE } from "utils/common";
import {
  flattenSheetDataFromJson,
  flattenSheetDataFromJsonFieldBased,
  getNewWorkbook,
  insertData,
  saveNewWorkbook,
} from "utils/excelExportService";

const TenderInformationHeaderActions = ({
  tenderData,
  managingTenderData,
  currentMenu,
  menus,
  setDiscardChanges,
}) => {
  const [discardChangesModalOpen, setDiscardChangesModalOpen] = useState(false);
  const {
    hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout,
    showSavingStateSpinnerNoTimeout,
  } = useGlobalStore((state) => ({
    hideLoadingSpinner: state.hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
  }));

  const updatePenalties = async () => {
    // if there are any penalties in initialPenalties that are not in penalties, delete them
    const penaltiesToDelete = managingTenderData.initialPenalties.filter(
      (initialPenalty) => {
        const penalty = managingTenderData.penalties.find(
          (penalty) => penalty.penaltyId === initialPenalty.penaltyId,
        );
        return !penalty;
      },
    );
    if (penaltiesToDelete.length) {
      const penaltiesDeletionRequest = penaltiesToDelete.map((penalty) => {
        return { penaltyId: penalty.penaltyId };
      });
      await deletePenalties(penaltiesDeletionRequest);
    }

    // if there are any penalties in penalties that are not in initialPenalties, create them
    const penaltiesToCreate = managingTenderData.penalties.filter((penalty) => {
      const initialPenalty = managingTenderData.initialPenalties.find(
        (initialPenalty) => initialPenalty.penaltyId === penalty.penaltyId,
      );
      return !initialPenalty;
    });
    let penaltiesCreationRequest = [];
    if (penaltiesToCreate.length) {
      penaltiesCreationRequest = penaltiesToCreate.map((penalty) => {
        return {
          TenderId: managingTenderData.tenderId,
          PenaltyReasonId: penalty.penaltyReasonId,
          // PenaltyId: +penalty.penaltyId,
          Data: JSON.stringify({
            agreedDeliveryDate: penalty.agreedDeliveryDate
              ? moment(new Date(penalty.agreedDeliveryDate), "DD/MM/YYYY")
                  .toDate()
                  .toISOString()
              : null,
            actualDeliveryDate: penalty.actualDeliveryDate
              ? moment(new Date(penalty.actualDeliveryDate), "DD/MM/YYYY")
                  .toDate()
                  .toISOString()
              : null,
            daysLate: penalty.daysLate,
            finePercentage: penalty.finePercentage,
            others: penalty.others,
          }),
        };
      });
    }

    // if there are any penalties in penalties that are in initialPenalties, update them
    const penaltiesToUpdate = managingTenderData.penalties.filter((penalty) => {
      const initialPenalty = managingTenderData.initialPenalties.find(
        (initialPenalty) => initialPenalty.penaltyId === penalty.penaltyId,
      );
      return initialPenalty;
    });
    let penaltiesUpdateRequest = [];
    if (penaltiesToUpdate.length) {
      penaltiesUpdateRequest = penaltiesToUpdate.map((penalty) => {
        return {
          TenderId: managingTenderData.tenderId,
          PenaltyReasonId: penalty.penaltyReasonId,
          PenaltyId: +penalty.penaltyId,
          Data: JSON.stringify({
            agreedDeliveryDate: penalty.agreedDeliveryDate
              ? moment(new Date(penalty.agreedDeliveryDate), "DD/MM/YYYY")
                  .toDate()
                  .toISOString()
              : null,
            actualDeliveryDate: penalty.actualDeliveryDate
              ? moment(new Date(penalty.actualDeliveryDate), "DD/MM/YYYY")
                  .toDate()
                  .toISOString()
              : null,
            daysLate: penalty.daysLate,
            finePercentage: penalty.finePercentage,
            others: penalty.others,
          }),
        };
      });
    }
    let addOrUpdateRequest = [
      ...penaltiesCreationRequest,
      ...penaltiesUpdateRequest,
    ];
    await addOrUpdatePenalty(addOrUpdateRequest);
  };

  const handleSaveManageChanges = async () => {
    showSavingStateSpinnerNoTimeout();
    const manageDetails = managingTenderData.manageDetails.map((item) => {
      item.estimatedTotal = +item.estimatedTotal;
      return item;
    });
    const documentsIds = managingTenderData.legalDocuments.length
      ? managingTenderData.legalDocuments.map((item) => item.legalDocumentId)
      : [];

    const request = {
      TenderNumber: tenderData.tenderNumber,
      ManageFiles: managingTenderData.files,
      SellerIds: managingTenderData.assignments.sellers.map(
        (item) => item.sellerId,
      ),
      TagIds: managingTenderData.assignments.tags.map((item) => item.tagId),
      CategoryIds: managingTenderData.assignments.categories.map(
        (item) => item.categoryId,
      ),
      UserIds: managingTenderData.assignments.users.map((item) => item.userId),
      LegalDocumentIds: documentsIds,
      ManageComments: managingTenderData.manageComments,
      ManageDetails: manageDetails.map((item) => {
        const obj = {
          manageId: item.manageId,
          manageDetailId: item.manageDetailId,
          tenderDetailId: item.tenderDetailId,
          itemNumber: item.itemNumber,
          companyId: item.companyId,
          estimatedTotal: item.estimatedTotal,
          offered: item.offered,
          comments: item.comments,
          createdByUserId: item.createdByUserId,
          creationDate: item.creationDate,
          searchFilterResult: item.searchFilterResult,
          productId: item.productId,
          deliveryDate: item.deliveryDate,
          tagIds: item.tagIds,
          isPrimary: item.isPrimary,
        };
        return obj;
      }),
      ManageInternalStatusId: managingTenderData.assignments.internalStatus
        ? managingTenderData.assignments.internalStatus.manageInternalStatusId
        : null,
      ManageExtensions: managingTenderData.manageExtensions,
    };
    await manageTenderData(request);
    await updatePenalties();
    setTimeout(() => {
      hideLoadingSpinner();
      setDiscardChanges(true);
    }, 500);
  };
  //#region Export to Excel
  // -- EXPORT TO EXCEL LOGIC -- // // TODO: Refactor this logic to a service
  const handleData = useHandleDataByLang("TenderContainer", "Tab", tenderData);
  const langChange = useContext(LangContext);
  const intl = useIntl();

  const parseColumnsData = (columns, item) => {
    let obj = {};
    columns.forEach((column) => {
      const columnLabel = column.intlLabelKey
        ? intl.formatMessage({ id: column.intlLabelKey, defaultMessage: "N/A" })
        : column.label;
      if (column.render) {
        if (column.render(item[column.key], item)?.type) {
          obj[columnLabel] = item[column.key]?.toString();
        } else {
          obj[columnLabel] = column.render(item[column.key], item)?.toString();
        }
      } else if (column.cellType === "date" && item[column.key]) {
        //TODO: check if it can be done with render or smth
        obj[columnLabel] = formatShortDate(item[column.key]);
      } else if (column.cellType === "datetime" && item[column.key]) {
        //TODO: check if it can be done with render or smth
        obj[columnLabel] = formatDate(item[column.key]);
      } else {
        obj[columnLabel] = item[column.key]?.toString();
      }
    });
    return obj;
  };

  const handleExportCompleteInfo = async () => {
    showLoadingStateSpinnerNoTimeout();
    // Export Portal Info tabs
    let portalInfoMenus = menus[TenderPanel.PORTAL_INFO]?.items;
    let workbook = getNewWorkbook();
    let sheetCount = 0;

    portalInfoMenus.forEach((specificMenu, sheetIndex) => {
      if (!specificMenu.langForm) return;

      const { columnsExport, exportData } =
        specificMenu.title === TenderPanel.INFO_ITEMS_LIST
          ? getExportInfoItemsList(specificMenu)
          : getExcelData(specificMenu);
      populateSheet(
        workbook,
        sheetCount,
        `Info - ${specificMenu.titlePanel}`,
        exportData,
        columnsExport,
      );
      sheetCount++;
    });

    // Export Manage tabs
    let manageMenus = menus[TenderPanel.MANAGEMENT]?.items;
    let manageExportFunctions = {
      [TenderPanel.MANAGED_ITEMS_LIST]: exportAllDetails,
      [TenderPanel.LEGAL_DOCUMENTS]: exportLegalDocuments,
      [TenderPanel.SANCTIONS]: exportSanctions,
      [TenderPanel.CHECKLIST]: exportChecklist,
    };
    for (let i = 0; i < manageMenus.length; i++) {
      const specificMenu = manageMenus[i];
      if (!manageExportFunctions[specificMenu.title]) continue;
      await manageExportFunctions[specificMenu.title](workbook, sheetCount);
      sheetCount++;
    }

    // Save the workbook
    await saveExcel(workbook, "Info Gestión");
    hideLoadingSpinner();
  };

  const getExcelData = (selectedMenu = currentMenu) => {
    const result = handleData.getDataByCountry(selectedMenu.idLang);
    let columns = [];
    selectedMenu.langForm.forEach((item) => {
      // put all columns in one array, from all elements in elements
      if (!langChange.configForm[item]) return;
      const config = langChange.configForm[item].elements;
      if (!config) return;
      config.forEach((item) => {
        if (item.props.columns) {
          columns = [...columns, ...item.props.columns];
        }
      });
    });

    // Parse columns data to get the value of each column, returns an object with the column label as key and the value as value
    let finalData = [];
    if (Array.isArray(result)) {
      result.forEach((item) => {
        finalData.push(parseColumnsData(columns, item));
      });
    } else {
      finalData.push(parseColumnsData(columns, result));
    }

    // Map columns and data to get the export data
    let columnsExport = columns.map((item) =>
      item.intlLabelKey
        ? intl.formatMessage({ id: item.intlLabelKey, defaultMessage: "N/A" })
        : item.label,
    );
    let exportData = flattenSheetDataFromJson(finalData, columnsExport);

    return { columnsExport, exportData };
  };

  const handleExportIndividualTabInfo = async (
    selectedMenu = currentMenu,
    sheetIndex = 0,
  ) => {
    if (!selectedMenu.showExport) return;
    const { columnsExport, exportData } = getExcelData(selectedMenu);

    let workbook = getNewWorkbook();
    populateSheet(
      workbook,
      sheetIndex,
      selectedMenu.title,
      exportData,
      columnsExport,
    );

    return workbook;
  };

  const populateSheet = (workbook, sheetNumber, sheetName, data, columns) => {
    // Workbook starts with 1 sheet, so if sheetNumber is > 0, we need to add a new sheet
    if (sheetNumber > 0) {
      workbook.addWorksheet(sheetName);
    }

    const sheet1 = workbook.getWorksheet(sheetNumber + 1);
    sheet1.name = sheetName;

    // Style header row
    sheet1.getRow(1).font = { bold: true };

    // Write items data
    insertData(sheet1, data, "A1");

    // Set column widths
    columns.forEach((_, columnIndex) => {
      const letter = String.fromCharCode(65 + columnIndex);
      sheet1.getColumn(letter).width = 25;
    });
  };

  const saveExcel = async (workbook, fileTitle) => {
    const fileName = `${fileTitle} - ${
      isLangPE() ? tenderData.fileNumber : tenderData.tenderNumber
    }.xlsx`;
    return await saveNewWorkbook(workbook, fileName);
  };
  // --  -- //
  //#endregion Export to Excel

  //#region Export Info Items List
  const getExportInfoItemsList = (menu) => {
    const data = handleData.getDataByCountry(menu.idLang);
    let columns = [];
    menu.langForm.forEach((item) => {
      if (!langChange.configForm[item]) return;
      const cols = langChange.configForm[item].element.columns;
      if (!cols) return;
      columns = [...columns, ...cols];
    });
    const header = columns.map((item) => item.header);

    // MRT Export section (TODO: make an aux function)
    // Remove columns without accessorKey or with hideToExport
    let columnsCopy = [
      ...columns.filter((col) => col.accessorKey && !col.hideToExport),
    ];
    // flatten with json parse the "data" field in result
    // and add the keys of 'data' to the table columns
    let resultParsed = data.map((item) => {
      if (item.data) {
        let dataParsed = JSON.parse(item.data);
        // add its keys to HeaderTable if they are not already there
        Object.keys(dataParsed).forEach((key) => {
          if (!columnsCopy.find((x) => x.accessorKey === key)) {
            columnsCopy.push({ accessorKey: key, header: key, width: 150 });
          }
        });
        return { ...item, ...dataParsed };
      } else {
        return { ...item };
      }
    });

    // Apply the Cell function respectively to each result
    resultParsed = resultParsed.map((item) => {
      let newItem = { ...item };
      columnsCopy.forEach((col) => {
        if (col.Cell) {
          let cellFunction =
            col.excelExportFormat || col.getDefaultCellComponent || col.Cell;
          let finalCell = cellFunction({
            cell: {
              getValue: () => {
                // If an accessorKey has nested properties, get the value from the nested object
                if (col.accessorKey.includes(".")) {
                  let nestedKeys = col.accessorKey.split(".");
                  let nestedValue = item;
                  nestedKeys.forEach((key) => {
                    nestedValue = nestedValue[key];
                  });
                  return nestedValue;
                }
                return item[col.accessorKey];
              },
              row: { original: item },
            },
            column: { ...col },
          });

          // Only add the value if it is a string or number
          if (typeof finalCell === "string" || typeof finalCell === "number") {
            newItem[col.accessorKey] = finalCell;
          }
          //if it is a react component, get the text if exists
          else if (
            typeof finalCell === "object" &&
            finalCell?.props &&
            typeof finalCell.props.children === "string"
          ) {
            newItem[col.accessorKey] = finalCell.props.children;
          } else if (!finalCell) {
            newItem[col.accessorKey] = "";
          }
        }
      });
      return newItem;
    });
    // End MRT Export section

    // Filter result parsed to get only the columns that are in columns.accessorKey
    resultParsed = resultParsed.map((item) => {
      let newItem = null;
      columnsCopy.forEach((col) => {
        if (newItem === null) {
          newItem = {};
        }
        newItem[col.accessorKey] = item[col.accessorKey];
      });
      return newItem;
    });
    let exportData = flattenSheetDataFromJsonFieldBased(
      resultParsed,
      columnsCopy,
    );

    return { columnsExport: header, exportData };
  };
  //#endregion Export Info Items List

  //#region Manage Export Functions
  // -- Manage Export Functions -- //
  const exportAllDetails = async (workbook, sheetNumber) => {
    if (sheetNumber > 0) {
      workbook.addWorksheet("-");
    }
    let tagsList = await listTags({
      orderBy: "",
      pageSize: 1000,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    });
    tagsList.data = tagsList.data.map((tag) => {
      return { id: tag.tagId, name: tag.name };
    });
    let allDetailsHeader = managedItemsListHeader(
      intl,
      tenderData.modalityType,
      tagsList.data,
    );
    let dataToExportFormatted = getItemsListFormattedInitialData(
      managingTenderData.manageDetails,
      tagsList.data,
    );

    const { exportHeader, exportData, exportItemsData, exportTenderData } =
      getItemsListData(
        intl,
        tenderData,
        allDetailsHeader,
        dataToExportFormatted,
      );

    fillItemsListSheet(
      workbook,
      intl,
      sheetNumber,
      exportHeader,
      exportData,
      exportItemsData,
      exportTenderData,
    );
  };

  const exportLegalDocuments = async (workbook, sheetNumber) => {
    let formattedData = managingTenderData.legalDocuments.map((item) => {
      return {
        ...item,
        endDate: item.endDate ? formatShortDate(item.endDate) : "",
        active: item.active ? "Si" : "No",
      };
    });

    let exportHeader = LegalDocumentsHeader(intl).filter(
      (item) => item.header && !item.hideToExport,
    );
    let exportData = flattenSheetDataFromJsonFieldBased(
      formattedData,
      exportHeader,
    );

    populateSheet(
      workbook,
      sheetNumber,
      intl.formatMessage({
        id: "etq_CartaFianza",
        defaultMessage: "Carta Fianza",
      }),
      exportData,
      exportHeader,
    );
  };

  const exportSanctions = async (workbook, sheetNumber) => {
    let penalties = await listPenalties();
    const data = penalties.data.map((item) => {
      return { ...item, ...JSON.parse(item.data) };
    });
    // filter items that have TenderId null or are about to be added
    const penaltiesFilteredByTenderIds = data.filter((item) => {
      return item.tenderId === tenderData.tenderId;
    });

    let penaltyReasons = await listPenaltyReasons();
    let penaltyReasonsList = penaltyReasons.data.map((item) => {
      return { id: item.penaltyReasonId, name: item.description };
    });

    let header = SanctionsHeader(penaltyReasonsList)
      .filter((item) => !item.hideToExport)
      .map((item) => {
        return { accessorKey: item.accessorKey, header: item.header };
      });

    // set Description to penaltyReasonId so it can be exported with the display value and not the id
    let formattedData = penaltiesFilteredByTenderIds.map((item) => {
      return {
        ...item,
        agreedDeliveryDate: item.agreedDeliveryDate
          ? formatShortDate(item.agreedDeliveryDate)
          : "",
        actualDeliveryDate: item.actualDeliveryDate
          ? formatShortDate(item.actualDeliveryDate)
          : "",
        penaltyReasonId: item.penaltyReasonDesc,
      };
    });
    let exportData = flattenSheetDataFromJsonFieldBased(formattedData, header);
    populateSheet(
      workbook,
      sheetNumber,
      "Sanciones",
      exportData,
      header.map((item) => item.header),
    );
  };

  const exportChecklist = async (workbook, sheetNumber) => {
    let header = ChecklistHeader.filter((item) => !item.hideToExport).map(
      (item) => item.header,
    );
    let data = JSON.parse(JSON.parse(tenderData?.extension)?.Info);
    let tenderDetailExtension =
      tenderData.tenderDetails.length > 0
        ? JSON.parse(tenderData.tenderDetails[0].extension)
        : {};

    let manageExtensions = (managingTenderData.manageExtensions || []).filter(
      (manageExtension) => {
        return (
          manageExtension.manageId === managingTenderData.manageId &&
          manageExtension.manageExtensionId != null
        );
      },
    );
    if (manageExtensions.length > 0) {
      let extensionData = manageExtensions.map((item) => {
        return {
          ...item,
          manageExtensionId: item.manageExtensionId,
          manageId: item.manageId,
          listData: JSON.parse(item.data),
        };
      });
      manageExtensions = extensionData[0].listData;
    } else {
      manageExtensions = baseChecklistData(data, tenderDetailExtension);
    }
    manageExtensions.forEach((item) => {
      delete item["id"];
    });
    let dataToExport = manageExtensions.map((item) => {
      return {
        ...item,
        status: item.status ? "Si" : "No",
      };
    });

    let exportData = flattenSheetDataFromJson(dataToExport, header);
    populateSheet(workbook, sheetNumber, "Checklist", exportData, header);
  };
  // --  -- //
  //#endregion Manage Export Functions

  //#region Email form
  const [emailFormOpen, setEmailFormOpen] = useState(false);
  const handleEmailFormClose = () => setEmailFormOpen(false);
  const handleEmailFormOpen = () => setEmailFormOpen(true);
  //#endregion Email form

  return (
    <>
      <Box display="flex" gap={2}>
        {currentMenu?.showActionPanel && (
          <>
            <Tooltip
              id="close-button"
              title="Descartar cambios"
              disableInteractive>
              <span>
                <IconButton
                  onClick={() => setDiscardChangesModalOpen(true)}
                  color="primary"
                  size="small"
                  disabled={!managingTenderData?.isModified}>
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              id="save-button"
              title="Guardar cambios"
              disableInteractive>
              <span>
                <IconButton
                  onClick={handleSaveManageChanges}
                  color="primary"
                  size="small"
                  disabled={!managingTenderData?.isModified}>
                  <SaveSharpIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              id="send-button"
              title="Enviar Licitación"
              disableInteractive>
              <span>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={handleEmailFormOpen}>
                  <SendSharpIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {currentMenu?.showExport && (
          <Tooltip id="export" title="Exportar" disableInteractive>
            <IconButton
              aria-label="export"
              color="primary"
              size="small"
              onClick={() =>
                handleExportIndividualTabInfo().then((workbook) => {
                  saveExcel(workbook, currentMenu.title);
                })
              }>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        )}
        {currentMenu?.showExportAll && (
          <Tooltip
            id="exportCompleteInfo"
            title="Exportar información completa"
            disableInteractive>
            <IconButton
              aria-label="export"
              color="primary"
              size="small"
              onClick={handleExportCompleteInfo}>
              <SaveAltIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <DeleteDialog
        open={discardChangesModalOpen}
        onClose={() => setDiscardChangesModalOpen(false)}
        onDelete={() => setDiscardChanges(true)}
        title="Descartar cambios"
        subTitle="¿Está seguro que desea descartar los cambios?"
        deleteText="Descartar"
      />
      <SendTenderByEmailForm
        open={emailFormOpen}
        onClose={handleEmailFormClose}
        tender={tenderData}
        managingTenderData={managingTenderData}
      />
    </>
  );
};

export default TenderInformationHeaderActions;
