import { appReducer } from "./appReducer";
import { userReducer } from "./userReducer";
import ThemeOptions from "./ThemeOptions";

const rootReducer = {
  user: userReducer,
  app: appReducer,
  ThemeOptions,
};

export default rootReducer;
