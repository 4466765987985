import { OpenInNew } from "@mui/icons-material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SendIcon from "@mui/icons-material/Send";
import { Box, Tooltip } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components/index";
import { Fragment, useRef, useState } from "react";
import TenderManagementStatusTabs from "./TenderManagementStatusTabs";
import {
  DeletedTenderManagementHeader,
  TenderManagementHeader,
} from "./tenderManagementHeader";

import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

import services from "services/services";
import { isLangAR } from "utils/common";
import AddTenderModal from "./AddTenderModal";
import DeleteTenderModal from "./DeleteTenderModal";
import EditViewModal from "./EditViewModal";
import SendTenderByEmailForm from "./SendTenderByEmailForm";
import SentEmailsDetails from "./SentEmailsDetails";
import TenderManagementFilters from "./TenderManagementFilters";
import TenderManagementOutsidersButton from "./TenderManagementOutsidersButton";

export const DEFAULT_STATUS_ID = 0;
const DELETED_STATUS_ID = 99;

// Used to add the sent emails column to the table
const addHeaderColumns = (header, column) => {
  if (!header.find((h) => h.accessorKey === column.accessorKey)) {
    header.splice(3, 0, column);
  }
  return header;
};

const SentEmailsTooltip = ({ cell, column, resendEmail }) => {
  const tenderSentEmails = useGlobalStore((state) => state.tenderSentEmails);
  if (
    !tenderSentEmails.find((t) => t.tenderId === cell.row.original.tenderId)
  ) {
    return null;
  }
  return (
    <Tooltip
      title={
        <SentEmailsDetails
          tender={cell.row.original}
          sentTenders={tenderSentEmails.filter(
            (t) => t.tenderId === cell.row.original.tenderId,
          )}
          resendEmail={resendEmail}
        />
      }
      placement="right-start">
      <MarkEmailReadIcon />
    </Tooltip>
  );
};

const getHeaderEmailColumn = (resendEmail) => ({
  // TODO: meterlo en el header porq se hace siempre
  intlId: "cols.enviados",
  accessorKey: "sendEmail",
  header: "E-Mail",
  size: 130,
  enableSorting: false,
  disableTooltip: true,
  align: "center",
  Cell: ({ cell, column }) => (
    <SentEmailsTooltip cell={cell} column={column} resendEmail={resendEmail} />
  ),
});

const TenderManagement = (props) => {
  const { setShouldRefetch, getManageFiltersRequest } = useGlobalStore(
    (state) => ({
      setShouldRefetch: state.setShouldRefetch,
      getManageFiltersRequest: state.getManageFiltersRequest,
    }),
  );
  const currentStatusGroup = useGlobalStore(
    (state) => state.viewsState.manage.currentStatusGroup,
  );
  const currentStatusIdRef = useRef(currentStatusGroup);
  currentStatusIdRef.current = currentStatusGroup;

  const [addTenderModalOpen, setAddTenderModalOpen] = useState(false);
  const [deleteTenderModalOpen, setDeleteTenderModalOpen] = useState(false);
  const [editViewModalOpen, setEditViewModalOpen] = useState(false);
  const [tenderToDelete, setTenderToDelete] = useState(null);
  const [emailFormOpen, setEmailFormOpen] = useState(false);
  const [emailFormTender, setEmailFormTender] = useState(null);
  const [previousEmailData, setPreviousEmailData] = useState(null);

  const handleAddTenderModalClose = (resultsFound) => {
    setAddTenderModalOpen(false);
    if (resultsFound) setShouldRefetch();
  };

  const handleDeleteTenderModalClose = (removeSuccess) => {
    setDeleteTenderModalOpen(false);
    setTenderToDelete(null);
    if (removeSuccess) setShouldRefetch();
  };

  const handleEditViewModalClose = () => {
    setEditViewModalOpen(false);
  };

  const handleEmailFormClose = () => {
    setPreviousEmailData(null);
    setEmailFormTender(null);
    setEmailFormOpen(false);
  };

  const resendEmail = (tender, emailData) => {
    setPreviousEmailData(emailData);
    setEmailFormTender(tender);
    setEmailFormOpen(true);
  };

  const fetchAllData = async (pageNumber, pageSize, filter, sorting) => {
    let orderBy = sorting.length > 0 ? sorting[0].id : "publishedDate";
    let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;

    const request = {
      OrderBy: orderBy,
      PageSize: pageSize,
      Filter: filter,
      PageNumber: pageNumber,
      OrderAsc: orderAsc,
      StatusId: currentStatusIdRef.current,
      ...getManageFiltersRequest(),
    };
    const response = await services.manage.getManage(request);

    return {
      result: response.data.currentPageItems,
      total: response.data.totalItems,
    };
  };

  const contextMenuOptions = [
    {
      id: "send-email",
      title: "Enviar licitación",
      icon: <SendIcon fontSize="small" />,
      onClick: (rows, table) => {
        setEmailFormTender(rows[0]);
        setEmailFormOpen(true);
        table.resetRowSelection();
      },
    },
  ].concat(
    isLangAR()
      ? [
          {
            id: "open-link",
            title: "Link al portal / licitación",
            icon: <OpenInNew fontSize="small" />,
            onClick: async (rows) => {
              if (rows[0].link) window.open(rows[0].link, "_blank");
            },
          },
        ]
      : [],
  );

  const options = {
    tableId: "gridTenderManagement",
    header: addHeaderColumns(
      TenderManagementHeader,
      getHeaderEmailColumn(resendEmail),
    ),
    fullHeight: true,
    intlHeaderId: "app.columnsGest",
    tableHeaderTitle: "Gestión",
    toolbarButtons: {
      Add: true,
      AddFunc: () => setAddTenderModalOpen(true),
      Refresh: true,
      Delete: true,
      DeleteFunc: (row, table) => {
        if (!row.fileNumber) return; // TOOD: maybe show a message to the user
        setTenderToDelete(row);
        setDeleteTenderModalOpen(true);
        table.resetRowSelection();
      },
      Export: true,
      ExportFilename: "Gestión - Licitaciones",
      Send: true,
      SendFunc: (row, table) => {
        if (!row.fileNumber) return; // TOOD: maybe show a message to the user
        setEmailFormTender(row);
        setEmailFormOpen(true);
        table.resetRowSelection();
      },
      View: true,
      ViewFunc: () => setEditViewModalOpen(true),
    },
    contextMenuOptions: contextMenuOptions,
    enableRowSelection: true,
    rowDoubleClickFunc: (row) => {
      // navigate to individual tender info management page
      props.history.push(
        `/gestion-informacion/${encodeURIComponent(row.tenderNumber)}`,
      );
    },
  };

  const optionsDeleted = {
    tableId: "gridTenderManagementDeleted",
    header: DeletedTenderManagementHeader,
    fullHeight: true,
    intlHeaderId: "app.columnsGest",
    tableHeaderTitle: "Gestión",
    toolbarButtons: {
      Refresh: true,
      Export: true,
      ExportFilename: "Gestión - Licitaciones eliminadas",
      View: true,
      ViewFunc: () => setEditViewModalOpen(true),
    },
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Gestión de Licitaciones"
        icon={<AppRegistrationIcon />}
        startComponent={
          <>
            <TenderManagementStatusTabs />
            <TenderManagementFilters />
          </>
        }
        endComponent={
          <TenderManagementOutsidersButton history={props.history} />
        }
      />
      <Box display="flex" gap={2} height="100%">
        <Box
          display="flex"
          flex={1}
          sx={{
            // These are needed to make the Box fill the parent container and not the viewport. Prevents overflow of table.
            width: "0px",
            maxWidth: "-webkit-fill-available",
          }}>
          {currentStatusGroup === DELETED_STATUS_ID && (
            <FSTMaterialReactTable
              options={optionsDeleted}
              getData={fetchAllData}
            />
          )}
          {currentStatusGroup !== DELETED_STATUS_ID && (
            <FSTMaterialReactTable options={options} getData={fetchAllData} />
          )}
        </Box>
      </Box>

      <AddTenderModal
        open={addTenderModalOpen}
        onClose={handleAddTenderModalClose}
      />
      <DeleteTenderModal
        open={deleteTenderModalOpen}
        onClose={handleDeleteTenderModalClose}
        tenderToDelete={tenderToDelete}
      />
      <EditViewModal
        open={editViewModalOpen}
        onClose={handleEditViewModalClose}
        onSave={handleEditViewModalClose}
      />
      <SendTenderByEmailForm
        open={emailFormOpen}
        onClose={handleEmailFormClose}
        tender={emailFormTender}
        emailData={previousEmailData}
      />
    </Fragment>
  );
};

export default TenderManagement;
