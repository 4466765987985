import { Card, Grid, Skeleton } from "@mui/material";
import { Fragment } from "react";

export default function HomeLoadingState() {
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className="border-0 card-shadow-success p-3 mb-4">
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={25}
              sx={{ borderRadius: "8px" }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={25}
              sx={{ borderRadius: "8px", mt: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={25}
              sx={{ borderRadius: "8px", mt: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={25}
              sx={{ borderRadius: "8px", mt: 1 }}
            />
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}
