import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function BatchData(props) {
  return (
    <Card className="card-box text-black-50 bg-secondary p-2">
      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Otras condiciones"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.otherConditions.all.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.allData.otherConditions.all.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}>
                  {props.allData.otherConditions.paymentMethod.name}
                </TableCell>
                <TableCell>
                  {props.allData.otherConditions.paymentMethod.data}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}>
                  {props.allData.otherConditions.incoterm.name}
                </TableCell>
                <TableCell>
                  {props.allData.otherConditions.incoterm.data}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Pólizas"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            {props.allData.policy.map((row) => (
              <>
                <TableHead>
                  <TableRow>
                    {row.map((row2) => (
                      <TableCell key={row2.id}>{row2.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                    {row.map((row3) => (
                      <TableCell key={row3.id}>{row3.data}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </>
            ))}
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Colegios Profesionales"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.college.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                {props.allData.college.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
