import { TenderFilesParameters } from "pages/TenderInformationContent/tenderFilesParameters";
import { useGlobalStore } from "global-store/useGlobalStore";

export const downloadBase64File = (fileName, data) => {
  const linkSource = `data:application/octet-stream;base64,${data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const validateFileSize = (file) => {
  if (file.size > TenderFilesParameters.MaxFileSize10MB) {
    const message = `El archivo ${file.name} no puede superar el tamaño maximo permitido de 10 MB!`;
    const setSnackbar = useGlobalStore.getState().setSnackbar;
    setSnackbar({
      open: true,
      title: "Error",
      subTitle: message,
      severity: "error",
    });
    return false;
  }
  return true;
};
