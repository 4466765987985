import { Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import { Add, FilterList, Save } from "@mui/icons-material";
import { emptyFilterContent } from "pages/SmartSearch/FiltersAdmin/FiltersManageModalContent";

export default function FiltersPanelHeaderActions({
  filters,
  handleToggleAllFilters,
  handleSaveSelectedFilters,
  setFilterPanelOpen,
  addEditFilter,
  selectedFilters,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 300,
        bgcolor: "background.paper",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        px: 2,
      }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
        <Tooltip
          title="Seleccionar todos"
          placement="bottom"
          disableInteractive>
          <IconButton
            size="small"
            onClick={handleToggleAllFilters}
            sx={{ ml: "-17px", borderRadius: "0px" }} //  17px = 1rem + 1px
          >
            <Checkbox
              checked={filters.every((item) => item.checked)}
              tabIndex={-1}
              disableRipple
              color="secondary"
            />
          </IconButton>
        </Tooltip>
        <span className="font-weight-bold font-size-md text-uppercase">
          Filtros
        </span>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Tooltip title="Guardar filtros seleccionados" placement="bottom">
          <IconButton
            edge="end"
            component="span"
            className="icon-size-filter"
            size="small"
            onClick={handleSaveSelectedFilters}
            disabled={
              selectedFilters !== null &&
              filters
                .filter((item) => item.checked)
                .map((item) => item.searchFilterId)
                .sort()
                .join(",") === selectedFilters.sort().join(",")
            }
            aria-label="comments">
            <Save />
          </IconButton>
        </Tooltip>
        <Tooltip title="Agregar" placement="bottom">
          <IconButton
            edge="end"
            className="icon-size-filter"
            size="small"
            onClick={() => addEditFilter(emptyFilterContent)}
            aria-label="comments">
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ocultar" placement="right">
          <IconButton
            edge="end"
            className="icon-size-filter"
            size="small"
            onClick={() => setFilterPanelOpen(false)}
            aria-label="comments">
            <FilterList />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
