import { Delete, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  alpha,
} from "@mui/material";
import SaveDialog from "components/ModalDialogs/SaveDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import moment from "moment";
import { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import {
  isLangAR,
  isLangCL,
  nullifyUndefinedValues,
  splitArrayIntoPairs,
} from "utils/common";
import OutsiderTendersFormComponent from "./OutsiderTenderFormComponent";
import { manageOutsiderTenders } from "./outsiderTendersService";
import useOutsiderTenderColumns from "./useOutsiderTenderColumns";

const OutsiderTendersManualInsert = ({
  height,
  tenderTypes,
  tendersStatus,
  sellers,
  categories,
  tags,
  getSourceId,
}) => {
  const intl = useIntl();
  const outsiderTenders = useOutsiderTenderColumns();
  const [saveConfirm, setSaveConfirm] = useState(false);
  const {
    setSnackbarErrorCustom,
    setSnackbarSaveCustom,
    hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout,
    userInfo,
  } = useGlobalStore((state) => ({
    setSnackbarErrorCustom: state.setSnackbarErrorCustom,
    setSnackbarSaveCustom: state.setSnackbarSaveCustom,
    hideLoadingSpinner: state.hideLoadingSpinner,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    userInfo: state.userInfo,
  }));
  const currencyList = userInfo.currencyList.map((item) => ({
    ...item,
    id: item.currencyId,
    name: item.code,
  }));

  const [tenderInputValues, setTenderInputValues] = useState(
    outsiderTenders
      .getTenderValues(tenderTypes, tendersStatus, currencyList)
      .map((item) => ({
        ...item,
        inputValue: "",
      })),
  );

  const [tenderDetailInputValues, setTenderDetailInputValues] = useState([]);
  const [tenderManageInputValues, setTenderManageInputValues] = useState(
    outsiderTenders
      .getTenderManageValues(sellers, tags, categories)
      .map((item) => ({
        ...item,
        inputValue: "",
      })),
  );

  const addTenderDetailInputValue = () => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    let newTenderDetailInputValue = outsiderTenders
      .getTenderDetailValues(currencyList)
      .map((item) => ({
        ...item,
        inputValue: "",
      }));
    newTenderDetailInputValues.push({
      detail: newTenderDetailInputValue,
      awarded: [],
      manageDetail: outsiderTenders
        .getTenderManageDetailValues()
        .map((item) => ({ ...item, inputValue: "" })),
    });
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const addTenderDetailAwardInputValue = (idx) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    newTenderDetailInputValues[idx].awarded.push(
      outsiderTenders.getTenderDetailAwardValues(currencyList).map((item) => ({
        ...item,
        inputValue: "",
      })),
    );
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const deleteTenderDetailInputValue = (idx) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    newTenderDetailInputValues.splice(idx, 1);
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const deleteTenderDetailAwardedInputValue = (idxTD, idx) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    newTenderDetailInputValues[idxTD].awarded.splice(idx, 1);
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const handleTenderInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderInputValues = [...tenderInputValues];
    if (newTenderInputValues[idx].type === "date") {
      newTenderInputValues[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      );
    } else if (newTenderInputValues[idx].type === "number") {
      newTenderInputValues[idx].inputValue = newValue;
    } else {
      newTenderInputValues[idx].inputValue = e.target.value;
    }
    setTenderInputValues(newTenderInputValues);
  };

  const handleTenderManageInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderManageInputValues = [...tenderManageInputValues];
    if (newTenderManageInputValues[idx].type === "date") {
      newTenderManageInputValues[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      ).toISOString();
    } else if (newTenderManageInputValues[idx].type === "number") {
      newTenderManageInputValues[idx].inputValue = newValue;
    } else {
      newTenderManageInputValues[idx].inputValue = e.target.value;
    }
    setTenderManageInputValues(newTenderManageInputValues);
  };

  const handleTenderDetailInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    if (newTenderDetailInputValues[idx2].detail[idx].type === "date") {
      newTenderDetailInputValues[idx2].detail[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      );
    } else if (newTenderDetailInputValues[idx2].detail[idx].type === "number") {
      newTenderDetailInputValues[idx2].detail[idx].inputValue = newValue;
    } else {
      newTenderDetailInputValues[idx2].detail[idx].inputValue = e.target.value;
    }
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const handleTenderDetailAwardInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    if (newTenderDetailInputValues[idx2].awarded[idx3][idx].type === "date") {
      newTenderDetailInputValues[idx2].awarded[idx3][idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      );
    } else if (
      newTenderDetailInputValues[idx2].awarded[idx3][idx].type === "number"
    ) {
      newTenderDetailInputValues[idx2].awarded[idx3][idx].inputValue = newValue;
    } else {
      newTenderDetailInputValues[idx2].awarded[idx3][idx].inputValue =
        e.target.value;
    }
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const handleTenderManageDetailInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    if (newTenderDetailInputValues[idx2].manageDetail[idx].type === "date") {
      newTenderDetailInputValues[idx2].manageDetail[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      );
    } else if (
      newTenderDetailInputValues[idx2].manageDetail[idx].type === "number"
    ) {
      newTenderDetailInputValues[idx2].manageDetail[idx].inputValue = newValue;
    } else {
      newTenderDetailInputValues[idx2].manageDetail[idx].inputValue =
        e.target.value;
    }
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const verifyRequiredFields = () => {
    let error = false;
    // Tender
    tenderInputValues.forEach((tenderInputValue) => {
      if (tenderInputValue.required && tenderInputValue.inputValue === "") {
        error = true;
      }
    });
    // Manage
    tenderManageInputValues.forEach((tenderManageInputValue) => {
      if (
        tenderManageInputValue.required &&
        tenderManageInputValue.inputValue === ""
      ) {
        error = true;
      }
    });
    // Tender Details
    tenderDetailInputValues.forEach((tenderDetailInputValue) => {
      tenderDetailInputValue.detail.forEach((tenderDetailInputValueDetail) => {
        if (
          tenderDetailInputValueDetail.required &&
          tenderDetailInputValueDetail.inputValue === ""
        ) {
          error = true;
        }
      });
      tenderDetailInputValue.manageDetail.forEach(
        (tenderDetailInputValueManageDetail) => {
          if (
            tenderDetailInputValueManageDetail.required &&
            tenderDetailInputValueManageDetail.inputValue === ""
          ) {
            error = true;
          }
        },
      );
      tenderDetailInputValue.awarded.forEach(
        (tenderDetailInputValueAwarded) => {
          tenderDetailInputValueAwarded.forEach(
            (tenderDetailInputValueAwardedDetail) => {
              if (
                tenderDetailInputValueAwardedDetail.required &&
                tenderDetailInputValueAwardedDetail.inputValue === ""
              ) {
                error = true;
              }
            },
          );
        },
      );
    });
    return error;
  };

  const getInputValuesByName = (data) => {
    return data.reduce((acc, curr) => {
      if (curr.type === "select" && curr.inputValue !== "") {
        // get the 'name' of the selected option
        acc[curr.columnValue] = curr.options.find(
          (option) => option.id === curr.inputValue,
        ).name;
      } else {
        acc[curr.columnValue] = curr.inputValue !== "" ? curr.inputValue : null;
      }
      return acc;
    }, {});
  };

  const getInputManageValues = (data) => {
    return data.reduce((acc, curr) => {
      let manageItem = null;
      if (curr.inputValue !== "") {
        manageItem = curr.options.find(
          (option) => option.id === curr.inputValue,
        );
        delete manageItem.id;
      }

      acc[curr.columnValue] = manageItem !== null ? [manageItem] : [];
      return acc;
    }, {});
  };

  const resetForm = () => {
    setTenderInputValues(
      outsiderTenders
        .getTenderValues(tenderTypes, tendersStatus, currencyList)
        .map((item) => ({
          ...item,
          inputValue: "",
        })),
    );
    setTenderDetailInputValues([
      {
        detail: outsiderTenders
          .getTenderDetailValues(currencyList)
          .map((item) => ({
            ...item,
            inputValue: "",
          })),
        awarded: [],
        manageDetail: outsiderTenders
          .getTenderManageDetailValues()
          .map((item) => ({
            ...item,
            inputValue: "",
          })),
      },
    ]);
    setTenderManageInputValues(
      outsiderTenders
        .getTenderManageValues(sellers, tags, categories)
        .map((item) => ({
          ...item,
          inputValue: "",
        })),
    );
  };

  const handleSave = async () => {
    setSaveConfirm(false);
    if (verifyRequiredFields()) {
      setSnackbarErrorCustom("Debe completar los campos requeridos.");
      return;
    }
    showLoadingStateSpinnerNoTimeout("Guardando licitación...");
    let tender = {
      ...getInputValuesByName(tenderInputValues),
      ...getInputManageValues(tenderManageInputValues),
    };

    tender.Details = tenderDetailInputValues.map((tenderDetailInputValue) => {
      let tenderDetail = getInputValuesByName(tenderDetailInputValue.detail);
      tenderDetail = {
        ...tenderDetail,
        ...getInputValuesByName(tenderDetailInputValue.manageDetail),
      };

      tenderDetail.Awardeds = tenderDetailInputValue.awarded.map(
        (tenderDetailInputValueAwarded) => {
          return getInputValuesByName(tenderDetailInputValueAwarded);
        },
      );
      return tenderDetail;
    });

    let result = {
      // Tender
      TenderNumber: tender.tenderNumber.trim(), // si hay mismo tender number, se agregan los details nomas
      FileNumber: tender.fileNumber
        ? tender.fileNumber.trim()
        : tender.tenderNumber.trim(),
      PublishedDate: tender.publishedDate
        ? tender.publishedDate.toISOString()
        : tender.publishedDate,
      TenderName: tender.tenderName,
      TenderDescription: tender.tenderDescription,
      Currency: tender.currency,
      EstimatedValue: tender.estimatedValue,
      Extension: {
        Info: JSON.stringify({
          SourceName: tender.sourceName,
          Mail: tender.mail,
        }),
      },
      TenderType: isLangCL() ? "Convenio Marco" : tender.tenderType, // mandan la string (se da en excel opciones) --> despues se mapea (BE)
      Status: tender.tenderStatus, // mandan la string (se da en excel opciones) --> despues se mapea (BE)
      ClientUnit: {
        // BE looks if the clientUnit exists, if not, creates it
        UniqueId: tender.clientUnitUniqueId.trim(),
        Name: tender.clientUnitName.trim(),
        ...(tender.clientUniqueId != null || tender.clientName != null
          ? {
              Client: {
                UniqueId: tender.clientUniqueId.trim(),
                Name: tender.clientName.trim(),
              },
            }
          : {}),
      },
      EndDate: tender.endDate ? tender.endDate.toISOString() : tender.endDate,
      AwardDate: tender.awardDate ? tender.awardDate.toISOString() : null,
      Link: tender.link,
      BidSecurity: tender.bidSecurity,
      FaithfulObservance: tender.faithfulObservance,
      BidTypeLine: null, // probablemente no se use
      ScrapedDate: new Date().toISOString(), //automatic -> fecha de hoy (poner aca)
      SourceId: getSourceId(), // externo, completado x nosotros (extAR, etc)

      Details: tender.Details.map((tenderDetail) => {
        return {
          ItemNumber: tenderDetail.tenderDetailItemNumber,
          Code: tenderDetail.tenderDetailCode,
          Description: tenderDetail.tenderDetailDescription,
          Qty: tenderDetail.tenderDetailQty,
          UOM: tenderDetail.tenderDetailUom
            ? tenderDetail.tenderDetailUom
            : "Unidad",
          EstimatedValue: tenderDetail.tenderDetailEstimatedValue,
          EstimatedValueCurrency:
            tenderDetail.tenderDetailEstimatedValueCurrency,
          Observations: tenderDetail.tenderDetailObservation,
          Specifications: tenderDetail.tenderDetailSpecification,
          Conditioning: tenderDetail.tenderDetailConditioning,
          DetailExtension: {
            Info: JSON.stringify({
              OfferedProduct: tenderDetail.tenderDetailOfferedProduct, // Used in CL
            }),
          },
          Awardeds: tenderDetail.Awardeds.map((tenderDetailAwarded) => {
            return {
              ammount: tenderDetailAwarded.tenderDetailAwardedAmmount,
              qty: tenderDetailAwarded.tenderDetailAwardedQty,
              description:
                tenderDetailAwarded.tenderDetailAwardedCompetitorDescription,
              currrency: tenderDetailAwarded.tenderDetailAwardedCurrency,
              Competitor: {
                // BE looks if the competitor exists, if not, creates it
                UniqueId:
                  tenderDetailAwarded.tenderDetailAwardedCompetitorUniqueId,
                Name: tenderDetailAwarded.tenderDetailAwardedCompetitorName,
              },
            };
          }),
          //Manage detail
          Offered:
            (isLangCL() && tenderDetail.tenderManageDetailOffered === "") ||
            tenderDetail.tenderManageDetailOffered === "Si"
              ? true
              : false,
          Comments: tenderDetail.tenderManageDetailComments,
          EstimatedTotal: tenderDetail.tenderManageDetailEstimatedTotal,
          DeliveryDate: tenderDetail.tenderManageDetailDeliveryDate
            ? tenderDetail.tenderManageDetailDeliveryDate.toISOString()
            : null,
          ProductCode: tenderDetail.tenderManageDetailProductCode?.productCode,
          OfferedDate: tenderDetail.tenderManageDetailOfferedDate,
        };
      }),

      //Manage
      Sellers:
        tender.tenderManageSeller != null ? tender.tenderManageSeller : [],
      Categories:
        tender.tenderManageCategory != null ? tender.tenderManageCategory : [],
      Tags: tender.tenderManageTag != null ? tender.tenderManageTag : [],
    };
    result = nullifyUndefinedValues(result);

    manageOutsiderTenders([result])
      .then((response) => {
        setSnackbarSaveCustom("Licitación guardada correctamente.");
        resetForm();
      })
      .catch((error) => {
        setSnackbarErrorCustom(
          "Ocurrió un error al guardar la licitación. Por favor intente nuevamente.",
        );
      })
      .finally(() => {
        hideLoadingSpinner();
      });
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "1.5rem",
          gap: "1rem",
        }}>
        <Typography sx={{ fontSize: 14 }}>
          Ingrese los datos de la {isLangCL() ? "cotización" : "licitación"} a
          añadir. Los campos marcados con * son obligatorios.
        </Typography>
      </div>

      <Box sx={{ px: 4, mt: 4 }}>
        <Typography variant="h5" sx={{ mb: 3, fontSize: 17 }}>
          {isLangCL() ? "Datos de Cotización" : "Datos de Licitación"}
        </Typography>
        {/* Tender Data */}
        <Grid container spacing={2}>
          {splitArrayIntoPairs(tenderInputValues).map((tenderDualValue, idx) =>
            tenderDualValue.map((tenderValue, idx2) => (
              <OutsiderTendersFormComponent
                key={idx2}
                index={2 * idx + idx2}
                outsiderValue={tenderValue}
                onChange={handleTenderInputValueChange}
              />
            )),
          )}
        </Grid>

        {/* Tender Manage */}
        <Typography variant="h5" sx={{ mb: 3, mt: 5, fontSize: 17 }}>
          Datos de Gestión
        </Typography>
        <Grid container spacing={2}>
          {splitArrayIntoPairs(tenderManageInputValues).map(
            (tenderManageDualValue, idx) =>
              tenderManageDualValue.map((tenderManageValue, idx2) => (
                <OutsiderTendersFormComponent
                  key={idx2}
                  index={2 * idx + idx2}
                  outsiderValue={tenderManageValue}
                  onChange={handleTenderManageInputValueChange}
                />
              )),
          )}
        </Grid>

        {/* Tender Details */}
        {tenderDetailInputValues.map((tenderDetailValue, idxTD) => (
          <Accordion
            sx={{
              mt: "2rem",
              backgroundColor: alpha("#a9a9cfc2", 0.25),
              borderRadius: "10px",
              "&:before": { display: "none" }, // Removes the top border
            }}
            key={idxTD}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ alignItems: "center" }}>
              <Typography
                variant="h5"
                sx={{ alignSelf: "center", fontSize: 17 }}>
                {`Datos de ${
                  isLangCL()
                    ? "Ítem"
                    : `${intl.formatMessage({
                        id: "tab.ListaItems",
                        defaultMessage: "Lista de Items",
                      })}`
                }: ${idxTD + 1}`}
              </Typography>
              {/* Delete Button */}
              {tenderDetailInputValues.length > 0 && (
                <IconButton
                  sx={{ float: "right", ml: "auto" }}
                  onClick={() => deleteTenderDetailInputValue(idxTD)}
                  size="small">
                  <Delete />
                </IconButton>
              )}
            </AccordionSummary>

            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}>
                <Typography sx={{ mb: "1rem" }} variant="h5">
                  {isLangCL() ? `Cotización` : `Licitación`}
                </Typography>
                <Grid container spacing={2}>
                  {splitArrayIntoPairs(tenderDetailValue.detail).map(
                    (tenderDetailDualValue, idx) =>
                      tenderDetailDualValue.map(
                        (tenderDetailSingleItem, idx2) => (
                          <OutsiderTendersFormComponent
                            key={idx2}
                            index={2 * idx + idx2}
                            indexTopElem={idxTD}
                            outsiderValue={tenderDetailSingleItem}
                            onChange={handleTenderDetailInputValueChange} //change
                          />
                        ),
                      ),
                  )}
                </Grid>

                {/* Tender Manage Detail */}
                <Typography sx={{ mt: "2rem", mb: "1rem" }} variant="h5">
                  {`Gestión`}
                </Typography>
                <Grid container spacing={2}>
                  {splitArrayIntoPairs(tenderDetailValue.manageDetail).map(
                    (tenderMDDualValue, idx) =>
                      tenderMDDualValue.map((tenderMDSingleItem, idx2) => (
                        <OutsiderTendersFormComponent
                          key={idx2}
                          index={2 * idx + idx2}
                          indexTopElem={idxTD}
                          outsiderValue={tenderMDSingleItem}
                          onChange={handleTenderManageDetailInputValueChange}
                        />
                      )),
                  )}
                </Grid>

                {/* Tender Detail Awarded */}
                {tenderDetailValue.awarded.length > 0 &&
                  tenderDetailValue.awarded.map((TDAwardedValue, idxAward) => (
                    <Accordion
                      sx={{
                        mt: "2rem",
                        backgroundColor: "rgba(118, 75, 162, 0.25)",
                        borderRadius: "10px",
                        "&:before": { display: "none" }, // Removes the top border
                      }}
                      key={idxAward}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{ alignItems: "center" }}>
                        <Typography
                          variant="h5"
                          sx={{ alignSelf: "center", fontSize: 17 }}>
                          {`Datos de Adjudicación / Oferta: ${idxAward + 1}`}
                        </Typography>
                        {/* Delete Button */}
                        {tenderDetailValue.awarded.length > 0 && (
                          <IconButton
                            sx={{ float: "right", marginLeft: "auto", mr: 2 }}
                            onClick={() =>
                              deleteTenderDetailAwardedInputValue(
                                idxTD,
                                idxAward,
                              )
                            }
                            size="small">
                            <Delete />
                          </IconButton>
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {splitArrayIntoPairs(TDAwardedValue).map(
                            (TDAwardedSingleItem, idx) =>
                              TDAwardedSingleItem.map((awardedItem, idx2) => (
                                <OutsiderTendersFormComponent
                                  key={idx2}
                                  index={2 * idx + idx2}
                                  indexTopElem={idxTD}
                                  indexAux={idxAward}
                                  outsiderValue={awardedItem}
                                  onChange={
                                    handleTenderDetailAwardInputValueChange
                                  }
                                />
                              )),
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                {isLangAR() && (
                  <Button
                    sx={{ mt: "2rem", width: "fit-content" }}
                    onClick={() => addTenderDetailAwardInputValue(idxTD)}
                    variant="outlined"
                    color="primary">
                    Agregar Adjudicación / Oferta
                  </Button>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          onClick={addTenderDetailInputValue}
          sx={{ mt: 4 }}
          variant="outlined"
          color="primary">
          Agregar Item
        </Button>
        <div>
          <Button
            onClick={() => setSaveConfirm(true)}
            sx={{ mt: 4, mb: 3 }}
            variant="contained"
            color="primary">
            Guardar {isLangCL() ? "Cotización" : "Licitación"}
          </Button>
        </div>
      </Box>
      <SaveDialog
        title={`Guardar ${isLangCL() ? "Cotización" : "Licitación"}`}
        onClose={() => {
          setSaveConfirm(false);
        }}
        open={saveConfirm}
        subTitle={`¿Esta seguro que desea guardar la ${
          isLangCL() ? "cotización" : "licitación"
        }
          ? Una vez guardada no podrá ser modificada.`}
        onSave={handleSave}
      />
    </Fragment>
  );
};

export default OutsiderTendersManualInsert;
