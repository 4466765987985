export const SmartSearchHeader = [
  {
    intlId: "cols.Filtro",
    accessorKey: "searchFilterName",
    header: "Filtro",
    size: 150,
  },
  {
    intlId: "cols.Id",
    accessorKey: "tenderNumber",
    header: "Id",
    size: 170,
  },
  {
    intlId: "cols.Numenclatura",
    accessorKey: "fileNumber",
    header: "Nomenclatura",
    size: 250,
  },
  {
    intlId: "cols.Nombre",
    accessorKey: "tenderName",
    header: "Nombre",
    size: 200,
  },
  {
    intlId: "cols.Modalidad",
    accessorKey: "modalityType",
    header: "Modalidad",
    size: 180,
  },
  {
    intlId: "cols.Descripcion",
    accessorKey: "tenderDescription",
    header: "Descripción",
    size: 150,
  },
  {
    intlId: "cols.Region",
    accessorKey: "regionName",
    header: "Región",
    size: 120,
  },
  {
    intlId: "cols.Portal",
    accessorKey: "sourceName",
    header: "Portal",
    size: 100,
  },
  {
    intlId: "cols.Entidad",
    accessorKey: "clientUnitName",
    header: "Entidad",
    size: 200,
  },
  {
    intlId: "cols.ClientName", // Used exclusively in AR. "Servicio Administrativo Financiero"
    accessorKey: "clientName",
    header: "Cliente",
  },
  {
    intlId: "cols.Publicacion",
    accessorKey: "publishedDate",
    header: "Publicación",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.Cierre",
    accessorKey: "endDate",
    header: "Cierre",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.Subasta",
    accessorKey: "bidTypeLine",
    header: "Subasta",
    size: 150,
  },
  {
    intlId: "cols.Link",
    accessorKey: "link",
    header: "Link",
    size: 150,
    disableTooltip: true,
    Cell: ({ cell }) => (
      <a href={cell.getValue()} target="_blank" rel="noopener">
        Link
      </a>
    ),
  },
  {
    intlId: "cols.Item",
    accessorKey: "itemNumber",
    header: "Item",
    size: 100,
  },
  {
    intlId: "cols.Desc",
    accessorKey: "description",
    header: "Desc. Producto/Servicio",
    size: 300,
  },
  {
    intlId: "cols.Observaciones",
    accessorKey: "observation",
    header: "Observaciones",
  },
  {
    intlId: "cols.Especificaciones",
    accessorKey: "specification",
    header: "Especificaciones",
  },
  {
    intlId: "cols.Acondicionamiento",
    accessorKey: "conditioning",
    header: "Acondicionamiento",
  },
  {
    intlId: "cols.Cantidad",
    accessorKey: "qty",
    header: "Cantidad",
    size: 120,
  },
  {
    intlId: "cols.Unidad",
    accessorKey: "uom",
    header: "Unidad de medida",
    size: 160,
  },
  {
    intlId: "cols.Moneda",
    accessorKey: "currency",
    header: "Moneda",
    size: 160,
  },
  {
    intlId: "cols.GestionadoPor",
    accessorKey: "userManage",
    header: "Gestionado por",
    size: 150,
  },
  {
    intlId: "cols.GestionadoEn",
    accessorKey: "dateCreationManage",
    header: "Gestionado en",
    size: 150,
    cellType: "date",
  },
  {
    intlId: "cols.DestacadoPor",
    accessorKey: "userHighlightName",
    header: "Destacado por",
    size: 150,
  },
  {
    intlId: "cols.DestacadoEn",
    accessorKey: "highlightedDate",
    header: "Destacado en",
    size: 150,
    cellType: "date",
  },
  {
    intlId: "cols.VistoPor",
    accessorKey: "userViewedName",
    header: "Visto por",
    size: 150,
  },
  {
    intlId: "cols.VistoEn",
    accessorKey: "viewedDate",
    header: "Visto en",
    size: 150,
    cellType: "date",
  },
];

export const attachmentResultsHeader = [
  {
    intlId: "cols.Filtro",
    accessorKey: "searchFilterName",
    header: "Filtro",
    size: "150",
    enableSorting: true,
  },
  {
    intlId: "cols.OrdenAño",
    accessorKey: "orderYear",
    header: "Orden / Año",
    size: "150",
    allowSorting: false,
  },
  {
    intlId: "cols.Id",
    accessorKey: "tenderNumber",
    header: "Id",
    size: "200",
    align: "center",
    enableSorting: true,
  },
  {
    intlId: "cols.Numenclatura",
    accessorKey: "fileNumber",
    header: "Nomenclatura",
    size: "250",
    enableSorting: true,
  },
  {
    intlId: "cols.Nombre",
    accessorKey: "tenderName",
    header: "Nombre",
    size: "200",
    enableSorting: true,
  },
  {
    intlId: "cols.Extracto",
    accessorKey: "excerpt",
    header: "Extracto",
    size: "150",
    enableSorting: true,
  },
  {
    intlId: "cols.Portal",
    accessorKey: "portal",
    header: "Portal",
    size: "100",
    enableSorting: true,
    disableTooltip: true,
    Cell: ({ cell, column }) => {
      let portalLink;
      if (cell.getValue() === "MinisterioSaludPBA") {
        portalLink =
          "https://sistemas.ms.gba.gov.ar/LicitacionesyContrataciones/web/app.php/publico/licitacion/lista";
      } else if (cell.getValue() === "PAMICentral") {
        portalLink = "https://prestadores.pami.org.ar/result.php?c=7-5&par=1";
      } else if (cell.getValue() === "PAMIUgls") {
        portalLink = "https://prestadores.pami.org.ar/result.php?c=7-5&par=2";
      } else if (cell.getValue() === "ComprasGarrahan") {
        portalLink = "https://compras.garrahan.gov.ar/Licitaciones/Llamado";
      }

      return (
        <span>
          <a
            href={portalLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff" }}>
            {cell.getValue()}
          </a>
        </span>
      );
    },
  },
  {
    intlId: "cols.Entidad",
    accessorKey: "clientUnit",
    header: "Entidad",
    size: "200",
    enableSorting: true,
  },
  {
    intlId: "cols.Publicacion",
    accessorKey: "publishedDate",
    header: "Publicación",
    size: "150",
    align: "center",
    enableSorting: true,
    cellType: "datetime",
  },
  {
    intlId: "cols.Cierre",
    accessorKey: "endDate",
    header: "Cierre",
    size: "150",
    align: "center",
    enableSorting: true,
    cellType: "datetime",
  },
  {
    intlId: "cols.GestionadoPor",
    accessorKey: "userManage",
    header: "Gestionado por",
    size: "150",
    align: "center",
    enableSorting: true,
  },
  {
    intlId: "cols.GestionadoEn",
    accessorKey: "dateCreationManage",
    header: "Gestionado en ",
    size: "150",
    align: "center",
    enableSorting: true,
    cellType: "datetime",
  },
  {
    intlId: "cols.DestacadoPor",
    accessorKey: "userHighlightName",
    header: "Destacado por",
    size: "150",
    align: "center",
    enableSorting: true,
  },
  {
    intlId: "cols.DestacadoEn",
    accessorKey: "highlightedDate",
    header: "Destacado en",
    size: "150",
    align: "center",
    enableSorting: true,
    cellType: "datetime",
  },
  {
    intlId: "cols.VistoPor",
    accessorKey: "userViewedName",
    header: "Visto por",
    size: "150",
    align: "center",
    enableSorting: true,
  },
  {
    intlId: "cols.VistoEn",
    accessorKey: "viewedDate",
    header: "Visto en",
    size: "150",
    align: "center",
    enableSorting: true,
    cellType: "datetime",
  },
];
