import GroupIcon from "@mui/icons-material/Group";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import { Fragment, useState } from "react";
import {
  addOrUpdateRecipient,
  deleteRecipient,
  listRecipients,
} from "./settingsService";

const Recipients = (props) => {
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const {
    setSnackbarSaveChanges,
    setSnackbarMissingData,
    showSavingStateSpinner,
    setShouldRefetch,
  } = useGlobalStore((state) => ({
    setSnackbarSaveChanges: state.setSnackbarSaveChanges,
    setSnackbarMissingData: state.setSnackbarMissingData,
    showSavingStateSpinner: state.showSavingStateSpinner,
    setShouldRefetch: state.setShouldRefetch,
  }));

  const handleCloseModalDeleteConfirm = () => {
    setModalDeleteConfirm(false);
  };

  const handleDelete = async () => {
    showSavingStateSpinner();
    await deleteRecipient(itemToDelete);
    setModalDeleteConfirm(false);
    setSnackbarSaveChanges();
    setShouldRefetch();
  };

  const handleSave = async (item) => {
    // Error checking
    if (!item.firstName || !item.lastName || !item.emails) {
      setSnackbarMissingData();
      return;
    }
    showSavingStateSpinner();
    await addOrUpdateRecipient(item);
    setSnackbarSaveChanges();
    return true;
  };

  const fetchTableData = async (page, pageSize, filter) => {
    const recipients = await listRecipients();
    return {
      result: recipients.data,
      total: recipients.data.length,
    };
  };

  const columns = [
    {
      accessorKey: "active",
      header: "Activo",
      disableTooltip: true,
      cellType: "checkbox",
      editType: "checkbox",
      // alignHeader: true,
      size: 110,
    },
    {
      accessorKey: "firstName",
      header: "Nombre",
    },
    {
      accessorKey: "lastName",
      header: "Apellido",
    },
    {
      accessorKey: "emails",
      header: "Emails",
    },
    {
      accessorKey: "phones",
      header: "Teléfonos",
    },
  ];

  const options = {
    tableId: "gridSettingsRecipients",
    header: columns,
    fullHeight: true,
    tableHeaderTitle: "Destinatarios",
    toolbarButtons: {
      Add: true,
    },
    enableEditing: true,
    enableDeleting: true,
    deleteFunc: (row) => {
      setItemToDelete(row);
      setModalDeleteConfirm(true);
    },
    saveFunc: handleSave,
  };

  return (
    <Fragment>
      <PageTitle
        titleHeading="Destinatarios"
        titleDescription="Modificar destinatarios de mails"
        icon={<GroupIcon />}
      />
      <FSTMaterialReactTable options={options} getData={fetchTableData} />

      <DeleteDialog
        open={modalDeleteConfirm}
        onClose={handleCloseModalDeleteConfirm}
        onDelete={handleDelete}
        title={`¿Está seguro que desea eliminar el destinatario ${itemToDelete?.firstName} ${itemToDelete?.lastName}?`}
      />
    </Fragment>
  );
};

export default Recipients;
