import { Box } from "@mui/material";
import { alpha } from "@mui/system";
import { memo } from "react";

function HeaderLastUpdate({ lastUpdateTime }) {
  return (
    <Box
      sx={{
        height: "1.25rem",
        display: "flex",
        padding: "0.75rem 0.5rem 0.75rem 0.5rem",
        fontSize: "0.8rem",
        alignItems: "center",
        fontWeight: "normal",
        borderRadius: "0.5rem",
        mr: 2,
        color: alpha("rgb(0,0,0)", 0.54),
        backgroundColor: alpha("rgb(0,0,0)", 0.04),
      }}
      component="span">
      Última actualización: {lastUpdateTime}
    </Box>
  );
}

export default memo(HeaderLastUpdate);
