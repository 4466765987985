import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  Input,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AutocompletePaging from "components/CustomFields/AutocompletePaging";
import NumericField from "components/CustomFields/NumericField";
import { useState } from "react";
import { CompactPicker } from "react-color";

const TextEditCell = ({ cell, column, table }) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <TextField
      value={value}
      variant="standard"
      fullWidth
      onChange={(e) => {
        if (e.target.value === "") {
          cell.row._valuesCache[column.columnDef.accessorKey] = null;
        } else {
          cell.row._valuesCache[column.columnDef.accessorKey] = e.target.value;
        }
        setValue(e.target.value);
      }}
    />
  );
};

const CheckboxEditCell = ({ cell, column, table }) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Checkbox
        sx={{ p: 0 }}
        checked={value}
        onChange={(e) => {
          cell.row._valuesCache[column.columnDef.accessorKey] =
            e.target.checked;
          setValue(e.target.checked);
        }}
        color="secondary"
      />
    </div>
  );
};

const SelectEditCell = ({ cell, column, table, options }) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ width: "100%" }}>
      <TextField
        variant="standard"
        fullWidth
        id={`select-edit-cell-${column.columnDef.accessorKey}-${cell.row.id}`}
        select
        value={value || ""}
        onChange={(e) => {
          cell.row._valuesCache[column.columnDef.accessorKey] = e.target.value;
          setValue(e.target.value);
        }}>
        {options.map((option) => (
          <MenuItem
            key={`${column.columnDef.accessorKey}-${option.id}`}
            value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

const MultiselectEditCell = ({ cell, column, table, options }) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ width: "100%" }}>
      <FormControl sx={{ width: "100%" }}>
        <Select
          id={`multiselect-edit-cell-${column.columnDef.accessorKey}-${cell.row.id}`}
          multiple
          value={value || []}
          onChange={(e) => {
            cell.row._valuesCache[column.columnDef.accessorKey] =
              e.target.value;
            setValue(e.target.value);
          }}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={options.find((option) => option.id === value).name}
                />
              ))}
            </Box>
          )}>
          {options.map((option) => (
            <MenuItem
              key={`${column.columnDef.accessorKey}-${option.id}`}
              value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const DateEditCell = ({ cell, column, table }) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div className="d-flex align-items-center justify-content-center">
      <DatePicker
        value={value}
        format="DD/MM/YYYY"
        onChange={(newValue) => {
          cell.row._valuesCache[column.columnDef.accessorKey] = newValue;
          setValue(newValue);
        }}
        slotProps={{ textField: { variant: "standard" } }}
      />
    </div>
  );
};

const AutocompleteEditCell = ({
  cell,
  column,
  table,
  options,
  optionsLabel,
}) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ width: "100%" }}>
      <Autocomplete
        id={`autocomplete-edit-cell-${column.columnDef.accessorKey}-${cell.row.id}-${cell.row.index}`}
        options={options || []}
        getOptionLabel={optionsLabel || ((option) => option.name)}
        fullWidth
        value={value}
        onChange={(e, newValue) => {
          cell.row._valuesCache[column.columnDef.accessorKey] = newValue;
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    </div>
  );
};

const AutocompletePagingEditCell = ({
  cell,
  column,
  table,
  options,
  optionsLabel,
  loadOptionsCallback,
}) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ width: "100%" }}>
      <AutocompletePaging
        value={value}
        loadOptionsCallback={loadOptionsCallback}
        onChange={(newValue) => {
          cell.row._valuesCache[column.columnDef.accessorKey] = newValue;
          setValue(newValue);
        }}
        getOptionLabel={optionsLabel}
        fullWidth
      />
    </div>
  );
};

const NumericEditCell = ({ cell, column, table }) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div className="d-flex align-items-center justify-content-center">
      <NumericField
        value={value}
        variant="standard"
        fullWidth
        onChange={(e) => {
          cell.row._valuesCache[column.columnDef.accessorKey] = e.target.value;
          setValue(e.target.value);
        }}
      />
    </div>
  );
};

const ActiveInactiveButton = styled(Button)(({ theme }) => ({
  width: "7rem",
  "&.badge-success": {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  "&.badge-danger": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const ActiveInactiveEditCell = ({ cell, column, table }) => {
  const [value, setValue] = useState(cell.getValue());
  return (
    <div className="d-flex align-items-center justify-content-center">
      <ActiveInactiveButton
        className={`btn-pill badge  
          ${value ? "badge-success" : "badge-danger"}`}
        onClick={() => {
          cell.row._valuesCache[column.columnDef.accessorKey] = !value;
          setValue(!value);
        }}>
        {value ? "Activo" : "Inactivo"}
      </ActiveInactiveButton>
    </div>
  );
};

const ColorPickerEditCell = ({ cell, column, table }) => {
  const [value, setValue] = useState(cell.getValue());
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (color) => setValue(color.hex);
  const handleSave = () => {
    cell.row._valuesCache[column.columnDef.accessorKey] = value;
    handleClose();
  };
  const handleCancel = () => {
    setValue(cell.getValue());
    handleClose();
  };

  return (
    <>
      <Button onClick={handleClick} variant="outlined" color="secondary">
        <>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: value,
              mr: 0.5,
              borderRadius: "4px",
            }}
          />
          <KeyboardArrowDown sx={{ fontSize: 16 }} />
        </>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}>
        <Card>
          <CompactPicker color={value || "#ffffff"} onChange={handleChange} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              gap: "1rem",
              alignItems: "center",
            }}>
            <Button variant="contained" size="small" onClick={handleSave}>
              Aplicar
            </Button>
            <Button size="small" onClick={handleCancel}>
              Cancelar
            </Button>
          </Box>
        </Card>
      </Popover>
    </>
  );
};

export const getEditComponent = (
  type,
  options,
  optionsLabel,
  loadOptionsCallback,
  defaultEdit,
) => {
  switch (type) {
    case "checkbox":
      return {
        Edit: ({ cell, column, table }) => (
          <CheckboxEditCell cell={cell} column={column} table={table} />
        ),
      };
    case "select":
      return {
        Edit: ({ cell, column, table }) => (
          <SelectEditCell
            cell={cell}
            column={column}
            table={table}
            options={options}
          />
        ),
      };
    case "multiselect":
      return {
        Edit: ({ cell, column, table }) => (
          <MultiselectEditCell
            cell={cell}
            column={column}
            table={table}
            options={options}
          />
        ),
      };
    case "active-inactive":
      return {
        Edit: ({ cell, column, table }) => (
          <ActiveInactiveEditCell cell={cell} column={column} table={table} />
        ),
      };
    case "date":
      return {
        Edit: ({ cell, column, table }) => (
          <DateEditCell cell={cell} column={column} table={table} />
        ),
      };
    case "autocomplete":
      return {
        Edit: ({ cell, column, table }) => (
          <AutocompleteEditCell
            cell={cell}
            column={column}
            table={table}
            options={options}
            optionsLabel={optionsLabel}
          />
        ),
      };
    case "numeric":
      return {
        Edit: ({ cell, column, table }) => (
          <NumericEditCell cell={cell} column={column} table={table} />
        ),
      };
    case "autocomplete-paging":
      return {
        Edit: ({ cell, column, table }) => (
          <AutocompletePagingEditCell
            cell={cell}
            column={column}
            table={table}
            options={options}
            optionsLabel={optionsLabel}
            loadOptionsCallback={loadOptionsCallback}
          />
        ),
      };
    case "colorpicker":
      return {
        Edit: ({ cell, column, table }) => (
          <ColorPickerEditCell cell={cell} column={column} table={table} />
        ),
      };

    default:
      return (
        defaultEdit || {
          Edit: ({ cell, column, table }) => (
            <TextEditCell cell={cell} column={column} table={table} />
          ),
        }
      );
  }
};
