const useRefetchTableDataStore = (set) => ({
  shouldRefetch: false,
  setShouldRefetch: () => {
    set({ shouldRefetch: true });
    setTimeout(() => {
      set({ shouldRefetch: false });
    }, 1000);
  },
});

export default useRefetchTableDataStore;
