import {
  Box,
  Checkbox,
  Chip,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import NumericField from "components/CustomFields/NumericField";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useEffect, useState } from "react";
import {
  addOrUpdateCompanySetting,
  listCompanySettings,
} from "./settingsService";
import { getSources } from "pages/SmartSearch/smartSearchService";

const initialData = {
  ruc: "",
  limitAppealSimpleTender: 0,
  limitAppealPublicTender: 0,
  limitWorkingDaysToSign: 0,
  limitWorkingDaysToSendDoc: 0,
  limitWarrantyExpiration: 0,
  faithfulObservanceThreshold: 0,
  faithfulObservanceThresholdCurrency: "",
  sendNotificationToSeller: false,
  getBACOffers: false,
  smartSearchSources: [],
};

const fieldsData = [
  {
    name: "ruc",
    translation: "Identificador",
    type: "text",
  },
  {
    name: "limitAppealSimpleTender",
    translation: "Límite de apelación simple de licitación (días)",
    type: "number",
  },
  {
    name: "limitAppealPublicTender",
    translation: "Límite de apelación pública de licitación (días)",
    type: "number",
  },
  {
    name: "limitWorkingDaysToSign",
    translation: "Límite de días para firmar",
    type: "number",
  },
  {
    name: "limitWorkingDaysToSendDoc",
    translation: "Límite de días para enviar documentación",
    type: "number",
  },
  {
    name: "limitWarrantyExpiration",
    translation: "Límite de vencimiento de garantía (días)",
    type: "number",
  },
  {
    name: "faithfulObservanceThreshold",
    translation: "Umbral para requerir Boleta de Garantia",
    type: "number",
  },
  {
    name: "faithfulObservanceThresholdCurrency",
    translation: "Moneda del Umbral para requerir Boleta de Garantia",
    type: "text",
  },
  {
    name: "sendNotificationToSeller",
    translation: "Enviar notificación a ejecutivo",
    type: "switch",
  },
  {
    name: "getBACOffers",
    translation: "Mostrar ofertas de BAC",
    type: "switch",
  },
  {
    name: "smartSearchSources",
    translation: "Portales para resultados de Smart Search",
    type: "multiselect",
  },
];

const CompanySettingModal = ({ open, onClose }) => {
  const { showSavingStateSpinnerNoTimeout, hideLoadingSpinner, setSnackbar } =
    useGlobalStore((state) => ({
      showSavingStateSpinnerNoTimeout: state.showSavingStateSpinnerNoTimeout,
      hideLoadingSpinner: state.hideLoadingSpinner,
      setSnackbar: state.setSnackbar,
    }));

  const [data, setData] = useState(initialData);
  const [sources, setSources] = useState([]);

  const initData = async () => {
    const result = await listCompanySettings();
    const resultData = {
      ...result.data,
      smartSearchSources: result.data.smartSearchSources
        ? result.data.smartSearchSources.split(",").map((x) => +x)
        : [],
    };
    setData(resultData);
    const sources = await getSources();
    setSources(sources.data);
  };

  const handleChange = (event) => {
    if (
      fieldsData.find((x) => x.name === event.target.name)?.type === "switch"
    ) {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const handleSave = async () => {
    showSavingStateSpinnerNoTimeout();
    await addOrUpdateCompanySetting({
      ...data,
      smartSearchSources: data.smartSearchSources.join(","),
    });
    hideLoadingSpinner();

    setSnackbar({
      open: true,
      severity: "success",
      title: "Éxito",
      subTitle: "La configuración ha sido almacenada.",
    });
    onClose();
  };

  useEffect(() => {
    if (open) initData();
  }, [open]);

  return (
    <Fragment>
      <GeneralPurposeModal
        title="Configuración de la empresa"
        open={open}
        onClose={onClose}
        onSave={handleSave}>
        <Grid container spacing={2}>
          {fieldsData.map((item, idx) => (
            <Grid
              container
              sx={{
                alignItems: "center",
                pb: "2%",
                ...(item.name === "ruc" ? { pt: "2%" } : {}),
              }}
              spacing={2}
              key={idx}>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <Typography sx={{ ml: "4%" }} variant="h5">
                  {item.translation}
                </Typography>
              </Grid>
              <Grid item xs={7} sx={{ textAlign: "center" }}>
                {item.type === "switch" && (
                  <Switch
                    sx={{ whiteSpace: "nowrap" }}
                    color="primary"
                    checked={data[item.name]}
                    name={item.name}
                    onChange={handleChange}
                    size="medium"
                  />
                )}
                {item.type === "text" && (
                  <TextField
                    sx={{ width: "70%" }}
                    fullWidth
                    value={data[item.name]}
                    variant="outlined"
                    size="small"
                    type={item.type}
                    name={item.name}
                    onChange={handleChange}
                  />
                )}
                {item.type === "number" && (
                  <NumericField
                    sx={{ width: "70%" }}
                    fullWidth
                    value={data[item.name]}
                    variant="outlined"
                    size="small"
                    type={item.type}
                    name={item.name}
                    onChange={handleChange}
                  />
                )}
                {item.type === "multiselect" && (
                  <Select
                    sx={{ width: "70%" }}
                    fullWidth
                    value={data[item.name]}
                    variant="outlined"
                    size="small"
                    type={item.type}
                    name={item.name}
                    onChange={handleChange}
                    multiple
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={sources.find((x) => x.id === value)?.name}
                            sx={{ m: 0.5 }}
                          />
                        ))}
                      </Box>
                    )}>
                    {sources.map((source) => (
                      <MenuItem key={source.id} value={source.id}>
                        <Checkbox
                          checked={data[item.name].indexOf(source.id) > -1}
                        />
                        <ListItemText primary={source.name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </GeneralPurposeModal>
    </Fragment>
  );
};

export default CompanySettingModal;
