const initialState = {
  open: false,
  severity: "success",
  title: "",
  subTitle: "",
};

const saveChanges = {
  open: true,
  severity: "success",
  title: "Éxito",
  subTitle: "Los cambios han sido guardados correctamente",
};

const missingData = {
  open: true,
  severity: "error",
  title: "Error",
  subTitle: "Datos incorrectos o faltantes",
};

const errorState = {
  open: true,
  severity: "error",
  title: "Error",
  subTitle: "Ha ocurrido un error. Intente nuevamente",
};

const useSnackbarStore = (set) => ({
  snackbar: initialState,
  setSnackbar: (snackbar) => set({ snackbar }),
  resetSnackbar: () => set({ snackbar: initialState }),
  setSnackbarSaveChanges: () => set({ snackbar: saveChanges }),
  setSnackbarMissingData: () => set({ snackbar: missingData }),
  setSnackbarError: () => set({ snackbar: errorState }),
  setSnackbarErrorCustom: (subTitle, title = "Error") =>
    set({ snackbar: { ...errorState, title, subTitle } }),
  setSnackbarSaveCustom: (subTitle, title = "Éxito") =>
    set({ snackbar: { ...saveChanges, title, subTitle } }),
  setSnackbarInfoCustom: (subTitle, title = "Información") =>
    set({ snackbar: { open: true, title, subTitle, severity: "info" } }),
  setSnackbarWarningCustom: (subTitle, title = "Advertencia") =>
    set({ snackbar: { open: true, title, subTitle, severity: "warning" } }),
});

export default useSnackbarStore;
