import clsx from "clsx";
import PropTypes from "prop-types";

import { Box, Paper } from "@mui/material";

PageTitle.propTypes = {
  justifyStart: PropTypes.bool,
  icon: PropTypes.node,
  titleHeading: PropTypes.string,
  titleDescription: PropTypes.string,
  startComponent: PropTypes.node,
  endComponent: PropTypes.node,
};

function PageTitle(props) {
  return (
    <Paper
      square
      elevation={2}
      className={clsx("app-page-title", "app-page-title-alt-1")}
      id="app-page-title"
      style={{
        backgroundColor: "#fff",
        ...(props.justifyStart && { justifyContent: "flex-start" }),
      }}>
      <Box className="app-page-title--first">
        {props.icon && (
          <Paper
            elevation={2}
            className="app-page-title--iconbox d-70 d-flex align-items-center bg-secondary justify-content-center">
            {props.icon}
          </Paper>
        )}
        <div className="app-page-title--heading">
          <h1 style={{ whiteSpace: "nowrap" }}>{props.titleHeading}</h1>
          {props.titleDescription && (
            <div className="app-page-title--description">
              {props.titleDescription}
            </div>
          )}
        </div>
        {props.startComponent}
      </Box>

      <div className="d-flex align-items-center">{props.endComponent}</div>
    </Paper>
  );
}

export default PageTitle;
