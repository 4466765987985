import { get, post } from "config/axios";

export const getClientUnitsByUniqueIds = async (uniqueIds) => {
  return await post("/api/clientunitcompany/getclientunitsbyrucs", uniqueIds);
};

export const manageOutsiderTenders = async (data) => {
  return await post("/api/tender/manageoutsidertenders", data);
};

export const getScrapingStatus = async () => {
  return await get("/api/tender/GetScrapingStatus");
};
