import { Edit } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";

export default function FiltersPanelList({
  filters,
  handleToggleFilter,
  addEditFilter,
}) {
  return (
    <List
      style={{
        width: "100%",
        maxWidth: 270,
        backgroundColor: "background.paper",
      }}>
      {filters.length === 0 && (
        <Box
          sx={{
            display: "flex",
            mt: "4rem",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"80%"} />
          <Skeleton animation="wave" width={"80%"} />
        </Box>
      )}
      {filters.map((filter, idx) => {
        return (
          <PanelListItem
            key={`list-item-${filter.searchFilterId}`}
            filter={filter}
            idx={idx}
            handleToggleFilter={handleToggleFilter}
            addEditFilter={addEditFilter}
          />
        );
      })}
    </List>
  );
}

const PanelListItem = ({ filter, idx, handleToggleFilter, addEditFilter }) => {
  return (
    <ListItem
      key={`list-item-${filter.searchFilterId}`}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="comments"
          onClick={() => addEditFilter(filter)}>
          <Edit />
        </IconButton>
      }
      disablePadding>
      <ListItemButton
        disableRipple
        role={undefined}
        onClick={handleToggleFilter(filter, idx)}
        sx={{ height: "inherit", py: 0 }}
        dense>
        <ListItemIcon sx={{ minWidth: 0, pr: 0.5 }}>
          <Checkbox
            edge="start"
            checked={filter.checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": filter.searchFilterId }}
            color="secondary"
          />
        </ListItemIcon>
        <ListItemText
          id={filter.searchFilterId}
          primary={`${filter.name} (${filter.count})`}
          primaryTypographyProps={{ fontSize: "13px" }}
          sx={{
            mr: 1,
            my: "0.375rem",
            textOverflow: "ellipsis",
            overflowWrap: "break-word",
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
