import moment from "moment";
import {
  formatShortDate,
  isLangCL,
  isLangCO,
  isLangCR,
  isLangMX,
  isLangPE,
} from "utils/common";
import { flattenSheetDataFromJson, insertData } from "utils/excelExportService";

export const fillItemsListSheet = (
  workbook,
  intl,
  sheetNumber,
  exportHeader,
  exportData,
  exportItemsData,
  exportTenderData,
) => {
  const sheet1 = workbook.worksheets[sheetNumber];
  sheet1.name = intl.formatMessage({
    id: "tab.ListaItems",
    defaultMessage: "Lista de Items",
  });

  // Write tender data
  insertData(sheet1, exportTenderData, "B2");
  // Bold titles from B2 to B{totalColumns} & left align for the data column (C)
  exportTenderData.forEach((item, index) => {
    sheet1.getCell(`B${index + 2}`).font = { bold: true };
    sheet1.getCell(`C${index + 2}`).alignment = { horizontal: "left" };
  });

  // Write items data
  insertData(sheet1, flattenSheetDataFromJson(exportData, exportHeader), "A10");

  // Header bold
  sheet1.getRow(10).font = { bold: true };
  // center text for every row in the range
  let rows = sheet1.getRows(10, exportItemsData.length + 10);
  rows.forEach((row) => (row.alignment = { horizontal: "center" }));
  // center text left for second column after header
  rows = sheet1.getRows(11, exportItemsData.length + 10);
  rows.forEach((row) => (row.getCell(2).alignment = { horizontal: "left" }));

  // Set column widths
  sheet1.getColumn("A").width = 10;
  sheet1.getColumn("B").width = 30;
  sheet1.getColumn("C").width = 25;
  sheet1.getColumn("D").width = 15;
  sheet1.getColumn("E").width = 15;
  sheet1.getColumn("F").width = 15;
  sheet1.getColumn("G").width = 15;
  sheet1.getColumn("H").width = 35;
  sheet1.getColumn("I").width = 30;
  sheet1.getColumn("J").width = 30;
  sheet1.getColumn("K").width = 30;
};

// TODO: improve so the header contains the excel export format
export const getItemsListFormattedInitialData = (manageDetails, tagsList) => {
  return manageDetails.map((item) => {
    return {
      ...item,
      offered: item.offered ? "Si" : "No",
      isPrimary: item.isPrimary ? "No" : "Si",
      tagIds: item.tagIds
        .map((tag) => tagsList.find((t) => t.id === tag)?.name)
        .join(", "),
      ...(isLangCR() || isLangPE() || isLangCL() || isLangCO() || isLangMX()
        ? {
            deliveryDate: item.deliveryDate
              ? formatShortDate(item.deliveryDate)
              : "",
          }
        : {}),
      ...(!item.productCode && !item.productDesc
        ? {}
        : {
            product: `(${item.productCode}) - ${item.productDesc}${
              item.data
                ? ` - ${Object.values(JSON.parse(item.data)).join(" - ")}`
                : ""
            }`,
          }),
      estimatedValueTotal: item.estimatedTotal
        ? item.estimatedTotal * item.qty
        : "",
    };
  });
};

export const getItemsListData = (intl, tenderData, header, formattedData) => {
  let exportHeader = header
    .filter((item) => item.header && !item.hideToExport)
    .map((item) => item.header);
  let exportColumns = header
    .filter((item) => item.header && !item.hideToExport)
    .map((item) => item.accessorKey);
  let exportData = formattedData.map((item) => {
    let exportItem = {};
    exportColumns.forEach((column) => {
      exportItem[column] = item[column];
    });
    return exportItem;
  });
  let exportItemsData = [[...exportHeader], ...exportData];
  let exportTenderData = [
    [
      intl.formatMessage({
        id: "text.Nomenclatura",
        defaultMessage: "Nomenclatura",
      }),
      tenderData.fileNumber,
    ],
    [
      intl.formatMessage({
        id: "text.Fecha_Publicacion",
        defaultMessage: "Fecha Publicación",
      }),
      moment(tenderData.publishedDate).format("DD/MM/YYYY"),
    ],
    [
      intl.formatMessage({
        id: "text.Convocatoria",
        defaultMessage: "Convocatoria",
      }),
      tenderData.tenderNumber,
    ],
    [
      intl.formatMessage({
        id: "text.Tipo_Compra",
        defaultMessage: "Tipo Compra",
      }),
      tenderData.tenderTypeDesc,
    ],
    [
      intl.formatMessage({
        id: "text.Entidad",
        defaultMessage: "Entidad",
      }),
      tenderData.clientUnitName,
    ],
    [
      intl.formatMessage({
        id: "text.Descripcion",
        defaultMessage: "Descripción",
      }),
      tenderData.tenderName,
    ],
  ];

  return {
    exportHeader,
    exportData,
    exportItemsData,
    exportTenderData,
  };
};
