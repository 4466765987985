import { Fragment } from "react";

import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useGlobalStore } from "global-store/useGlobalStore";

export default function CustomSnackbar(props) {
  const { snackbar, resetSnackbar } = useGlobalStore((state) => ({
    snackbar: state.snackbar,
    resetSnackbar: state.resetSnackbar,
  }));
  const { open, severity, title, subTitle } = snackbar;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    resetSnackbar();
  };
  return (
    <Fragment>
      <div className="text-center">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}>
          <MuiAlert className="mb-4" severity={severity}>
            <div className="d-flex align-items-center align-content-center">
              <div>
                <strong className="d-block" style={{ textAlign: "start" }}>
                  {title}
                </strong>
                {subTitle}
              </div>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                className="ml-4"
                onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </MuiAlert>
        </Snackbar>
      </div>
    </Fragment>
  );
}
