import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { ADMIN_GENERAL_ROLE_ID, ADMIN_ROLE_ID } from "utils/constants";

const AuthRoute = (props) => {
  const history = useHistory();

  const initCheckAuth = async () => {
    const isAuthorized = await getRouteAuthorization(
      props.verify,
      props.location.pathname,
    );
    // If isAuthorized is false, then the user is not authorized to access the page
    if (!isAuthorized) {
      history.push("/");
    }
  };

  useEffect(() => {
    initCheckAuth();
  }, []);

  /* Example (admin):
  userInfo: {
    applicationModules: (3) [{…}, {…}, {…}]
    isAdmin: true
    isAdminGeneral: false
    userModules: []
  }
  */
  const getRouteAuthorization = (userInfo, pathName) => {
    if (pathName === "/") return true;

    // Verify if the path is a direct match with the route
    if (props.children.some((item) => item.props.path === pathName)) {
      const itemRoute = props.children.find(
        (item) => item.props.path === pathName,
      );
      return verifyAuth(userInfo, itemRoute);
    }

    // Verify if the path is a match with the route with a parameter
    // Example: /gestion-informacion/:tenderNumber
    // First, we need to get the generic path that has a parameter
    const arrayPath = pathName.split("/");
    const newPath = "/" + arrayPath[1] + "/:";

    // Then, we need to verify if the generic path is a match with the route
    if (
      props.children.some((item) => item.props.path.indexOf(newPath) !== -1)
    ) {
      const itemRoute = props.children.find(
        (item) => item.props.path.indexOf(newPath) !== -1,
      );
      return verifyAuth(userInfo, itemRoute);
    }

    return true;
  };

  const verifyAuth = (userInfo, itemRoute) => {
    const { isAdmin, isAdminGeneral, applicationModules, userModules } =
      userInfo;

    // Verify if user is admin general
    if (itemRoute.props.isAdminGeneral) {
      return isAdminGeneral;
    }
    // Verify if user is admin
    if (itemRoute.props.isAdmin) {
      return isAdmin;
    }
    // Verify if the user has the module enabled to access the page
    if (itemRoute.props.moduleId) {
      const modules = isAdmin ? applicationModules : userModules;
      return modules.some((item) => item.name === itemRoute.props.moduleId);
    }

    return true; // Default page with no restrictions (might need recheck [TODO], also can be login, etc)
  };

  const { children } = props;
  return children;
};

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  const { user } = state;
  const { loginPending, roleId } = user;
  const userModules = user.applicationModules;
  const { applicationModules } = app;

  return {
    ...ownProps,
    loginPending,
    verify: {
      userModules,
      applicationModules,
      isAdmin: roleId === ADMIN_ROLE_ID || roleId === ADMIN_GENERAL_ROLE_ID,
      isAdminGeneral: roleId === ADMIN_GENERAL_ROLE_ID,
    },
  };
};

export default withRouter(connect(mapStateToProps)(AuthRoute));
