import { useIntl } from "react-intl";
import ModalCRUD from "./ModalCRUD";
import {
  addOrUpdateBank,
  deleteBank,
  getBanks,
} from "./administrationServices";

const columns = [{ accessorKey: "bankName", header: "Nombre", size: 375 }];

const messages = {
  saveSuccess: "El banco se almacenó correctamente.",
  deleteSuccess: "El banco se eliminó correctamente.",
  missingData: "Por favor ingrese el nombre del banco.",
  cannotDelete: "El banco no puede ser eliminado porque está siendo utilizado.",
  deleteConfirmation: (item) =>
    `¿Está seguro que desea eliminar el banco ${item?.bankName}?`,
};

const BankModal = ({ open, onClose }) => {
  const intl = useIntl();
  const bankTitle = intl.formatMessage({
    id: "title.Banco.Administracion",
    defaultMessage: "Bancos",
  });

  const saveVerification = (data) => {
    return data.bankName;
  };

  const handleDelete = async (item) => {
    const request = [{ bankId: item.bankId }];
    await deleteBank(request);
  };

  const handleSave = async (data) => {
    const request = [data];
    await addOrUpdateBank(request);
  };

  return (
    <ModalCRUD
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      columns={columns}
      tableInfo={{ id: "gridBankModal", header: bankTitle }}
      modalTitle={bankTitle}
      fetchFunction={getBanks}
      saveVerification={saveVerification}
      messages={messages}
    />
  );
};

export default BankModal;
