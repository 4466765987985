import { PURCHASE_ORDER_ANALYSIS_MODULE_ID } from "utils/constants";
import AnalysisFilters from "../AnalysisFilters/AnalysisFilters";

const PurchaseOrderAnalysisFilters = () => {
  return (
    <AnalysisFilters analysisModuleId={PURCHASE_ORDER_ANALYSIS_MODULE_ID} />
  );
};

export default PurchaseOrderAnalysisFilters;
