import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { memo, useState } from "react";

const exportOptions = [
  { id: 1, value: "Exportar" },
  { id: 2, value: "Gestionadas" },
  { id: 3, value: "Destacadas" },
];

function HeaderExportOptions({ handleExport }) {
  const [exportButtonOpen, setExportButtonOpen] = useState(null);

  const handleClick = (event) => {
    setExportButtonOpen(event.currentTarget);
  };

  const handleClose = () => {
    setExportButtonOpen(null);
  };

  const onExport = (id) => {
    handleClose();
    handleExport(id);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        size="small"
        aria-label="split button"
        disableElevation
        color="secondary">
        <Button
          sx={{ fontSize: "0.75rem", textTransform: "uppercase" }}
          onClick={() => onExport(exportOptions[0].id)}>
          <Box sx={{ height: 20, fontWeight: 500 }}>
            {exportOptions[0].value}
          </Box>
        </Button>
        <Button size="small" aria-haspopup="true" onClick={handleClick}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        id="simple-menu2"
        anchorEl={exportButtonOpen}
        keepMounted
        open={Boolean(exportButtonOpen)}
        onClose={handleClose}>
        {exportOptions.slice(1).map((option) => (
          <MenuItem key={option.id} onClick={() => onExport(option.id)}>
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default memo(HeaderExportOptions);
