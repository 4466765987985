import { Fragment } from "react";

import clsx from "clsx";

import { Paper } from "@mui/material";

import { connect } from "react-redux";
import falconLogo from "../../assets/images/imgFalcon.png";

const Footer = (props) => {
  const { footerShadow, sidebarToggle, footerFixed, userInfo } = props;
  return (
    <Fragment>
      <Paper
        square
        elevation={footerShadow ? 11 : 2}
        className={clsx("app-footer text-black-50", {
          "app-footer--fixed": footerFixed,
          "app-footer--fixed__collapsed": sidebarToggle,
        })}>
        <div className="app-footer--inner">
          <div className="app-footer--first">
            <span>
              <img
                src={falconLogo}
                alt="Falcon"
                style={{
                  width: "120px",
                  height: "35px",
                  marginRight: "8px",
                }}
              />
              {`Tenders`}
              <span style={{ margin: "10px" }}>{`-`}</span>
              <span>{`Versión: ${userInfo.appVersion}`}</span>
            </span>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});
export default connect(mapStateToProps)(Footer);
