import { css as emotionCss } from "@emotion/css";
import { css } from "@mui/material";
import { convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useIsWidthUp from "utils/hooks/useIsWidthUp";

const styles = {
  editor: {
    border: "1px solid #d1d2db",
    padding: "8px",
    borderRadius: "8px",
    height: `80% !important`,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      WebkitAppearance: "none",
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      WebkitBoxShadow: "0 0 1px rgba(0, 0, 0, 0.205)",
    },
  },
  editorMdDown: {
    border: "1px solid #d1d2db",
    padding: "8px",
    borderRadius: "8px",
    height: `69% !important`,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      WebkitAppearance: "none",
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      WebkitBoxShadow: "0 0 1px rgba(0, 0, 0, 0.205)",
    },
  },
  wrapper: {
    height: "280px",
    borderRadius: "8px",
    padding: "8px",
    border: "1px solid #d1d2db",
  },
  toolbar: {
    border: "1px solid #d1d2db",
    borderRadius: "8px",
    padding: "8px",
    justifyContent: "center !important",
  },
};

const TextEditor = ({ onChange }) => {
  const isMdUp = useIsWidthUp("md");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const onChangeText = (value) => {
    setEditorState(value);
    const newText = convertToHTML(editorState.getCurrentContent());
    onChange(newText);
  };

  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();

      reader.onload = function (e) {
        img.src = this.result;
        resolve({
          data: {
            link: img.src,
          },
        });
      };
    });
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName={emotionCss(css(styles.wrapper))}
        editorClassName={emotionCss(
          css(isMdUp ? styles.editor : styles.editorMdDown),
        )}
        toolbarClassName={emotionCss(css(styles.toolbar))}
        onEditorStateChange={onChangeText}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            ignmentEnabled: true,
            previewImage: true,
            uploadCallback: uploadCallback,
            alt: { present: false, mandatory: false },
          },
        }}
        localization={{
          locale: "es",
        }}
      />
    </div>
  );
};

export default TextEditor;
