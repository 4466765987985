import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import NumericField from "components/CustomFields/NumericField";
import moment from "moment";
import { getSources } from "pages/SmartSearch/smartSearchService";
import { Fragment, useEffect, useState } from "react";
import { TENDER_ANALYSIS_MODULE_ID } from "utils/constants";
import { getDateRanges, getTypes } from "../analysisService";
import { FIXED_TIME_RANGE } from "./ManageAnalysisFiltersModal";
import { useIntl } from "react-intl";

const FILTER_NAME_MAX_LENGTH = 50;

const ManageFilterBasicData = ({
  filter,
  handleFilterToManageChange,
  handleBasicDataDateRangeChange,
  analysisFilterModuleId,
}) => {
  const intl = useIntl();
  // Data
  const [dateRanges, setDateRanges] = useState([]);
  const [types, setTypes] = useState([]);
  const [sources, setSources] = useState([]);
  // State
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [openTypesTooltip, setOpenTypesTooltip] = useState(false);
  const [openSourcesTooltip, setOpenSourcesTooltip] = useState(false);

  const sourceIdsToSourceData = (sourceIds, sourcesList) => {
    if (sourceIds && sourceIds.length > 0) {
      const sourcesIds = sourceIds.split(",").map((item) => {
        return sourcesList.find((source) => source.id === parseInt(item));
      });
      return sourcesIds;
    }
    return [];
  };

  // fetch dateranges, types and sources at the same time with promise all
  // set the state of each one
  const fetchDateRanges = async () => {
    const response = await getDateRanges();
    setDateRanges(response.data);
  };

  const fetchTypes = async () => {
    const response = await getTypes(analysisFilterModuleId);
    setTypes(response.data);
  };

  const fetchSources = async () => {
    const response = await getSources();
    // Filter PAC
    const sourcesFiltered = response.data.filter((item) => item.name !== "PAC");
    setSources(sourcesFiltered);
    // Set sources from sourceIds
    // TODO: refactor alongside be to return the sources in the same format as the types (so we dont have to do this)
    handleFilterToManageChange({
      ...filter,
      sources: sourceIdsToSourceData(filter.sourceIds, sourcesFiltered),
    });
  };

  const fetchAll = async () => {
    await Promise.all([fetchDateRanges(), fetchTypes(), fetchSources()]);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const showDot =
    filter.types.length > 0 ||
    (filter.rangeFrom !== "" && filter.rangeFrom > 0) ||
    (filter.rangeTo !== "" && filter.rangeTo > 0) ||
    (filter.result !== "" && filter.result) ||
    (filter.applyToCompetitorDescription !== "" &&
      filter.applyToCompetitorDescription) ||
    (filter.sourceIds !== "" && filter.sourceIds !== null);

  const handleChange = (key, value) => {
    if (value.length > FILTER_NAME_MAX_LENGTH) return;
    handleFilterToManageChange({
      ...filter,
      [key]: value,
    });
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={11}>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              label={`${intl.formatMessage({
                id: "filtros.Nombre",
                defaultMessage: "Nombre del Filtro",
              })} (máx. 50 caracteres)`}
              variant="outlined"
              value={filter.analysisFilterName}
              name="analysisFilterName"
              onChange={(e) =>
                handleChange("analysisFilterName", e.target.value)
              }
              required
              error={!filter.analysisFilterName.trim()}
              helperText={
                !filter.analysisFilterName.trim()
                  ? "El nombre del filtro es requerido"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              options={dateRanges}
              getOptionLabel={(option) => (option ? option.value : "")}
              isOptionEqualToValue={(option, value) =>
                +option.key === +value.key
              }
              value={filter.range}
              onChange={(event, newValue) => {
                handleBasicDataDateRangeChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "filtros.Periodo",
                    defaultMessage: "Período",
                  })}
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              label={intl.formatMessage({
                id: "filtros.Desde",
                defaultMessage: "Desde",
              })}
              value={filter.startDate}
              format="DD/MM/YYYY"
              onChange={(newValue) => handleChange("startDate", newValue)}
              readOnly={+FIXED_TIME_RANGE.key !== +filter.range.key}
              minDate={moment(filter.startDate).subtract(10, "y")}
              maxDate={moment().add(1, "d")}
              slotProps={{
                textField: {
                  required: true,
                  error: moment(filter.startDate) > moment(filter.endDate),
                  helperText:
                    moment(filter.startDate) > moment(filter.endDate)
                      ? "La fecha Desde no puede ser mayor a la fecha Hasta"
                      : "",
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              label={intl.formatMessage({
                id: "filtros.Hasta",
                defaultMessage: "Hasta",
              })}
              value={filter.endDate}
              format="DD/MM/YYYY"
              onChange={(newValue) => handleChange("endDate", newValue)}
              readOnly={+FIXED_TIME_RANGE.key !== +filter.range.key}
              minDate={moment(filter.startDate).add(1, "d")}
              maxDate={moment().add(1, "y")}
              slotProps={{
                textField: {
                  required: true,
                  error: moment(filter.startDate) > moment(filter.endDate),
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={1} sx={{ textAlign: "center" }}>
          <Badge color="error" variant="dot" invisible={!showDot}>
            <IconButton
              aria-label="show-more-fields"
              size="medium"
              onClick={() => {
                setShowMoreFields(!showMoreFields);
              }}>
              {showMoreFields ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Badge>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ display: showMoreFields ? "block" : "none" }}>
        <Grid container item spacing={2} xs={11}>
          <Grid
            item
            xs={12}
            sm={analysisFilterModuleId === TENDER_ANALYSIS_MODULE_ID ? 2 : 4}>
            <Tooltip
              open={openTypesTooltip}
              title={filter.types?.map((item) => item.name).join(", ")}
              placement="bottom">
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel>Tipo</InputLabel>
                <Select
                  id="Types"
                  multiple
                  label="Tipo"
                  value={filter.types}
                  onChange={(event) =>
                    handleChange("types", event.target.value)
                  }
                  onMouseEnter={() => setOpenTypesTooltip(true)}
                  onMouseLeave={() => setOpenTypesTooltip(false)}
                  onClick={() => setOpenTypesTooltip(false)}
                  renderValue={(selected) =>
                    selected.map((item) => item.name).join(", ")
                  }>
                  {types.map((type) => (
                    <MenuItem key={type.entityTypeId} value={type}>
                      <Checkbox
                        checked={filter.types.some(
                          (elem) => elem.entityTypeId === type.entityTypeId,
                        )}
                        size="small"
                      />
                      <Tooltip title={type.name} placement="right">
                        <ListItemText primary={type.name} />
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sm={analysisFilterModuleId === TENDER_ANALYSIS_MODULE_ID ? 2 : 4}>
            <Tooltip
              open={openSourcesTooltip}
              title={filter.sources?.map((item) => item.name).join(", ")}
              placement="bottom">
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel>Portal</InputLabel>
                <Select
                  id="Sources"
                  multiple
                  label="Portal"
                  value={filter.sources}
                  disabled={sources.length <= 1} // If there is only one source (portal), disable the select
                  onChange={(event) =>
                    handleChange("sources", event.target.value)
                  }
                  onMouseEnter={() => setOpenSourcesTooltip(true)}
                  onMouseLeave={() => setOpenSourcesTooltip(false)}
                  onClick={() => setOpenSourcesTooltip(false)}
                  renderValue={(selected) =>
                    selected.map((item) => item.name).join(", ")
                  }>
                  {sources.map((portal) => (
                    <MenuItem key={portal.id} value={portal}>
                      <Checkbox
                        checked={filter.sources?.some(
                          (elem) => elem.id === portal.id,
                        )}
                        size="small"
                      />
                      <Tooltip title={portal.name} placement="right">
                        <ListItemText primary={portal.name} />
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumericField
              name="RangeFrom"
              label="Valor Desde"
              value={filter.rangeFrom}
              fullWidth
              onChange={(e) => {
                let value = e.target.value;
                if (value !== "") value = +value;
                handleChange("rangeFrom", value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumericField
              name="RangeTo"
              label="Valor Hasta"
              value={filter.rangeTo}
              fullWidth
              onChange={(e) => {
                let value = e.target.value;
                if (value !== "") value = +value;
                handleChange("rangeTo", value);
              }}
            />
          </Grid>
          {analysisFilterModuleId === TENDER_ANALYSIS_MODULE_ID && (
            <>
              <Grid item xs={12} sm={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filter.result}
                      onChange={(e) => handleChange("result", e.target.checked)}
                      size="small"
                    />
                  }
                  label="Sólo adjudicadas"
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControlLabel
                  sx={{ whiteSpace: "nowrap" }}
                  control={
                    <Switch
                      checked={filter.applyToCompetitorDescription}
                      onChange={(e) =>
                        handleChange(
                          "applyToCompetitorDescription",
                          e.target.checked,
                        )
                      }
                      size="small"
                    />
                  }
                  label="Aplicar en desc. vendedor"
                  labelPlacement="top"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ManageFilterBasicData;
