import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Countries, Idiom } from "./AvailableCompanyCountries.js";

const initialFormState = {
  ruc: "",
  tenantId: "",
  clientId: "",
  clientSecret: "",
  countryCode: "PE",
  language: "es",
};

const AddCompanyModal = ({ open, onClose, onSave, appModules }) => {
  const [form, setForm] = useState(initialFormState);
  const [invalidFormFields, setInvalidFormFields] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleClose = () => {
    setInvalidFormFields(false);
    setForm(initialFormState);
    onClose();
  };

  const handleSave = () => {
    if (
      !form.ruc.trim() ||
      !form.tenantId.trim() ||
      !form.clientId.trim() ||
      !form.clientSecret.trim() ||
      !form.countryCode.trim() ||
      !form.language.trim()
    ) {
      setInvalidFormFields(true);
      return;
    }
    onSave(form);
    handleClose();
  };

  // TODO: map textfields to not repeat code
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" sx={{ fontSize: 18 }}>
        Añadir Empresa
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Complete todos los campos para añadir un nueva empresa.
        </DialogContentText>
        <TextField
          variant="outlined"
          margin="dense"
          id="ruc"
          label="Empresa RUC"
          fullWidth
          size="small"
          required
          value={form.ruc}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="tenantId"
          label="Inquilino"
          fullWidth
          size="small"
          required
          value={form.tenantId}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="clientId"
          label="Cliente"
          fullWidth
          size="small"
          required
          value={form.clientId}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="clientSecret"
          label="Valor"
          fullWidth
          size="small"
          required
          value={form.clientSecret}
          onChange={handleChange}
        />
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={Countries}
              openOnFocus
              defaultValue={Countries.find(
                (country) => country.id === initialFormState.countryCode,
              )}
              onChange={(e, value) => {
                setForm({ ...form, countryCode: value?.id });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Paises"
                  variant="outlined"
                  size="small"
                  id="countryCode"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={Idiom}
              openOnFocus
              defaultValue={Idiom.find(
                (idiom) => idiom.id === initialFormState.language,
              )}
              onChange={(e, value) => {
                setForm({ ...form, language: value?.id });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Idioma"
                  variant="outlined"
                  size="small"
                  id="language"
                />
              )}
            />
          </Grid>
        </Grid>
        {invalidFormFields && (
          <DialogContentText
            sx={{
              mt: 2,
              bgcolor: `rgba(248, 50, 69, 0.5)`,
              p: 2,
              borderRadius: 1,
              fontSize: 14,
              fontWeight: 500,
            }}>
            Campos faltantes o inválidos. Complete los campos requeridos.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          type="submit">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCompanyModal;
