import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add, Edit, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useEffect, useState } from "react";
import {
  BsFillFileEarmarkExcelFill,
  BsFillFileEarmarkPdfFill,
} from "react-icons/bs";
import { saveExcel, savePdf } from "utils/excelExportService";
import ManageAnalysisFiltersModal from "./AnalysisFilters/ManageAnalysisFiltersModal";
import SearchFiltersModal from "./AnalysisFilters/SearchFiltersModal";
import {
  downloadReport,
  getAnalysisFilterById,
  getAnalysisFilters,
  getReportCategories,
} from "./analysisService";

const EXPORT_TYPE_PDF = 1;
const EXPORT_TYPE_EXCEL = 2;

const AnalysisHeaderActions = ({
  selectedData,
  setSelectedData,
  analysisModuleId,
}) => {
  // Filters and reports
  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  // Modals logic
  const [modalFilterManageOpen, setModalFilterManageOpen] = useState(false);
  const [itemToModify, setItemToModify] = useState(null);
  const [modalSearchFiltersOpen, setModalSearchFiltersOpen] = useState(false);

  const { showLoadingStateSpinnerNoTimeout, hideLoadingSpinner, setSnackbar } =
    useGlobalStore((state) => ({
      showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
      hideLoadingSpinner: state.hideLoadingSpinner,
      setSnackbar: state.setSnackbar,
    }));

  const loadFilters = async (setFilter = true) => {
    const response = await getAnalysisFilters(analysisModuleId);
    setFilters(response.data);
    if (setFilter) {
      setSelectedFilter(response.data[0]);
      return response.data[0];
    }
    return response.data;
  };

  const loadReports = async () => {
    const response = await getReportCategories(analysisModuleId);
    let finalReports = [];
    response.data.forEach((report) => {
      report.reports.forEach((r) => {
        finalReports.push({
          id: r.id,
          name: `${report.name} - ${r.name}`,
          fileName: r.fileName,
          icon: r.data,
        });
      });
    });
    setReports(finalReports);
    setSelectedReport(finalReports[0]);
    return finalReports[0];
  };

  const setInitialData = async () => {
    // set report loaded false (maybe not needed)
    const initialFilter = await loadFilters();
    const initialReport = await loadReports();
    setSelectedData({
      ...selectedData,
      filterUniqueId: initialFilter.uniqueId,
      reportName: initialReport.fileName,
      finishedLoading: true,
    });
  };

  useEffect(() => {
    if (selectedFilter && selectedReport) {
      setSelectedData({
        ...selectedData,
        filterUniqueId: selectedFilter.uniqueId,
        reportName: selectedReport.fileName,
        finishedLoading: true,
      });
    }
  }, [selectedFilter, selectedReport]);

  useEffect(() => {
    setInitialData();
  }, []);

  //#region Export logic
  const handleReportExport = (exportType, downloadFunction) => {
    const request = {
      ReportId: selectedReport.id,
      ExportType: exportType,
      AnalysisFilterId: +selectedFilter.uniqueId,
    };
    downloadReport(request)
      .then((response) => {
        downloadFunction(response.data);
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          severity: "error",
          title: "Se ha generado un error al exportar el reporte",
          subTitle:
            "Por favor, intente nuevamente. Si el problema persiste, contacte a soporte.",
        });
      });
    setSnackbar({
      open: true,
      severity: "info",
      title: "Generando resultados...",
      subTitle: "En unos momentos se descargará el reporte.",
    });
  };

  const downloadExcel = (excelData) => {
    const filterName = selectedFilter ? selectedFilter.name : "default";
    const fileName = `${selectedReport.name} - ${filterName}.xlsx`;
    saveExcel(excelData, fileName);
  };

  const downloadPDF = (pdfData) => {
    const filterName = selectedFilter ? selectedFilter.name : "default";
    const fileName = `${selectedReport.name} - ${filterName}.pdf`;
    savePdf(pdfData, fileName);
  };
  //#endregion

  //#region Manage filter logic
  const handleOpenModalNewFilter = () => {
    setItemToModify(null);
    setModalFilterManageOpen(true);
  };
  const handleOpenModalFilterManage = async () => {
    const response = await getAnalysisFilterById(selectedFilter.uniqueId);
    setItemToModify(response.data);
    setModalFilterManageOpen(true);
  };
  const handleCloseModalFilterManage = () => {
    setModalFilterManageOpen(false);
    setItemToModify(null);
  };

  const handleSaveFilterManage = async (request) => {
    handleCloseModalFilterManage();
    setSelectedData({ ...selectedData, finishedLoading: false }); // Refreshes report

    showLoadingStateSpinnerNoTimeout();
    const newFilters = await loadFilters(false);
    if (!request.AnalysisFilterId) {
      // If it's a new filter, find it by name
      const newFilter = newFilters.find(
        (f) => f.name === request.AnalysisFilterName,
      );
      setSelectedFilter({
        uniqueId: newFilter.uniqueId,
        name: newFilter.name,
      });
    } else {
      setSelectedFilter({
        uniqueId: request.AnalysisFilterId,
        name: request.AnalysisFilterName,
      });
    }

    hideLoadingSpinner();
    return true;
  };
  //#endregion

  //#region Search filter logic
  const handleOpenModalSearchFilters = () => {
    setModalSearchFiltersOpen(true);
  };
  const handleCloseModalSearchFilters = () => {
    setModalSearchFiltersOpen(false);
  };
  //#endregion

  return (
    <Fragment>
      <ManageAnalysisFiltersModal
        open={modalFilterManageOpen}
        onClose={handleCloseModalFilterManage}
        onSave={handleSaveFilterManage}
        filter={itemToModify}
        analysisFilterModuleId={analysisModuleId}
      />
      <SearchFiltersModal
        open={modalSearchFiltersOpen}
        onClose={handleCloseModalSearchFilters}
        analysisFilterModuleId={analysisModuleId}
        setSelectedFilter={setSelectedFilter}
      />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6} width="300px">
              <Autocomplete
                options={reports}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  +option.id === +value.id
                }
                fullWidth
                value={selectedReport}
                onChange={(e, newValue) => {
                  setSelectedReport(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Reportes"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedReport?.icon && (
                        <FontAwesomeIcon
                          icon={selectedReport.icon}
                          style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} width="300px">
              <Autocomplete
                options={filters}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  +option.uniqueId === +value.uniqueId
                }
                fullWidth
                value={selectedFilter}
                onChange={(e, newValue) => {
                  setSelectedFilter(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" placeholder="Filtros" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item sx={{ minWidth: 106 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Tooltip title="Exportar a Excel">
                    <IconButton
                      sx={{ borderRadius: 1, color: "green" }}
                      onClick={() =>
                        handleReportExport(EXPORT_TYPE_EXCEL, downloadExcel)
                      }>
                      <BsFillFileEarmarkExcelFill />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Exportar a PDF">
                    <IconButton
                      color="error"
                      sx={{ borderRadius: 1 }}
                      onClick={() =>
                        handleReportExport(EXPORT_TYPE_PDF, downloadPDF)
                      }>
                      <BsFillFileEarmarkPdfFill />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ minWidth: 106 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                startIcon={<Add />}
                onClick={handleOpenModalNewFilter}>
                Agregar
              </Button>
            </Grid>
            <Grid item sx={{ minWidth: 106 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                startIcon={<Edit />}
                onClick={handleOpenModalFilterManage}>
                Editar
              </Button>
            </Grid>
            <Grid item sx={{ minWidth: 106 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                startIcon={<Search />}
                onClick={handleOpenModalSearchFilters}>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AnalysisHeaderActions;
