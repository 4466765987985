import { useGlobalStore } from "global-store/useGlobalStore";
import { useEffect, useCallback } from "react";

const useDynamicHeight = (padding = 278) => {
  const { height, setHeight } = useGlobalStore((state) => ({
    height: state.height,
    setHeight: state.setHeight,
  }));

  const handleResize = useCallback(() => {
    const rest = window.innerHeight - padding;
    setHeight(rest);
  }, [setHeight, padding]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return height;
};

export default useDynamicHeight;
