const initialState = {
  companyName: null,
  fullName: null,
  loginPending: null,
  roleId: null,
  isAdmin: null,
  isAdminGeneral: null,
  userModules: null,
  applicationModules: null,
  language: null,
  pendingActive: null,
  appVersion: null,
  manageDefaultTab: null,
  currencyList: null,
  currencyDefault: null,
  legalDocumentWTStatus: null,
  tendersStatus: null,
};

const useUserInfoStore = (set) => ({
  userInfo: initialState,
  setUserInfo: (userInfo) => set({ userInfo }),
});

export default useUserInfoStore;
