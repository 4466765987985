import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import useDownloadLang from "./langDownload";
import langPE from "../lang/format-message/es-PE.json";
import configPE from "../lang/format-form/ObjectFormPE.js";

const LangContext = React.createContext(null);

const LangProvider = ({ children }) => {
  const lang = useDownloadLang();

  const [message, setMessage] = useState(langPE);
  const [locale, setLocale] = useState("es-PE");
  const [configForm, setConfigForm] = useState(configPE);

  const setLanguage = (language) => {
    const langDetail = lang.getLangDetail(language);
    setMessage(langDetail.message);
    setLocale(langDetail.locale);
    setConfigForm(langDetail.configForm);
  };

  return (
    <LangContext.Provider
      value={{ setLanguage: setLanguage, configForm: configForm }}>
      {/* <IntlProvider locale={locale} messages={message}> */}
      {children}
      {/* </IntlProvider> */}
    </LangContext.Provider>
  );
};

export { LangProvider, LangContext };
