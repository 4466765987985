import { AnimatePresence, motion } from "framer-motion";
import { Fragment, Suspense, lazy, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { StyledEngineProvider, ThemeProvider } from "@mui/system";

import MuiTheme from "./theme";

// Layout Blueprints

import { LeftSidebar } from "./layout-blueprints";

// Pages

import AadCallback from "AadCallback";
import AuthConfig from "AuthConfig";
import AuthRoute from "AuthRoute";
import Loading from "Loading";
import SuspenseLoading from "SuspenseLoading";
import { useGlobalStore } from "global-store/useGlobalStore";
import useDownloadLang from "intl/context/langDownload";
import Home from "pages/Home";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { ADMIN_GENERAL_ROLE_ID, ADMIN_ROLE_ID } from "utils/constants";
import TenderAnalysisFilters from "pages/Analysis/TenderAnalysis/TenderAnalysisFilters";
import TenderAnalysis from "pages/Analysis/TenderAnalysis/TenderAnalysis";
import TenderManagementCalendar from "pages/TenderManagement/Calendar/TenderManagementCalendar";
import TenderInformation from "pages/TenderInformation/TenderInformation";
import TenderManagement from "pages/TenderManagement/TenderManagement";
import SmartSearch from "pages/SmartSearch/SmartSearch/SmartSearch";
import Recipients from "pages/Settings/Recipients";
import NotificationsAlerts from "pages/Settings/NotificationsAlerts";
import PurchaseOrderAnalysisFilters from "pages/Analysis/PurchaseOrderAnalysis/PurchaseOrderAnalysisFilters";
import PurchaseOrderAnalysis from "pages/Analysis/PurchaseOrderAnalysis/PurchaseOrderAnalysis";
import TenderManagementLegalDocuments from "pages/TenderManagement/LegalDocuments/TenderManagementLegalDocuments";
import TenderManagementPenalties from "pages/TenderManagement/Penalties/TenderManagementPenalties";
import OutsiderTenders from "pages/TenderManagement/OutsiderTenders/OutsiderTenders";
import SmartSearchFiltersAdmin from "pages/SmartSearch/FiltersAdmin/SmartSearchFiltersAdmin";
import PAC from "pages/SmartSearch/PAC/PAC";
import Companies from "pages/Settings/Companies/Companies";
import Users from "pages/Settings/Users/Users";
import CompetitorsOC from "pages/Settings/CompetitorsOC";
import OrganismsPAC from "pages/Settings/OrganismsPAC";

const AppRoutes = (props) => {
  const location = useLocation();
  const lang = useDownloadLang();
  const setIntlConfigForm = useGlobalStore((state) => state.setIntlConfigForm);

  useEffect(() => {
    setIntlConfigForm(
      lang.getLangDetail(props?.userInfo?.language)?.configForm,
    );
  }, [props?.userInfo?.language]);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.4,
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <AnimatePresence>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path={"/aad-callback"} component={AadCallback} />
              {props.validUrlProtocol && (
                <SuspenseLoading msg={props.acquireTokenError}>
                  <IntlProvider
                    locale={
                      lang.getLangDetail(props?.userInfo?.language)?.locale
                    }
                    messages={
                      lang.getLangDetail(props?.userInfo?.language)?.message
                    }>
                    <AuthConfig>
                      <Route
                        path={[
                          "/",
                          "/destinatarios",
                          "/notificaciones",
                          "/usuarios",
                          "/empresas",
                          "/competidores-oc",
                          "/entidades-pac",
                          "/pac",
                          "/smart-search/resultados",
                          "/smart-search/filtros",
                          "/gestion",
                          "/gestion/calendario",
                          "/gestion/garantias",
                          "/gestion/penalidades",
                          "/gestion-informacion/:tenderNumber",
                          "/gestion/externas",
                          "/analisis",
                          "/analisis/filtros",
                          "/orden-compra",
                          "/orden-compra/filtros",
                        ]}>
                        <LeftSidebar>
                          <Switch location={location} key={location.pathname}>
                            <Fragment>
                              <motion.div
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                                transition={pageTransition}
                                style={{ height: "inherit" }}>
                                <AuthRoute>
                                  <Route path="/" exact component={Home} />
                                  <Route
                                    path="/analisis/filtros"
                                    component={TenderAnalysisFilters}
                                    exact
                                    moduleId={"AL"}
                                  />
                                  <Route
                                    path="/analisis"
                                    component={TenderAnalysis}
                                    exact
                                    moduleId={"AL"}
                                  />
                                  <Route
                                    path="/orden-compra/filtros"
                                    component={PurchaseOrderAnalysisFilters}
                                    exact
                                    moduleId={"OC"}
                                  />
                                  <Route
                                    path="/orden-compra"
                                    component={PurchaseOrderAnalysis}
                                    exact
                                    moduleId={"OC"}
                                  />
                                  <Route
                                    path="/gestion/calendario"
                                    component={TenderManagementCalendar}
                                    exact
                                    moduleId={"GL"}
                                  />
                                  <Route
                                    path="/gestion/garantias"
                                    component={TenderManagementLegalDocuments}
                                    exact
                                    moduleId={"GL"}
                                  />
                                  <Route
                                    path="/gestion/penalidades"
                                    component={TenderManagementPenalties}
                                    exact
                                    moduleId={"GL"}
                                  />
                                  <Route
                                    path="/gestion-informacion/:tenderNumber"
                                    render={(props) => (
                                      <TenderInformation {...props} />
                                    )}
                                    moduleId={"GL"}
                                  />
                                  <Route
                                    path="/gestion"
                                    component={TenderManagement}
                                    exact
                                    moduleId={"GL"}
                                  />
                                  <Route
                                    path="/gestion/externas"
                                    component={OutsiderTenders}
                                    exact
                                    moduleId={"GL"}
                                  />
                                  <Route
                                    path="/smart-search/resultados"
                                    component={SmartSearch}
                                    exact
                                    moduleId={"SS"}
                                  />
                                  <Route
                                    path="/smart-search/filtros"
                                    component={SmartSearchFiltersAdmin}
                                    exact
                                    moduleId={"SS"}
                                  />
                                  <Route
                                    path="/pac"
                                    component={PAC}
                                    moduleId={"PAC"}
                                  />
                                  <Route
                                    path="/empresas"
                                    component={Companies}
                                    isAdminGeneral={true}
                                  />
                                  <Route
                                    path="/usuarios"
                                    component={Users}
                                    isAdmin={true}
                                  />
                                  <Route
                                    path="/competidores-oc"
                                    component={CompetitorsOC}
                                    isAdmin={true}
                                  />
                                  <Route
                                    path="/entidades-pac"
                                    component={OrganismsPAC}
                                    isAdmin={true}
                                  />
                                  <Route
                                    path="/destinatarios"
                                    component={Recipients}
                                    isAdmin={true}
                                  />
                                  <Route
                                    path="/notificaciones"
                                    component={NotificationsAlerts}
                                    isAdmin={true}
                                  />
                                </AuthRoute>
                              </motion.div>
                            </Fragment>
                          </Switch>
                        </LeftSidebar>
                      </Route>
                    </AuthConfig>
                  </IntlProvider>
                </SuspenseLoading>
              )}
            </Switch>
          </Suspense>
        </AnimatePresence>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  const { user } = state;
  const { companyName, fullName, loginPending, roleId, language } = user;
  const userModules = user.applicationModules;
  const { appVersion, applicationModules } = app;

  const isAdmin = roleId === ADMIN_ROLE_ID || roleId === ADMIN_GENERAL_ROLE_ID;
  const isAdminGeneral = roleId === ADMIN_GENERAL_ROLE_ID;
  return {
    ...ownProps,
    userInfo: {
      companyName,
      fullName,
      loginPending,
      roleId,
      isAdmin,
      isAdminGeneral,
      userModules,
      applicationModules,
      language,
      appVersion,
    },
  };
};

export default connect(mapStateToProps)(AppRoutes);
