import { MonetizationOn } from "@mui/icons-material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components";
import {
  addOrUpdatePenalty,
  getPenalties,
} from "pages/TenderInformationContent/tenderInformationContentService";
import { Fragment, useState } from "react";
import PenaltiesManageFormModal from "./PenaltiesManageFormModal";

const columns = [
  {
    accessorKey: "tenderId",
    header: "Licitación",
    size: "120",
    align: "center",
    Cell: ({ cell, column }) => (cell.getValue() === 1 ? "Si" : "No"),
  },
  {
    accessorKey: "customerCode",
    header: "Cod. Cliente",
    size: "180",
    align: "center",
  },
  { accessorKey: "customerName", header: "Cliente", size: "180" },
  { accessorKey: "saleOrder", header: "SO", size: "180", align: "center" },
  { accessorKey: "purchaseOrderCode", header: "Orden Compra", size: "180" },
  {
    accessorKey: "invoiceNumber",
    header: "Número Fact.",
    size: "180",
    align: "center",
  },
  {
    accessorKey: "invoiceDate",
    header: "Fecha Fact.",
    size: "150",
    align: "center",
    cellType: "date",
  },
  {
    accessorKey: "invoiceAmount",
    header: "Valor Fact.",
    size: "150",
    textAlign: "Right",
    Cell: ({ cell, column }) =>
      cell.getValue()
        ? cell.getValue().toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : null,
  },
  {
    accessorKey: "penaltyAmount",
    header: "Multa",
    size: "150",
    textAlign: "Right",
    Cell: ({ cell, column }) =>
      cell.getValue()
        ? cell.getValue().toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : null,
  },
  {
    accessorKey: "penaltyReasonDesc",
    header: "Motivo",
    size: "200",
    align: "center",
  },
  {
    accessorKey: "responsible",
    header: "Responsable",
    size: "180",
    align: "center",
  },
  {
    accessorKey: "caseNumber",
    header: "Case # SF",
    size: "180",
    align: "center",
  },
  { accessorKey: "description", header: "Observaciones", size: "300" },
];

const TenderManagementPenalties = () => {
  const [penaltyToAddModalOpen, setPenaltyToAddModalOpen] = useState(false);

  const {
    userInfo,
    setShouldRefetch,
    showSavingStateSpinner,
    setSnackbarSaveCustom,
  } = useGlobalStore((state) => ({
    userInfo: state.userInfo,
    setShouldRefetch: state.setShouldRefetch,
    showSavingStateSpinner: state.showSavingStateSpinner,
    setSnackbarSaveCustom: state.setSnackbarSaveCustom,
  }));

  const fetchData = async (
    pageNumber,
    pageSize,
    filter,
    sorting,
    exporting,
  ) => {
    let orderBy = sorting.length > 0 ? sorting[0].id : "";
    let orderAsc = sorting.length > 0 ? !sorting[0].desc : false;
    const request = {
      orderBy: orderBy,
      pageSize: pageSize,
      filter: filter,
      pageNumber: pageNumber,
      orderAsc: orderAsc,
    };

    const response = await getPenalties(request);
    // flatten with json parse the "data" field in data.currentPageItems
    // (this is done to obtain all the generic fields in the data because it has different intl fields)
    const currentPageItems = response.data.currentPageItems.map((item) => {
      return { ...item, ...JSON.parse(item.data) };
    });
    return {
      result: currentPageItems,
      total: response.data.totalItems,
    };
  };

  const options = {
    tableId: "gridTenderManagementPenalties",
    header: columns,
    fullHeight: true,
    tableHeaderTitle: "Penalidades",
    toolbarButtons: {
      Add: true,
      AddFunc: () => setPenaltyToAddModalOpen(true),
      Export: true,
    },
  };

  const handleCloseModal = () => {
    setPenaltyToAddModalOpen(false);
  };

  const handleSave = async (request) => {
    showSavingStateSpinner();
    await addOrUpdatePenalty(request);
    setShouldRefetch();
    setSnackbarSaveCustom("Penalidad guardada exitosamente");
    handleCloseModal();
  };

  return (
    <Fragment>
      <PageTitle titleHeading={"Penalidades"} icon={<MonetizationOn />} />
      <PenaltiesManageFormModal
        open={penaltyToAddModalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
      />
      <FSTMaterialReactTable options={options} getData={fetchData} />
    </Fragment>
  );
};

export default TenderManagementPenalties;
