import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { setupInitialData } from "actions/appActions";
import { setupUserData } from "actions/userActions";
import { axiosConfig } from "config/axios";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SuspenseLoading from "SuspenseLoading";

const mapStateToProps = (state, ownProps) => {
  return { ...ownProps };
};

function AuthConfig(props) {
  const { finishedConfig, setFinishedConfig } = useGlobalStore((state) => ({
    finishedConfig: state.finishedConfig,
    setFinishedConfig: state.setFinishedConfig,
  }));
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    async function init() {
      axiosConfig(props.dispatch, setFinishedConfig);
      props.dispatch(setupUserData(appInsights));
      props.dispatch(setupInitialData());
    }
    init();
  }, []);

  const { children } = props;
  return (
    <SuspenseLoading
      checkFinishedLoading={true}
      betweenPagesLoading={true}
      finishedConfig={finishedConfig}>
      {children}
    </SuspenseLoading>
  );
}

export default withRouter(connect(mapStateToProps)(AuthConfig));
