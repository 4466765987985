import Add from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import { isLangAR, isLangCL } from "utils/common";

function TenderManagementOutsidersButton({ history }) {
  const handleRedirectToOutsiderTenders = () => {
    history.push("/gestion/externas");
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {(isLangAR() || isLangCL()) && (
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={handleRedirectToOutsiderTenders}>
          <Add sx={{ mr: 1 }} />
          Agregar Licitaciones Externas
        </Button>
      )}
    </Box>
  );
}

export default TenderManagementOutsidersButton;
