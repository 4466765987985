import { FormattedForm } from "intl/utils/format-elements/formattedForm";
import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import { Fragment, useEffect, useState } from "react";

// TODO: Abstract this component to a generic component that can be used in other places
export default function OffersInfo({ data }) {
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);

  const initData = () => {
    const result = handelData.getDataByCountry("tab.Ofertas");
    setData([result]);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Fragment>
      {runInit && <FormattedForm id={"TabOffersInfo"} data={getData} />}
    </Fragment>
  );
}
