import { getSentTenders } from "pages/TenderManagement/tenderManagementService";

const useTenderSentEmailsStore = (set) => ({
  tenderSentEmails: [],
  fetchTenderSentEmails: async () => {
    const response = await getSentTenders();
    set({ tenderSentEmails: response.data });
  },
});

export default useTenderSentEmailsStore;
