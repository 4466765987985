import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  Menu,
  Skeleton,
  Typography,
} from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useState } from "react";
import useManageStatusGroups from "utils/hooks/useManageStatusGroups";

const TenderManagementStatusTabs = ({}) => {
  const {
    isLoading,
    isError,
    data: statusGroups,
    error,
  } = useManageStatusGroups();

  const { viewsState, setManageViewsState, setShouldRefetch } = useGlobalStore(
    (state) => ({
      viewsState: state.viewsState,
      setManageViewsState: state.setManageViewsState,
      setShouldRefetch: state.setShouldRefetch,
    }),
  );
  const {
    manage: { currentStatusGroup },
  } = viewsState;
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleSelectStatus = (id) => {
    setManageViewsState({ ...viewsState.manage, currentStatusGroup: id });
    setTimeout(() => handleCloseMenu(), 0);
    setTimeout(() => setShouldRefetch(), 0);
  };

  if (isLoading) {
    return (
      <>
        <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
        <Box display="flex" alignItems="center" ml={2} minWidth={200}>
          <Skeleton animation="wave" width="100%" />
        </Box>
      </>
    );
  }

  return (
    <>
      <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
      <Box display="flex" alignItems="center" ml={2} minWidth={200}>
        <Button
          color="secondary"
          size="small"
          onClick={handleClickMenu}
          startIcon={
            <Badge
              badgeContent={
                statusGroups.find(
                  (item) => item.statusId === currentStatusGroup,
                )?.qtty
              }
              color="secondary"
              sx={{ mr: 3 }}>
              {
                statusGroups.find(
                  (item) => item.statusId === currentStatusGroup,
                )?.icon
              }
            </Badge>
          }
          endIcon={<ArrowDropDownIcon sx={{ ml: 2 }} />}>
          <span style={{ fontSize: "0.86rem" }}>
            {
              statusGroups.find((item) => item.statusId === currentStatusGroup)
                ?.statusDesc
            }
          </span>
        </Button>

        <Menu
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          classes={{ list: "p-0" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}>
          <div className="overflow-hidden dropdown-menu-xl">
            <List className="nav-danger nav-pills flex-column p-3">
              <Typography
                className="text-black pb-2 px-3 font-weight-bold"
                component="div">
                Estados
              </Typography>

              {statusGroups.map((singleStatus, idx) => {
                return (
                  <ListItemButton
                    key={idx}
                    className="rounded"
                    onClick={() => handleSelectStatus(singleStatus.statusId)}
                    selected={singleStatus.statusId === currentStatusGroup}>
                    <div className="nav-link-icon opacity-6 mr-2">
                      {singleStatus.icon}
                    </div>
                    <span>{singleStatus.statusDesc}</span>
                    <span className="ml-auto badge badge-first">
                      {singleStatus.qtty}
                    </span>
                  </ListItemButton>
                );
              })}
            </List>
          </div>
        </Menu>
      </Box>
    </>
  );
};

export default TenderManagementStatusTabs;
