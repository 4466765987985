import { formatValueLocale } from "utils/common";

const commonHeader = [
  {
    intlId: "cols.Ejecutivos",
    accessorKey: "sellers",
    header: "Ejecutivos",
    size: 250,
    Cell: ({ cell }) =>
      cell.getValue()?.map((seller, idx) => (
        <span
          key={seller.sellerId}
          style={{
            fontWeight: seller.active ? "bold" : "normal",
            color: seller.active ? undefined : "gray",
          }}>
          {seller.name}
          {idx < cell.getValue().length - 1 ? ", " : ""}
        </span>
      )),
    enableSorting: false,
    excelExportFormat: ({ cell }) =>
      cell
        .getValue()
        .map((seller) => seller.name)
        .join(", "),
  },
  {
    intlId: "cols.Publicacion",
    accessorKey: "publishedDate",
    header: "Publicación",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.Nomenclatura",
    accessorKey: "fileNumber",
    header: "Nomenclatura",
    size: 315,
  },
  {
    intlId: "cols.Portal",
    accessorKey: "portal",
    header: "Portal",
    size: 150,
  },
  {
    intlId: "cols.Convocatoria",
    accessorKey: "tenderNumber",
    header: "Convocatoria",
    size: 130,
  },
  {
    intlId: "cols.Region",
    accessorKey: "regionName",
    header: "Región",
    size: 120,
  },
  {
    intlId: "cols.Entidad",
    accessorKey: "clientUnitName",
    header: "Entidad",
    size: 400,
  },
  {
    intlId: "cols.ClientName", // Used exclusively in AR. "Servicio Administrativo Financiero"
    accessorKey: "clientName",
    header: "Cliente",
    size: 400,
  },
  {
    intlId: "cols.Nombre",
    accessorKey: "tenderName",
    header: "Nombre",
    size: 400,
  },
  {
    intlId: "cols.Modalidad",
    accessorKey: "modalityType",
    header: "Modalidad",
    size: 180,
  },
  {
    intlId: "cols.Situacion",
    accessorKey: "manageInternalStatusDesc",
    header: "Situación",
    Cell: ({ cell }) => (
      <div className="d-flex align-items-center justify-content-center">
        <span
          className={`btn-pill badge`}
          style={{
            backgroundColor: cell.row.original.manageInsternalStatusColor,
            color: "white",
          }}>
          {cell.getValue()}
        </span>
      </div>
    ),
    disableTooltip: true,
  },
  {
    intlId: "cols.Etiquetas",
    accessorKey: "tags",
    header: "Etiquetas",
    enableSorting: false,
    Cell: ({ cell }) => cell.getValue()?.join(" - "),
  },
  {
    intlId: "cols.OContratacion",
    accessorKey: "tenderDescription",
    header: "O.Contratación",
    size: 150,
  },
  {
    intlId: "cols.Link",
    accessorKey: "link",
    header: "Link",
    size: 150,
    disableTooltip: true,
    Cell: ({ cell }) => (
      <a href={cell.getValue()} target="_blank">
        Link
      </a>
    ),
  },
  {
    intlId: "cols.Referencial",
    accessorKey: "estimatedValue",
    header: "Estimado/Referencial",
    size: 180,
    Cell: ({ cell }) => formatValueLocale(cell.getValue(), 2, 2),
  },
  {
    intlId: "cols.Moneda",
    accessorKey: "currency",
    header: "Moneda",
    size: 110,
  },
  {
    intlId: "cols.Estado",
    accessorKey: "tenderStatusDesc",
    header: "Estado",
    size: 130,
  },
  {
    intlId: "cols.Items",
    accessorKey: "detailsQty",
    header: "Items",
    size: 140,
  },
  {
    intlId: "cols.Cierre",
    accessorKey: "endDate",
    header: "Cierre",
    size: 150,
    cellType: "datetime",
  },
];

export const TenderManagementHeader = [
  ...commonHeader,
  {
    intlId: "cols.Finconsultas",
    accessorKey: "questionsReceptionEnd",
    header: "Fin consultas",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.BasesInt",
    accessorKey: "basesIntegratedEnd",
    header: "Bases int.",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.FinRegistro",
    accessorKey: "registryEnd",
    header: "Fin registro",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.FinPresProp",
    accessorKey: "offersReceptionEnd",
    header: "Fin pres. Prop.",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.BuenaPro",
    accessorKey: "awardEnd",
    header: "Buena Pro",
    size: 150,
    cellType: "datetime",
  },
  {
    intlId: "cols.Usuario",
    accessorKey: "userManage",
    header: "Usuario",
    size: 150,
  },
  {
    intlId: "cols.Segmento",
    accessorKey: "categories",
    header: "Segmento",
    size: 150,
    enableSorting: false,
    Cell: ({ cell }) => cell.getValue()?.join(" - "),
  },
  {
    intlId: "cols.Gestionada",
    accessorKey: "dateCreationManage",
    header: "Gestionada",
    size: 150,
    cellType: "datetime",
  },
];

export const DeletedTenderManagementHeader = [
  ...commonHeader,
  {
    accessorKey: "userManage",
    header: "Usuario",
    size: 130,
  },
  {
    accessorKey: "dateCreationManage",
    header: "Gestionada",
    size: 150,
    cellType: "datetime",
  },
  {
    accessorKey: "deleteReasonDesc",
    header: "Motivo",
    size: 150,
  },
  {
    accessorKey: "deleteComment",
    header: "Comentario",
    size: 150,
  },
  {
    accessorKey: "deleteDate",
    header: "Eliminada",
    size: 150,
    cellType: "datetime",
  },
];
