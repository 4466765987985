import { Fragment, useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { Settings } from "@mui/icons-material";
import {
  getGeneralDataNotifications,
  listCompanySettings,
  listNotifications,
} from "pages/Settings/settingsService";
import { SOURCES_LINKS } from "utils/constants";
import HomeTitle from "../HomeTitle";
import HomeLoadingState from "../HomeLoadingState";

const LoadingState = () => {
  return (
    <Fragment>
      <HomeTitle title="Ajustes" TitleIcon={Settings} />
      <HomeLoadingState />
    </Fragment>
  );
};

export default function HomeSettingsStats({ sources, loading, ss }) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeSources, setActiveSources] = useState([]);
  const [activeNotifTypes, setActiveNotifTypes] = useState([]);

  const initData = async () => {
    const result = await listCompanySettings();
    const ids = result.data.smartSearchSources?.split(",").map((x) => +x);
    let activeSources = sources.filter((x) => ids.includes(x.id));
    activeSources = activeSources
      .map((x) => ({
        ...x,
        url: SOURCES_LINKS[x.name],
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setActiveSources(activeSources);

    const listNotif = await listNotifications();
    const notifData = await getGeneralDataNotifications();
    let notifTypes = notifData.data.notificationType; // '{stringkey: id}'
    let notifTypeIds = listNotif.data
      .filter((x) => x.active)
      .map((x) => x.notificationTypeId);
    let notifTypeNames = Object.keys(notifTypes).filter((x) =>
      notifTypeIds.includes(notifTypes[x]),
    );
    notifTypeNames = notifTypeNames.map((x) => ({
      id: notifTypes[x],
      name: x,
    }));
    setActiveNotifTypes(notifTypeNames);
    setIsLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  if (loading || isLoading) {
    return <LoadingState />;
  }

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className="card-box border-0 card-shadow-success p-3 mb-4">
            <div className="d-flex align-items-center">
              <Settings fontSize="medium" sx={{ mr: 2 }} />
              <Typography variant="h4">Ajustes</Typography>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {ss && (
          <Grid item xs={12} lg={6}>
            <Card className="card-box mb-4">
              <div className="card-header pr-2">
                <div className="card-header--title font-weight-bold">
                  Portales activos para resultados de Smart Search
                </div>
                <div className="card-header--actions"></div>
              </div>
              <CardContent className="p-1" sx={{ overflowY: "hidden" }}>
                <List
                  className="py-0"
                  sx={{
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      WebkitAppearance: "none",
                      width: "10px",
                      height: "10px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "4px",
                      backgroundColor: "#a9a9cfc2",
                      WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f5f5f5",
                    },
                    height: 336,
                  }}>
                  {activeSources.map((item, idx) => (
                    <ListItem
                      className="d-flex justify-content-between align-items-center py-2"
                      key={item.id}>
                      <div className="d-flex align-items-center">
                        <div>
                          <a
                            href={item.url}
                            target="_blank"
                            className="font-weight-bold text-black">
                            {item.name}
                          </a>
                        </div>
                      </div>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className="ml-4"
                        href={item.url}
                        target="_blank">
                        Ingresar
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card className="card-box mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title font-weight-bold">
                Notificaciones Activas
              </div>
              <div className="card-header--actions"></div>
            </div>
            <Box
              className="table-responsive"
              sx={{
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  WebkitAppearance: "none",
                  width: "10px",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "4px",
                  backgroundColor: "#a9a9cfc2",
                  WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
                height: 344,
              }}>
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {activeNotifTypes.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="font-weight-bold text-black">
                            {item.name}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}
