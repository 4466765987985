import { capitalize, formatValueLocale, getDataJsonBucle } from "utils/common";
import { ConvertToDateLong } from "utils/DateHelper";

export default {
  TenderContainer: {
    Filter: {
      Tab: [
        {
          id: "tab.General",
          keys: "",
          types: "O",
        },
        {
          id: "tab.Cronograma",
          keys: "tenderStages",
          types: "O",
        },
        {
          id: "tab.ListaItems",
          keys: "tenderDetails",
          types: "O",
        },
        {
          id: "tab.Documentos",
          keys: "tenderDocuments",
          types: "O",
        },
      ],
    },
  },
  TenderGestion: {
    Filter: {
      menuInfo: [
        "tab.General",
        "tab.Cronograma",
        "tab.ListaItems",
        "tab.Documentos",
      ],
      menuGestion: [
        "tab.Asignaciones",
        "tab.ListaItems",
        "tab.CartasFianza",
        "tab.Comentarios",
      ],
    },
  },
  ManagementCalendarExportValues: {
    elements: [
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => item.toString(),
      },
      {
        id: "cols.Publicacion",
        value: "publishedDate",
        format: (item) => ConvertToDateLong(item),
      },
      { id: "cols.Nomenclatura", value: "fileNumber" },
      { id: "cols.Convocatoria", value: "tenderNumber" },
      { id: "cols.Entidad", value: "clientUnitName" },
      { id: "cols.Nombre", value: "tenderName" },
      { id: "cols.Situacion", value: "manageInternalStatusDesc" },
      { id: "cols.OContratacion", value: "tenderDescription" },
      { id: "cols.Moneda", value: "currency" },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      { id: "cols.Items", value: "detailsQty" },
      { id: "cols.Usuario", value: "userManage" },
      {
        id: "cols.Segmento",
        value: "categories",
        format: (item) => (item.length > 0 ? item[0] : ""),
      },
      {
        id: "cols.Gestionada",
        value: "dateCreationManage",
        format: (item) => ConvertToDateLong(item),
      },
    ],
  },
  ManagementCalendarValues: {
    elements: [
      { id: "cols.Convocatoria", value: "tenderNumber" },
      {
        id: "cols.Nombre",
        value: "tenderName",
        format: (item) => capitalize(item),
      },
      {
        id: "cols.Entidad",
        value: "clientUnitName",
        format: (item) => capitalize(item),
      },
      { id: "cols.Estado", value: "tenderStatusDesc" },
      {
        id: "cols.Situacion",
        value: "manageInternalStatusDesc",
        format: (item) => (item ? item : "Sin Asignar"),
      },
      { id: "cols.Portal", value: "portal" },
      {
        id: "cols.Ejecutivos",
        value: "sellers",
        format: (item) => (item.length > 0 ? item.toString() : "Sin Asignar"),
      },
    ],
  },
  EmailSubjectConfig: {
    elements: [
      { id: "cols.Nombre", name: "tenderName" },
      { id: "cols.Id", name: "tenderNumber" },
      { id: "cols.Cierre", name: "endDate" },
      { id: "text.Tipo_Compra", name: "tenderTypeDesc" },
      { id: "cols.Portal", name: "portal" },
      { id: "cols.Entidad", name: "clientUnitName" },
      { id: "cols.ClientName", name: "clientName" },
      { id: "cols.Usuario", name: "userManage" },
    ],
  },
  TenderGeneralInfo: {
    elements: [
      {
        id: "text.Nomenclatura",
        value: "fileNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Convocatoria",
        value: "tenderNumber",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Fecha_Publicacion",
        value: "publishedDate",
        type: "date",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Tipo_Compra",
        value: "tenderTypeDesc",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Entidad",
        value: "clientUnitName",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.ClientName",
        value: "clientName",
        xs: 12,
        sm: 6,
        md: 3,
      },
      {
        id: "text.Descripcion",
        value: "tenderName",
        xs: 12,
        sm: 12,
        md: 6,
      },
    ],
  },
  ListCompGeneralInfo: {
    component: [
      {
        type: "Grid",
        id_ff: "TabGeneralInfoFirst",
      },
      {
        type: "Typography", // Empty space
        props: { style: { marginTop: "2rem" } },
      },
      {
        type: "Grid",
        id_ff: "TabGeneralInfoLast",
        style: { marginTop: "2rem" },
      },
    ],
  },
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            {
              key: "tenderDescription",
              intlLabelKey: "tabG.Objeto_Contratacion",
            },
            {
              key: "extension",
              intlLabelKey: "tabG.TipoModalidad",
              render: (value, row) =>
                getDataJsonBucle("Info.character", value, "J.V") ?? "-",
            },
            {
              key: "extension",
              intlLabelKey: "tabG.Caracter",
              render: (value, row) =>
                getDataJsonBucle("Info.modalityType", value, "J.V") ?? "-",
            },
            {
              key: "publishedDate",
              intlLabelKey: "tabG.Fecha_Publicacion",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabGeneralInfoLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            {
              key: "clientUnitName",
              intlLabelKey: "tabG.Entidad_Contratante",
            },
            {
              key: "extension",
              intlLabelKey: "tabG.Ramo",
              render: (value, row) =>
                getDataJsonBucle("Info.branch", value, "J.V") ?? "-",
            },
            {
              key: "clientName",
              intlLabelKey: "tabG.ClientName",
            },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "name", intlLabelKey: "tabC.Etapa" },
            {
              key: "endDate",
              intlLabelKey: "tabC.Fecha_Fin",
              cellType: "datetime",
            },
          ],
        },
      },
    ],
  },
  TabItemListFirst: {
    element: {
      type: "FSTMRT",
      columns: [
        {
          accessorKey: "itemNumber",
          header: "#",
          size: "60",
          enableSorting: false,
        },
        {
          accessorKey: "extension",
          header: "Partida específica",
          size: 130,
          Cell: ({ cell, column }) =>
            cell.getValue()
              ? JSON.parse(cell.getValue())
                ? JSON.parse(cell.getValue()).batchNumber
                : ""
              : "",
        },
        {
          accessorKey: "specification",
          header: "Descripción CUCoP+",
          size: "140",
          enableSorting: false,
        },
        {
          accessorKey: "description",
          header: "Descripción detallada",
          size: "220",
          enableSorting: false,
        },
        {
          accessorKey: "code",
          header: "Clave CUCoP+",
          size: "110",
          enableSorting: false,
        },
        {
          accessorKey: "qty",
          header: "Cantidad solicitada",
          size: "60",
          textAlign: "center",
          enableSorting: false,
        },
        {
          accessorKey: "uom",
          header: "Unidad de medida",
          size: "90",
          enableSorting: false,
        },
        // {
        //   id: "tabL.Adjudicaciones",
        //   accessorKey: "tenderDetailAwardeds",
        //   header: "Adjudicaciones/Ofertas",
        //   size: "100",
        //   hideToExport: true,
        //   disableTooltip: true,
        //   Cell: ({ cell, column }) => {
        //     if (!cell.getValue() || cell.getValue().length === 0) return null;
        //     return (
        //       <ButtonModalDetail
        //         queryClient={"TabItemListLast"}
        //         detail={"Adjudicaciones / Ofertas"}
        //         data={cell.getValue()}
        //         result={{}}
        //         titleModal={"Adjudicaciones / Ofertas"}
        //         modalName={"TabItemListLast"}
        //       />
        //     );
        //   },
        // },
      ],
    },
  },
  TabItemListLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "competitorName", intlLabelKey: "tabL.Adj.Postor" },
            { key: "qty", intlLabelKey: "tabL.Adj.Cantidad" },
            {
              key: "ammount",
              intlLabelKey: "tabL.Adj.Monto",
              render: (value) => formatValueLocale(value),
            },
            // { key: "?", intlLabelKey: "Subtotal" },
            // { key: "?", intlLabelKey: "IVA" },
          ],
        },
      },
    ],
  },
  ListCompDocuments: {
    component: [
      {
        type: "Grid",
        id_ff: "TabProcedureDocument",
      },
    ],
  },
  TabProcedureDocument: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", intlLabelKey: "tabD.Nro" },
            { key: "name", intlLabelKey: "tabD.Documento" },
            {
              key: "link",
              intlLabelKey: "tabD.Archivo",
              render: (value, row) => (
                <a href={value} target="_blank">
                  {row.name}
                </a>
              ),
            },
          ],
        },
      },
    ],
  },

  ValueCmb_Carta: {
    objectName: "subjectsMX",
  },

  // From now on, not used.
  TabDocumentsFolds: { elements: [] },
  TabDocumentsParticularClauses: { elements: [] },
  TabDocumentsAnnexes: { elements: [] },
  TabDocumentsTechnicalFolds: { elements: [] },
  TabGeneralProcedureActions: { elements: [] },
  TabSupervisingJob: { elements: [] },
  TabRequirement: { elements: [] },
  ListCompWarraty: {
    listData: "StructureDataWarrantyXX",
    component: [],
  },
  TabWarranty: { elements: [] },
  TabOffersInfo: { elements: [] },
  TabContractConditions: { elements: [] },
  TabDelivery: { elements: [] },
  TabRelatedOfficials: { elements: [] },
  TabAwardedContracts: { elements: [] },
  TabAwardedPurchaseOrders: { elements: [] },
  TabItemModalDeliveryDetail: { elements: [] },
};
