import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AutocompletePaging from "components/CustomFields/AutocompletePaging";
import NumericField from "components/CustomFields/NumericField";
import { getProducts } from "pages/Administration/administrationServices";

const OutsiderTendersFormComponent = ({
  outsiderValue,
  onChange,
  index,
  indexTopElem = null,
  indexAux = null,
}) => {
  const loadOptionsCallback = async (request) => {
    return getProducts(request);
  };

  return (
    <Grid item xs={12} sm={6} key={index}>
      {outsiderValue === null ? (
        <div style={{ width: 300 }} />
      ) : (
        <Grid
          container
          spacing={4}
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Grid item sx={{ width: 250 }}>
            <Typography variant="h6">
              {outsiderValue.columnName}
              {outsiderValue.required && (
                <span style={{ color: "red" }}> *</span>
              )}
              {(outsiderValue.columnValue === "tenderNumber" ||
                outsiderValue.columnValue === "clientUnitUniqueId" ||
                outsiderValue.columnValue === "clientUnitName") && (
                <span style={{ color: "red" }}>*</span>
              )}
            </Typography>
          </Grid>
          <Grid item sx={{ minWidth: 300, mr: "2rem" }}>
            {outsiderValue.type === "select" && (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                required={outsiderValue.required}
                value={outsiderValue.inputValue}
                select
                onChange={(e) =>
                  onChange(e, index, indexTopElem, indexAux, outsiderValue)
                }>
                {outsiderValue?.options?.map((option, idx) => (
                  <MenuItem key={idx} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {outsiderValue.type === "date" && (
              <DateTimePicker
                // InputProps={{ readOnly: true }}
                // invalidDateMessage="Fecha inválida"
                format="DD/MM/YYYY HH:mm"
                value={outsiderValue.inputValue || null}
                onChange={(newValue) =>
                  onChange(
                    null,
                    index,
                    indexTopElem,
                    indexAux,
                    outsiderValue,
                    newValue,
                  )
                }
                slotProps={{
                  textField: {
                    required: outsiderValue.required,
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            )}
            {outsiderValue.type === "number" && (
              <NumericField
                name={outsiderValue.columnName}
                value={outsiderValue.inputValue}
                onChange={(e, newValue) =>
                  onChange(
                    { target: { value: +e.target.value } },
                    index,
                    indexTopElem,
                    indexAux,
                    outsiderValue,
                    +e.target.value,
                  )
                }
                required={outsiderValue.required}
                size="small"
                // decimalCharacter=","
                // decimalPlaces={2}
                // digitGroupSeparator="."
                label=""
                fullWidth
              />
            )}
            {outsiderValue.type === "productAutocomplete" && (
              <AutocompletePaging
                value={outsiderValue.inputValue}
                loadOptionsCallback={loadOptionsCallback}
                onChange={(newValue) => {
                  onChange(
                    {
                      target: { value: newValue },
                    },
                    index,
                    indexTopElem,
                    indexAux,
                    outsiderValue,
                    newValue,
                  );
                }}
                getOptionLabel={(option) => {
                  if (!option || (!option.productCode && !option.productDesc))
                    return "";
                  return `(${option.productCode}) - ${option.productDesc}${
                    option.data
                      ? ` - ${Object.values(JSON.parse(option.data)).join(
                          " - ",
                        )}`
                      : ""
                  }`;
                }}
                fullWidth
                textFieldVariant="outlined"
                textFieldSize="small"
              />
            )}
            {outsiderValue.type !== "select" &&
              outsiderValue.type !== "date" &&
              outsiderValue.type !== "number" &&
              outsiderValue.type !== "productAutocomplete" && (
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  required={outsiderValue.required}
                  value={outsiderValue.inputValue}
                  onChange={(e) =>
                    onChange(e, index, indexTopElem, indexAux, outsiderValue)
                  }
                />
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OutsiderTendersFormComponent;
