import { useState } from "react";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FlagIcon from "@mui/icons-material/Flag";
import GroupIcon from "@mui/icons-material/Group";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useIntl } from "react-intl";

const AdministrationMenu = (props) => {
  const modals = {
    Seller: false,
    Bank: false,
    InternalStatus: false,
    Tag: false,
    Product: false,
    Category: false,
    DeleteReason: false,
  };

  const [modal, setModal] = useState(modals);

  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = (modalName) => {
    setModal({ ...modals, [modalName]: true });
    setAnchorEl(null);
  };

  const handleModalClose = () => {
    setAnchorEl(null);
    setModal(modals);
  };

  const modalsData = {
    Seller: { title: "Ejecutivos", icon: <GroupIcon /> },
    Bank: {
      title: intl.formatMessage({
        id: "title.Banco.Administracion",
        defaultMessage: "Bancos",
      }),
      icon: <HomeWorkIcon />,
    },
    InternalStatus: { title: "Situaciones", icon: <FlagIcon /> },
    Tag: { title: "Etiquetas", icon: <LocalOfferIcon /> },
    Product: { title: "Productos", icon: <ViewListIcon /> },
    Category: { title: "Segmentos de Mercado", icon: <BusinessIcon /> },
    DeleteReason: {
      title: "Motivos de eliminación",
      icon: <DeleteOutlineIcon />,
    },
  };

  return (
    <>
      <Box component="span" pr="2">
        <Tooltip title="Administración" disableInteractive>
          <IconButton
            onClick={handleClick}
            color="inherit"
            className="btn-inverse mx-1 d-50"
            size="large">
            <ManageAccountsIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}>
          <div className="overflow-hidden dropdown-menu-xl">
            <List className="nav-danger nav-pills flex-column p-3">
              <Typography
                className="text-black pb-2 px-3 font-weight-bold"
                component="div">
                Administración
              </Typography>

              {Object.keys(modals).map((modalName, idx) => (
                <ListItem
                  key={idx}
                  button
                  className="rounded-sm"
                  onClick={() => handleModalOpen(modalName)}>
                  <div className="nav-link-icon opacity-6 mr-2">
                    {modalsData[modalName].icon}
                  </div>
                  <span>{modalsData[modalName].title}</span>
                </ListItem>
              ))}
            </List>
          </div>
        </Popover>
      </Box>

      {/* Modals */}
      {Object.keys(modals).map((modalName, idx) => {
        const Modal = require(`pages/Administration/${modalName}Modal`).default;
        return (
          <Modal
            key={idx}
            open={modal[modalName]}
            onClose={handleModalClose}
            {...props}
          />
        );
      })}
    </>
  );
};

export default AdministrationMenu;
