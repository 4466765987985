import useGetFormConf from "intl/utils/hooks/useGetFormConf";
import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import { Fragment, useEffect, useState } from "react";
import GetSelectComponent from "./GetSelectComponent";

export default function Warranty({ data }) {
  const getForm = useGetFormConf("ListCompWarraty");
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);

  const initData = () => {
    const result = handelData.getDataByCountry("tab.Garantias");
    const dataAux = getForm.getDataUse(result);
    setData([dataAux]);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {getData.map((d, idx) => (
        <Fragment key={idx}>
          {getForm.GetFormConf().map((item, index) => (
            <GetSelectComponent
              key={index}
              item={item}
              data={d.data}
              dataExt={d}
            />
          ))}
        </Fragment>
      ))}
    </>
  );
}
