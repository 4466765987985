import { fulfilled, pending, rejected } from "../utils/asyncStatusGenerator";
import { userActionTypes } from "../actions/userActions";

const initialState = {
  finishedUserLogin: false,
  loginPending: true,
  loginError: "",
  roleId: null,
  lastName: "",
  firstName: "",
  fullName: "",
  roleName: "",
  companyName: "",
  language: "",
  applicationModules: [],
  userId: null,
};

export const userReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case pending(userActionTypes.LOGIN):
      return {
        ...state,
        loginPending: true,
        finishedUserLogin: false,
        loginError: "",
      };
    case fulfilled(userActionTypes.LOGIN):
      const {
        roleId,
        lastName,
        firstName,
        fullName,
        roleName,
        companyName,
        language,
        applicationModules,
        userId,
        clientSettings,
      } = payload;
      const manageDefaultTab = clientSettings
        ? clientSettings.manageDefaultTab
        : 0;
      const selectedFilters = clientSettings
        ? clientSettings.selectedFilters
        : null;

      return {
        ...state,
        loginPending: false,
        finishedUserLogin: true,
        roleId,
        lastName,
        firstName,
        fullName,
        roleName,
        companyName,
        language,
        applicationModules,
        userId,
        manageDefaultTab,
        selectedFilters,
      };
    case rejected(userActionTypes.LOGIN):
      return {
        ...state,
        loginPending: false,
        finishedUserLogin: true,
        loginError: payload,
      };

    default:
      return state;
  }
};
