import { AttachFile } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { formatValueLocale } from "utils/common";
import { downloadBase64File } from "utils/fileHelper";

const LegalDocumentsHeader = (intl) => [
  {
    accessorKey: "number",
    header: "Número",
    size: "180",
    align: "center",
  },
  {
    accessorKey: "bankName",
    header: intl.formatMessage({
      id: "title.Banco.GridGarantia",
      defaultMessage: "Banco",
    }),
    size: "180",
  },
  {
    accessorKey: "subject",
    header: "Motivo",
    size: "170",
  },
  {
    accessorKey: "reference",
    header: "Referencia",
    size: "260",
  },
  {
    accessorKey: "currencyDescription",
    header: "Moneda",
    size: "200",
    align: "center",
  },
  {
    accessorKey: "value",
    header: "Monto",
    size: "165",
    align: "right",
    Cell: ({ cell, column }) =>
      cell.row.original.currencyId
        ? `${cell.row.original.currencySymbol} ${formatValueLocale(
            cell.getValue(),
            2,
          )}`
        : null,
  },
  {
    accessorKey: "statusDescription",
    header: "Estado",
    size: "145",
    align: "center",
  },
  {
    accessorKey: "endDate",
    header: "Vencimiento",
    size: "160",
    align: "center",
    cellType: "date",
  },
  {
    accessorKey: "active",
    header: "Activa",
    size: "140",
    align: "center",
    disableTooltip: true,
    Cell: (
      { cell, column }, //(cell.getValue() ? "Si" : "No"),
    ) => (
      <div className="d-flex align-items-center justify-content-center">
        <span
          className={`btn-pill badge  
        ${cell.getValue() ? "badge-success" : "badge-danger"}`}>
          {cell.getValue() ? "Si" : "No"}
        </span>
      </div>
    ),
    excelExportFormat: ({ cell }) => (cell.getValue() ? "Si" : "No"),
  },
  {
    accessorKey: "documentFiles",
    header: "Adjunto",
    size: "140",
    align: "center",
    hideToExport: true,
    disableTooltip: true,
    Cell: ({ cell, column }) => (
      <div>
        {cell.getValue()[0] ? (
          <Tooltip title="Descargar archivo">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                downloadBase64File(
                  cell.getValue()[0].fileName,
                  cell.getValue()[0].content,
                );
              }}>
              <AttachFile fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    ),
  },
];

export default LegalDocumentsHeader;
