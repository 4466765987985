import { TenderPanel } from "./tenderPanelItems";

export default {
  [TenderPanel.PORTAL_INFO]: {
    subheader: TenderPanel.PORTAL_INFO,
    isDefault: true,
    items: [
      {
        etq_title: TenderPanel.GENERAL,
        title: TenderPanel.GENERAL,
        titlePanel: "Información General del Procedimiento",
        href: "/general",
        showActionPanel: false,
        isDefault: true,
        idLang: "tab.General",
        showExport: true,
        showExportAll: true,
        langForm: [
          "TabGeneralInfoFirst",
          "TabGeneralInfoSecond",
          "TabGeneralInfoLast",
        ],
      },
      {
        etq_title: TenderPanel.SCHEDULE,
        title: TenderPanel.SCHEDULE,
        titlePanel: "Cronograma",
        href: "/cronograma",
        showActionPanel: false,
        isDefault: true,
        idLang: "tab.Cronograma",
        showExport: true,
        showExportAll: true,
        langForm: ["TabSchedule"],
      },
      {
        etq_title: TenderPanel.INFO_ITEMS_LIST,
        title: TenderPanel.INFO_ITEMS_LIST,
        titlePanel: "Listado de Items",
        idLang: "tab.ListaItems",
        hasManageTable: true,
        showExportAll: true,
        langForm: ["TabItemListFirst"],
      },
      {
        etq_title: TenderPanel.DOCUMENTS,
        title: TenderPanel.DOCUMENTS,
        titlePanel: "Documento del Procedimiento",
        idLang: "tab.Documentos",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.ACTIONS,
        title: TenderPanel.ACTIONS,
        titlePanel: "Acciones Generales del Procedimiento",
        idLang: "tab.Acciones",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.TRADES,
        title: TenderPanel.TRADES,
        titlePanel: "Oficios de Supervision",
        idLang: "tab.Oficios",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.REQUIREMENTS,
        title: TenderPanel.REQUIREMENTS,
        titlePanel: "Requisitos",
        idLang: "tab.Requisitos",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.WARRANTIES,
        title: TenderPanel.WARRANTIES,
        titlePanel: "Garantías",
        idLang: "tab.Garantias",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.OFFERS,
        title: TenderPanel.OFFERS,
        titlePanel: "Ofertas",
        idLang: "tab.Ofertas",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.CONDITIONS,
        title: TenderPanel.CONDITIONS,
        titlePanel: "Condiciones Contrato",
        idLang: "tab.Condiciones",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.DELIVERY,
        title: TenderPanel.DELIVERY,
        titlePanel: "Entrega",
        //showActionPanel: true,
        idLang: "tab.Entrega",
        showExport: true,
        showExportAll: true,
        langForm: ["TabDelivery"],
      },
      {
        etq_title: TenderPanel.OFFICIALS,
        title: TenderPanel.OFFICIALS,
        titlePanel: "Funcionarios relacionados",
        //showActionPanel: true,
        idLang: "tab.Funcionarios",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.CONTRACTS,
        title: TenderPanel.CONTRACTS,
        titlePanel: "Contratos",
        // showActionPanel: true,
        idLang: "tab.Contratos",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.PURCHASE_ORDERS,
        title: TenderPanel.PURCHASE_ORDERS,
        titlePanel: "Ordenes de Pedido",
        // showActionPanel: true,
        idLang: "tab.OrdenesPedido",
        showExportAll: true,
      },
    ],
  },
  [TenderPanel.MANAGEMENT]: {
    subheader: TenderPanel.MANAGEMENT,
    items: [
      {
        etq_title: TenderPanel.ASSIGNMENTS,
        title: TenderPanel.ASSIGNMENTS,
        titlePanel: "Asignaciones",
        href: "/entidades",
        showActionPanel: true,
        isDefault: true,
        idLang: "tab.Asignaciones",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.MANAGED_ITEMS_LIST,
        title: TenderPanel.MANAGED_ITEMS_LIST,
        titlePanel: "Gestión de items",
        showActionPanel: true,
        idLang: "tab.ListaItems",
        showExportAll: true,
        hasManageTable: true,
      },
      {
        etq_title: TenderPanel.LEGAL_DOCUMENTS,
        title: TenderPanel.LEGAL_DOCUMENTS,
        titlePanel: "Cartas Fianza",
        showActionPanel: true,
        idLang: "tab.CartasFianza",
        showExportAll: true,
        hasManageTable: true,
      },
      {
        etq_title: TenderPanel.COMMENTS,
        title: TenderPanel.COMMENTS,
        titlePanel: "Comentarios",
        showActionPanel: true,
        idLang: "tab.Comentarios",
        hasManageTable: true, // Used to hide the Card around the table so it takes the whole space
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.SANCTIONS,
        title: TenderPanel.SANCTIONS,
        titlePanel: "Sanciones",
        showActionPanel: true,
        idLang: "tab.Sanciones",
        showExportAll: true,
        hasManageTable: true,
      },
      {
        etq_title: TenderPanel.CHECKLIST,
        title: TenderPanel.CHECKLIST,
        titlePanel: "Checklist",
        showActionPanel: true,
        idLang: "tab.Checklist",
        showExportAll: true,
        hasManageTable: true,
      },
    ],
  },
};
