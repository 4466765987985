import { Button } from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useStoreDataClient } from "utils/hooks/useStoreData";

const ButtonModalDetail = (props) => {
  const queryClient = useStoreDataClient(props.queryClient);
  const showTenderInfoDetailModal = useGlobalStore(
    (state) => state.showTenderInfoDetailModal,
  );

  const handleClick = () => {
    let result = {
      ...props.result,
      detail: props.detail,
      data: [props.data],
    };

    queryClient.setDataQuery(result);
    showTenderInfoDetailModal(props.modalName, props.titleModal, result);
  };

  return (
    <Button onClick={handleClick} style={{ color: "#1976d2" }} size="small">
      Detalle
    </Button>
  );
};

export default ButtonModalDetail;
