export default {
  StructureDataWarrantyAR: {
    state: {
      keys: [
        "bidSecurity",
        "bidSecurityIsChecked",
        "refusePreAwardWarranty",
        "refusePreAwardWarrantyPercentage",
        "refuseSheetWarranty",
        "refuseSheetWarrantyPercentage",
        "validCurrency",
      ],
      structure: {
        validCurrencyTitle:
          "Monedas habilitadas para presentación de Garantías",
        validCurrencyDescription: "_validCurrency",
        data: [
          {
            type: "Garantía de impugnación al pliego",
            indicator: "_refuseSheetWarrantyPercentage",
            description: "_refuseSheetWarranty",
          },
          {
            type: "Garantía de impugnación a la preadjudicación",
            indicator: "_refusePreAwardWarrantyPercentage",
            description: "_refusePreAwardWarranty",
          },
          //{
          //  type: "Garantía de impugnación a la pre selección",
          //  indicator: ,
          //  description: ,
          //},
          {
            type: "Garantía de mantenimiento de oferta",
            indicator: "_bidSecurityIsChecked",
            description: "_bidSecurity",
          },
        ],
      },
    },
    render: (state) => {
      const { keys, structure, data } = state;
      // This is done because faithful observances comes as an array instead of separate key-values
      // Filter previous parsed faithful observance data to refresh only the necessary fields
      structure.data = structure.data.filter(
        (item) => !item.type.includes("Garantía de cumplimiento de contrato"),
      );
      // Add parsed faithful observances data to the structure
      data.faithfulObservances.map((row, idx) => {
        structure.data = [
          ...structure.data,
          {
            type: `Garantía de cumplimiento de contrato (${idx + 1})`,
            indicator:
              row.isChecked && row.percentage === 0
                ? "Si"
                : row.isChecked === false && row.percentage === 0
                ? "No"
                : row.percentage !== 0
                ? `${row.percentage} %`
                : "",
            description: row.text,
          },
        ];
      });

      // Handle key-values case
      let newData = JSON.stringify(structure);
      keys.map((key) => {
        if (!data.hasOwnProperty(key) || data[key] === null) data[key] = "";
        // Handle IsPercentage
        if (
          data[key] &&
          key.includes("Percentage") &&
          typeof data[key] !== "string"
        )
          data[key] = `${data[key]} %`;
        if (data[key] === true) data[key] = "Sí";
        if (data[key] === false) data[key] = "No";
        if (data[key] === 0) data[key] = "";

        const rex = new RegExp("_" + key + "\\b", "g");
        newData = newData.replaceAll(rex, data[key]).replaceAll(/\b/g, "");
      });
      newData = JSON.parse(newData);
      newData = {
        ...newData,
        data: newData.data.filter((item) => item.description.trim() != ""),
      };
      return newData;
    },
  },
};
