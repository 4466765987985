import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function useIsWidthUp(breakpoint) {
  const [isWidthUp, setIsWidthUp] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up(breakpoint));

  useEffect(() => {
    setIsWidthUp(isMdUp);
  }, [isMdUp]);

  return isWidthUp;
}

export default useIsWidthUp;
