import { BarChart } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { PageTitle } from "layout-components/index";
import moment from "moment";
import { Fragment, useCallback, useEffect, useState } from "react";
import Iframe from "react-iframe";
import { acquireToken } from "config/msalConfig";
import AnalysisHeaderActions from "./AnalysisHeaderActions";

export const SERVER_HOSTNAME = process.env.REACT_APP_SERVER_HOSTNAME;
export const SERVER_PORT = process.env.REACT_APP_API_PORT;

const Analysis = ({ analysisModuleId }) => {
  const { height } = useGlobalStore((state) => ({
    height: state.height,
  }));
  const [url, setUrl] = useState("");
  const [auth, setAuth] = useState();
  const [selectedData, setSelectedData] = useState({
    filterUniqueId: "",
    reportName: "",
    finishedLoading: false,
    lastRefresh: moment().format(),
  });

  const getAuth = async () => {
    const { token } = await acquireToken();
    setAuth(token?.idToken.rawIdToken);
  };

  useEffect(() => {
    getAuth();
  }, []);

  useEffect(() => {
    if (selectedData.finishedLoading) {
      const aux = `${SERVER_HOSTNAME}:${SERVER_PORT}/api/Reporting?ReportName=${selectedData.reportName}&AnalysisFilterId=${selectedData.filterUniqueId}`;
      setUrl(aux);
    }
  }, [selectedData]);

  // Reloads the page when the token expires (the Iframe component needs fresh token to load the report)
  const handleLoad = () => {
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data.hasOwnProperty("message")) {
        const message = e.data.message;
        if (message.error.indexOf("Token expired") > -1) {
          window.location.reload();
          // TODO: Refresh token, avisar que el token expiró y hacer que recargue la página
        }
      }
    });
  };

  //#region Height handling for iframe card
  // -- Height handling for iframe card -- //
  // This is used to make the content card height dynamic, so it can be resized when the window is resized
  // it also takes into account the height of the app page title, so it can be resized accordingly
  const [cardHeight, setCardHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const handleResize = useCallback(() => setWidth(window.innerWidth), []);
  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const appPageTitle = document.getElementById("app-page-title");
    if (appPageTitle) {
      if (appPageTitle.clientHeight > 72 && appPageTitle.clientHeight < 165) {
        setCardHeight(height + 70);
      } else if (appPageTitle.clientHeight > 165) {
        setCardHeight(height + 39);
      } else {
        setCardHeight(height + 126);
      }
    }
  }, [height, width]);
  // --  -- //
  //#endregion

  return (
    <Fragment>
      <PageTitle
        icon={<BarChart />}
        startComponent={
          <AnalysisHeaderActions
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            analysisModuleId={analysisModuleId}
          />
        }
      />
      <Grid
        container
        spacing={2}
        sx={{ ml: -4, mt: -4, width: "calc(100% + 3.1rem)" }}>
        <Grid item xs={12}>
          <Card
            className="card-box text-black-50 bg-secondary p-2"
            sx={{ height: cardHeight + 16, borderRadius: 0 }}>
            {selectedData.finishedLoading && auth && (
              <Iframe
                url={url}
                width="100%"
                height={`${cardHeight + 16 - 15}`}
                id="myiFrame"
                // key={selectedData.lastRefresh} //TODO: tbd if this is needed
                className="iframe_class bg-secondary"
                display="initial"
                position="relative"
                name={auth}
                styles={{ borderRadius: "8px" }}
                onLoad={handleLoad}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Analysis;
