import { BorderColor, Edit } from "@mui/icons-material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { useGlobalStore } from "global-store/useGlobalStore";
import moment from "moment";
import { listTags } from "pages/TenderInformationContent/tenderInformationContentService";
import { Fragment, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { getNewWorkbook, saveNewWorkbook } from "utils/excelExportService";
import {
  fillItemsListSheet,
  getItemsListData,
  getItemsListFormattedInitialData,
} from "./managedItemsListExcelService";
import managedItemsListHeader from "./managedItemsListHeader";
import { isLangPE } from "utils/common";

const ManagedItemsList = ({
  tenderData,
  managingTenderData,
  handleManagingTenderData,
  tenderManageData,
}) => {
  const [tags, setTags] = useState([]);
  const tagsRef = useRef();
  tagsRef.current = tags;

  const [alternativeItemIdCounter, setAlternativeItemIdCounter] = useState(0);

  const {
    height,
    userInfo,
    setShouldRefetch,
    showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    settingsChanged,
    resetSettingsChanged,
  } = useGlobalStore((state) => ({
    height: state.height,
    userInfo: state.userInfo,
    setShouldRefetch: state.setShouldRefetch,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    settingsChanged: state.settingsChanged,
    resetSettingsChanged: state.resetSettingsChanged,
  }));

  const intl = useIntl();
  const columns = managedItemsListHeader(
    intl,
    tenderData.modalityType,
    tagsRef.current,
  );

  const getTags = async () => {
    let res = await listTags({
      orderBy: "",
      pageSize: 1000,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    });

    res.data = res.data.map((tag) => {
      return { id: tag.tagId, name: tag.name };
    });
    setTags(res.data);
  };

  useEffect(() => {
    getTags();
  }, []);

  const fetchData = async (pageNumber, pageSize, filter, sorting) => {
    let alternativeCounter = alternativeItemIdCounter;
    // add property 'product' to manageDetails finding it in products
    managingTenderData.manageDetails = managingTenderData.manageDetails.map(
      (item) => {
        item.estimatedTotal = item.estimatedTotal || "";
        item.comments = item.comments || "";

        let product = item.productId
          ? {
              productId: item.productId,
              productDesc: item.productDesc,
              productCode: item.productCode,
              data: item.productData,
            }
          : null;

        if (!item.isPrimary) {
          item.alternativeItemId = alternativeCounter + 1;
          alternativeCounter++;
        }
        return { ...item, product };
      },
    );

    return {
      result: managingTenderData.manageDetails.slice(
        (pageNumber - 1) * pageSize,
        pageNumber * pageSize,
      ),
      total: managingTenderData.manageDetails.length,
    };
  };

  const handleSave = async (item, exitEditingMode) => {
    // update productId, Desc, Code & Data in the item
    if (item.product?.productId) {
      item.productId = item.product.productId;
      item.productDesc = item.product.productDesc;
      item.productCode = item.product.productCode;
      item.productData = item.product.data;
    }
    // Delete if product is null
    else {
      item.productId = null;
      item.productDesc = null;
      item.productCode = null;
      item.productData = null;
    }
    item.estimatedTotal = item.estimatedTotal || null; // Needed to mantain consistency (it previously comes as null)
    item.comments = item.comments || null; // Needed to mantain consistency (it previously comes as null)

    if (item.deliveryDate) {
      let deliveryDate = new Date(item.deliveryDate);

      // Verify if is valid
      if (deliveryDate.toString() === "Invalid Date") {
        deliveryDate = null;
      }
      item.deliveryDate = deliveryDate
        ? moment(deliveryDate, "DD/MM/YYYY").toDate().toISOString()
        : null;
    }

    // update the single item in manageDetails
    let manageDetails = [...managingTenderData.manageDetails].map((md) => {
      if (md.itemNumber === item.itemNumber) {
        return {
          ...md,
          ...item,
        };
      }
      return md;
    });

    handleManagingTenderData({
      ...managingTenderData,
      manageDetails: manageDetails,
    });

    exitEditingMode();
    setShouldRefetch();
    return false;
  };

  const exportExcel = async (manageDetails) => {
    let dataToExportFormatted = getItemsListFormattedInitialData(
      manageDetails,
      tagsRef.current,
    );
    const { exportHeader, exportData, exportItemsData, exportTenderData } =
      getItemsListData(intl, tenderData, columns, dataToExportFormatted);

    let workbook = getNewWorkbook();
    fillItemsListSheet(
      workbook,
      intl,
      0, // sheetNumber
      exportHeader,
      exportData,
      exportItemsData,
      exportTenderData,
    );

    await saveNewWorkbook(
      workbook,
      `${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${
        isLangPE() ? tenderData.fileNumber : tenderData.tenderNumber
      }.xlsx`,
    );
  };

  const createAlternativeItem = async (data) => {
    showLoadingStateSpinnerNoTimeout();
    // add new item with same data as selected item, removing the ManageDetailId and setting isPrimary to false
    let newManageDetail = {
      ...data,
      estimatedTotal: null,
      offered: false,
      productId: null,
      productCode: null,
      productDesc: null,
      productData: null,
      tagIds: [],
      comments: null,
      deliveryDate: null,
      FullDescription: null,
      manageDetailId: null,
      alternativeItemId: alternativeItemIdCounter + 1,
      isPrimary: false,
    };
    delete newManageDetail.manageDetailId;
    setAlternativeItemIdCounter(alternativeItemIdCounter + 1);

    const indexToInsert = managingTenderData.manageDetails.findIndex(
      (item) => item.tenderDetailId === data.tenderDetailId,
    );
    let newManageDetails = [...managingTenderData.manageDetails];
    newManageDetails.splice(indexToInsert + 1, 0, newManageDetail);

    handleManagingTenderData({
      ...managingTenderData,
      manageDetails: newManageDetails,
    });

    hideLoadingSpinner();
    setTimeout(() => {
      setShouldRefetch();
    }, 300);
  };

  const deleteAlternativeItem = async (data) => {
    showLoadingStateSpinnerNoTimeout();
    // remove item from list
    let filteredManageDetails = managingTenderData.manageDetails.filter(
      (item) => {
        return item.alternativeItemId !== data.alternativeItemId;
      },
    );

    handleManagingTenderData({
      ...managingTenderData,
      manageDetails: filteredManageDetails,
    });

    hideLoadingSpinner();
    setTimeout(() => {
      setShouldRefetch();
    }, 300);
  };

  useEffect(() => {
    if (settingsChanged) {
      getTags();
      resetSettingsChanged();
    }
  }, [settingsChanged]);

  const contextMenuOptions = [
    {
      id: "create-alternative",
      title: "Crear ítem alternativo",
      icon: <BorderColor fontSize="small" />,
      onClick: async (rows) => {
        if (!rows[0].isPrimary) {
          return;
        }
        createAlternativeItem(rows[0]);
      },
    },
    {
      id: "delete-alternative",
      title: "Eliminar ítem alternativo",
      icon: <Edit fontSize="small" />,
      onClick: async (rows) => {
        if (rows[0].isPrimary) {
          return;
        }
        deleteAlternativeItem(rows[0]);
      },
    },
  ];

  const options = {
    tableId: "gridManagementManagedItemsList",
    header: columns,
    height: height - 217, // Aribitrary height to fit exactly the content
    tableHeaderTitle: intl.formatMessage({
      id: "tab.ListaItems",
      defaultMessage: "Lista de Items",
    }),
    toolbarButtons: {
      Export: true,
      ExportFunc: exportExcel,
    },
    enableEditing: true,
    saveFunc: handleSave,
    contextMenuOptions: contextMenuOptions,
  };

  return (
    <Fragment>
      <FSTMaterialReactTable options={options} getData={fetchData} />
    </Fragment>
  );
};

export default ManagedItemsList;
