import { get, post } from "config/axios";

export const sendTenderMail = async (request) => {
  return await post("/api/Notification/SendTenderMail", request);
};

export const sendtenderMailIds = async () => {
  return await get("/api/notification/getsenttenderids");
};

export const getSentTenders = async () => {
  return await get("/api/notification/getsenttenders");
};

export const getEmailData = async (id) => {
  return await get(`/api/notification/getemaildata/${id}`);
};

export const getManageList = async (request) => {
  return await post("/api/Manage/GetManageList", request);
};

export const getManageStatusGroups = async () => {
  return await post("/api/Manage/GetManageStatusGroups");
};

export const getDeletedManageList = async (request) => {
  return await post("/api/DeletedTenders/GetDeletedTendersList", request);
};

export const manageTender = async (request) => {
  return await post("/api/Manage/ManageTender", request);
};

export const deleteTender = async (request) => {
  return await post("/api/Manage/DeleteTenders", request);
};

export const getAwardedReport = async (request) => {
  return await post("/api/Notification/GetAwardedReport", request);
};
