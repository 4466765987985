import AwardedContracts from "./InfoContent/AwardedContracts";
import AwardedPurchaseOrders from "./InfoContent/AwardedPurchaseOrders";
import ContractConditions from "./InfoContent/ContractConditions";
import Delivery from "./InfoContent/Delivery";
import GeneralInfo from "./InfoContent/GeneralInfo";
import GeneralProcedureActions from "./InfoContent/GeneralProcedureActions";
import InfoItemsList from "./InfoContent/InfoItemsList";
import OffersInfo from "./InfoContent/OffersInfo";
import ProcedureDocument from "./InfoContent/ProcedureDocument";
import RelatedOfficials from "./InfoContent/RelatedOfficials";
import Requirement from "./InfoContent/Requirement";
import Schedule from "./InfoContent/Schedule";
import SupervisingJob from "./InfoContent/SupervisingJob";
import Warranty from "./InfoContent/Warranty";
import { TenderPanel } from "./tenderPanelItems";
import { Card } from "@mui/material";

const TenderInfoTabContent = ({ tender, menu, itemListShouldShowInfo }) => {
  // TODO: Abstract components to a more generic way (most of them are the same)
  const selectedCard = {
    [TenderPanel.GENERAL]: <GeneralInfo data={tender} />,
    [TenderPanel.SCHEDULE]: <Schedule data={tender} />,
    ...(itemListShouldShowInfo
      ? {
          [TenderPanel.INFO_ITEMS_LIST]: (
            <InfoItemsList
              data={tender}
              tenderData={tender}
              showManageInfo={false}
            />
          ),
        }
      : {}),
    [TenderPanel.DOCUMENTS]: <ProcedureDocument data={tender} />,
    [TenderPanel.ACTIONS]: <GeneralProcedureActions data={tender} />,
    [TenderPanel.TRADES]: <SupervisingJob data={tender} />,
    [TenderPanel.REQUIREMENTS]: <Requirement data={tender} />,
    [TenderPanel.WARRANTIES]: <Warranty data={tender} />,
    [TenderPanel.OFFERS]: <OffersInfo data={tender} />,
    [TenderPanel.CONDITIONS]: <ContractConditions data={tender} />,
    [TenderPanel.DELIVERY]: <Delivery data={tender} />,
    [TenderPanel.OFFICIALS]: <RelatedOfficials data={tender} />,
    [TenderPanel.CONTRACTS]: <AwardedContracts data={tender} />,
    [TenderPanel.PURCHASE_ORDERS]: <AwardedPurchaseOrders data={tender} />,
  };

  return (
    selectedCard[menu?.title] &&
    (menu?.hasManageTable ? (
      selectedCard[menu?.title]
    ) : (
      <Card
        className="card-box text-black-50 mb-4 p-3"
        sx={{
          // height: contentHeight, //height - 64,
          height: "inherit",
          // height: "100%",
          overflowY: "scroll",
          overflowX: "scroll",
          // Custom scrollbar
          "&::-webkit-scrollbar": {
            WebkitAppearance: "none",
            width: "10px",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(118, 75, 162, 0.4)", // same color as gradient on the right, with alpha
            WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, 0.205)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f5f5f5",
          },
        }}>
        {selectedCard[menu?.title]}
      </Card>
    ))
  );
};

export default TenderInfoTabContent;
