import clsx from "clsx";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Box, Paper, Drawer } from "@mui/material";

import navItems from "./navItems";
import SidebarMenu from "../SidebarMenu";
import SidebarFooter from "../SidebarFooter";
import SidebarHeader from "../SidebarHeader";
import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from "../../reducers/ThemeOptions";

const Sidebar = (props) => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarToggle,
    userInfo,
  } = props;
  const intl = useIntl();

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const { isAdmin, applicationModules, userModules } = userInfo;
  const modules = isAdmin ? applicationModules : userModules;
  // Filters the navItems based on the modules the user has access to
  const menuItems = navItems(intl);
  const filteredNavItems = menuItems[0].content.filter((item) =>
    modules.some((module) => module.name === item.name || item.name === "HOME"),
  );
  menuItems[0].content = filteredNavItems;

  const sidebarMenuContent = (
    <div className={clsx({ "app-sidebar-nav-close": sidebarToggle })}>
      {menuItems.map((list) => (
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

  return (
    <>
      {/* This sidebar display shows when the screen is small and user opens it */}
      <Box sx={{ display: { lg: "none", xs: "block" } }}>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}>
              {sidebarMenuContent}
              <SidebarFooter />
            </Box>
          </PerfectScrollbar>
        </Drawer>
      </Box>

      {/* This sidebar display shows when the screen is large and it is open */}
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Paper
          className={clsx("app-sidebar-wrapper", {
            "app-sidebar-wrapper-close": sidebarToggle,
          })}
          square
          elevation={4}
          style={{ position: "fixed" }}>
          <SidebarHeader />
          <div
            className={clsx("app-sidebar-menu", {
              "app-sidebar-collapsed": sidebarToggle,
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}>
                {sidebarMenuContent}
                <SidebarFooter />
              </Box>
            </PerfectScrollbar>
          </div>
        </Paper>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
