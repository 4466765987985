export const TenderType = {
  ALL_TENDERS: "ALL_TENDERS",
  ALL_TENDERS_ERROR: "ALL_TENDERS_ERROR",
  ALL_TENDERS_DEFAULT: "ALL_TENDERS_DEFAULT",
  CLEAR_LIST: "CLEAR_LIST",
};

export const TenderPanel = {
  PORTAL_INFO: "Info del Portal",

  GENERAL: "General",
  SCHEDULE: "Cronograma",
  INFO_ITEMS_LIST: "Info - Lista de Items",
  DOCUMENTS: "Documentos",
  ACTIONS: "Acciones",
  TRADES: "Oficios",
  CONTRACTS: "Contratos",
  PURCHASE_ORDERS: "Ordenes de Pedido",
  OFFICIALS: "Funcionarios relacionados",
  DELIVERY: "Entrega",
  REQUIREMENTS: "Requisitos",
  WARRANTIES: "Garantías",
  OFFERS: "Ofertas",
  CONDITIONS: "Condiciones Contrato",

  MANAGEMENT: "Gestión",

  ASSIGNMENTS: "Asignaciones",
  MANAGED_ITEMS_LIST: "Items",
  ITEMS_MANAGED: "Gestionados", //not used
  LEGAL_DOCUMENTS: "Cartas Fianza",
  COMMENTS: "Comentarios",
  SANCTIONS: "Sanciones",
  CHECKLIST: "Checklist",

  LOADING_SCREEN: "Pantalla de Carga",
};
