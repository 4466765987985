import { Box, DialogContentText, TextField } from "@mui/material";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import { useState } from "react";
import { BarLoader } from "react-spinners";
import { manageTender } from "./tenderManagementService";
import ResponsiveTable from "components/ResponsiveTable/ResponsiveTable";

const NO_RESULT = "Sin resultado";
const NOT_FOUND = "No encontrada";

const AddTenderModal = ({ open, onClose }) => {
  const [tenderIds, setTenderIds] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  const handleChange = (e) => {
    setTenderIds(e.target.value);
  };

  const handleClose = () => {
    const resultsFound =
      results &&
      results[0].Result !== NO_RESULT &&
      results[0].Result !== NOT_FOUND;
    onClose(resultsFound);
    setTenderIds("");
    setResults(null);
    setLoading(false);
  };

  const handleAddTenders = async () => {
    setLoading(true);
    const tenderIdsArray = tenderIds.split("\n").filter((id) => id !== "");
    const payload = tenderIdsArray.map((id) => ({ tenderNumber: id }));
    const response = await manageTender(payload);
    const parsedResults = response.data.map((r) => {
      return {
        TenderNumber: r.tenderNumber || "",
        Result: r.result || NO_RESULT,
      };
    });
    setResults(parsedResults);
    setLoading(false);
  };

  return (
    <GeneralPurposeModal
      open={open}
      onClose={handleClose}
      title="Agregar Licitaciones"
      closeText={results ? "Salir" : "Cancelar"}
      maxWidth="sm"
      onSave={results ? null : handleAddTenders}
      onSaveDisabled={loading || results}
      saveText="Agregar">
      {results === null ? (
        <>
          <DialogContentText sx={{ mb: 2 }}>
            Ingrese el identificador de convocatoria de licitacion a agregar
            (uno por línea)
          </DialogContentText>
          <TextField
            margin="dense"
            id="tenderIds"
            label="Licitaciones"
            fullWidth
            multiline
            rows={10}
            value={tenderIds}
            onChange={handleChange}
            disabled={loading}
          />
        </>
      ) : (
        <>
          <DialogContentText sx={{ mb: 2 }}>Resultados</DialogContentText>
          {results ? (
            <ResponsiveTable
              columns={[
                { key: "TenderNumber", label: "Convocatoria" },
                { key: "Result", label: "Estado" },
              ]}
              data={results || []}
            />
          ) : (
            <DialogContentText sx={{ color: "red", textAlign: "center" }}>
              Ocurrió un error al leer el estado de las licitaciones.
            </DialogContentText>
          )}
        </>
      )}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}>
          <BarLoader color={"var(--primary)"} loading={true} />
        </Box>
      )}
    </GeneralPurposeModal>
  );
};

export default AddTenderModal;
