import ModalCRUD from "./ModalCRUD";
import {
  addOrUpdateCategory,
  deleteCategory,
  getCategories,
} from "./administrationServices";

const columns = [{ accessorKey: "name", header: "Nombre", size: 375 }];

const messages = {
  saveSuccess: "El segmento de mercado se almacenó correctamente.",
  deleteSuccess: "El segmento de mercado se eliminó correctamente.",
  missingData: "Por favor ingrese el nombre del segmento de mercado.",
  cannotDelete:
    "El segmento de mercado no puede ser eliminado porque está siendo utilizado.",
  deleteConfirmation: (item) =>
    `¿Está seguro que desea eliminar el segmento de mercado ${item?.name}?`,
};

const CategoryModal = ({ open, onClose }) => {
  const saveVerification = (data) => {
    return data.name;
  };

  const handleDelete = async (item) => {
    const request = [{ categoryId: item.categoryId }];
    await deleteCategory(request);
  };

  const handleSave = async (data) => {
    const request = [data];
    await addOrUpdateCategory(request);
  };

  return (
    <ModalCRUD
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      columns={columns}
      tableInfo={{ id: "gridCategoryModal", header: "Segmentos de Mercado" }}
      modalTitle={"Segmentos de Mercado"}
      fetchFunction={getCategories}
      saveVerification={saveVerification}
      messages={messages}
    />
  );
};

export default CategoryModal;
