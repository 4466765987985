import {
  Box,
  Card,
  Grid,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import TenderInfoTabContent from "pages/TenderInformationContent/TenderInfoTabContent";
import TenderMenuObject from "pages/TenderInformationContent/tenderMenuObject";
import { TenderPanel } from "pages/TenderInformationContent/tenderPanelItems";
import { useGlobalStore } from "global-store/useGlobalStore";
import useLangForm from "intl/utils/hooks/useLangForm";
import { useEffect, useState } from "react";
import { isLangCR } from "utils/common";

const CenteredScrollableTabs = styled(Tabs)(({ theme }) => ({
  justifyContent: "center",
  "& .MuiTabs-scroller": {
    flexGrow: "0",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
}

const SmartSearchTabsContainer = ({ tenderData, finishedLoading }) => {
  const filterForm = useLangForm();
  const height = useGlobalStore((state) => state.height);

  const [activeTab, setActiveTab] = useState(0);
  const [menusList, setMenusList] = useState([]);
  const [currentMenu, setCurrentMenu] = useState(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentMenu(menusList[newValue]);
  };

  const getMenusList = () => {
    let portalInfoItems = TenderMenuObject[TenderPanel.PORTAL_INFO];
    let menuFilter = JSON.parse(
      JSON.stringify(
        filterForm.FormatFilterFormObject(
          "TenderContainer",
          "Tab",
          portalInfoItems.items,
        ),
      ),
    ); // Copy list to prevent filtering and then not being able to restore them
    // filter "Contratos" menu if tender status is not "Contrato"
    // and also "Ordenes de pedido" if modalityType is not "Según demanda", otherwise there wouldn't be data to show
    if (isLangCR() && tenderData) {
      if (tenderData.portalStatus !== "Contrato") {
        menuFilter = menuFilter.filter(
          (item) => item.etq_title !== "Contratos",
        );
      }
      if (
        tenderData.portalStatus !== "Contrato" ||
        tenderData.modalityType !== "Según demanda"
      ) {
        menuFilter = menuFilter.filter(
          (item) => item.etq_title !== "Ordenes de Pedido",
        );
      }
    }
    setMenusList(menuFilter);
    setCurrentMenu(menuFilter[0]);
  };

  useEffect(() => {
    getMenusList();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      className="bg-white ">
      <CenteredScrollableTabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleTabChange}>
        {menusList.map((menu, index) => (
          <Tab
            className="py-3"
            key={menu.etq_title}
            label={menu.etq_title}
            value={index}
          />
        ))}
      </CenteredScrollableTabs>
      {menusList.map((menu, index) => (
        <TabPanel value={activeTab} index={index} key={menu.etq_title}>
          <Grid container spacing={4}>
            <Grid item xs display="flex">
              {menu.hasManageTable ? (
                <Box
                  flex={1}
                  sx={{
                    height: `calc(${height}px - 150px + 1rem)`, // same height as with the card below, but with the padding on the top and bottom
                    pb: "1rem",
                    flexGrow: 1,
                  }}>
                  <TenderInfoTabContent
                    tender={tenderData}
                    menu={menu}
                    itemListShouldShowInfo={true}
                  />
                </Box>
              ) : !finishedLoading ? (
                <Stack alignItems="center" width="100%" gap={1} mt={2}>
                  <Skeleton animation="wave" width="75%" />
                  <Skeleton animation="wave" width="75%" />
                  <Skeleton animation="wave" width="75%" />
                  <Skeleton animation="wave" width="75%" />
                </Stack>
              ) : (
                <Box
                  flex={1}
                  sx={{
                    height: `calc(${height}px - 150px + 1rem)`,
                    maxWidth: "calc(1280px - 2rem)", // 1280px is the max width of the 'lg' modal size, minus the padding on the left and right
                  }}>
                  <TenderInfoTabContent
                    tender={tenderData}
                    menu={menu}
                    itemListShouldShowInfo={true}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
};

export default SmartSearchTabsContainer;
