import { AccountBalance } from "@mui/icons-material";
import LegalDocuments from "pages/TenderInformationContent/ManagementContent/LegalDocuments/LegalDocuments";
import { PageTitle } from "layout-components";
import { Fragment } from "react";
import { useIntl } from "react-intl";

//TODO: ver por que el 'edit' es solo view en el previous code
const TenderManagementLegalDocuments = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <PageTitle
        titleHeading={intl.formatMessage({
          id: "tab.CartasFianza",
          defaultMessage: "Cartas Fianza",
        })}
        titleDescription={
          "Modificar y/o eliminar " +
          intl.formatMessage({
            id: "tab.CartasFianza",
            defaultMessage: "Cartas Fianza",
          })
        }
        icon={<AccountBalance />}
      />
      <LegalDocuments
        managingTenderData={{}}
        handleManagingTenderData={() => {}}
        tenderManageData={{}}
        isInTenderRoute={true}
      />
    </Fragment>
  );
};

export default TenderManagementLegalDocuments;
