import { Delete, GetApp, UploadFile } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getTags } from "pages/Administration/administrationServices";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useEffect, useState } from "react";
import { downloadBase64File } from "utils/fileHelper";
import { TenderFilesParameters } from "../tenderFilesParameters";
import {
  listCategories,
  listInternalStatus,
} from "../tenderInformationContentService";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { listNonAdminUsers, listUsers } from "pages/Settings/settingsService";
import { ADMIN_ROLE_ID } from "utils/constants";
import administration from "services/administration";
import { Info } from "@mui/icons-material";

const Assignments = ({ managingTenderData, handleManagingTenderData }) => {
  // -- Assignments -- //
  const [tags, setTags] = useState([]);
  const [internalStatus, setInternalStatus] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const { userInfo } = useGlobalStore((state) => ({
    userInfo: state.userInfo,
  }));

  const getCategoryList = async () => {
    const response = await listCategories();
    setCategories(response.data);
  };

  const getInternalStatusList = async () => {
    const response = await listInternalStatus();
    setInternalStatus(response.data);
  };

  const getSellersList = async () => {
    const response = await administration.listActiveSellers();
    setSellers(response);
  };

  const getTagList = async () => {
    const response = await getTags({
      search: "",
      orderBy: "tagId",
      orderAsc: true,
    });
    setTags(response.data);
  };

  const getUserList = async () => {
    const response =
      userInfo.roleId === ADMIN_ROLE_ID
        ? await listUsers()
        : await listNonAdminUsers();
    setUsers(response.data);
  };

  const fetchAllData = async () => {
    return await Promise.all([
      getCategoryList(),
      getInternalStatusList(),
      getSellersList(),
      getTagList(),
      getUserList(),
    ]);
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleAssignmentsChange = (id, newValue) => {
    handleManagingTenderData({
      ...managingTenderData,
      assignments: {
        ...managingTenderData.assignments,
        [id]: newValue,
      },
    });
  };

  // -- Files -- //
  const { setSnackbarErrorCustom, settingsChanged, resetSettingsChanged } =
    useGlobalStore((state) => ({
      setSnackbarErrorCustom: state.setSnackbarErrorCustom,
      settingsChanged: state.settingsChanged,
      resetSettingsChanged: state.resetSettingsChanged,
    }));

  const downloadFile = (data) => {
    downloadBase64File(data.fileName, data.content);
  };

  const handleFilesChange = (files) => {
    handleManagingTenderData({
      ...managingTenderData,
      files,
    });
  };

  const addFiles = () => {
    // TODO: improve this to avoid using document
    document.getElementById("upload-button-file").click();
  };

  const deleteFile = (index) => {
    const aux = [...managingTenderData.files];
    aux.splice(index, 1);
    handleFilesChange(aux);
  };

  const validateFileSize = (file) => {
    if (file.size > TenderFilesParameters.MaxFileSize10MB) {
      const message = `El archivo ${file.name} no puede superar el tamaño maximo permitido de 10 MB!`;
      setSnackbarErrorCustom(message);
      return false;
    }
    return true;
  };

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;
    const _file = {
      fileName: event.target.files[0].name,
    };
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      _file.content = e.target.result.split(",")[1];
      handleFilesChange([...managingTenderData.files, _file]);
    };
  };

  useEffect(() => {
    if (settingsChanged) {
      fetchAllData();
      resetSettingsChanged();
    }
  }, [settingsChanged]);

  // -- Delete Dialog -- //
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const handleDeleteDialogOpen = (file) => {
    setFileToDelete(file);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteFile = () => {
    deleteFile(fileToDelete);
    setDeleteDialogOpen(false);
  };

  return (
    <Grid container spacing={3} padding={2}>
      <Grid item xs={12} md={6}>
        <Stack direction="column" gap={3} alignItems="center">
          <Autocomplete
            options={internalStatus}
            id="internalStatus"
            openOnFocus
            value={managingTenderData.assignments.internalStatus}
            getOptionLabel={(option) => option.description || ""}
            onChange={(event, newValue) =>
              handleAssignmentsChange("internalStatus", newValue)
            }
            fullWidth
            isOptionEqualToValue={(option, value) =>
              option?.manageInternalStatusId === value?.manageInternalStatusId
            }
            renderInput={(params) => (
              <TextField {...params} label="Situacion" variant="outlined" />
            )}
          />
          <Autocomplete
            options={tags}
            id="tags"
            multiple
            fullWidth
            value={managingTenderData.assignments.tags}
            onChange={(event, newValue) =>
              handleAssignmentsChange("tags", newValue)
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Etiquetas" variant="outlined" />
            )}
          />
          <Autocomplete
            options={sellers}
            id="sellers"
            multiple
            fullWidth
            value={managingTenderData.assignments.sellers}
            onChange={(event, newValue) =>
              handleAssignmentsChange("sellers", newValue)
            }
            getOptionLabel={(option) =>
              `${option.lastName}, ${option.firstName}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Ejecutivos" variant="outlined" />
            )}
          />
          <Autocomplete
            options={categories}
            id="categories"
            multiple
            fullWidth
            value={managingTenderData.assignments.categories}
            onChange={(event, newValue) =>
              handleAssignmentsChange("categories", newValue)
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Segmento de Mercado"
              />
            )}
          />
          <Stack direction="row" gap={1} alignItems="center" width="100%">
            <Autocomplete
              options={users}
              id="users"
              multiple
              fullWidth
              value={managingTenderData.assignments.users}
              onChange={(event, newValue) =>
                handleAssignmentsChange("users", newValue)
              }
              getOptionLabel={(option) =>
                `${option.lastName}, ${option.firstName}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Usuarios" variant="outlined" />
              )}
            />
            <Tooltip
              title="Este Proceso será visualizado únicamente por los Usuarios seleccionados en este campo."
              placement="top">
              <Info color="info" />
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <input
          accept="image/*,.pdf,.doc,.docx"
          style={{ display: "none" }}
          id="upload-button-file"
          type="file"
          hidden
          onInput={(e) => handleFileUpload(e)}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <Card className="card-box text-black-50 mb-4 p-2">
          <CardHeader title="Listado de Archivos" />
          <div className="divider border-2 mb-2 border-light rounded" />
          <CardContent>
            <List>
              {managingTenderData.files.length === 0 && (
                <ListItem>
                  <ListItemText primary="No posee archivos adjuntos" />
                </ListItem>
              )}
              {managingTenderData.files.map((item, index) => (
                <>
                  <ListItem key={`${item.fileName}-${index}`}>
                    <ListItemText primary={item.fileName} />
                    <ListItemSecondaryAction>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Tooltip title="Descargar" placement="top">
                          <IconButton
                            edge="end"
                            size="small"
                            aria-label="download"
                            color="secondary"
                            onClick={() => downloadFile(item)}>
                            <GetApp />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar" placement="top">
                          <IconButton
                            edge="end"
                            size="small"
                            aria-label="delete"
                            color="secondary"
                            onClick={() => handleDeleteDialogOpen(index)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </CardContent>
          <div className="divider border-2 mb-2 border-light rounded" />
          <CardActions sx={{ gap: 2 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => addFiles()}
              startIcon={<UploadFile />}
              disabled={
                managingTenderData.files.length >=
                TenderFilesParameters.MaxFiles
              }>
              Adjuntar Archivo
            </Button>
            <Typography variant="caption">
              {`Máximo ${TenderFilesParameters.MaxFiles} archivos de hasta 10MB cada uno.`}{" "}
              {/* Remember to change this value when you change the file size */}
            </Typography>
          </CardActions>
        </Card>
      </Grid>

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        title="Eliminar Archivo"
        subTitle="¿Está seguro que desea eliminar este archivo?"
        onDelete={handleDeleteFile}
      />
    </Grid>
  );
};

export default Assignments;
