import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import GeneralPurposeModal from "components/Modals/GeneralPurposeModal";
import TextEditor from "components/TextEditor/TextEditor";
import { listCompanySettings } from "pages/Settings/settingsService";
import { TenderFilesParameters } from "pages/TenderInformationContent/tenderFilesParameters";
import { useGlobalStore } from "global-store/useGlobalStore";
import parse from "html-react-parser";
import moment from "moment";
import { MuiChipsInput } from "mui-chips-input";
import { useEffect, useState } from "react";
import { dateTimeFormat } from "utils/common";
import { sendTenderMail } from "./tenderManagementService";

const isISODate = (date) => {
  return moment(date, moment.ISO_8601, true).isValid();
};
const formatISODate = (date) => {
  return moment(date, moment.ISO_8601, true).format(dateTimeFormat);
};

const SendTenderByEmailForm = ({
  open,
  onClose,
  tender,
  emailData = null,
  managingTenderData = null,
}) => {
  const [email, setEmail] = useState({
    Subject: "",
    NotificationRecipients: "",
    Message: "",
    Files: [],
    Sellers: [],
    active: true,
    previousEmailContent: null,
  });
  const [showPreviousEmail, setShowPreviousEmail] = useState(false);
  const {
    setSnackbar,
    sellers,
    showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner,
    fetchTenderSentEmails,
    setShouldRefetch,
  } = useGlobalStore((state) => ({
    setSnackbar: state.setSnackbar,
    sellers: state.sellers,
    showLoadingStateSpinnerNoTimeout: state.showLoadingStateSpinnerNoTimeout,
    hideLoadingSpinner: state.hideLoadingSpinner,
    fetchTenderSentEmails: state.fetchTenderSentEmails,
    setShouldRefetch: state.setShouldRefetch,
  }));

  const [defaultSubject, setDefaultSubject] = useState("");
  const loadDefaultSubject = async () => {
    const response = await listCompanySettings();
    if (!response.data.subject) {
      setDefaultSubject("");
      return;
    }
    let subject = response.data.subject.split(",");
    subject = subject.map((item) => {
      if (isISODate(tender[item])) {
        return formatISODate(tender[item]);
      }
      return tender[item];
    });
    subject = subject.join(" | ");
    subject += "] ";
    let finalSubject = "[" + subject;
    setDefaultSubject(finalSubject);
  };

  const handleClose = () => {
    setEmail({
      Subject: "",
      NotificationRecipients: "",
      Message: "",
      Files: [],
      Sellers: [],
      active: true,
      previousEmailContent: null,
    });
    setShowPreviousEmail(false);
    onClose();
  };

  const loadEmailData = () => {
    if (emailData !== null) {
      setEmail(emailData);
    }
  };

  const loadSellers = () => {
    // load sellers if any. from managingTenderData or tender
    const foundSellers =
      (managingTenderData?.assignments?.sellers?.length > 0 && [
        ...managingTenderData.assignments.sellers,
      ]) ||
      (tender?.sellers?.length > 0 && [
        ...tender.sellers.map((seller) =>
          sellers.find((s) => s.sellerId === seller.id),
        ),
      ]) ||
      [];
    setEmail({ ...email, Sellers: foundSellers });
  };

  useEffect(() => {
    if (open) {
      loadEmailData();
      loadDefaultSubject();
      loadSellers();
    }
  }, [open]);

  const viewAttachedEmailContent = () => {
    if (email.previousEmailContent && !showPreviousEmail) {
      setShowPreviousEmail(true);
    } else {
      setShowPreviousEmail(false);
    }
  };

  const validateFileSize = (file) => {
    if (file.size > TenderFilesParameters.MaxFileSize10MB) {
      const message = `El archivo ${file.name} no puede superar el tamaño maximo permitido de 10 MB!`;
      setSnackbar({
        open: true,
        title: "Error",
        subTitle: message,
        severity: "error",
      });
      return false;
    }
    return true;
  };

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;
    const _file = {
      FileName: event.target.files[0].name,
    };
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      _file.FileContent = e.target.result.split(",")[1];
      setEmail({ ...email, Files: [...email.Files, _file], active: false });
    };
  };

  const removeFile = (index) => {
    const files = [...email.Files];
    files.splice(index, 1);
    setEmail({ ...email, Files: files });
  };

  const handleSave = async () => {
    showLoadingStateSpinnerNoTimeout("Enviando licitación...");
    const request = {
      Subject: `${defaultSubject}${email.Subject}`,
      Title: "",
      Message: attachPreviousEmailContent(),
      NotificationRecipients: email.NotificationRecipients,
      SellerIds: email.Sellers.map((item) => item.sellerId),
      ChannelId: 1,
      NotificationTypeId: 1,
      TenderNumber: tender.tenderNumber,
      TenderIds: [tender.tenderId],
      Files: email.Files,
    };

    if (tender.onDemandNotificationId) {
      request.onDemandNotificationId = tender.onDemandNotificationId;
    }

    await sendTenderMail(request);
    setSnackbar({
      open: true,
      title: "Éxito",
      subTitle: "La licitación se envió correctamente.",
      severity: "success",
    });
    fetchTenderSentEmails();
    hideLoadingSpinner();
    setShouldRefetch();
    handleClose();
  };

  const attachPreviousEmailContent = () => {
    if (email.previousEmailContent) {
      return (
        email.Message +
        '<hr style="margin: 15px 0px 15px 0px;">\n' +
        email.previousEmailContent
      );
    }
    return email.Message;
  };

  return (
    <GeneralPurposeModal
      open={open}
      onClose={handleClose}
      title="Enviar licitación vía email"
      closeText="Cancelar"
      saveText="Enviar"
      maxWidth="md"
      onSave={handleSave}
      onSaveDisabled={
        !email.NotificationRecipients.length && !email.Sellers.length
      }>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="Subject"
            name="Subject"
            label="Asunto"
            variant="outlined"
            fullWidth
            value={email.Subject}
            onChange={(e) => setEmail({ ...email, Subject: e.target.value })}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="Sellers"
            options={sellers}
            value={email.Sellers}
            getOptionLabel={(option) =>
              `${option.lastName}, ${option.firstName}`
            }
            onChange={(e, value) => setEmail({ ...email, Sellers: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ejecutivos"
                variant="outlined"
                required={email.NotificationRecipients.length === 0}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiChipsInput
            id="NotificationRecipients"
            value={email.NotificationRecipients}
            variant="outlined"
            label="Emails"
            required={email.Sellers.length === 0}
            placeholder="Ingrese un email"
            fullWidth
            onChange={(value) => {
              setEmail({ ...email, NotificationRecipients: value });
            }}
            validate={(chipValue) => {
              return {
                isError: !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(chipValue),
                textError: "Ingrese un email válido",
              };
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextEditor
            onChange={(value) => setEmail({ ...email, Message: value })}
          />
        </Grid>

        <Grid item xs={2}>
          <input
            accept="image/*,.pdf,.doc,.docx"
            style={{ display: "none" }}
            id="upload-button-file-send-email"
            type="file"
            onInput={(e) => {
              handleFileUpload(e);
            }}
            onClick={(event) => {
              if (email.Files.length >= TenderFilesParameters.MaxFiles) {
                event.preventDefault(); // Prevents opening the upload file dialog
                return;
              }
              event.target.value = null;
            }}
          />
          <Tooltip id="upload-button-file-send-email" title="Adjuntar">
            <label htmlFor="upload-button-file-send-email">
              <Button
                variant="contained"
                color="secondary"
                disabled={email.Files.length >= TenderFilesParameters.MaxFiles}
                aria-label="upload picture"
                size="small"
                component="span"
                startIcon={<UploadFileIcon />}>
                Adjuntar
              </Button>
            </label>
          </Tooltip>
        </Grid>
        <Grid item xs={10}>
          {email.Files.length > 0 && (
            <Box
              sx={{ justifyContent: "center", float: "left" }}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              gap={0.5}>
              {email.Files.map((value, index) => (
                <Chip
                  key={index}
                  icon={<AttachFileIcon />}
                  label={value.FileName}
                  onDelete={() => removeFile(index)}
                  variant="outlined"
                />
              ))}
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: email.previousEmailContent ? "block" : "none" }}>
          <Button
            variant="contained"
            color="secondary"
            aria-label="upload picture"
            size="small"
            onClick={viewAttachedEmailContent}
            startIcon={<AttachEmailIcon />}>
            {showPreviousEmail ? "Ocultar" : "Mostrar"} email adjunto
          </Button>
          {showPreviousEmail && email.previousEmailContent && (
            <div>{parse(email.previousEmailContent)}</div>
          )}
        </Grid>
      </Grid>
    </GeneralPurposeModal>
  );
};

export default SendTenderByEmailForm;
