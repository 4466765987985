import ModalCRUD from "./ModalCRUD";
import {
  addOrUpdateDeleteReason,
  deleteDeleteReason,
  getDeleteReasons,
} from "./administrationServices";

const columns = [
  { accessorKey: "description", header: "Descripción", size: 375 },
];

const messages = {
  saveSuccess: "El motivo de eliminación se almacenó correctamente.",
  deleteSuccess: "El motivo de eliminación se eliminó correctamente.",
  missingData: "Por favor ingrese el nombre del motivo de eliminación.",
  cannotDelete:
    "El motivo de eliminación no puede ser eliminado porque está siendo utilizado.",
  deleteConfirmation: (item) =>
    `¿Está seguro que desea eliminar el motivo de eliminación ${item?.description}?`,
};

const DeleteReasonModal = ({ open, onClose }) => {
  const saveVerification = (data) => {
    return data.description;
  };

  const handleDelete = async (item) => {
    const request = [{ deleteReasonId: item.deleteReasonId }];
    await deleteDeleteReason(request);
  };

  const handleSave = async (data) => {
    const request = [data];
    await addOrUpdateDeleteReason(request);
  };

  return (
    <ModalCRUD
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      columns={columns}
      tableInfo={{
        id: "gridDeleteReasonModal",
        header: "Motivos de eliminación",
      }}
      modalTitle={"Motivos de eliminación"}
      fetchFunction={getDeleteReasons}
      saveVerification={saveVerification}
      messages={messages}
    />
  );
};

export default DeleteReasonModal;
