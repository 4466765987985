import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function EngagementData(props) {
  return (
    <Card className="card-box text-black-50 bg-secondary p-2">
      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información de la contratación"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {props.allData.engagement?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}>
                    {row.name}
                  </TableCell>
                  <TableCell>{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Información de usuarios"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.users?.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.allData.usersData?.map((row) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={row.id}>
                  {row.data.map((row2) => (
                    <TableCell key={row2}>{row2}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3 className="font-weight-bold font-size-md text-uppercase p-2">
          {"Archivo Adjunto"}
        </h3>
        <TableContainer
          component={Paper}
          className="card-box text-black-50 bg-secondary">
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.attachments?.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.allData.attachmentsData?.map((row) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={row.id}>
                  {row.data.map((row2) => (
                    <TableCell key={row2}>{row2}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
