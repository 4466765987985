import { Fragment, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  Menu,
} from "@mui/material";
import { styled } from "@mui/system";
import { useGlobalStore } from "global-store/useGlobalStore";
import useIsWidthUp from "utils/hooks/useIsWidthUp";

const StyledBadge = styled(Badge)(({ theme }) => ({
  badge: {
    backgroundColor: "var(--success)",
    color: "var(--success)",
    boxShadow: "0 0 0 2px #fff",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function HeaderUserbox({
  userNameInfo,
  loginPending,
  fullName,
  companyName,
  logout,
}) {
  const { userInfo } = useGlobalStore((state) => ({
    userInfo: state.userInfo,
  }));
  const appInsights = useAppInsightsContext();
  const isWidthUp = useIsWidthUp("md");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot">
            <Avatar sx={{ width: 32, height: 32 }}>
              <AccountCircleIcon sx={{ width: 32, height: 32 }} />
            </Avatar>
            <Box
              sx={{
                alignSelf: "center",
                px: 1.5,
                fontSize: "0.90rem",
                alignContent: "center",
              }}>
              {!!loginPending || userNameInfo.length === 0 ? (
                <CircularProgress
                  sx={{ mx: 2, alignSelf: "center", mt: "0.5em" }}
                  size={"1.5rem"}
                />
              ) : (
                isWidthUp && userNameInfo
              )}
            </Box>
          </StyledBadge>
        </Box>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={["fas", "angle-down"]} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-2">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0 pb-0">
            <Box>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot">
                <Avatar sx={{ width: 32, height: 32 }}>
                  <AccountCircleIcon sx={{ width: 32, height: 32 }} />
                </Avatar>
              </StyledBadge>
            </Box>
            <div className="pt-2">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {fullName}
              </div>
              <span className="text-black-50 text-center">{companyName}</span>
            </div>
            <Divider className="w-100 mt-2 mb-2" />
            {/* <ListItemButton
              className="justify-content-center"
              sx={{ width: "100%" }}>
              <FontAwesomeIcon
                icon={["far", "user"]}
                className="nav-icon-wrapper text-primary mr-2"
              />
              Perfil
            </ListItemButton> */}
            <ListItemButton
              className="justify-content-center"
              sx={{ width: "100%" }}
              onClick={() => logout(userInfo, appInsights, null)}>
              <LogoutIcon
                className="nav-icon-wrapper text-primary mr-2"
                fontSize="small"
              />
              Salir
            </ListItemButton>
            {/* <Divider className="w-100" />
            <ListItem className="p-0">
              <div className="grid-menu grid-menu-2col w-100">
                <div className="py-3">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-center">
                      <div>
                        <FontAwesomeIcon
                          icon={["far", "chart-bar"]}
                          className="font-size-xxl text-info"
                        />
                      </div>
                      <div className="pl-3 line-height-sm">
                        <b className="font-size-lg">$9,693</b>
                        <span className="text-black-50 d-block">revenue</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Tooltip arrow title="Facebook">
                <Button className="text-facebook">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={["fab", "facebook"]} />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip arrow title="Dribbble">
                <Button className="text-dribbble mr-2 ml-2">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={["fab", "dribbble"]} />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip arrow title="Twitter">
                <Button className="text-twitter">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={["fab", "twitter"]} />
                  </span>
                </Button>
              </Tooltip>
            </ListItem> */}
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
