import {
  Add,
  ArrowDropDown,
  FilterAlt,
  FilterAltOff,
  Info,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TenderManagementFilters() {
  const {
    manageFilters,
    setManageFilters,
    resetAppliedManageFilters,
    areManageFiltersApplied,
    setShouldRefetch,
  } = useGlobalStore((state) => ({
    manageFilters: state.manageFilters,
    setManageFilters: state.setManageFilters,
    resetAppliedManageFilters: state.resetAppliedManageFilters,
    areManageFiltersApplied: state.areManageFiltersApplied,
    setShouldRefetch: state.setShouldRefetch,
  }));
  const { filters: appliedFilters } = manageFilters;

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleChange = (event) => {
    setManageFilters({
      [event.target.name]: event.target.value,
    });
  };

  const areFiltersApplied = areManageFiltersApplied();

  return (
    <>
      <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 2 }} />
      <Badge color="primary" invisible={!areFiltersApplied} variant="dot">
        <Button
          color="secondary"
          size="small"
          onClick={handleClickMenu}
          startIcon={<FilterAlt sx={{ mr: 1 }} />}
          endIcon={<ArrowDropDown sx={{ ml: 2 }} />}>
          <span style={{ fontSize: "0.86rem" }}>Filtros</span>
        </Button>
      </Badge>

      <Menu
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Box className="dropdown-menu-xl">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ pb: 1.5, pt: 1, px: 1 }}>
            <Typography
              className="text-black  px-3 font-weight-bold"
              component="div">
              Filtros
            </Typography>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              startIcon={<FilterAltOff />}
              onClick={() => {
                resetAppliedManageFilters();
                setShouldRefetch();
              }}>
              Limpiar
            </Button>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={setShouldRefetch}>
              Aplicar
            </Button>
          </Stack>
          <Divider sx={{ mb: 1.5 }} />

          <Stack
            direction="column"
            spacing={3}
            alignItems="center"
            sx={{ pb: 2 }}>
            <FormControl sx={{ width: 240 }}>
              <Select
                multiple
                displayEmpty
                value={appliedFilters.sellers}
                onChange={handleChange}
                size="small"
                autoWidth
                name="sellers"
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) return <em>Ejecutivos</em>;
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            manageFilters.sellers.find(
                              (item) => item.id === value,
                            )?.name
                          }
                          size="small"
                        />
                      ))}
                    </Box>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem disabled value="">
                  <em>Ejecutivos</em>
                  <Divider />
                </MenuItem>
                {manageFilters.sellers.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 240 }}>
              <Select
                multiple
                displayEmpty
                value={appliedFilters.tags}
                onChange={handleChange}
                size="small"
                autoWidth
                name="tags"
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) return <em>Etiquetas</em>;
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} size="small" />
                      ))}
                    </Box>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem disabled value="">
                  <em>Etiquetas</em>
                </MenuItem>
                {manageFilters.tags.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 240 }}>
              <Select
                multiple
                displayEmpty
                value={appliedFilters.manageInternalStatus}
                onChange={handleChange}
                size="small"
                autoWidth
                name="manageInternalStatus"
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) return <em>Situaciones</em>;
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            manageFilters.manageInternalStatus.find(
                              (item) => item.manageInternalStatusId === value,
                            )?.description
                          }
                          size="small"
                        />
                      ))}
                    </Box>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem disabled value="">
                  <em>Situaciones</em>
                </MenuItem>
                {manageFilters.manageInternalStatus.map((value) => (
                  <MenuItem
                    key={value.manageInternalStatusId}
                    value={value.manageInternalStatusId}>
                    {value.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 240 }}>
              <Select
                multiple
                displayEmpty
                value={appliedFilters.sources}
                onChange={handleChange}
                size="small"
                autoWidth
                name="sources"
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) return <em>Portales</em>;
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            manageFilters.sources.find(
                              (item) => item.id === value,
                            )?.name
                          }
                          size="small"
                        />
                      ))}
                    </Box>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem disabled value="">
                  <em>Portales</em>
                </MenuItem>
                {manageFilters.sources.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 240 }}>
              <Select
                multiple
                displayEmpty
                value={appliedFilters.users}
                onChange={handleChange}
                size="small"
                autoWidth
                name="users"
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) return <em>Usuarios</em>;
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            manageFilters.users.find(
                              (item) => item.userId === value,
                            )?.firstName +
                            " " +
                            manageFilters.users.find(
                              (item) => item.userId === value,
                            )?.lastName
                          }
                          size="small"
                        />
                      ))}
                    </Box>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem disabled value="">
                  <em>Usuarios</em>
                </MenuItem>
                {manageFilters.users.map((value) => (
                  <MenuItem key={value.userId} value={value.userId}>
                    {value.firstName} {value.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      </Menu>
    </>
  );
}
