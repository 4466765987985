import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import DeleteDialog from "components/ModalDialogs/DeleteDialog";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment, useState } from "react";

export const SanctionsHeader = (penaltyReasonsList) => [
  {
    accessorKey: "agreedDeliveryDate",
    header: "Fecha de entrega pactada",
    size: "100",
    align: "center",
    cellType: "date",
    editType: "date",
  },
  {
    accessorKey: "actualDeliveryDate",
    header: "Fecha entrega efectiva",
    size: "100",
    align: "center",
    cellType: "date",
    editType: "date",
  },
  {
    accessorKey: "daysLate",
    header: "Días de atraso",
    size: "100",
    align: "center",
    editType: "numeric",
  },
  {
    accessorKey: "finePercentage",
    header: "Multa (%)",
    size: "100",
    align: "center",
    editType: "numeric",
  },
  {
    accessorKey: "others",
    header: "Otros",
    size: "100",
    align: "center",
  },
  {
    accessorKey: "penaltyReasonId",
    header: "Resultado",
    size: "120",
    editType: "select",
    editOptions: penaltyReasonsList,
    Cell: ({ cell }) => {
      return penaltyReasonsList?.find(
        (penaltyReason) => penaltyReason.id === cell.getValue(),
      )?.name;
    },
  },
];
/*manageTenderData --> se le carga manageTenderData.penalties de ListPenalties (filtrado por tenderId)

En la tabla se puede ABM, modifica manageTenderData.penalties

Si saco necesito saber cual saque para el id y hacer el delete.

Iniciar con manageTenderData.initialPenalties y manageTenderData.penalties, luego comparar.
*/

const Sanctions = ({
  managingTenderData,
  handleManagingTenderData,
  tenderManageData,
}) => {
  const { height, userInfo, setShouldRefetch, setSnackbar } = useGlobalStore(
    (state) => ({
      height: state.height,
      userInfo: state.userInfo,
      setShouldRefetch: state.setShouldRefetch,
      setSnackbar: state.setSnackbar,
    }),
  );
  const fetchData = async (pageNumber, pageSize, filter, sorting) => {
    return {
      result: managingTenderData.penalties,
      total: managingTenderData.penalties.length,
    };
  };

  const handleSave = (row, exitEditingMode) => {
    if (!row.penaltyReasonId) {
      setSnackbar({
        open: true,
        title: "Error",
        subTitle: "Debe seleccionar un resultado",
        severity: "error",
      });
      return false;
    }
    let penaltyModified = managingTenderData.penalties.find(
      (penalty) => penalty.penaltyId === row.penaltyId,
    );
    let penaltiesCopy = [...managingTenderData.penalties];
    if (penaltyModified) {
      // Update the penalties list with the new modified row
      penaltiesCopy = penaltiesCopy.map((penalty) => {
        if (penalty.penaltyId === row.penaltyId) return row;
        return penalty;
      });
    } else {
      // Add the new row to the penalties list
      penaltiesCopy = [row, ...penaltiesCopy];
    }
    handleManagingTenderData({
      ...managingTenderData,
      penalties: penaltiesCopy,
    });
    exitEditingMode();
    setShouldRefetch();
    return false;
  };

  const [deletePenaltyOpen, setDeletePenaltyOpen] = useState(false);
  const [penaltyToDelete, setPenaltyToDelete] = useState({
    penalty: null,
    exitEditingMode: null,
  });

  const handleDelete = () => {
    let penaltiesCopy = [...managingTenderData.penalties];
    penaltiesCopy = penaltiesCopy.filter(
      (penalty) => penalty.penaltyId !== penaltyToDelete.penalty.penaltyId,
    );
    handleManagingTenderData({
      ...managingTenderData,
      penalties: penaltiesCopy,
    });
    penaltyToDelete.exitEditingMode();
    setShouldRefetch();
    return false;
  };

  const options = {
    tableId: "gridManagementSanctions",
    header: SanctionsHeader(managingTenderData?.penaltyReasons),
    height: height - 217, // Aribitrary height to fit exactly the content
    tableHeaderTitle: "Sanciones",
    toolbarButtons: {
      Add: true,
    },
    enableEditing: true,
    enableDeleting: true,
    deleteFunc: (row, exitEditingMode) => {
      setPenaltyToDelete({ penalty: row, exitEditingMode });
      setDeletePenaltyOpen(true);
    },
    saveFunc: handleSave,
  };

  return (
    <Fragment>
      <FSTMaterialReactTable getData={fetchData} options={options} />
      <DeleteDialog
        open={deletePenaltyOpen}
        onClose={() => {
          setDeletePenaltyOpen(false);
          setPenaltyToDelete({ penalty: null, exitEditingMode: null });
        }}
        onDelete={handleDelete}
        title="Eliminar sanción"
        subTitle="¿Está seguro que desea eliminar la sanción?"
      />
    </Fragment>
  );
};

export default Sanctions;
