import { Grid, Card, Skeleton, Tooltip } from "@mui/material";
import { useGlobalStore } from "global-store/useGlobalStore";
import { LangContext } from "intl/context/langContext";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { formatShortDate } from "utils/common";

const defaultTenderInfoData = [
  {
    id: "text.Nomenclatura",
    value: "fileNumber",
    xs: 12,
    sm: 6,
    md: 4,
  },
  {
    id: "text.Fecha_Publicacion",
    value: "publishedDate",
    type: "date",
    xs: 12,
    sm: 6,
    md: 2,
  },
  {
    id: "text.Convocatoria",
    value: "tenderNumber",
    xs: 12,
    sm: 6,
    md: 3,
  },
  {
    id: "text.Tipo_Compra",
    value: "tenderTypeDesc",
    xs: 12,
    sm: 6,
    md: 3,
  },
  {
    id: "text.Entidad",
    value: "clientUnitName",
    xs: 12,
    sm: 12,
    md: 4,
  },
  {
    id: "text.Descripcion",
    value: "tenderName",
    xs: 12,
    sm: 12,
    md: 8,
  },
];

const TenderGeneralInfo = ({ tenderData, finishedLoading }) => {
  const intl = useIntl();
  const langChange = useContext(LangContext);
  const intlTenderInfo = langChange.configForm["TenderGeneralInfo"];
  const intlConfigForm = useGlobalStore((state) => state.intlConfigForm);

  const getValue = (id, type, template) => {
    if (template) return template(tenderData);

    let value = tenderData[id];
    if (type === "date") {
      value = formatShortDate(tenderData[id]);
    }
    return value;
  };

  const finalTenderInfoData = intlTenderInfo
    ? intlTenderInfo.elements
    : defaultTenderInfoData;

  return (
    <div className="hero-wrapper bg-composed-wrapper h-100">
      <div className="flex-grow-1 w-100 d-flex align-items-center">
        <div className="bg-composed-wrapper--bg opacity-5" />
        <div className="bg-composed-wrapper--content p-3">
          <Grid container spacing={2}>
            {!finishedLoading ? (
              <Grid item xs>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Grid>
            ) : (
              <>
                {finalTenderInfoData.map((item, i) => (
                  <Grid
                    item
                    xs={item.xs}
                    sm={item.sm}
                    md={item.md}
                    key={`${item.id}`}>
                    <Card className="card-box text-black-50 bg-secondary p-2">
                      <div
                        className="font-weight-bold font-size-sm text-uppercase"
                        style={{
                          textWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        title={intl.formatMessage({
                          id: item.id,
                          defaultMessage: "N/A",
                        })}>
                        {intl.formatMessage({
                          id: item.id,
                          defaultMessage: "N/A",
                        })}
                      </div>
                      <Tooltip
                        title={getValue(item.value, item.type, item.template)}>
                        <div
                          className="font-size-sm"
                          style={{
                            textWrap: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}>
                          {getValue(item.value, item.type, item.template) ||
                            "-"}
                        </div>
                      </Tooltip>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default TenderGeneralInfo;
