import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { styled } from "@mui/system";
import { useGlobalStore } from "global-store/useGlobalStore";
import { Fragment } from "react";
import { formatDate } from "utils/common";
import { getEmailData } from "./tenderManagementService";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "white",
  marginTop: "-0.5rem",
  marginLeft: "-1rem",
  marginRight: "-1rem",
  marginBottom: "-0.5rem",
  padding: "0.25rem",
  maxHeight: 300,
  borderRadius: "0.5rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    WebkitAppearance: "none",
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "8px",
  },
  "&:hover::-webkit-scrollbar": {
    WebkitAppearance: "none",
    width: "10px",
  },
  "&:hover::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    WebkitBoxShadow: "0 0 1px rgba(0, 0, 0, 0.205)",
  },
  "&:hover::-webkit-scrollbar-track": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "8px",
  },
}));

const SentEmailsDetails = ({ sentTenders, tender, resendEmail }) => {
  const { showLoadingStateSpinner, sellers } = useGlobalStore((state) => ({
    showLoadingStateSpinner: state.showLoadingStateSpinner,
    sellers: state.sellers,
  }));
  const getEtqTo = (recipients, seller) => {
    const size = recipients.length + seller.length;
    const etq = seller.length > 0 ? seller[0] : recipients[0];
    return etq + (size > 1 ? ", +" + (size - 1) : "");
  };

  const handleResendEmail = async (event, tenderData) => {
    event.stopPropagation(); // prevent the row from being selected
    showLoadingStateSpinner();
    const previousEmailData = await getEmailData(
      tenderData.onDemandNotificationId,
    );

    let matchedSellers = [];
    sellers.forEach((item) => {
      const value = previousEmailData.data.sellerIds.find(
        (ind) => ind === item.sellerId,
      );
      if (value) {
        matchedSellers.push(item);
      }
    });

    const formattedEmailData = {
      Subject: previousEmailData.data.subject,
      NotificationRecipients: previousEmailData.data.recipients,
      Message: "",
      Files: [],
      Sellers: matchedSellers,
      previousEmailContent:
        `<p  style="margin: 20px 0px 0px 15px;" >Último envío: ${formatDate(
          tenderData.sentDate,
        )}  -  ${previousEmailData.data.recipients}</p>\n` +
        previousEmailData.data.body,
      active: true,
    };

    const tenderExtraInfo = {
      ...tender,
      onDemandNotificationId: parseInt(tenderData.onDemandNotificationId),
    };

    resendEmail(tenderExtraInfo, formattedEmailData);
  };

  return (
    <StyledCard>
      <CardHeader
        sx={{
          p: "0.5rem 1rem",
          display: "flex",
          width: 200,
        }}
        title={
          <div style={{ fontSize: "0.8rem" }}>
            <span>Detalle Enviados</span>
            <span style={{ float: "right" }}>{sentTenders.length}</span>
          </div>
        }
      />
      {sentTenders.map((item, idx) => (
        <Fragment key={`${tender.tenderId}-${idx}`}>
          <Divider color="white" />
          <CardContent
            sx={{
              p: "0.5rem 1rem",
              display: "flex",
              width: 200,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.3)",
              },
            }}
            onClick={(e) => handleResendEmail(e, item)}>
            <div style={{ fontSize: "0.75rem" }}>
              <div>Para: {getEtqTo(item.recipients, item.sellerNames)}</div>
              <div>Fecha: {formatDate(item.sentDate)}</div>
              <div>Envíos: {item.sentCount}</div>
            </div>
          </CardContent>
        </Fragment>
      ))}
    </StyledCard>
  );
};

export default SentEmailsDetails;
