import { getSources } from "pages/SmartSearch/smartSearchService";

const initialState = {
  sources: [],
};

// TODO: Move to react query and make all pages use this getSources
const useAppDataStore = (set) => ({
  appData: initialState,
  fetchAppData: async () => {
    const [sources] = await Promise.all([getSources()]);
    set({
      appData: {
        sources: sources.data,
      },
    });
  },
});

export default useAppDataStore;
