import { Checkbox, Tooltip } from "@mui/material";
import moment from "moment";
import { dateTimeFormat, dateTimeShortFormat } from "utils/common";

const CheckboxCell = ({ cell, column }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Checkbox checked={cell.getValue()} disabled sx={{ p: 0 }} />
    </div>
  );
};

const ActiveInactiveCell = ({ cell, column }) => {
  const value = cell.getValue();
  // Render a chip with 'activo' or 'inactivo' depending on the value, change color depending on the value
  return (
    <div className="d-flex align-items-center justify-content-center">
      <span
        className={`btn-pill badge  
          ${value ? "badge-success" : "badge-danger"}`}>
        {value ? "Activo" : "Inactivo"}
      </span>
    </div>
  );
};

const TooltipCell = (props) => {
  let { children } = props;

  return (
    <Tooltip title={children ? children : ""}>
      <span>{children ? children : ""}</span>
    </Tooltip>
  );
};

export const getCellComponent = (
  type,
  defaultCell,
  disableTooltip,
  excelExportFormat,
) => {
  switch (type) {
    case "checkbox":
      return {
        Cell: ({ cell, column }) => (
          <CheckboxCell cell={cell} column={column} />
        ),
      };
    case "active-inactive":
      return {
        Cell: ({ cell, column }) => (
          <ActiveInactiveCell cell={cell} column={column} />
        ),
      };
    case "datetime":
      return {
        Cell: ({ cell, column }) => {
          if (cell.getValue() == null) return null; // Checks for null or undefined
          return (
            <TooltipCell>
              {moment(cell.getValue()).format(dateTimeFormat)}
            </TooltipCell>
          );
        },
      };
    case "date":
      return {
        Cell: ({ cell, column }) => {
          if (cell.getValue() == null) return null; // Checks for null or undefined
          return (
            <TooltipCell>
              {moment(cell.getValue()).format(dateTimeShortFormat)}
            </TooltipCell>
          );
        },
      };
    default: {
      let cellToShow = null;
      if (!defaultCell) {
        cellToShow = ({ cell, column }) => {
          if (cell.getValue() == null) return null; // Checks for null or undefined
          return cell.getValue();
        };
      } else {
        cellToShow = defaultCell;
      }

      return (
        {
          Cell: ({ cell, column }) =>
            disableTooltip ? (
              cellToShow({ cell, column })
            ) : (
              <TooltipCell>{cellToShow({ cell, column })}</TooltipCell>
            ),
          getDefaultCellComponent: cellToShow,
          excelExportFormat: excelExportFormat,
        } || {}
      );
    }
  }
};
