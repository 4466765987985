import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

const initialFormState = {
  login: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confPass: "",
  active: true,
  applicationModulesIds: [],
  isAdmin: false,
  roleId: 2,
};

const AddUserModal = ({ open, onClose, onSave, appModules }) => {
  const [form, setForm] = useState(initialFormState);
  const [emailError, setEmailError] = useState({ error: false, message: "" });
  const [invalidFormFields, setInvalidFormFields] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
    if (e.target.id === "email") {
      validateEmail(e.target.value);
    }
  };

  const handleClose = () => {
    setInvalidFormFields(false);
    setEmailError({ error: false, message: "" });
    setForm(initialFormState);
    onClose();
  };

  const handleSave = () => {
    if (
      !form.login.trim() ||
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.password.trim() ||
      !form.confPass.trim() ||
      form.applicationModulesIds.length === 0 ||
      emailError.error ||
      form.password !== form.confPass
    ) {
      setInvalidFormFields(true);
      return;
    }
    onSave(form);
    handleClose();
  };

  const validateEmail = (email) => {
    if (email.trim()) {
      let emailValidity = !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
      setEmailError({
        error: emailValidity,
        message: emailValidity ? "Email inválido" : "",
      });
      return emailValidity;
    }
  };

  // TODO: map textfields to not repeat code
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <form>
        <DialogTitle id="form-dialog-title" sx={{ fontSize: 18 }}>
          Añadir Usuario
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Complete todos los campos para añadir un nuevo usuario.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="dense"
            id="login"
            label="Usuario"
            fullWidth
            size="small"
            required
            value={form.login}
            onChange={handleChange}
            autoComplete="username"
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="firstName"
            label="Nombre"
            fullWidth
            size="small"
            required
            value={form.firstName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="lastName"
            label="Apellido"
            fullWidth
            size="small"
            required
            value={form.lastName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            size="small"
            required
            type="email"
            value={form.email}
            onChange={handleChange}
            error={emailError.error}
            helperText={emailError.message}
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="password"
            label="Contraseña"
            fullWidth
            size="small"
            required
            type="password"
            value={form.password}
            onChange={handleChange}
            autoComplete="new-password"
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="confPass"
            label="Confirmar Contraseña"
            fullWidth
            size="small"
            required
            type="password"
            value={form.confPass}
            onChange={handleChange}
            autoComplete="new-password"
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="applicationModulesIds"
            label="Módulos"
            fullWidth
            size="small"
            required
            value={form.applicationModulesIds}
            onChange={(e) => {
              setForm({ ...form, applicationModulesIds: e.target.value });
            }}
            select
            SelectProps={{
              multiple: true,
              id: "applicationModulesIds",
              renderValue: (selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        appModules?.find((option) => option.id === value).name
                      }
                    />
                  ))}
                </Box>
              ),
            }}>
            {appModules?.map((option, idx) => (
              <MenuItem key={idx} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isAdmin"
                    checked={form.isAdmin}
                    onChange={(e) => {
                      setForm({ ...form, isAdmin: e.target.checked });
                    }}
                    color="secondary"
                  />
                }
                label="Administrador"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="active"
                    checked={form.active}
                    onChange={(e) => {
                      setForm({ ...form, active: e.target.checked });
                    }}
                    color="secondary"
                  />
                }
                label="Activo"
              />
            </Grid>
          </Grid>
          {invalidFormFields && (
            <DialogContentText
              sx={{
                mt: 2,
                bgcolor: `rgba(248, 50, 69, 0.5)`,
                p: 2,
                borderRadius: 1,
                fontSize: 14,
                fontWeight: 500,
              }}>
              Campos faltantes o inválidos. Complete los campos requeridos.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            type="submit">
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserModal;
