import { useContext } from "react";
import { LangContext } from "../../context/langContext";
import { useIntl } from "react-intl";

const useGetFormConf = (id) => {
  const langChange = useContext(LangContext);
  const intl = useIntl();

  const validateData = (item) => {
    let value = item;
    if (Object.keys(item).some((key) => key === "id_fm")) {
      value.label = intl.formatMessage({
        id: item.id_fm,
        defaultMessage: item.intl_efault,
      });
    }
    return value;
  };

  const GetFormConf = () => {
    const filter = langChange.configForm[id];
    let data = [];
    filter.component.map((item) => {
      data.push(validateData(item));
    });
    return data;
  };

  const getSection = () => {
    const filter = langChange.configForm[id];
    return filter.data.section ? filter.data.section : "0";
  };

  const getDataUse = (data) => {
    let name = "";
    const filter = langChange.configForm[id];
    if (Object.keys(filter).some((key) => key === "listData")) {
      name = filter.listData;
    }
    if (name) {
      const dataAux = langChange.configForm[name];
      if (dataAux.hasOwnProperty("render")) {
        const result = dataAux.render({ ...dataAux.state, data });
        return result;
      }
    }
    return data;
  };

  return {
    GetFormConf,
    getSection,
    getDataUse,
  };
};

export default useGetFormConf;
