import { Box } from "@mui/material";
import FSTMaterialReactTable from "components/MaterialReactTable/MaterialReactTable";
import { pacHeader } from "./pacHeader";

function PACResults({ fetchAllData, handleExport }) {
  const options = {
    tableId: "gridPAC",
    header: pacHeader,
    fullHeight: true,
    intlHeaderId: "app.columnsPAC",
    tableHeaderTitle: "PAC",
    toolbarButtons: {
      Refresh: true,
      Export: true,
      ExportFunc: handleExport,
    },
  };

  return (
    <Box
      display="flex"
      flex={1}
      sx={{
        // These are needed to make the Box fill the parent container and not the viewport. Prevents overflow of table.
        width: "0px",
        maxWidth: "-webkit-fill-available",
      }}>
      <FSTMaterialReactTable options={options} getData={fetchAllData} />
    </Box>
  );
}

export default PACResults;
