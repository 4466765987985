import { Grid, Paper } from "@mui/material";
import { ClimbingBoxLoader } from "react-spinners";

export default function Loading() {
  const min = 1;
  const max = 10;
  const imgName = `background_${
    Math.floor(Math.random() * (+max - +min)) + +min
  }.jpg`;
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url("assets/${imgName}")`,
        backgroundSize: "100% 100%",
      }}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ minHeight: "100vh" }}>
        <Grid item xs={4} sx={{ minWidth: "20vw", textAlign: "center" }}>
          <Paper
            sx={{
              display: "inline-block",
              padding: "0.7em",
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,0.8)",
            }}>
            <div className="font-size-xl text-center pt-3">
              Falconsoft Tenders
              <span className="font-size-lg d-block text-dark">
                Espere mientras se carga la aplicación
              </span>
            </div>
            <div className="d-flex align-items-center flex-column px-4">
              <ClimbingBoxLoader color={"#5383ff"} loading={true} />
            </div>
            <p>{`Cargando...`}</p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
